/// <reference  types="@types/googlemaps"  />
import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { CommonService } from '../services/common.service';
import { JwtService } from '../services/jwt.service';
import { SecuredService } from '../services/secured.service';
import { TranslateService } from '@ngx-translate/core';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';

@Component({
  selector: 'app-booking',
  templateUrl: './booking.component.html',
  styleUrls: ['./booking.component.css']
})
export class BookingComponent implements OnInit {

  loading: boolean = true;
  bookingResultView: boolean = false;
  isDriver: Boolean = false;
  isCompany: Boolean = false;
  driver: any;
  company: any;
  id: number;
  bookings: any;
  hourly_rate: number;
  requested_status: boolean = false;
  accepted_status: boolean = false;
  rejected_status: boolean = false;

  @ViewChild('gmap') gmapElement:  any;

  constructor(private route: ActivatedRoute,
    private router: Router,
    private commonService: CommonService,
    private jwtService: JwtService,
    private securedService: SecuredService,
    private translate: TranslateService
    ) { }

  ngOnInit(): void {
  	if (this.jwtService.isDriver) {
    	this.isDriver = true;
    }else {
    	this.isCompany = true;
    }
  	this.route.params.subscribe(params => {
      let id = params['id'];
      this.id = id;
      this.loading = true;
      this.securedService.getBooking(id).subscribe(
        result => {
          this.bookings = result.booking;
          this.requested_status = this.bookings.some( booking => booking.booking_status === 'REQUESTED');
          
          if(this.isDriver) {
          	this.company = result.profile;
          } else {
          	this.driver = result.profile;
          }
          this.hourly_rate = result.hourly_rate;
          this.loading = false;
          
          const  myLatlng  =  {lat: parseFloat(this.bookings[0].location_latitude), lng: parseFloat(this.bookings[0].location_longitude)};

          const  mapOptions  = {
            zoom:  15,
            center:  myLatlng
          };
          const  map  =  new  google.maps.Map(this.gmapElement.nativeElement, mapOptions);
          const  marker  =  new  google.maps.Marker({
            position:  myLatlng,
            title:  this.bookings[0].location_address
          });
          marker.setMap(map);
        },
        error => {
          this.loading = false;
          if (error.status == 429 || error.status == 500) {
            alert(this.translate.instant('COMMON.500'));
          }
        }
      );
    });
  }

  rejectRow(booking) {
    if(confirm("Are you sure to reject for this date?")) {
      booking.booking_status = "REJECTED"
    }
  }

  acceptRow(booking) {
    booking.booking_status = "ACCEPTED"
  }

  changeRow(booking, e) {
    if(e.target.checked) {
      booking.booking_status = "ACCEPTED";
    } else {
      if(confirm("Are you sure to reject for this date?")) {
        booking.booking_status = "REJECTED";
      }
    }
  }

  changeRowReject(booking, e) {
    if(e.target.checked) {
      if(confirm("Are you sure to reject for this date?")) {
        booking.booking_status = "REJECTED";
      }
    } else {
      booking.booking_status = "ACCEPTED";
    }
  }

  confirmBooking() {
    this.loading = true;
    const formData = new FormData();
    for (var i in this.bookings) { 
      formData.append(this.bookings[i].id, (this.bookings[i].booking_status == "REQUESTED" ? "ACCEPTED" : this.bookings[i].booking_status));
    }

    this.securedService.confirmBooking(formData)
      .subscribe(result => {
        this.loading = false;
        this.requested_status = false;
        this.bookingResultView = true;
      },
        error => {
          this.loading = false;
          alert(error);
        }
      );
  }

}
