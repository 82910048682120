import { Component, OnInit, ChangeDetectorRef, ElementRef } from '@angular/core';
import { CommonService } from '../services/common.service';
import { SecuredService } from '../services/secured.service'
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { JwtService } from '../services/jwt.service';
import { Country } from '../interfaces/Country';
import { passValidator } from '../validator';
import * as $ from 'jquery';
import { Router } from '@angular/router';
import { DataSharingService } from '../services/data-sharing.service';
import * as Globals from '../global';
import { TranslateService } from '@ngx-translate/core';
import { HttpEvent, HttpEventType } from '@angular/common/http';

@Component({
  selector: 'app-become-driver',
  templateUrl: './become-driver.component.html',
  styleUrls: ['./become-driver.component.css']
})
export class BecomeDriverComponent implements OnInit {

  constructor(private ref: ChangeDetectorRef,
    private translate: TranslateService,

    public router: Router, private dataSharingService: DataSharingService, private securedService: SecuredService, private jwtService: JwtService, private fb: FormBuilder, private commonService: CommonService) {

    this.intSignup();

  }

  pricing_list: any;
  loading: Boolean = false;
  ltdDriver: Boolean = false;
  payeDriver: Boolean = false;
  showPriceSection: Boolean = false;
  hide_ltd_driver_type: Boolean = false;
  houly_rate:any;
  houly_rate_night:any
  driver_category:any;
  years_driving = Array(35).fill(0).map((x,i)=>i);
  totalSteps: Number = 4;
  currentStep: Number = 1;
  phoneVerification = {
    number: "",
    status: "NOT_VERIFIED",//NOT_VERIFIED,OTP_SENT,VERIFIED
    buttonLabel: "Verify",
    verfication_id: "",
    phonecode: ""
  }

  tuayform: FormGroup;
  licenceform: FormGroup;
  documentUploadForm: FormGroup;


  validationErrorsUserDetails = {
    years_driving: '',
    aboutme: '',
    previous_work: '',
    //hourly_rate: '',
    gender: '',
    dateofbirth: ''
  };

  resetValidationErrorsUserDetails() {
    this.validationErrorsUserDetails = {
      years_driving: '',
      aboutme: '',
      //hourly_rate: '',
      previous_work: '',
      gender: '',
      dateofbirth: ''
    };
  }
  ngOnInit(): void {
    if (this.jwtService.isDriver) {
      this.router.navigate(['profile']);
      return;
    }
    if (this.jwtService.isCompany) {
      this.router.navigate(['drivers']);
      return;
    }

    this.loading = true;

    this.commonService.getPricingDetail().subscribe(
      result => {
        this.pricing_list = result;
        this.loading = false;
      },
      error => {
        this.loading = false;
        if (error.status == 429 || error.status == 500) {
          alert(this.translate.instant('COMMON.500'));
        }
      }
    );

    var mainThis = this;
    $(document).ready(function () {
      $('#city-select2').select2({
        disabled: false,
        placeholder: 'City',
        minimumInputLength: 2,
        ajax: {
          url: function (params) {
            var country = 232;
            var url = Globals.API_ENDPOINT + 'general/city/' + country + '?';
            return url + params.term;
          },
          data: function (params) {
            var query = {
              search: params.term,
              type: 'public'
            }
            // Query parameters will be ?search=[term]&type=public
            return query;
          },
          processResults: function (data) {
            // Transforms the top-level key of the response object from 'items' to 'results'
            let resultsData: any = [];
            for (let i = 0; i < data.length; i++) {
              resultsData.push({ id: data[i].id, text: data[i].city });
            }
            return {
              results: resultsData
            };
          }
        }
      });
    });

    this.tuayform = this.fb.group({
      years_driving: new FormControl(null),
      aboutme: [''],
      previous_work: [''],
      //hourly_rate: ['10.5'],
      gender: [''],
      dateofbirth: [''],
    });

    this.licenceform = this.fb.group({
      licence_number: [''],
      check_code: ['']
    });

    this.documentUploadForm = this.fb.group({
      licence_front: [''],
      licence_back: [''],
      flt_certificate: [''],
      cpc_front: [''],
      cpc_back: [''],
      digicard_front: [''],
      digicard_back: ['']
    });
  }
  licence_front: any;
  licence_back: any;
  flt_certificate: any;
  cpc_front: any;
  cpc_back: any;
  digicard_front: any;
  digicard_back: any;
  fileUploadProgress: number = 0;
  onFileChange(event,file_type) {
    this[file_type] = event.target.files[0];
  }

  
  
  validateUserDetailsForm(): Boolean {

    this.resetValidationErrorsUserDetails();
    
    if (this.tuayform.controls.years_driving.value == null) {
      this.validationErrorsUserDetails.years_driving = this.translate.instant('Year of driving required');
    }
    if (!this.tuayform.controls.gender.value) {
      this.validationErrorsUserDetails.gender = this.translate.instant('BECOME_A_DRIVER.gender_required');
    }
    


    if (this.validationErrorsUserDetails.years_driving ||
      this.validationErrorsUserDetails.gender) {
      return false;
    }
    return true;
  }

  submitUserDetails() {
    if (!this.validateUserDetailsForm()) {
      return;
    }

    let body = JSON.stringify(
      {
        years_driving: this.tuayform.controls.years_driving.value,
        previous_work: this.tuayform.controls.previous_work.value,
        //hourly_rate: this.tuayform.controls.hourly_rate.value,
        gender: this.tuayform.controls.gender.value,
        dob: this.tuayform.controls.dateofbirth.value,
        aboutme: this.tuayform.controls.aboutme.value
      }
    );

    this.loading = true;
    this.securedService.updateDriverDetails(body)
      .subscribe(result => {
        this.loading = false;
        if(this.driver_category==6) {
          this.currentStep = 5;
        } else if(this.driver_category==5) {
          this.currentStep = 4;
        }  else {
          this.currentStep = 3;

        }
      },
        error => {
          this.loading = false;

          if (error.status == 422) {
            for (var i = 0; i < error.error.length; i++) {
              let element = error.error[i];
              if (element.field == 'years_driving') {
                this.validationErrorsUserDetails.years_driving = element.message;
              }
              if (element.field == 'gender') {
                this.validationErrorsUserDetails.gender = element.message;
              }
              /* if (element.field == 'hourly_rate') {
                this.validationErrorsUserDetails.hourly_rate = element.message;
              } */
              if (element.field == 'previous_work') {
                this.validationErrorsUserDetails.previous_work = element.message;
              }
              if (element.field == 'dob') {
                this.validationErrorsUserDetails.dateofbirth = element.message;
              }
              if (element.field == 'aboutme') {
                this.validationErrorsUserDetails.aboutme = element.message;
              }
            }
          }
          if (error.status == 429 || error.status == 500) {
            alert(this.translate.instant('COMMON.500'));
          }

        }
      );
  }

  //licence detail

  validationErrorsLicenceDetails = {
    licence_number: '',
    check_code: ''
  };

  resetValidationErrorsLicenceDetails() {
    this.validationErrorsLicenceDetails = {
      licence_number: '',
      check_code: ''
    };
  }

  validationErrorsDocumentUpload = {
    licence_front: '',
    licence_back: '',
    flt_certificate: '',
    cpc_front: '',
    cpc_back: '',
    digicard_front: '',
    digicard_back: ''
  };

  resetValidationErrorsDocumentUpload() {
    this.validationErrorsDocumentUpload = {
      licence_front: '',
      licence_back: '',
      flt_certificate: '',
      cpc_front: '',
      cpc_back: '',
      digicard_front: '',
      digicard_back: ''
    };
  }

  validateLicenceDetailsForm(): Boolean {
    this.resetValidationErrorsLicenceDetails();
    
    if (!this.licenceform.controls.licence_number.value) {
      this.validationErrorsLicenceDetails.licence_number = 'Last 8 characters of licence is required';
    }
    let remText = this.licenceform.controls.licence_number.value.replace(/\s/g, "")
    let licence_length = remText.length;
    if(licence_length!=8) {
      this.validationErrorsLicenceDetails.licence_number = 'Must have last 8 characters of licence';
    }
    if (!this.licenceform.controls.check_code.value) {
      this.validationErrorsLicenceDetails.check_code = 'Check code is required'
    }
    if (this.validationErrorsLicenceDetails.licence_number ||
      this.validationErrorsLicenceDetails.check_code) {
      return false;
    }
    return true;
  }


  validateDocumentUploadForm(): Boolean {

    this.resetValidationErrorsDocumentUpload();
    if(this.driver_category==5){
      if (!this.flt_certificate) {
        this.validationErrorsDocumentUpload.flt_certificate = 'FLT certificate is required';
        return false;
      }
      return this.checkUploadValid(this.flt_certificate, 'flt_certificate');
    }
    
    if(this.driver_category!=5 && this.driver_category!=6){
      if (this.licence_front) {
        let valid = this.checkUploadValid(this.licence_front, 'licence_front');
        if(!valid) {
          return false;
        }
      }
      if (this.licence_back) {
        let valid = this.checkUploadValid(this.licence_back, 'licence_back');
      }

      if(this.driver_category!=1){
        if (this.cpc_front) {
          let valid = this.checkUploadValid(this.cpc_front, 'cpc_front');
          if(!valid) {
            return false;
          }
        }
        if (this.cpc_back) {
          let valid = this.checkUploadValid(this.cpc_back, 'cpc_back');
          if(!valid) {
            return false;
          }
        }
        if (this.digicard_front) {
          let valid = this.checkUploadValid(this.digicard_front, 'digicard_front');
          if(!valid) {
            return false;
          }
        }
        if (this.digicard_back) {
          let valid = this.checkUploadValid(this.digicard_back, 'digicard_back');
          if(!valid) {
            return false;
          }
        }
      }
    }
    return true;
  }

  isAValidImageExtension(ext: any) {
    if (!ext) {
      return false;
    }
    if (ext == 'png' || ext == 'jpg' || ext == 'jpeg' || ext == 'PNG' || ext == 'JPG' || ext == 'JPEG' || ext == 'pdf' || ext == 'PDF') {
      return true;
    }
    return false;
  }

  checkUploadValid(file, field: string) {
    let ext = file?.name?.split('.')?.pop();
    if (ext && ext != '' && !this.isAValidImageExtension(ext)) {
      this.validationErrorsDocumentUpload[field] =  'Only files with these extensions are allowed: png,jpg,jpeg';
      return false;  
    }
    return true;
  }

  submitLicenceDetails() {
    if (!this.validateLicenceDetailsForm()) {
      return;
    }
    let body = JSON.stringify(
      {
        licence_number: this.licenceform.controls.licence_number.value,
        check_code: this.licenceform.controls.check_code.value
      }
    );

    this.loading = true;
    this.securedService.addLicenceDetails(body)
      .subscribe(result => {
        this.loading = false;
        this.currentStep = 4;
      },
        error => {
          this.loading = false;
          if (error.status == 422) {
            for (var i = 0; i < error.error.length; i++) {
              let element = error.error[i];
              if (element.field == 'licence_number') {
                this.validationErrorsLicenceDetails.licence_number = element.message;
              }
              if (element.field == 'check_code') {
                this.validationErrorsLicenceDetails.check_code = element.message;
              }
            }
          }
          if (error.status == 429 || error.status == 500) {
            alert(this.translate.instant('COMMON.500'));
          }

        }
      );
  }

  submitDocumentUpload() {
    if (!this.validateDocumentUploadForm()) {
      //console.log(this.validationErrorsDocumentUpload);
      return;
    }
    this.loading = true;
    let uploadFile = false;
    const formData = new FormData();
    if(this.driver_category==5){
      if(this.flt_certificate) {
        formData.append("flt_certificate", this.flt_certificate);
        uploadFile = true;
      }
    }
    if(this.driver_category!=5 && this.driver_category!=6){

      if (this.licence_front) {
        formData.append("licence_front", this.licence_front);
        uploadFile = true;
      }
      if (this.licence_back) {
        formData.append("licence_back", this.licence_back);
        uploadFile = true;
      }

      if(this.driver_category!=1){
        if (this.cpc_front) {
          formData.append("cpc_front", this.cpc_front);
          uploadFile = true;
        }
        if (this.cpc_back) {
          formData.append("cpc_back", this.cpc_back);
          uploadFile = true;
        }
        if (this.digicard_front) {
          formData.append("digicard_front", this.digicard_front);
          uploadFile = true;
        }
        if (this.digicard_back) {
          formData.append("digicard_back", this.digicard_back);
          uploadFile = true;
        }
      }
    }
    if(uploadFile) {
      this.securedService.uploadLicenceDocuments(formData)
        .subscribe((event: HttpEvent<any>) => { 
          switch (event.type) {
            /* case HttpEventType.Sent:
              console.log('Request has been made!');
              break;
            case HttpEventType.ResponseHeader:
              console.log('Response header has been received!');
              break; */
            case HttpEventType.UploadProgress:
              this.fileUploadProgress = Math.round(event.loaded / event.total * 100);
              //console.log(`Uploaded! ${this.fileUploadProgress}%`);
              break;
            case HttpEventType.Response:
              // console.log('User successfully created!', event.body);
              this.loading = false;
              this.currentStep = 5;
              this.fileUploadProgress = 0;
          }
        },
        result => {
          this.fileUploadProgress = 0;
          this.loading = false;
          if (result.ok) {
            this.currentStep = 5;
          } else {
            if (result.status == 422) {
              for (var i = 0; i < result.error.length; i++) {
                let element = result.error[i];
                if (element.field == 'flt_certificate') {
                  this.validationErrorsDocumentUpload.flt_certificate = element.message;
                }
                if (element.field == 'licence_front') {
                  this.validationErrorsDocumentUpload.licence_front = element.message;
                }
                if (element.field == 'licence_back') {
                  this.validationErrorsDocumentUpload.licence_back = element.message;
                }
                if (element.field == 'cpc_front') {
                  this.validationErrorsDocumentUpload.cpc_front = element.message;
                }
                if (element.field == 'cpc_back') {
                  this.validationErrorsDocumentUpload.cpc_back = element.message;
                }
                if (element.field == 'digicard_front') {
                  this.validationErrorsDocumentUpload.digicard_front = element.message;
                }
                if (element.field == 'digicard_back') {
                  this.validationErrorsDocumentUpload.digicard_back = element.message;
                }
              }
            }
            if (result.status == 429 || result.status == 500) {
              alert(this.translate.instant('COMMON.500'));
            }

          }
        },
      );
    } else {
      this.loading = false;
      this.currentStep = 5;
    }
  }

  //signup
  validationErrors = {
    driver_type: '',
    driver_category: '',
    company_registration: '',
    national_insurance_number: '',
    email: 'Invalid Email.',
    mobile: 'Invalid phone.',
    city: 'Invalid city.',
    full_name: 'Invalid Full name.',
    password: 'Password should be minimum 6 characters long.',
    cnfpass: this.translate.instant('BECOME_A_DRIVER.confirm_password_not_matching')
  };
  validationErrorsServer = {
    driver_type: '',
    driver_category: '',
    company_registration: '',
    national_insurance_number: '',
    email: '',
    mobile: '',
    full_name: '',
    city: '',
    password: '',
    cnfpass: ''
  };

  form: FormGroup;

  intSignup() {
    this.form = this.fb.group({
      driver_type: [''],
      driver_category: [''],
      company_registration: [''],
      national_insurance_number: [''],
      full_name: [''],
      city: [''],
      password: [''],
      cnfpass: ['', passValidator],
      email: [''],
      mobile: ['']
    });

    this.form.controls.password.valueChanges
      .subscribe(
        x => this.form.controls.cnfpass.updateValueAndValidity()
      )
  }


  showPricingDetail() {
    if(this.form.controls.driver_category.value && this.form.controls.driver_category.value=="6") {
      this.hide_ltd_driver_type = true;
      document.getElementById("PAYE").click();
    } else {
      this.hide_ltd_driver_type = false;
    }
    if(this.form.controls.driver_type.value && this.form.controls.driver_category.value) {
      this.houly_rate = this.pricing_list[this.form.controls.driver_category.value][this.form.controls.driver_type.value]['hourly_rate'];
      this.houly_rate_night = this.pricing_list[this.form.controls.driver_category.value][this.form.controls.driver_type.value]['hourly_rate_night'];
      /* if(this.form.controls.driver_type.value=="PAYE") {
        this.houly_rate = this.houly_rate+"(NI & TAX DEDUCTABLE)";
      } */
      this.showPriceSection = true;
    }else {
      this.showPriceSection = false;
    }

    if(this.form.controls.driver_type.value) {
      if(this.form.controls.driver_type.value=="PAYE") {
        this.ltdDriver = false;
        this.payeDriver = true;
      } else {
        this.ltdDriver = true;
        this.payeDriver = false;
      }
    }
  }


  onTouch() {
    this.form.markAsTouched();
    this.form.controls.full_name.markAsTouched();
    this.form.controls.password.markAsTouched();
    this.form.controls.mobile.markAsTouched();
    this.form.controls.email.markAsTouched();
    this.form.controls.driver_type.markAsTouched();
    this.form.controls.driver_category.markAsTouched();
  }

  onSignupSubmit() {
    this.validationErrorsServer = {
      driver_type: '',
      driver_category: '',
      company_registration: '',
      national_insurance_number: '',
      email: '',
      full_name: '',
      mobile: '',
      password: '',
      cnfpass: '',
      city: ''
    };

    if (!this.form.controls.driver_type.value) {
      this.validationErrorsServer.driver_type = "Select your mode of enrollment";
    }

    if (this.form.controls.driver_type.value=="PAYE") { 
      if(this.form.controls.national_insurance_number.value=="") {
        this.validationErrorsServer.national_insurance_number = "Enter your national insurance number";
        return false;
      }/*  else {
        let ni_number = this.form.controls.national_insurance_number.value;
        var patt = new RegExp(/^(?!BG)(?!GB)(?!NK)(?!KN)(?!TN)(?!NT)(?!ZZ)(?:[A-CEGHJ-PR-TW-Z][A-CEGHJ-NPR-TW-Z])(?:\s*\d\s*){6}([A-D]|\s)$/);
        if(!patt.test(ni_number)) {
          this.validationErrorsServer.national_insurance_number = "Enter correct national insurance number";
          return false;
        }
      } */
    }

    if (this.form.controls.driver_type.value=="LTD" && this.form.controls.company_registration.value=="") {
      this.validationErrorsServer.company_registration = "Enter company registration number";
      return false;
    }

    if (!this.form.controls.driver_category.value) {
      this.validationErrorsServer.driver_category = "Select the licence category";
    }

    if (!(this.form.controls.email.value)){
      this.validationErrorsServer.email = "Enter your email";
    }

    if (!(this.form.controls.full_name.value)){
      this.validationErrorsServer.full_name = "Enter your full name";
    }

    if (!(this.form.controls.mobile.value)){
      this.validationErrorsServer.mobile = "Enter your mobile number";
    }
    if (!(this.form.controls.password.value)){
      this.validationErrorsServer.password = "Enter your password";
    }

    if ((this.form.controls.cnfpass.value || this.form.controls.password.value) && this.form.controls.cnfpass.value != this.form.controls.password.value) {
      this.validationErrorsServer.cnfpass = this.translate.instant('BECOME_A_DRIVER.confirm_password_not_matching');
    }

    let selectedCity = $('#city-select2').select2('data');
    if (!selectedCity[0]) {
      this.validationErrorsServer.city = this.translate.instant('BECOME_A_DRIVER.city_required');
    }

    if (this.validationErrorsServer.driver_type || this.validationErrorsServer.driver_category || this.validationErrorsServer.email ||
      this.validationErrorsServer.full_name || this.validationErrorsServer.mobile || this.validationErrorsServer.cnfpass || this.validationErrorsServer.city) {
      return false;
    }
    
    this.loading = true;
    let body = {}
    if(this.form.controls.driver_type.value=="PAYE") {
      body = JSON.stringify({
        driver_type: this.form.controls.driver_type.value,
        driver_category: this.form.controls.driver_category.value,
        national_insurance_number: this.form.controls.national_insurance_number.value,
        name: this.form.controls.full_name.value,
        email: this.form.controls.email.value,
        mobile: this.form.controls.mobile.value,
        city_id: $('#city-select2').select2('data')[0].id,
        password: this.form.controls.password.value
      });
    } else if(this.form.controls.driver_type.value=="LTD"){
      body = JSON.stringify({
        driver_type: this.form.controls.driver_type.value,
        driver_category: this.form.controls.driver_category.value,
        company_registration: this.form.controls.company_registration.value,
        name: this.form.controls.full_name.value,
        email: this.form.controls.email.value,
        mobile: this.form.controls.mobile.value,
        city_id: $('#city-select2').select2('data')[0].id,
        password: this.form.controls.password.value
      });
    }
    
    this.jwtService.registerDriver(body)
      .subscribe(result => {
        this.loading = false;
        this.otpEmail = result.email.toString();
        document.getElementById("openModalButtonOTPEmail").click();
      },
        error => {
          this.loading = false;
          if (error.status == 422) {
            for (var i = 0; i < error.error.length; i++) {
              let element = error.error[i];

              if (element.field == 'name') {
                this.validationErrorsServer.full_name = element.message;
              }
              if (element.field == 'email') {
                this.validationErrorsServer.email = element.message;
              }
              if (element.field == 'mobile') {
                this.validationErrorsServer.mobile = element.message;
              }
              if (element.field == 'city_id') {
                this.validationErrorsServer.city = element.message;
              }
              if (element.field == 'password') {
                this.validationErrorsServer.password = element.message;
              }

            }
          }

        }
      );
    this.form.markAsTouched();
  }





  otpTextEmail: string = "";
  otpLoadingEmail: Boolean = false;
  otpResultViewEmail: Boolean = false;
  validationErrorsOtp: string = "";
  otpVerified: Boolean = false;
  otpEmail: string = "";
  otpResponseMessageEmail = {
    status: "",
    text: ""
  }
  resetOTPVerification() {
    this.otpTextEmail = "";
    this.otpLoadingEmail = false;
    this.otpResultViewEmail = false;
    this.otpVerified = false;
    this.validationErrorsOtp = "";
    this.otpEmail = "";
    this.otpResponseMessageEmail = {
      status: "",
      text: ""
    }
  }
  onOtpKeyupEmail(event: KeyboardEvent) {

    if (this.otpTextEmail) {
      let stringOtp = this.otpTextEmail.toString();
      if (stringOtp.length >= 6) {
        this.otpTextEmail = stringOtp.substring(0, 6);
      }
    }


  }

  submitOtpEmail() {

    this.validationErrorsOtp = ""
    if (!this.otpTextEmail || !this.otpEmail) {
      return;
    }
    this.otpLoadingEmail = true;
    this.commonService.submitEmailOtp(this.otpEmail, this.otpTextEmail)
      .subscribe(result => {
        this.otpLoadingEmail = false;

        this.otpResultViewEmail = true;
        this.otpResponseMessageEmail.status = this.translate.instant('BECOME_A_DRIVER.success');
        this.otpResponseMessageEmail.text = this.translate.instant('BECOME_A_DRIVER.email_verified_success');
        setTimeout(() => {
          document.getElementById("closeOTPModelPopup").click();
          this.loading = false;
          this.jwtService.setLoggedInData(result);
          this.dataSharingService.isLoggedIn.next(true);
          this.currentStep = 2;
          this.payeDriver = this.jwtService.isPaye;
          this.ltdDriver = this.jwtService.isLtd;
          this.driver_category = this.jwtService.driverCategory;
        }, 2000);
      },
        error => {
          this.otpLoadingEmail = false;
          //console.log(error);
          if (error.status == 429 || error.status == 500) {
            alert(this.translate.instant('COMMON.500'));

          }
          if (error.status == 422) {
            for (var i = 0; i < error.error.length; i++) {
              let element = error.error[i];

              if (element.field == 'code' || element.field == 'email') {
                this.validationErrorsOtp = element.message;
              }
            }
          }
          else {
            this.validationErrorsOtp = this.translate.instant('BECOME_A_DRIVER.wrong_OTP');
          }
        }
      );
  }

  resendOtpMsg = "";
  resendOtp() {
    this.validationErrorsOtp = ""
    if (!this.otpEmail) {
      return;
    }
    this.otpLoadingEmail = true;
    this.commonService.resendOtp(this.otpEmail)
      .subscribe(result => {
        this.otpLoadingEmail = false;
        this.resendOtpMsg = this.translate.instant('BECOME_A_DRIVER.RESEND_OTP_SUCCESS');

        setTimeout(() => {
          this.resendOtpMsg = ""
        }, 10000);
      },
        error => {
          this.otpLoadingEmail = false;
          //console.log(error);
          if (error.status == 429 || error.status == 500) {
            alert(this.translate.instant('COMMON.500'));

          }
          if (error.status == 422) {
            for (var i = 0; i < error.error.length; i++) {
              let element = error.error[i];



              if (element.field == 'code' || element.field == 'email') {
                this.validationErrorsOtp = element.message;
              }


            }
          }
        }
      );
  }
}
