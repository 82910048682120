import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { CommonService } from '../services/common.service';
import { JwtService } from '../services/jwt.service';
import { SecuredService } from '../services/secured.service';
import { TranslateService } from '@ngx-translate/core';
import { Driver } from '../interfaces/Driver';
import { Location } from '../interfaces/Location';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';

@Component({
  selector: 'app-driver',
  templateUrl: './driver.component.html',
  styleUrls: ['./driver.component.css']
})
export class DriverComponent implements OnInit {
  driverCategories = {1:'Van Driver', 2:'LGV/7.5TONNE Driver', 3: 'CLASS2 Driver', 4: 'CLASS1 Driver', 5: 'FLT Driver', 6: 'Warehouse worker / Drivers Mate'};
  stars: number[] = [1, 2, 3, 4, 5];
  loading: boolean = true;
  driver: Driver;
  bookingLoading: boolean = false;
  termsAccepted: boolean = true;
  vehicleTermsAccepted: boolean = true;
  bookingResultView: boolean = false;
  bookingForm: FormGroup;
  control: FormArray;
  touchedRows: any;
  today=new Date();
  errorRows: any;
  locationError: boolean = false;
  locationErrorString: string;
  hourlyRateError: boolean = false;
  hourlyRateErrorString: string;
  hirer_noteErrorString: string;
  id: number;
  companyLocations: Array<Location>;

  

  constructor(
  	private route: ActivatedRoute,
    private router: Router,
    private commonService: CommonService,
    private jwtService: JwtService,
    private securedService: SecuredService,
    private translate: TranslateService,
    private fb: FormBuilder
    ) { }

  ngOnInit(): void {
  	this.route.params.subscribe(params => {
      let id = params['id'];
      this.id = id;
      this.loading = true;
      this.securedService.getDriver(id).subscribe(
        result => {
          this.driver = result;
          this.loading = false;

          this.touchedRows = [];
    		  this.bookingForm = this.fb.group({
    		    Booking: this.fb.array([]),
            company_location_id: [''],
            hirer_note: [''],
            hourly_rate: [this.driver?.hourly_rate],
            hourly_rate_night: [this.driver?.hourly_rate_night]
    		  });
          this.errorRows = [];
    		  this.addRow();
        },
        error => {
          this.loading = false;
          if (error.status == 429 || error.status == 500) {
            alert(this.translate.instant('COMMON.500'));

          }

        }
      );
    });

    this.loadCompanyLocations();
  }

  ngAfterOnInit() {
    this.control = this.bookingForm.get('Booking') as FormArray;
  }

  initiateForm(): FormGroup {
    return this.fb.group({
      booking_date: ['', Validators.required],
      start_time: ['', [Validators.required]],
      end_time: ['']
    });
  }

  addRow() {
    const control =  this.bookingForm.get('Booking') as FormArray;
    control.push(this.initiateForm());
    this.errorRows.push([]);
  }

  deleteRow(index: number) {
    const control =  this.bookingForm.get('Booking') as FormArray;
    control.removeAt(index);
    this.errorRows.splice(index, 1);
  }

  /*editRow(group: FormGroup) {
    group.get('isEditable').setValue(true);
  }

  doneRow(group: FormGroup) {
    group.get('isEditable').setValue(false);
  }*/

  get getFormControls() {
    const control = this.bookingForm.get('Booking') as FormArray;
    return control;
  }

  bookDriver() {
    this.bookingLoading = true;
    let formError = false;
    this.locationError = false;
    this.locationErrorString = "";
    this.hourlyRateError = false;
    this.hourlyRateErrorString = "";
    const control = this.bookingForm.get('Booking') as FormArray;
    this.errorRows = [];
    let hourly_rate = this.bookingForm.get('hourly_rate').value;
    if(!hourly_rate) {
      this.hourlyRateError = true;
      this.hourlyRateErrorString = "Enter hourly rate";
      formError = true;
    } else if(hourly_rate<(this.driver.hourly_rate-5)) {
      this.hourlyRateError = true;
      this.hourlyRateErrorString = "This hourly rate is too low";
      formError = true;
    }
    let hourly_rate_night = this.bookingForm.get('hourly_rate_night').value;
    if(!hourly_rate_night) {
      hourly_rate_night = this.driver.hourly_rate_night;
    } else if(hourly_rate_night<(this.driver.hourly_rate_night-5)) {
      this.hourlyRateError = true;
      this.hourlyRateErrorString = "This night hourly rate is too low";
      formError = true;
    } else if(hourly_rate_night<hourly_rate) {
      this.hourlyRateError = true;
      this.hourlyRateErrorString = "This night hourly rate is too low";
      formError = true;
    }
    this.touchedRows = control.controls;
    for (var i in this.touchedRows) {
        let errors = [];
        if(this.touchedRows[i].valid) {
          let ToDate = new Date();
          //console.log(this.touchedRows[i].value['booking_date']);
          //console.log(this.touchedRows[i].value['start_time']);
          if (new Date(this.touchedRows[i].value['booking_date']+' '+this.touchedRows[i].value['start_time']).getTime() <= ToDate.getTime()) {
            errors.push("The Booking Date must be Bigger or Equal to today date");
            formError = true;
          }

          if(this.touchedRows[i].value['end_time'] && this.touchedRows[i].value['start_time']>this.touchedRows[i].value['end_time']) {
            errors.push('End time should be after start time');
            formError = true;
          }
        } else {
          errors.push('Enter all the fields'); 
          formError = true;
        }
        this.errorRows.push(errors);
    }
    

    let company_location_id = this.bookingForm.get('company_location_id').value;
    if(!company_location_id) {
      this.locationError = true;
      this.locationErrorString = "Please select location address";
      formError = true;
    }
    let hirer_note = this.bookingForm.get('hirer_note').value;

    if(hirer_note.length>516) {
      this.hirer_noteErrorString = "Instruction notes maximum 500 characters";
      formError = true;
    }
    
    if(formError) {
      this.bookingLoading = false;
      return false;
    } else {
      const formData = new FormData();
      for (var i in this.touchedRows) {
        formData.append("Booking["+i+"][start_time]", this.touchedRows[i].value['booking_date']+' '+this.touchedRows[i].value['start_time']+":00");
        if(this.touchedRows[i].value['end_time']) {
          formData.append("Booking["+i+"][end_time]", this.touchedRows[i].value['booking_date']+' '+this.touchedRows[i].value['end_time']+":00");
        }
        
      }
      formData.append('company_location_id', company_location_id);
      formData.append('hirer_note', hirer_note);
      formData.append('hourly_rate', hourly_rate);
      formData.append('hourly_rate_night', hourly_rate_night);
      
      this.securedService.bookDriver(formData, this.id)
        .subscribe(
        result => {
          this.bookingLoading = false;
          this.bookingResultView = true;
        },
        error => {
          this.errorRows = [];
          this.bookingLoading = false;
          if (error.status == 422) {
            for (var i in error.error) {
              let errors = [];
              for (var j = 0; j < error.error[i].length; j++) {
                errors.push(error.error[i][j].message); 
              }
              this.errorRows.push(errors);
            }
          }
        }
      );
    }
  }


  loadCompanyLocations() {
    this.bookingLoading = true;
    this.securedService.getCompanyLocations("").subscribe(
      result => {
        this.bookingLoading = false;
        this.companyLocations = result.items;
      },
      error => {
        this.bookingLoading = false;
        if (error.status == 429 || error.status == 500) {
          alert(this.translate.instant('COMMON.500'));

        }

      }
    );
  }

}
