<section class="inner-header-page">
    <div class="container">
        <div [hidden]="!loading" style="width: 100%;background-color: #efefef;min-height: 204px;">
            <div class="ion-padding" style="text-align: center;margin-top: 71px;min-height: 100px;">
                <img src="../../assets/images/loader.gif"
                    style=" margin-top: 10px;   width: 70px;padding-left: 20px;" />
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-md-8">
                <div class="left-side-container">
                    <div class="freelance-image"><a href="#">
                        <img src="{{driver?.profile_image?driver?.profile_image:'../../assets/images/no-dp-2.png'}}" class="img-responsive img-circle" alt=""></a>
                    </div>
                    <div class="header-details">
                        <h4>{{driver?.full_name}}<span class="pull-right">£{{driver?.hourly_rate}}/hr</span></h4>
                        <p>{{driverCategories[driver?.driver_category_id]}}</p>
                        <ul>
                            <li><a href="#"><i class="fa fa-map-marker"></i> {{driver?.city}}</a></li>
                            <li *ngIf="driver?.rating">
                                <div class="star-rating" style="line-height: 2;">
                                    <ng-container  *ngFor="let star of stars">
                                    <span *ngIf="driver.rating>=star" class="fa fa-star fill"></span>
                                    <span  *ngIf="(driver.rating>star && driver.rating<star+1)" class="fa fa-star-half fill"></span>
                                    <span *ngIf="!driver.rating">&nbsp;</span>
                                    </ng-container>
                                </div>
                            </li>
                            <!--<li>{{driver?.years_driving}} Year(s) Experience</li>-->
                            <li><div class="verified-action">Verified</div></li>
                            <li><span class="detail-info">Experience</span>: {{driver?.years_driving}} Year</li>
                            <li><span class="detail-info">Age</span>: {{driver?.dob? driver?.dob+' Year':'Not Set'}} </li>
                            <li><span class="detail-info">Gender</span>: {{driver?.gender | titlecase}}</li>
                        </ul>
                    </div>
                </div>
            </div>
            
            <div class="col-12 col-md-4 bl-1 br-gary">
                <div class="right-side-detail">
                    <ul>
                        <li *ngIf="driver?.booking_dates"><span class="detail-info" style="width: 100%;">Availability</span>
                            <ng-container  *ngFor="let booking_date of driver.booking_dates">
                                <span class="available-status">{{booking_date | date: 'dd MMM'}}</span>
                            </ng-container>
                        </li>
                        <li><button class="btn btn-warning mrg-bot-10 full-width btn-lg" data-toggle="modal" data-target="#bookingModal" style="padding:.5rem 3rem; background-color: #ff9800;color: #fff;">Book</button> <img [hidden]="!(driver?.driver_category_id==2||driver?.driver_category_id==3||driver?.driver_category_id==4)" style="cursor: pointer;" data-toggle="modal" data-target="#bookingModal" src="../../assets/images/flexirate_button.png"></li>
                    </ul>
                </div>
            </div>
        </div>
        
    </div>
</section>

<div class="clearfix"></div>

<section>
    <div class="container">
        <div class="row">
            <div class="col-md-8 col-sm-8">
                <div class="container-detail-box">
                
                    <div class="apply-job-header">
                        <h4>{{driver?.full_name}}</h4>
                        <a href="#" class="cl-success"><span>{{driverCategories[driver?.driver_category_id]}}</span></a>
                        <span><i class="fa fa-map-marker"></i>{{driver?.city}}</span>
                    </div>
                    
                    <div class="apply-job-detail">
                        <h6 style="text-decoration: underline;">Previous work</h6>
                        <p>{{ driver?.previous_work }}</p>
                        <p></p>
                        <hr>
                        <h6 style="text-decoration: underline;">Detailed profile information</h6>
                        <p>{{ driver?.aboutme }}</p>
                        <p></p>
                    </div>

                    <button class="btn btn-success"  data-toggle="modal" data-target="#bookingModal" style="padding:.5rem 3rem; color: #fff;">Book</button>
                    <!-- <a href="#" class="btn btn-success">Make An Offer</a> -->
                    
                </div>
            </div>
            
            <!-- Sidebar Start-->
            <div class="col-md-4 col-sm-4">
                
                <!-- Make An Offer -->
                <div class="sidebar-container">
                    <div class="sidebar-box">
                        <span class="sidebar-status">{{driverCategories[driver?.driver_category_id]}}</span>
                        <h4 class="flc-rate">£{{driver?.hourly_rate}}/hr</h4>
                        <div class="sidebar-inner-box">
                            <div class="sidebar-box-thumb">
                                <img src="{{driver?.profile_image?driver?.profile_image:'../../assets/images/no-dp-2.png'}}" class="img-responsive img-circle" alt="">
                            </div>
                            <div class="sidebar-box-detail">
                                <h4>{{driver?.full_name}}</h4>
                                <span class="desination">{{driverCategories[driver?.driver_category_id]}}</span>
                            </div>
                        </div>
                        <div class="sidebar-box-extra">
                            <ul class="status-detail">
                                <li class="br-1"><strong>{{driver?.years_driving}} Year</strong>Experience</li>
                                <li class="br-1"><strong>Age</strong>{{driver?.dob}} Year</li>
                                <li><strong>Gender</strong>{{driver?.gender | titlecase}}</li>
                            </ul>
                        </div>
                    </div>
                    <button class="btn btn-sidebar bt-1 bg-success"  data-toggle="modal" data-target="#bookingModal">Book</button>
                </div>
            </div>
        </div>
        <!-- End Sidebar -->
        
    </div>
</section>

<div id="bookingModal" class="modal fade" role="dialog" data-keyboard="false" data-backdrop="static">
    <div class="modal-dialog">
        <!-- Modal content-->
        <div class="modal-content" style="min-height: 200px;;">
            <div class="modal-header">
                <h5 class="modal-title" style="color: #337777;font-family: Lato-Regular;text-align: center;">Book {{driver?.full_name}}</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body  booking" [hidden]="bookingLoading || bookingResultView" style="margin:auto;">
                <div style="text-align: center;" *ngIf="!loading">
                    <form [formGroup]="bookingForm">
                        <div class="alert alert-success" style="font-weight: bold;" role="alert" *ngIf="(driver?.hourly_rate|number:'2.2') == (driver?.hourly_rate_night|number:'2.2')">
                            The offered per hour rate is <br>
                            <span>£ <input class="form-control" style="display: inline-block; width: 100px;" formControlName="hourly_rate" type="number" min="7" step="0.1"></span>
                            <div class="validation_error" *ngIf="hourlyRateError">
                                <span>{{hourlyRateErrorString}}</span>
                            </div>
                            <img [hidden]="!(driver?.driver_category_id==2||driver?.driver_category_id==3||driver?.driver_category_id==4)" src="../../assets/images/flexirate_button.png" style="margin-left: 2px;">
                        </div>
                        <div style="font-weight: bold;" class="alert alert-success" role="alert" *ngIf="(driver?.hourly_rate|number:'2.2') != (driver?.hourly_rate_night|number:'2.2')">
                            The offered per hour rate is <br>
                            <div style="width: 50%;float: left;">Day : £ <input class="form-control" style="display: inline-block; width: 100px;" formControlName="hourly_rate" type="number" min="7" step="0.1"></div>
                            <div style="width:50%;display: contents;">Night : £ <input class="form-control" style="display: inline-block; width: 100px;" formControlName="hourly_rate_night" type="number" min="7" step="0.1"></div>
                            <div class="validation_error" *ngIf="hourlyRateError">
                                <span>{{hourlyRateErrorString}}</span>
                            </div>
                            <img [hidden]="!(driver?.driver_category_id==2||driver?.driver_category_id==3||driver?.driver_category_id==4)" src="../../assets/images/flexirate_button.png" style="margin-top: 2px;">
                        </div>
                        <ng-container formArrayName="Booking"
                            *ngFor="let group of getFormControls.controls ; let i=index">
                            <div class="row" [formGroupName]="i">
                                <div class="col-12">
                                    <div class="row" style="{{errorRows[i].length === 0?'':'color: #b22222;'}}">
                                        <div class="col-12 col-sm-12 col-lg-6">
                                            <label for="booking_date" class="control-label col-12"
                                                style="{{group.get('booking_date').valid?'':'color: #b22222;'}}">Booking
                                                Date</label>
                                            <input class="form-control col-12" type="date"
                                                formControlName="booking_date" value="{{today}}" min="{{today}}">
                                        </div>
                                        <div class="col-12 col-sm-12 col-lg-6">
                                            <div class="row">
                                                <div class="col-12 col-sm-12 col-lg-12">
                                                    <label for="start_time" class="control-label col-12"
                                                        style="{{group.get('start_time').valid?'':'color: #b22222;'}}">Start
                                                        time</label>
                                                    <input class="form-control col-12" type="time"
                                                        formControlName="start_time">
                                                </div>
                                                <!--<div class="col-12 col-sm-6 col-lg-6">
                                                    <label for="end_time" class="control-label col-12"
                                                        style="{{group.get('end_time').valid?'':'color: #b22222;'}}">End
                                                        time</label>
                                                    <input class="form-control col-12" type="time"
                                                        formControlName="end_time">
                                                    <a [ngStyle]="{'color': '#676666', 'cursor':'pointer'}"
                                                        (click)="deleteRow(i)" *ngIf="i >0">remove</a>
                                                </div>-->

                                            </div>
                                        </div>
                                        <div *ngIf="errorRows[i]" class="validation_error">

                                            <ul>
                                                <ng-container *ngFor="let error of errorRows[i]">
                                                    <li>{{error}}</li>
                                                </ng-container>
                                            </ul>

                                        </div>
                                    </div>
                                    <hr>
                                </div>
                            </div>
                        </ng-container>

                        <div class="action-container" style="width:100%;">
                            <button class="btn" style="background-color: #337777;border-color: #337777;float: right;"
                                type="submit" (click)="addRow()" *ngIf="getFormControls.controls?.length < 6">Add
                                Another Day</button>
                        </div>

                        <div class="form-group row" style="width:100%; padding-top:12px;">
                            <div class="input-group">
                                <label for="hirer_note" class="control-label col-12 col-sm-4 col-lg-3" style="text-align: left;">Notes:</label>
                                <textarea  class="form-control" formControlName="hirer_note" rows="4" placeholder="Additional Instructions"></textarea>
                            </div>
                            <div class="validation_error" *ngIf="hirer_noteErrorString">
                                <span>{{hirer_noteErrorString}}</span>
                            </div>
                        </div>

                        <div class="form-group row" style="width:100%; padding-top:5px;">
                            <div class="input-group">
                                <label for="company_location_id" class="control-label col-12 col-sm-6 col-lg-4">Location
                                    Address:</label>
                                <select *ngIf="companyLocations?.length>0" formControlName="company_location_id"
                                    class="form-control">
                                    <option *ngFor="let companyLocation of companyLocations"
                                        [value]="companyLocation.id">
                                        {{companyLocation.address_title}} - {{companyLocation.address}}
                                    </option>
                                </select>

                                <a *ngIf="companyLocations?.length<=0" data-dismiss="modal" routerLink="/profile"
                                    style="color: rgb(243, 115, 42); text-decoration: underline dotted rgb(243, 115, 42);float:right;margin-right: 5px;cursor: pointer;">Add
                                    Location Address</a>

                            </div>
                            <div class="validation_error" *ngIf="locationError">
                                <span>{{locationErrorString}}</span>
                            </div>
                        </div>

                    </form>
                </div>
                <hr>
                <div [hidden]="bookingLoading || bookingResultView" class="form-group form-check"
                    style="margin: auto; margin-bottom: 5px;">
                    <input type="checkbox" class="form-check-input" [checked]="termsAccepted"
                        (change)="termsAccepted = !termsAccepted">
                    <label class="form-check-label" for="termsAccepted">I Accept <a href="/assets/terms-of-business.pdf"
                            target="_blank" style="font-family: Lato-Regular; cursor: pointer; color: #337777;">Terms Of
                            Business</a></label><br />
                    <input type="checkbox" class="form-check-input" [checked]="vehicleTermsAccepted"
                        (change)="vehicleTermsAccepted = !vehicleTermsAccepted">
                    <label class="form-check-label" for="vehicleTermsAccepted">We confirm we will provide the driver
                        with a vehicle and relevant valid insurance that complies with UK law</label>
                </div>
            </div>

            <div [hidden]="!bookingLoading">
                <div style="text-align: center;
                            margin-top: 62px;
                            min-height: 100px; ">

                    <img src="../../assets/images/loader.gif"
                        style="margin-top: 10px;   width: 70px;padding-left: 20px;" />
                </div>
            </div>


            <div class="text-center" [hidden]="bookingLoading || !bookingResultView"
                style="min-height: 115px;margin-top: 48px;">

                <span class="font-weight-normal" style="font-size:18px;color: #337777;font-family: Lato-Regular;">
                    Booking Successfull
                </span>
                <br />
                <div
                    style="width: 50px;height: 2px;background-color: rgb(243, 115, 42);margin-left: auto;margin-right: auto;">
                </div>

                <div style="margin-top: 10px;">
                    We have notified the driver and waiting for his confimation
                </div>


            </div>


            <div class="button-group" [hidden]="bookingLoading || bookingResultView" style="text-align: center;">
                <button type="button" style="background-color: rgb(243, 115, 42);;
                border-color: rgb(243, 115, 42);font-family: Lato-Regular; padding-left: 22px;padding-right: 22px;"
                    (click)="bookDriver()" class="btn btn-info" [disabled]="!termsAccepted || !vehicleTermsAccepted">
                    Book </button>

            </div>

            <div class="button-group" style="text-align: right;">
                <button [hidden]="bookingLoading" type="button" id="closBookingModelPopup" class="btn btn-default"
                    data-dismiss="modal">Close</button>
            </div>
        </div>

    </div>
</div>