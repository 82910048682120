<div class="profile-section"
    style=" margin-top: 65px;min-height: 700px;padding-top: 30px;">
    <div class="container">
        <div class="row" style="padding: 0 15px;text-align: center;">
            <div [hidden]="!loading" style="width: 100%;background-color: #efefef;min-height: 204px;">
                <div class="ion-padding" style="text-align: center;margin-top: 71px;min-height: 100px;">
                    <img src="../../assets/images/loader.gif"
                        style=" margin-top: 10px;   width: 70px;padding-left: 20px;" />
                </div>
            </div>
            
            <div *ngIf="!loading" class="col-12 col-sm-8" style="margin-top: 50px;">
            	<h2 style="font-size: 25px;color: #337777;font-family: Lato-Regular;text-decoration: underline;">Booking Detail</h2>
            	<div class="text-center alert alert-success" role="alert" [hidden]="!bookingResultView" style="min-height: 25px;margin-top: 48px;">
	                <span class="font-weight-normal" style="font-size:18px;color: #337777;font-family: Lato-Regular;">
	                    Booking Confirmed Successfully. <a routerLink="/manage-booking">Manage Booking</a>
	                </span>
	            </div>
            	<ng-container *ngFor="let booking of bookings">
                <div class="row" style ="margin-top: 25px;">
                    <div class="col-12 col-sm-4 col-lg-4"> 
                        <label for="booking_date" class="control-label" style="font-family: Lato-Regular;">Booking Date</label>
                        <p class="form-control-static">{{ booking.start_time | date : 'mediumDate' }}</p>
                    </div>
                    <div class="col-12 col-sm-8 col-lg-8">
                        <div class="row">
                            <div class="col-4 col-sm-4 col-lg-4"> 
                                <label for="start_time" class="control-label" style="font-family: Lato-Regular;" >Start time</label>
                                <p class="form-control-static">{{ booking.start_time | date : 'HH:mm' }}</p>
                            </div>
                            <div class="col-4 col-sm-4 col-lg-4"> 
                                <label for="end_time" class="control-label" style="font-family: Lato-Regular;" >End time</label>
                                <p class="form-control-static">{{ booking.end_time | date : 'HH:mm' }}</p>
                            </div>
                            <div [hidden]="!requested_status" class="col-2 col-sm-2 col-lg-2" style="padding: 0px;"> 
                                <label for="end_time" class="control-label" style="font-family: Lato-Regular; color: green;" >Accept</label><br>
                                <input type="checkbox" class="form-check-input" [checked]="(booking.booking_status == 'REQUESTED' || booking.booking_status == 'ACCEPTED')" (change)="changeRow(booking,$event)" >
                                <!-- <button *ngIf="!loading && isDriver" class="btn" style="background-color: rgb(243, 115, 42);color: #fff; border-color:rgb(243, 115, 42);padding: 0px 5px; font-size: 12px;" [hidden]="!(booking.booking_status == 'REQUESTED' || booking.booking_status == 'ACCEPTED')" (click)="rejectRow(booking)"><i class="fa fa-times"></i> Reject</button>
                                <button *ngIf="!loading && isDriver" class="btn" style="color: #fff; background-color: #337777;border-color: #337777;padding: 0px 5px; font-size: 12px;" [hidden]="!(booking.booking_status == 'REJECTED')" (click)="acceptRow(booking)"><i class="fa fa-check"></i> Accept</button> -->
                            </div>
                            <div [hidden]="!requested_status" class="col-2 col-sm-2 col-lg-2" style="padding: 0px;"> 
                                <label for="end_time" class="control-label" style="font-family: Lato-Regular; color: red;" >Reject</label><br>
                                <input type="checkbox" class="form-check-input" [checked]="(booking.booking_status == 'REJECTED')" (change)="changeRowReject(booking,$event)" >
                                <!-- <button *ngIf="!loading && isDriver" class="btn" style="background-color: rgb(243, 115, 42);color: #fff; border-color:rgb(243, 115, 42);padding: 0px 5px; font-size: 12px;" [hidden]="!(booking.booking_status == 'REQUESTED' || booking.booking_status == 'ACCEPTED')" (click)="rejectRow(booking)"><i class="fa fa-times"></i> Reject</button>
                                <button *ngIf="!loading && isDriver" class="btn" style="color: #fff; background-color: #337777;border-color: #337777;padding: 0px 5px; font-size: 12px;" [hidden]="!(booking.booking_status == 'REJECTED')" (click)="acceptRow(booking)"><i class="fa fa-check"></i> Accept</button> -->
                            </div>
                            <!-- <div [hidden]="!requested_status" class="col-4 col-sm-4 col-lg-4"> 
                                <button *ngIf="!loading && isDriver" class="btn" style="background-color: rgb(243, 115, 42);color: #fff; border-color:rgb(243, 115, 42);padding: 0px 5px; font-size: 12px;" [hidden]="!(booking.booking_status == 'REQUESTED' || booking.booking_status == 'ACCEPTED')" (click)="rejectRow(booking)"><i class="fa fa-times"></i> Reject</button>
                                <button *ngIf="!loading && isDriver" class="btn" style="color: #fff; background-color: #337777;border-color: #337777;padding: 0px 5px; font-size: 12px;" [hidden]="!(booking.booking_status == 'REJECTED')" (click)="acceptRow(booking)"><i class="fa fa-check"></i> Accept</button>
                            </div> -->
                            <div [hidden]="requested_status" class="col-4 col-sm-4 col-lg-4"> 
                                <label for="end_time" class="control-label col-12" style="font-family: Lato-Regular;" > &nbsp;</label>
                                <p class="form-control-static" style="text-align: left;">{{ booking.booking_status }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            	</ng-container>
                <label class="control-label col-12" style="font-family: Lato-Regular;margin-bottom: 0px;">Location Address: </label>
                <address>
                    <strong>{{bookings[0].location_title}}</strong><br>
                    {{bookings[0].location_address}}<br>
                    <a data-toggle="modal" data-target="#bookingMapView" style="color: rgb(243, 115, 42); text-decoration: underline dotted rgb(243, 115, 42);cursor: pointer;">View Map</a>
                </address>

                
                <div class="form-group" style="margin-top:5px;">
                    <button *ngIf="!loading && isDriver"
                        class="btn btn-success btn-lg" style="padding-left: 40px;padding-right: 40px;background-color: #337777;border-color: #337777;" [hidden]="!requested_status"  (click)="confirmBooking()">Confirm Booking</button>
                    <button *ngIf="!loading && isCompany" [hidden]="!requested_status" data-toggle="modal" data-target="#bookingModal" 
                        class="btn btn-success btn-lg" style="padding-left: 40px;padding-right: 40px;background-color: #337777;border-color: #337777;">Cancel Booking</button>
                </div>
                <div class="divider"></div>

            </div>

            <div *ngIf="!loading && isCompany" class="col-12 col-sm-4" style="background-color: #efefef;min-height: 204px; padding-bottom: 20px;">
                <div style="width: 100%; margin-top: 40px;">
                    <div [hidden]="!driver?.profile_image" style="
                        background-image: url({{driver?.profile_image}});
                        height: 100px;   
                        margin: 10px auto;
                        width: 100px;
                        background-size: cover;
                        border-radius: 63px;
                        background-position: center;
                        ">
                    </div>
                    <img [hidden]="driver?.profile_image" style=" width: 100px;margin: 10px auto;"
                        src="../../assets/images/no-dp-2.png" />
                </div>
                <div class="col-12" style="font-family: Lato-Regular;">
                    {{driver?.full_name}}
                </div>
                <div class="col-12" style="font-family: Lato-Regular;">
                    <i class="fa fa-map-marker"></i> {{driver?.city}}
                </div>
                
            </div>

            <div *ngIf="!loading && isDriver" class="col-12 col-sm-4" style="background-color: #efefef;min-height: 204px; padding-bottom: 20px;">
                <div style="width: 100%; margin-top: 40px;">
                    <div [hidden]="!company?.company_logo" style="
                        background-image: url({{company?.company_logo}});
                        height: 100px;   
                        margin: 10px auto;
                        width: 100px;
                        background-size: cover;
                        border-radius: 63px;
                        background-position: center;
                        ">
                    </div>
                    <img [hidden]="company?.company_logo" style=" width: 100px;margin: 10px auto;"
                        src="../../assets/images/no-dp-2.png" />
                </div>
                <div class="col-12" style="font-family: Lato-Regular;">
                    {{company?.company_name}}
                </div>
                <div class="col-12" style="font-family: Lato-Regular;">
                    <i class="fa fa-map-marker"></i> {{company?.city}}
                </div>
                
                <div class="col-12" style="margin-top:20px;">
                    {{company?.about_company}}
                </div>
            </div>
        </div>
    </div>
</div>

<div id="bookingMapView" class="modal fade" role="dialog"  data-keyboard="false" data-backdrop="static">
    <div class="modal-dialog">
        <div class="modal-content" style="min-height: 200px;;">

            <div class="modal-body">
                <h5 style="color: #337777;font-family: Lato-Regular;text-align: center;">Location</h5>

                <div style="text-align: center;" >
                    <div #gmap style="height:300px; width:100%; min-width: 350px;">
                </div>
            </div>

            <div class="button-group" style="text-align: right;margin-bottom: -15px;margin-top: 15px;margin-right: -15px;">
                <button type="button" id="closLocationMapModelPopup" class="btn btn-default"
                    data-dismiss="modal">Close</button>
            </div>
        </div>

    </div>
</div>
