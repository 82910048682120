<div class="profile-section" style=" margin-top: 90px;min-height: 700px;padding-top: 30px;">
    <div class="container">

        <div class="row" style="padding: 0 15px;text-align: center;">
            <div [hidden]="!loadingProfileSection || !loading"
                style="width: 100%;background-color: #efefef;min-height: 204px;">
                <div class="ion-padding" style="text-align: center;margin-top: 71px;min-height: 100px;">
                    <img src="../../assets/images/loader.gif"
                        style=" margin-top: 10px;   width: 70px;padding-left: 20px;" />
                </div>
            </div>
            <div [hidden]="loadingProfileSection || loading" class="col-md-12"
                style="background-color: #efefef;min-height: 204px;">
                <div
                    style="display: none; height: 20px;color: #a29c9c;float: right;font-size: 31px;margin-right: 10px;">
                    <span><i class="fa fa-cog" aria-hidden="true"></i></span>
                </div>
                <div class="row" style="border: solid 1px #c5c5c5;">



                    <div class="col-sm-6">
                        <div class="col-12" style="margin: 10px;font-family: Lato-Regular;">
                            {{isDriver?driver?.full_name:(isOwnerDriver?ownerDriver?.full_name:company?.company_name)}}
                        </div>
                        <div class="col-12" style="width: 100%;float: left;">
                            <div [hidden]="isDriver?!driver?.profile_image:(isOwnerDriver?!ownerDriver?.profile_image:!company?.company_logo)" [style.background]="'url('+(isDriver?driver?.profile_image:(isOwnerDriver?ownerDriver?.profile_image:company?.company_logo))+')'" style="
                        height: 100px;   
                        margin: 10px auto;
                        width: 100px;
                        background-size: cover;
                        border-radius: 63px;
                        background-position: center;
                        ">
                            </div>
                            <img [hidden]="isDriver?driver?.profile_image:(isOwnerDriver?ownerDriver?.profile_image:company?.company_logo)"
                                style=" width: 100px;margin: 10px auto;" src="../../assets/images/no-dp-2.png" />
                        </div>
                        <div class="col-12" style="margin-top: 10px auto;cursor: pointer;overflow: hidden;">

                            <label class="myLabel-uploadprofilepic" style="cursor: pointer">
                                <input id="file" type="file" class="form-control"
                                    (change)="onProfileImageSelect($event)">
                                <span style="cursor: pointer;"
                                    [hidden]="isDriver?!driver?.profile_image:(isOwnerDriver?!ownerDriver?.profile_image:!company?.company_logo)">{{'PROFILE.change_profile_pic'
                                    | translate }}</span>
                                <span style="cursor: pointer;"
                                    [hidden]="isDriver?driver?.profile_image:(isOwnerDriver?ownerDriver?.profile_image:company?.company_logo)">
                                    {{'PROFILE.upload_profile_pic' | translate }}</span>
                            </label>

                        </div>  
                        <div *ngIf="isDriver" class="col-md-12" style="margin-bottom: 10px;"><i
                            class="fa fa-gbp" style="color: #337777;"></i>
                            <span style="color: #337777;"><b>{{ driver?.hourly_rate }}/hour</b></span>
                            

                        </div>  
                        <div *ngIf="isDriver && driver?.driver_category_id && driver?.driver_category_id!='6'" class="col-md-12"
                            style="margin: auto;margin-bottom: 10px;margin-top: 0px;overflow: hidden;">
                            <span [hidden]="driver?.licence_verified!='2'" class="input-group-addon"><i
                                    class="fa fa-id-badge" style="color: #337777;"></i> &nbsp;&nbsp;Driver Licence
                                Verified</span>
                            <span [hidden]="driver?.licence_verified!='1'" class="input-group-addon"><i
                                    class="fa fa-id-badge" style="color: rgb(243, 115, 42);"></i> &nbsp;&nbsp;Driver
                                Licence
                                Verification Pending</span>
                            <a [hidden]="driver?.licence_verified!='0'" data-toggle="modal"
                                data-target="#licenceVerificationModel" id="verify-licence-button"
                                style="color: rgb(243, 115, 42); text-decoration: underline dotted rgb(243, 115, 42);cursor: pointer;width: 200px;">Verify
                                Driver Licence</a>
                        </div>
                        <div [hidden]="driver?.licence_verified=='0' || !licenceRequired" class="col-md-12" style="margin-bottom: 10px;">
                            <button  data-toggle="modal"
                                data-target="#licenceVerificationModel" id="verify-licence-button" class="btn btn-success btn-md" style="background-color: rgb(243, 115, 42); color: #fff; border-color: rgb(243, 115, 42); padding: 5px 16px; font-size: 14px; border-radius: 5px;">Upload Documents</button>
                        </div>

                    </div>

                    <div class="col-sm-6" style="margin-top: 40px;">
                        <div class="form-group" style="margin-top:5px;">
                            <a class="btn btn-success btn-md"
                                style="padding-left: 40px;padding-right: 40px;background-color: #337777;border-color: #337777;width: 200px;"
                                [routerLink]="['/manage-booking']">Go to Bookings</a>
                        </div>

                        <div class="form-group" style="margin-top:5px;">
                            <a *ngIf="isCompany" class="btn btn-success btn-md"
                                style="padding-left: 40px;padding-right: 40px;background-color: #337777;border-color: #337777;width: 200px;"
                                [routerLink]="['/invoice']">Go to Invoices</a>
                            <a *ngIf="isDriver" class="btn btn-success btn-md"
                                style="padding-left: 40px;padding-right: 40px;background-color: #337777;border-color: #337777;width: 200px;"
                                [routerLink]="['/payout']">Go to Payments</a>
                        </div>
                    </div>
                    <div class="col-12 validation_error" style="text-align: center;">
                        <span>{{profileImageError}}</span>
                    </div>
                </div>

            </div>

            <!-- <app-today-booking style="width: 100%; margin-bottom: 50px;" ></app-today-booking> -->
            <!-- <a data-toggle="modal" data-target="#bankDetailModal" class="btn btn-warning"
                            style="background-color: rgb(243, 115, 42);color: rgb(255, 255, 255); float:right;margin-right: 5px;cursor: pointer;"
                            [hidden]="profileEdit">Add Bank Detail</a> -->
            
            <div class="col-12 " style="margin-top: -1px;
                            padding: 24px;
                            border: solid 1px #ccc;">
                <div class="col-12">
                    <h2 style="    font-size: 23px;
                    color: #337777;
                    font-family: Lato-Regular;
                  
                    width: 100%;
                    margin-bottom: 10px;
                   ">Basic Details</h2>
                   <div style="width:100%;height: 1px; background-color: #e6e6e6;    margin-bottom: 31px;">
                    </div>
                </div>

                <div class="form-group">
                    <div class="input-group">
                        <label for="email" class="control-label col-12 col-sm-12 col-lg-4">Email
                        </label>
                        <p class="form-control-static">{{ isCompany?company?.email:(isOwnerDriver?ownerDriver?.email:driver?.email) }} <a data-toggle="modal" data-target="#changeEmailModal"><i class="fa fa-pencil"
                            style="cursor: pointer;" title="Change Email"></i></a></p>
                    </div>
                </div>
                <div class="form-group">
                    <div class="input-group">
                        <label for="email" class="control-label col-12 col-sm-12 col-lg-4">Mobile
                        </label>
                        <p class="form-control-static">{{ isCompany?company?.mobile:(isOwnerDriver?ownerDriver?.mobile:driver?.mobile) }} <a data-toggle="modal" data-target="#changeMobileModal"><i class="fa fa-pencil"
                            style="cursor: pointer;" title="Change Mobile"></i></a></p>
                    </div>
                </div>
                <a data-toggle="modal" data-target="#changePasswordModal" style="color: rgb(243, 115, 42);cursor: pointer;float:right;">
                Change password</a>
                
            </div>
            <div *ngIf="isCompany && company" class="col-12 " style="margin-top: -1px;
            padding: 24px;
            border: solid 1px #ccc;">


                <form name="form" [formGroup]="companyForm" autocomplete="off">

                    <div class="col-12">

                        <h2 style="    font-size: 23px;
                        color: #337777;
                        font-family: Lato-Regular;
                      
                        width: 100%;
                        margin-bottom: 10px;
                       ">Hirer Details</h2>

                        <div style="width:100%;height: 1px; background-color: #e6e6e6;    margin-bottom: 31px;">

                        </div>
                    </div>


                    <div class="col-12 col-sm-12 col-lg-12" style="float:right;padding-right: 0px;">
                        <i class="fa fa-pencil" (click)="showEdit()" [hidden]="profileEdit"
                            style="float:right; cursor: pointer; margin-right: 5px;" title="Edit"></i>
                        <i class="fa fa-times" (click)="hideEdit()" [hidden]="!profileEdit"
                            style="float:right; cursor: pointer; margin-right: 5px;" title="Cancel"></i>
                    </div>



                    <div class="form-group" style="width: 100%;">
                        <div class="input-group">
                            <label for="company_name" class="control-label col-12 col-sm-12 col-lg-4">{{'SIGNUP.company'
                                | translate }}</label>
                            <p class="form-control-static" [hidden]="profileEdit">{{ company.company_name }}</p>
                            <input [hidden]="!profileEdit" type="text" formControlName="company_name"
                                class="form-control" placeholder="{{'SIGNUP.company' | translate }}">
                        </div>
                        <div class="validation_error">
                            <span
                                *ngIf="validationErrorsCompanyDetails.company_name">{{validationErrorsCompanyDetails.company_name}}</span>

                        </div>
                    </div>

                    <div class="form-group">
                        <div class="input-group">
                            <label for="contact_name" class="control-label col-12 col-sm-12 col-lg-4">Contact
                                Name</label>
                            <p class="form-control-static" [hidden]="profileEdit">{{ company.contact_name }}</p>
                            <input [hidden]="!profileEdit" type="text" formControlName="contact_name"
                                class="form-control" placeholder="Contact Name">
                        </div>
                        <div class="validation_error">
                            <span
                                *ngIf="validationErrorsCompanyDetails.contact_name">{{validationErrorsCompanyDetails.contact_name}}</span>

                        </div>
                    </div>
                    <div class="form-group">
                        <div class="input-group">
                            <label for="company_contact_number" class="control-label col-12 col-sm-12 col-lg-4">Company Contact
                                Number</label>
                            <p class="form-control-static" [hidden]="profileEdit">{{ company.company_contact_number }}</p>
                            <input [hidden]="!profileEdit" type="text" formControlName="company_contact_number"
                                class="form-control" placeholder="Company Contact Number">
                        </div>
                        <div class="validation_error">
                            <span
                                *ngIf="validationErrorsCompanyDetails.company_contact_number">{{validationErrorsCompanyDetails.company_contact_number}}</span>

                        </div>
                    </div>
                    <div class="form-group">
                        <div class="input-group">
                            <label for="company-city-select2"
                                class="control-label col-12 col-sm-12 col-lg-4">City</label>
                            <select formControlName="city" id="company-city-select2"
                                class="form-control  col-12 col-sm-12 col-lg-4" placeholder="City">


                            </select>
                        </div>
                        <div class="validation_error">
                            <span
                                *ngIf="validationErrorsCompanyDetails.city">{{validationErrorsCompanyDetails.city}}</span>
                        </div>
                    </div>

                    <div class="form-group">
                        <div class="input-group">
                            <label for="about_company" class="control-label col-12 col-sm-12 col-lg-4">About
                                Company</label>
                            <p class="form-control-static" [hidden]="profileEdit">{{ company.about_company }}</p>
                            <textarea [hidden]="!profileEdit" class="form-control" formControlName="about_company"
                                rows="2" placeholder="About Company"></textarea>
                        </div>
                        <div class="validation_error">
                            <span
                                *ngIf="validationErrorsCompanyDetails.about_company">{{validationErrorsCompanyDetails.about_company}}</span>
                        </div>
                    </div>

                    <div class="form-group" style="margin-top: 35px;;">
                        <button type="submit" [hidden]="!profileEdit" (click)="submitCompanyDetails()"
                            class="btn btn-primary btn-block btn-lg"
                            style="background-color: #337777;border-color: #337777;">Update</button>
                    </div>

                </form>
                <div class="row">
                    <div class="col-12">

                        <h2 style="    font-size: 23px;
                        color: #337777;
                        font-family: Lato-Regular;
                      
                        width: 100%;
                        margin-bottom: 10px;
                       ">
                            Locations</h2>

                        <div style="width:100%;height: 1px; background-color: #e6e6e6;    margin-bottom: 31px;">

                        </div>
                    </div>
                    <div class="col-12 col-sm-12 col-lg-12" style="float:right;">
                        <a data-toggle="modal" data-target="#locationModal" class="btn btn-warning"
                            style="background-color: rgb(243, 115, 42);color: rgb(255, 255, 255); float:right;margin-right: 5px;cursor: pointer;"
                            [hidden]="profileEdit" id="company-location-button"><i class="fa fa-plus" style="font-size: 14px;"></i> Add New
                            Location</a>
                        <a data-toggle="modal" data-target="#healthSafetyModal" class="btn btn-warning"
                            style="display: none;" id="health-safety-button">Health & Safety</a>
                    </div>
                </div>
                <div class="row" [hidden]="!locationListLoaded" style="margin-bottom: 15px;margin-top: 20px">
                    <div class="row container">
                        <ng-container *ngFor="let companyLocation of companyLocations; let i=index">
                            <div class="col-md-4">
                                <div class="col-md-12" [ngStyle]="{'margin-bottom': '10px', 'outline': 'none'}" style="margin-bottom: 10px;
                                        outline: none;
                                        border: solid 1px #e2e2e2;
                                        padding: 30px;">

                                    <address>
                                        <strong>{{companyLocation.address_title}}.</strong><br>
                                        {{companyLocation.address}}
                                    </address>
                                    <button class="btn" style="background-color: rgb(243, 115, 42);
                                        color: #fff;
                                        border-color: rgb(243, 115, 42);
                                        padding: 5px 16px;
                                        font-size: 14px;
                                        border-radius: 5px;" (click)="deleteLocation(companyLocation, i)"><i
                                            class="fa fa-times" style="font-size: 14px;"></i> Remove</button>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                    <!--<div *ngIf='locationViewMoreUrl' class="view-more-button" (click)="viewMore()">
                        VIEW MORE
                    </div>-->
                </div>
            </div>

            <div *ngIf="isDriver && driver" class="col-12" style="margin-top: -1px;
            padding: 24px;
            border: solid 1px #ccc;">
                <div class="col-12">
                    <h2 style="    font-size: 23px;
                                            color: #337777;
                                            font-family: Lato-Regular;
                                        
                                            width: 100%;
                                            margin-bottom: 10px;
                                        ">Driver Details</h2>
                    <div style="width:100%;height: 1px; background-color: #e6e6e6;    margin-bottom: 31px;">
                    </div>
                </div>
                <form name="form" [formGroup]="driverForm" autocomplete="off">
                    <div class="col-12 col-sm-12 col-lg-12" style="float:right;padding-right: 0px;">
                        <i class="fa fa-pencil" (click)="showEdit()" [hidden]="profileEdit"
                            style="float:right; cursor: pointer;margin-right: 5px;" title="Edit"></i>
                        <i class="fa fa-times" (click)="hideEdit()" [hidden]="!profileEdit"
                            style="float:right; cursor: pointer;margin-right: 5px;" title="Cancel"></i>
                    </div>
                    <div class="form-group row" style="width: 100%;">
                        <div class="input-group">
                            <label for="full_name"
                                class="control-label col-12 col-sm-12 col-lg-3">{{'BECOME_A_DRIVER.full_name' |
                                translate }}</label>
                            <p class="form-control-static" [hidden]="profileEdit">{{ driver.full_name }}</p>
                            <input [hidden]="!profileEdit" type="text" formControlName="full_name" class="form-control"
                                placeholder="{{'BECOME_A_DRIVER.full_name' | translate }}">

                        </div>
                        <div class="validation_error">
                            <span
                                *ngIf="validationErrorsDriverDetails.full_name">{{validationErrorsDriverDetails.full_name}}</span>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="input-group">
                            <label for="driver-city-select2"
                                class="control-label col-12 col-sm-12 col-lg-3">City</label>

                            <select formControlName="city" id="driver-city-select2"
                                class="form-control  col-12 col-sm-12 col-lg-4" placeholder="City">
                            </select>
                        </div>
                        <div class="validation_error">
                            <span
                                *ngIf="validationErrorsDriverDetails.city">{{validationErrorsDriverDetails.city}}</span>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="input-group">
                            <label for="years_driving" class="control-label col-12 col-sm-12 col-lg-3">Years of
                                driving</label>
                            <p class="form-control-static" [hidden]="profileEdit">{{ driver.years_driving }} Years</p>
                            <select [hidden]="!profileEdit" formControlName="years_driving" class="form-control"
                                placeholder="Years of driving">
                                <option [ngValue]="null">Years of driving</option>
                                <option *ngFor="let year of years_driving" [ngValue]="year">{{year}}</option>
                            </select>
                        </div>
                        <div class="validation_error">
                            <span
                                *ngIf="validationErrorsDriverDetails.years_driving">{{validationErrorsDriverDetails.years_driving}}</span>
                        </div>
                    </div>

                    <!-- <div class="form-group row">
                        <div class="input-group">
                            <label for="hourly_rate" class="control-label col-12 col-sm-12 col-lg-3">Hourly rate (<i
                                    class="fa fa-gbp" style="font-size: 14px;"></i>)</label>
                            <p class="form-control-static" [hidden]="profileEdit">{{ driver.hourly_rate }}</p>
                            <input [hidden]="!profileEdit" type="number" min="8" max="11" step="0.5"
                                formControlName="hourly_rate" class="form-control" placeholder="Set your hourly rate">
                        </div>
                        <span [hidden]="!profileEdit" class="offset-lg-3" style="font-size: 13px;text-align: left;">The
                            lower you offer, the higher you will appear in searches(max:11)</span>
                        <div class="validation_error">
                            <span
                                *ngIf="validationErrorsDriverDetails.hourly_rate">{{validationErrorsDriverDetails.hourly_rate}}</span>
                        </div>
                    </div> -->

                    <div class="form-group row">
                        <div class="input-group">
                            <label for="previous_work" class="control-label col-12 col-sm-12 col-lg-3">Previous
                                work</label>
                            <p class="form-control-static" [hidden]="profileEdit">{{ driver.previous_work }}</p>
                            <input [hidden]="!profileEdit" type="text" formControlName="previous_work"
                                class="form-control" placeholder="Previous Work">
                        </div>
                        <div class="validation_error">
                            <span
                                *ngIf="validationErrorsDriverDetails.previous_work">{{validationErrorsDriverDetails.previous_work}}</span>
                        </div>
                    </div>

                    <div class="form-group row">
                        <div class="input-group">
                            <label for="dateofbirth"
                                class="control-label col-12 col-sm-12 col-lg-3">{{'BECOME_A_DRIVER.dob' | translate
                                }}</label>
                            <p class="form-control-static" [hidden]="profileEdit">{{ driver.dob }}</p>
                            <input [hidden]="!profileEdit" type="date" formControlName="dateofbirth"
                                class="form-control" placeholder="{{'BECOME_A_DRIVER.dob' | translate }}"
                                value="1997-1-1" min="1997-1-1">

                        </div>
                        <div class="validation_error">
                            <span
                                *ngIf="validationErrorsDriverDetails.dateofbirth">{{validationErrorsDriverDetails.dateofbirth}}</span>
                        </div>
                    </div>

                    <div class="form-group row">
                        <div style="float: left; margin-left: 20px;">
                            <input id="male" type="radio" value="male" name="gender" formControlName="gender">
                            <label for="male"> &nbsp;
                                {{'BECOME_A_DRIVER.male' | translate }}</label>
                        </div>

                        <div style="float: left;    margin-left: 15px;">
                            <input id="female" type="radio" value="female" name="gender" formControlName="gender">
                            <label for="female"> &nbsp;
                                {{'BECOME_A_DRIVER.female' | translate }}</label>
                        </div>

                        <div style="float: left;    margin-left: 15px;">
                            <input id="other" type="radio" value="other" name="gender" formControlName="gender">
                            <label for="other"> &nbsp; Other</label>
                        </div>

                        <div class="validation_error">
                            <span
                                *ngIf="validationErrorsDriverDetails.gender">{{validationErrorsDriverDetails.gender}}</span>
                        </div>
                    </div>

                    <div class="form-group row">
                        <div class="input-group">
                            <label for="aboutme" class="control-label col-12 col-sm-12 col-lg-3">About yourself</label>
                            <p class="form-control-static" [hidden]="profileEdit">{{ driver.aboutme }}</p>
                            <textarea [hidden]="!profileEdit" class="form-control" formControlName="aboutme" rows="2"
                                placeholder="About yourself"></textarea>
                        </div>
                        <div class="validation_error">
                            <span
                                *ngIf="validationErrorsDriverDetails.aboutme">{{validationErrorsDriverDetails.aboutme}}</span>
                        </div>
                    </div>

                    <div class="form-group" style="margin-top: 35px;;">
                        <button type="submit" (click)="submitDriverDetails()" [hidden]="!profileEdit"
                            class="btn btn-primary btn-block btn-lg"
                            style="background-color: #337777;border-color: #337777;">Update</button>
                    </div>
                </form>
                <div class="row" [hidden]="!bankDetailLoaded" style="margin-bottom: 15px;margin-top: 20px">
                    <div class="col-12">

                        <h2 style="    font-size: 23px;
                                            color: #337777;
                                            font-family: Lato-Regular;
                                        
                                            width: 100%;
                                            margin-bottom: 10px;
                                        ">
                            Bank Details</h2>

                        <div style="width:100%;height: 1px; background-color: #e6e6e6;    margin-bottom: 31px;">

                        </div>
                    </div>

                    <div class="col-md-12">
                        <div class="col-12" style="margin-bottom: 10px;
                                        outline: none;
                                        border: solid 1px #e2e2e2;
                                        padding: 30px;" [ngStyle]="{'margin-bottom': '10px', 'outline': 'none'}">

                            <address>
                                <strong>Account Number : {{bankDetail?.account_number}}.</strong><br>
                                Name : {{bankDetail?.name}} <br>
                                Sortcode: {{bankDetail?.sortcode}}
                            </address>
                            <button class="btn" data-toggle="modal" data-target="#bankDetailModal"
                                style="background-color: rgb(243, 115, 42);
                                color: #fff;
                                border-color: rgb(243, 115, 42);
                                padding: 5px 16px;
                                font-size: 14px;
                                border-radius: 5px;"><i
                                    class="fa fa-pencil-square-o" style="font-size: 12px;"></i> Edit</button>
                        </div>
                    </div>

                </div>

                <div class="row" [hidden]="bankDetailLoaded">
                    <div class="col-12 col-sm-12 col-lg-12" style="float:right;">
                        <a data-toggle="modal" data-target="#bankDetailModal" class="btn btn-warning"
                            style="background-color: rgb(243, 115, 42);color: rgb(255, 255, 255); float:right;margin-right: 5px;cursor: pointer;"
                            [hidden]="profileEdit">Add Bank Detail</a>
                    </div>
                </div>

                <div class="row" [hidden]="!locationListLoaded" style="margin-bottom: 15px;margin-top: 20px">

                    <div class="row">
                        <div class="col-12">
    
                            <h2 style="    font-size: 23px;
                            color: #337777;
                            font-family: Lato-Regular;
                          
                            width: 100%;
                            margin-bottom: 10px;
                           ">
                                Location</h2>
    
                            <div style="width:100%;height: 1px; background-color: #e6e6e6;    margin-bottom: 31px;">
    
                            </div>
                        </div>
                        <div *ngIf="!driverLocation" class="col-12 col-sm-12 col-lg-12" style="float:right;">
                            <a data-toggle="modal" data-target="#driverLocationModal" class="btn btn-warning"
                                style="background-color: rgb(243, 115, 42);color: rgb(255, 255, 255); float:right;margin-right: 5px;cursor: pointer;"
                                [hidden]="profileEdit" id="driver-location-button"><i class="fa fa-plus" style="font-size: 14px;"></i> Add
                                Location</a>
                        </div>
                    </div>
                    <div class="row container" *ngIf="driverLocation">
                        <div class="col-md-4">
                            <div class="col-md-12" [ngStyle]="{'margin-bottom': '10px', 'outline': 'none'}" style="margin-bottom: 10px;
                                    outline: none;
                                    border: solid 1px #e2e2e2;
                                    padding: 30px;">

                                <address>
                                    <strong>{{driverLocation.address_title}}.</strong><br>
                                    {{driverLocation.address}} <br>
                                    Ready to travel : {{driverLocation.distance_ready_to_travel}} Miles
                                </address>
                                <button data-toggle="modal" data-target="#driverLocationModal" class="btn" style="background-color: rgb(243, 115, 42);
                                    color: #fff;
                                    border-color: rgb(243, 115, 42);
                                    padding: 5px 16px;
                                    font-size: 14px;
                                    border-radius: 5px;"><i
                                        class="fa fa-times" style="font-size: 14px;"></i> Edit</button>
                            </div>
                        </div>
                    </div>
                    <!--<div *ngIf='locationViewMoreUrl' class="view-more-button" (click)="viewMore()">
                        VIEW MORE
                    </div>-->
                </div>
            </div>

            <div *ngIf="isOwnerDriver && ownerDriver" class="col-12" style="margin-top: -1px;
            padding: 24px;
            border: solid 1px #ccc;">
                <div class="col-12">
                    <h2 style="    font-size: 23px;
                                            color: #337777;
                                            font-family: Lato-Regular;
                                        
                                            width: 100%;
                                            margin-bottom: 10px;
                                        ">Owner Details</h2>
                    <div style="width:100%;height: 1px; background-color: #e6e6e6;    margin-bottom: 31px;">
                    </div>
                </div>
                <form name="form" [formGroup]="ownerDriverForm" autocomplete="off">
                    <div class="col-12 col-sm-12 col-lg-12" style="float:right;padding-right: 0px;">
                        <i class="fa fa-pencil" (click)="showEdit()" [hidden]="profileEdit"
                            style="float:right; cursor: pointer;margin-right: 5px;" title="Edit"></i>
                        <i class="fa fa-times" (click)="hideEdit()" [hidden]="!profileEdit"
                            style="float:right; cursor: pointer;margin-right: 5px;" title="Cancel"></i>
                    </div>
                    <div class="form-group row" style="width: 100%;">
                        <div class="input-group">
                            <label for="full_name"
                                class="control-label col-12 col-sm-12 col-lg-3">{{'BECOME_A_DRIVER.full_name' |
                                translate }}</label>
                            <p class="form-control-static" [hidden]="profileEdit">{{ ownerDriver.full_name }}</p>
                            <input [hidden]="!profileEdit" type="text" formControlName="full_name" class="form-control"
                                placeholder="{{'BECOME_A_DRIVER.full_name' | translate }}">

                        </div>
                        <div class="validation_error">
                            <span
                                *ngIf="validationErrorsOwnerDriverDetails.full_name">{{validationErrorsOwnerDriverDetails.full_name}}</span>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="input-group">
                            <label for="owner-driver-city-select2"
                                class="control-label col-12 col-sm-12 col-lg-3">City</label>

                            <select formControlName="city" id="owner-driver-city-select2"
                                class="form-control  col-12 col-sm-12 col-lg-4" placeholder="City">
                            </select>
                        </div>
                        <div class="validation_error">
                            <span
                                *ngIf="validationErrorsOwnerDriverDetails.city">{{validationErrorsOwnerDriverDetails.city}}</span>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="input-group">
                            <label for="years_driving" class="control-label col-12 col-sm-12 col-lg-3">Years of
                                driving</label>
                            <p class="form-control-static" [hidden]="profileEdit">{{ ownerDriver.years_driving }} Years</p>
                            <select [hidden]="!profileEdit" formControlName="years_driving" class="form-control"
                                placeholder="Years of driving">
                                <option [ngValue]="null">Years of driving</option>
                                <option *ngFor="let year of years_driving" [ngValue]="year">{{year}}</option>
                            </select>
                        </div>
                        <div class="validation_error">
                            <span
                                *ngIf="validationErrorsOwnerDriverDetails.years_driving">{{validationErrorsOwnerDriverDetails.years_driving}}</span>
                        </div>
                    </div>

                    <!-- <div class="form-group row">
                        <div class="input-group">
                            <label for="hourly_rate" class="control-label col-12 col-sm-12 col-lg-3">Hourly rate (<i
                                    class="fa fa-gbp" style="font-size: 14px;"></i>)</label>
                            <p class="form-control-static" [hidden]="profileEdit">{{ ownerDriver.hourly_rate }}</p>
                            <input [hidden]="!profileEdit" type="number" min="8" max="11" step="0.5"
                                formControlName="hourly_rate" class="form-control" placeholder="Set your hourly rate">
                        </div>
                        <span [hidden]="!profileEdit" class="offset-lg-3" style="font-size: 13px;text-align: left;">The
                            lower you offer, the higher you will appear in searches(max:11)</span>
                        <div class="validation_error">
                            <span
                                *ngIf="validationErrorsDriverDetails.hourly_rate">{{validationErrorsDriverDetails.hourly_rate}}</span>
                        </div>
                    </div> -->

                    <div class="form-group row">
                        <div class="input-group">
                            <label for="previous_work" class="control-label col-12 col-sm-12 col-lg-3">Previous
                                work</label>
                            <p class="form-control-static" [hidden]="profileEdit">{{ ownerDriver.previous_work }}</p>
                            <input [hidden]="!profileEdit" type="text" formControlName="previous_work"
                                class="form-control" placeholder="Previous Work">
                        </div>
                        <div class="validation_error">
                            <span
                                *ngIf="validationErrorsOwnerDriverDetails.previous_work">{{validationErrorsOwnerDriverDetails.previous_work}}</span>
                        </div>
                    </div>

                    <div class="form-group row">
                        <div class="input-group">
                            <label for="dateofbirth"
                                class="control-label col-12 col-sm-12 col-lg-3">{{'BECOME_A_DRIVER.dob' | translate
                                }}</label>
                            <p class="form-control-static" [hidden]="profileEdit">{{ ownerDriver.dob }}</p>
                            <input [hidden]="!profileEdit" type="date" formControlName="dateofbirth"
                                class="form-control" placeholder="{{'BECOME_A_DRIVER.dob' | translate }}"
                                value="1997-1-1" min="1997-1-1">

                        </div>
                        <div class="validation_error">
                            <span
                                *ngIf="validationErrorsOwnerDriverDetails.dateofbirth">{{validationErrorsOwnerDriverDetails.dateofbirth}}</span>
                        </div>
                    </div>

                    <div class="form-group row">
                        <div style="float: left; margin-left: 20px;">
                            <input id="male" type="radio" value="male" name="gender" formControlName="gender">
                            <label for="male"> &nbsp;
                                {{'BECOME_A_DRIVER.male' | translate }}</label>
                        </div>

                        <div style="float: left;    margin-left: 15px;">
                            <input id="female" type="radio" value="female" name="gender" formControlName="gender">
                            <label for="female"> &nbsp;
                                {{'BECOME_A_DRIVER.female' | translate }}</label>
                        </div>

                        <div style="float: left;    margin-left: 15px;">
                            <input id="other" type="radio" value="other" name="gender" formControlName="gender">
                            <label for="other"> &nbsp; Other</label>
                        </div>

                        <div class="validation_error">
                            <span
                                *ngIf="validationErrorsOwnerDriverDetails.gender">{{validationErrorsOwnerDriverDetails.gender}}</span>
                        </div>
                    </div>

                    <div class="form-group row">
                        <div class="input-group">
                            <label for="aboutme" class="control-label col-12 col-sm-12 col-lg-3">About yourself</label>
                            <p class="form-control-static" [hidden]="profileEdit">{{ ownerDriver.aboutme }}</p>
                            <textarea [hidden]="!profileEdit" class="form-control" formControlName="aboutme" rows="2"
                                placeholder="About yourself"></textarea>
                        </div>
                        <div class="validation_error">
                            <span
                                *ngIf="validationErrorsOwnerDriverDetails.aboutme">{{validationErrorsOwnerDriverDetails.aboutme}}</span>
                        </div>
                    </div>

                    <div class="form-group" style="margin-top: 35px;;">
                        <button type="submit" (click)="submitDriverDetails()" [hidden]="!profileEdit"
                            class="btn btn-primary btn-block btn-lg"
                            style="background-color: #337777;border-color: #337777;">Update</button>
                    </div>
                </form>
                <div class="row" [hidden]="!bankDetailLoaded" style="margin-bottom: 15px;margin-top: 20px">
                    <div class="col-12">

                        <h2 style="    font-size: 23px;
                                            color: #337777;
                                            font-family: Lato-Regular;
                                        
                                            width: 100%;
                                            margin-bottom: 10px;
                                        ">
                            Bank Details</h2>

                        <div style="width:100%;height: 1px; background-color: #e6e6e6;    margin-bottom: 31px;">

                        </div>
                    </div>

                    <div class="col-md-12">
                        <div class="col-12" style="margin-bottom: 10px;
                                        outline: none;
                                        border: solid 1px #e2e2e2;
                                        padding: 30px;" [ngStyle]="{'margin-bottom': '10px', 'outline': 'none'}">

                            <address>
                                <strong>Account Number : {{bankDetail?.account_number}}.</strong><br>
                                Name : {{bankDetail?.name}} <br>
                                Sortcode: {{bankDetail?.sortcode}}
                            </address>
                            <button class="btn" data-toggle="modal" data-target="#bankDetailModal"
                                style="background-color: rgb(243, 115, 42);
                                color: #fff;
                                border-color: rgb(243, 115, 42);
                                padding: 5px 16px;
                                font-size: 14px;
                                border-radius: 5px;"><i
                                    class="fa fa-pencil-square-o" style="font-size: 12px;"></i> Edit</button>
                        </div>
                    </div>

                </div>

                <div class="row" [hidden]="bankDetailLoaded">
                    <div class="col-12 col-sm-12 col-lg-12" style="float:right;">
                        <a data-toggle="modal" data-target="#bankDetailModal" class="btn btn-warning"
                            style="background-color: rgb(243, 115, 42);color: rgb(255, 255, 255); float:right;margin-right: 5px;cursor: pointer;"
                            [hidden]="profileEdit">Add Bank Detail</a>
                    </div>
                </div>

                <div class="row" [hidden]="!locationListLoaded" style="margin-bottom: 15px;margin-top: 20px">

                    <div class="row">
                        <div class="col-12">
    
                            <h2 style="    font-size: 23px;
                            color: #337777;
                            font-family: Lato-Regular;
                          
                            width: 100%;
                            margin-bottom: 10px;
                           ">
                                Location</h2>
    
                            <div style="width:100%;height: 1px; background-color: #e6e6e6;    margin-bottom: 31px;">
    
                            </div>
                        </div>
                        <div *ngIf="!driverLocation" class="col-12 col-sm-12 col-lg-12" style="float:right;">
                            <a data-toggle="modal" data-target="#driverLocationModal" class="btn btn-warning"
                                style="background-color: rgb(243, 115, 42);color: rgb(255, 255, 255); float:right;margin-right: 5px;cursor: pointer;"
                                [hidden]="profileEdit" id="driver-location-button"><i class="fa fa-plus" style="font-size: 14px;"></i> Add
                                Location</a>
                        </div>
                    </div>
                    <div class="row container" *ngIf="driverLocation">
                        <div class="col-md-4">
                            <div class="col-md-12" [ngStyle]="{'margin-bottom': '10px', 'outline': 'none'}" style="margin-bottom: 10px;
                                    outline: none;
                                    border: solid 1px #e2e2e2;
                                    padding: 30px;">

                                <address>
                                    <strong>{{driverLocation.address_title}}.</strong><br>
                                    {{driverLocation.address}} <br>
                                    Ready to travel : {{driverLocation.distance_ready_to_travel}} Miles
                                </address>
                                <button data-toggle="modal" data-target="#driverLocationModal" class="btn" style="background-color: rgb(243, 115, 42);
                                    color: #fff;
                                    border-color: rgb(243, 115, 42);
                                    padding: 5px 16px;
                                    font-size: 14px;
                                    border-radius: 5px;"><i
                                        class="fa fa-times" style="font-size: 14px;"></i> Edit</button>
                            </div>
                        </div>
                    </div>
                    <!--<div *ngIf='locationViewMoreUrl' class="view-more-button" (click)="viewMore()">
                        VIEW MORE
                    </div>-->
                </div>
            </div>
        </div>
    </div>
</div>

<div id="locationModal" class="modal fade" role="dialog" data-keyboard="false" data-backdrop="static">
    <div class="modal-dialog">
        <!-- Modal content-->
        <div class="modal-content" style="min-height: 200px;;">
            <div class="modal-header">
                <h5 class="modal-title" style="color: #337777;font-family: Lato-Regular;text-align: center;">Add New Location</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body  booking" [hidden]="locationResultView" style="margin:auto;">

                <div style="text-align: center;" *ngIf="!loading">
                    <form name="form" [formGroup]="locationForm">
                        <div class="form-group row">
                            <div class="input-group">
                                <label for="addresstext" class="control-label col-12 col-sm-6 col-lg-4">Search
                                    Location</label>
                                <input class="form-control" type="text" #addresstext>
                            </div>
                            <div #gmap style="height:300px; width:100%;" [hidden]="locationLoading">
                            </div>
                        </div>
                        <br>
                        <div class="form-group row" [hidden]="locationLoading">
                            <div class="input-group">
                                <label for="address_title" class="control-label col-12 col-sm-6 col-lg-4">Address saved
                                    as:</label>
                                <input type="text" formControlName="address_title" class="form-control"
                                    placeholder="Address saved as">

                            </div>
                            <div class="validation_error col-12 col-sm-12 col-lg-12">
                                <span
                                    *ngIf="validationErrorsLocationDetails.address_title">{{validationErrorsLocationDetails.address_title}}</span>

                            </div>
                        </div>
                        <div class="form-group row" [hidden]="locationLoading">
                            <div class="input-group">
                                <label for="address" class="control-label col-12 col-sm-6 col-lg-4">Enter
                                    Address</label>
                                <input type="text" formControlName="address" class="form-control"
                                    placeholder="Enter the address">
                                <input type="hidden" formControlName="name">
                                <input type="hidden" formControlName="latitude">
                                <input type="hidden" formControlName="longitude">
                            </div>
                            <div class="validation_error col-12 col-sm-12 col-lg-12">
                                <span
                                    *ngIf="validationErrorsLocationDetails.address">{{validationErrorsLocationDetails.address}}</span>
                            </div>
                        </div>
                    </form>
                </div>
            </div>

            <div [hidden]="!locationSaving">
                <div style="text-align: center;
                            margin-top: 62px;
                            min-height: 100px; ">

                    <img src="../../assets/images/loader.gif"
                        style="margin-top: 10px;   width: 70px;padding-left: 20px;" />
                </div>
            </div>


            <div class="text-center" [hidden]="!locationResultView" style="min-height: 115px;margin-top: 48px;">

                <span class="font-weight-normal" style="font-size:18px;color: #337777;font-family: Lato-Regular;">
                    Location added successfully
                </span>


            </div>

            <div class="button-group" [hidden]="locationResultView" style="text-align: center;">
                <button type="button" style="background-color: rgb(243, 115, 42);;
                border-color: rgb(243, 115, 42);font-family: Lato-Regular; padding-left: 22px;padding-right: 22px;"
                    (click)="addLocation()" class="btn btn-info" [hidden]="locationLoading"> Save </button>

            </div>

            <div class="button-group" style="text-align: right;">
                <button type="button" id="closLocationModelPopup" class="btn btn-default"
                    data-dismiss="modal">Close</button>
            </div>
        </div>

    </div>
</div>

<div id="ownerDriverLocationModal" class="modal fade" role="dialog" data-keyboard="false" data-backdrop="static">
    <div class="modal-dialog">
        <!-- Modal content-->
        <div class="modal-content" style="min-height: 200px;">
            <div class="modal-header">
                <h5 class="modal-title" style="color: #337777;font-family: Lato-Regular;text-align: center;">Add Your Home Location</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body  booking" [hidden]="locationResultView" style="margin:auto;">

                <div style="text-align: center;" *ngIf="!loading">
                    <form name="form" [formGroup]="ownerDriverLocationForm">
                        <div class="form-group row">
                            <div class="input-group">
                                <label for="ownerDriverAddresstext" class="control-label col-12 col-sm-6 col-lg-4">Search
                                    Location</label>
                                <input class="form-control" type="text" #ownerDriverAddresstext>
                            </div>
                            <div #driverGmap style="height:300px; width:100%;" [hidden]="locationLoading">
                            </div>
                        </div>
                        <br>
                        <div class="form-group row" [hidden]="locationLoading">
                            <div class="input-group">
                                <label for="address_title" class="control-label col-12 col-sm-6 col-lg-4">Address saved
                                    as:</label>
                                <input type="text" formControlName="address_title" class="form-control"
                                    placeholder="Address saved as">

                            </div>
                            <div class="validation_error col-12 col-sm-12 col-lg-12">
                                <span
                                    *ngIf="validationErrorsOwnerDriverLocationDetails.address_title">{{validationErrorsOwnerDriverLocationDetails.address_title}}</span>

                            </div>
                        </div>
                        <div class="form-group row" [hidden]="locationLoading">
                            <div class="input-group">
                                <label for="address" class="control-label col-12 col-sm-6 col-lg-4">Enter
                                    Address</label>
                                <input type="text" formControlName="address" class="form-control"
                                    placeholder="Enter the address">
                                <input type="hidden" formControlName="name">
                                <input type="hidden" formControlName="latitude">
                                <input type="hidden" formControlName="longitude">
                            </div>
                            <div class="validation_error col-12 col-sm-12 col-lg-12">
                                <span
                                    *ngIf="validationErrorsOwnerDriverLocationDetails.address">{{validationErrorsOwnerDriverLocationDetails.address}}</span>
                            </div>
                        </div>

                        <div class="form-group row" [hidden]="locationLoading">
                            <div class="input-group">
                                <label for="distance_ready_to_travel" class="control-label col-12 col-sm-6 col-lg-4">Distance ready to travel for work(Miles):</label>
                                <input type="number" formControlName="distance_ready_to_travel" class="form-control"
                                    placeholder="Address saved as" min="5">

                            </div>
                            <div class="validation_error col-12 col-sm-12 col-lg-12">
                                <span
                                    *ngIf="validationErrorsOwnerDriverLocationDetails.distance_ready_to_travel">{{validationErrorsOwnerDriverLocationDetails.distance_ready_to_travel}}</span>

                            </div>
                        </div>
                    </form>
                </div>
            </div>

            <div [hidden]="!locationSaving">
                <div style="text-align: center;
                            margin-top: 62px;
                            min-height: 100px; ">

                    <img src="../../assets/images/loader.gif"
                        style="margin-top: 10px;   width: 70px;padding-left: 20px;" />
                </div>
            </div>


            <div class="text-center" [hidden]="!locationResultView" style="min-height: 115px;margin-top: 48px;">

                <span class="font-weight-normal" style="font-size:18px;color: #337777;font-family: Lato-Regular;">
                    Location added successfully
                </span>


            </div>

            <div class="button-group" [hidden]="locationResultView" style="text-align: center;">
                <button type="button" style="background-color: rgb(243, 115, 42);;
                border-color: rgb(243, 115, 42);font-family: Lato-Regular; padding-left: 22px;padding-right: 22px;"
                    (click)="addOwnerDriverLocation()" class="btn btn-info" [hidden]="locationLoading"> Save </button>

            </div>

            <div class="button-group" style="text-align: right;">
                <button type="button" id="closLocationModelPopup" class="btn btn-default"
                    data-dismiss="modal">Close</button>
            </div>
        </div>

    </div>
</div>

<div id="driverLocationModal" class="modal fade" role="dialog" data-keyboard="false" data-backdrop="static">
    <div class="modal-dialog">
        <!-- Modal content-->
        <div class="modal-content" style="min-height: 200px;">
            <div class="modal-header">
                <h5 class="modal-title" style="color: #337777;font-family: Lato-Regular;text-align: center;">Add Your Home Location</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body  booking" [hidden]="locationResultView" style="margin:auto;">

                <div style="text-align: center;" *ngIf="!loading">
                    <form name="form" [formGroup]="driverLocationForm">
                        <div class="form-group row">
                            <div class="input-group">
                                <label for="driverAddresstext" class="control-label col-12 col-sm-6 col-lg-4">Search
                                    Location</label>
                                <input class="form-control" type="text" #driverAddresstext>
                            </div>
                            <div #driverGmap style="height:300px; width:100%;" [hidden]="locationLoading">
                            </div>
                        </div>
                        <br>
                        <div class="form-group row" [hidden]="locationLoading">
                            <div class="input-group">
                                <label for="address_title" class="control-label col-12 col-sm-6 col-lg-4">Address saved
                                    as:</label>
                                <input type="text" formControlName="address_title" class="form-control"
                                    placeholder="Address saved as">

                            </div>
                            <div class="validation_error col-12 col-sm-12 col-lg-12">
                                <span
                                    *ngIf="validationErrorsDriverLocationDetails.address_title">{{validationErrorsDriverLocationDetails.address_title}}</span>

                            </div>
                        </div>
                        <div class="form-group row" [hidden]="locationLoading">
                            <div class="input-group">
                                <label for="address" class="control-label col-12 col-sm-6 col-lg-4">Enter
                                    Address</label>
                                <input type="text" formControlName="address" class="form-control"
                                    placeholder="Enter the address">
                                <input type="hidden" formControlName="name">
                                <input type="hidden" formControlName="latitude">
                                <input type="hidden" formControlName="longitude">
                            </div>
                            <div class="validation_error col-12 col-sm-12 col-lg-12">
                                <span
                                    *ngIf="validationErrorsDriverLocationDetails.address">{{validationErrorsDriverLocationDetails.address}}</span>
                            </div>
                        </div>

                        <div class="form-group row" [hidden]="locationLoading">
                            <div class="input-group">
                                <label for="distance_ready_to_travel" class="control-label col-12 col-sm-6 col-lg-4">Distance ready to travel for work(Miles):</label>
                                <input type="number" formControlName="distance_ready_to_travel" class="form-control"
                                    placeholder="Address saved as" min="5">

                            </div>
                            <div class="validation_error col-12 col-sm-12 col-lg-12">
                                <span
                                    *ngIf="validationErrorsDriverLocationDetails.distance_ready_to_travel">{{validationErrorsDriverLocationDetails.distance_ready_to_travel}}</span>

                            </div>
                        </div>
                    </form>
                </div>
            </div>

            <div [hidden]="!locationSaving">
                <div style="text-align: center;
                            margin-top: 62px;
                            min-height: 100px; ">

                    <img src="../../assets/images/loader.gif"
                        style="margin-top: 10px;   width: 70px;padding-left: 20px;" />
                </div>
            </div>


            <div class="text-center" [hidden]="!locationResultView" style="min-height: 115px;margin-top: 48px;">

                <span class="font-weight-normal" style="font-size:18px;color: #337777;font-family: Lato-Regular;">
                    Location added successfully
                </span>


            </div>

            <div class="button-group" [hidden]="locationResultView" style="text-align: center;">
                <button type="button" style="background-color: rgb(243, 115, 42);;
                border-color: rgb(243, 115, 42);font-family: Lato-Regular; padding-left: 22px;padding-right: 22px;"
                    (click)="addDriverLocation()" class="btn btn-info" [hidden]="locationLoading"> Save </button>

            </div>

            <div class="button-group" style="text-align: right;">
                <button type="button" id="closLocationModelPopup" class="btn btn-default"
                    data-dismiss="modal">Close</button>
            </div>
        </div>

    </div>
</div>

<div id="licenceVerificationModel" class="modal fade" role="dialog" data-keyboard="false" data-backdrop="static">
    <div class="modal-dialog">
        <!-- Modal content-->
        <div class="modal-content" style="min-height: 200px;">
            <div class="modal-header">
                <h5 class="modal-title" style="color: #337777;font-family: Lato-Regular;text-align: center;">Verify Driver Licence</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body" [hidden]="licenceResultView || licenceSaving" style="margin:auto;">

                <div class="alert alert-info " role="alert" style="margin: auto;" *ngIf="driver?.driver_category_id && driver?.driver_category_id!='5' && driver?.driver_category_id!='6' && (licenceRequired?.licence_number || licenceRequired?.check_code)">
                    To generate the check code visit <a href="https://www.gov.uk/view-driving-licence" target="_blank">
                        www.gov.uk/view-driving-licence </a>
                </div>
                <br>
                <div class="gray-icon  col-lg-12">

                    <form name="form" [formGroup]="licenceform" autocomplete="off">

                        <div class="form-group" *ngIf="driver?.driver_category_id && driver?.driver_category_id!='5' && driver?.driver_category_id!='6' && (licenceRequired?.licence_number || licenceRequired?.check_code)">
                            <div class="input-group">
                                <span class="input-group-addon"><i class="fa fa-id-badge"></i></span>
                                <input type="text" formControlName="licence_number" class="form-control"
                                    placeholder="Last 8 characters of your licence">
                            </div>
                            <div class="validation_error">
                                <span
                                    *ngIf="validationErrorsLicenceDetails.licence_number">{{validationErrorsLicenceDetails.licence_number}}</span>
                            </div>
                        </div>

                        <div class="form-group" *ngIf="driver?.driver_category_id && driver?.driver_category_id!='5' && driver?.driver_category_id!='6' && (licenceRequired?.licence_number || licenceRequired?.check_code)">
                            <div class="input-group">
                                <span class="input-group-addon"><i class="fa fa-lock"></i></span>
                                <input type="text" formControlName="check_code" class="form-control"
                                    placeholder="Check Code">
                            </div>
                            <span style="margin-left: 25px;font-size: 13px;">To generate the check code visit<a
                                    href="https://www.gov.uk/view-driving-licence" target="_blank">
                                    www.gov.uk/view-driving-licence </a></span>
                            <div class="validation_error">
                                <span
                                    *ngIf="validationErrorsLicenceDetails.check_code">{{validationErrorsLicenceDetails.check_code}}</span>
                            </div>
                        </div>

                        <hr *ngIf="driver?.driver_category_id && driver?.driver_category_id!='5' && driver?.driver_category_id!='6' && (licenceRequired?.licence_number || licenceRequired?.check_code)">

                        <div style="font-size: 1rem;font-family: Lato-Regular;margin-top: 30px;margin-bottom:20px;text-align: left" *ngIf="driver?.driver_category_id && driver?.driver_category_id!='5' && driver?.driver_category_id!='6' && (licenceRequired?.licence_front || licenceRequired?.licence_back || licenceRequired?.cpc_front || licenceRequired?.cpc_back || licenceRequired?.digicard_front || licenceRequired?.digicard_back)">
                            Upload below documents:
                        </div>

                        
                        <div style="font-size: 14px;font-family: Lato-Regular;margin-top: 10px;text-align: left" *ngIf="driver?.driver_category_id && driver?.driver_category_id=='5' && licenceRequired?.flt_certificate">
                            FLT cerificate of completion
                        </div>
                        <div style="text-align: center;" *ngIf="driver?.driver_category_id && driver?.driver_category_id=='5' && licenceRequired?.flt_certificate">
                            <label class="myLabel">

                                <input formControlName="flt_certificate" id="flt_certificate" type="file" class="form-control"
                                    (change)="onFileChange($event, 'flt_certificate')">
                                <span>
                                    <i class="fa fa-cloud-upload" aria-hidden="true"></i>
                                    Upload FLT cerificate
                                </span>
                            </label>


                            <div *ngIf="flt_certificate" style="color: #969696;">
                                {{flt_certificate.name}}
                            </div>
                            <div class="validation_error" style="text-align: center;height: 100%;">
                                <span *ngIf="validationErrorsLicenceDetails.flt_certificate">{{validationErrorsLicenceDetails.flt_certificate}}</span>
                            </div>
                        </div>


                        <div style="font-size: 14px;font-family: Lato-Regular;margin-top: 10px;text-align: left" *ngIf="driver?.driver_category_id && driver?.driver_category_id!='5' && driver?.driver_category_id!='6' && (licenceRequired?.licence_front || licenceRequired?.licence_back)">
                            Driving Licence
                        </div>

                        <div style="text-align: center;" *ngIf="driver?.driver_category_id && driver?.driver_category_id!='5' && driver?.driver_category_id!='6' && licenceRequired?.licence_front">
                            <label class="myLabel">

                                <input formControlName="licence_front" id="licence_front" type="file" class="form-control"
                                    (change)="onFileChange($event, 'licence_front')">
                                <span>
                                    <i class="fa fa-cloud-upload" aria-hidden="true"></i>
                                    Upload front side
                                </span>
                            </label>


                            <div *ngIf="licence_front" style="color: #969696;">
                                {{licence_front.name}}
                            </div>
                            <div class="validation_error" style="text-align: center;height: 100%;">
                                <span *ngIf="validationErrorsLicenceDetails.licence_front">{{validationErrorsLicenceDetails.licence_front}}</span>
                            </div>
                        </div>

                        <div style="text-align: center;" *ngIf="driver?.driver_category_id && driver?.driver_category_id!='5' && driver?.driver_category_id!='6' && licenceRequired?.licence_back">
                            <label class="myLabel">

                                <input formControlName="licence_back" id="licence_back" type="file" class="form-control"
                                    (change)="onFileChange($event, 'licence_back')">
                                <span>
                                    <i class="fa fa-cloud-upload" aria-hidden="true"></i>
                                    Upload back side
                                </span>
                            </label>


                            <div *ngIf="licence_back" style="color: #969696;">
                                {{licence_back.name}}
                            </div>
                            <div class="validation_error" style="text-align: center;height: 100%;">
                                <span *ngIf="validationErrorsLicenceDetails.licence_back">{{validationErrorsLicenceDetails.licence_back}}</span>
                            </div>
                        </div>


                        <div style="font-size: 14px;font-family: Lato-Regular;margin-top: 10px;text-align: left" *ngIf="driver?.driver_category_id && (driver?.driver_category_id=='2' || driver?.driver_category_id=='3' || driver?.driver_category_id=='4') && (licenceRequired?.cpc_front || licenceRequired?.cpc_back)">
                            CPC Card
                        </div>
                        <div style="text-align: center;" *ngIf="driver?.driver_category_id && (driver?.driver_category_id=='2' || driver?.driver_category_id=='3' || driver?.driver_category_id=='4') && licenceRequired?.cpc_front">
                            <label class="myLabel">

                                <input formControlName="cpc_front" id="cpc_front" type="file" class="form-control"
                                    (change)="onFileChange($event, 'cpc_front')">
                                <span>
                                    <i class="fa fa-cloud-upload" aria-hidden="true"></i>
                                    Upload front side
                                </span>
                            </label>


                            <div *ngIf="cpc_front" style="color: #969696;">
                                {{cpc_front.name}}
                            </div>
                            <div class="validation_error" style="text-align: center;height: 100%;">
                                <span *ngIf="validationErrorsLicenceDetails.cpc_front">{{validationErrorsLicenceDetails.cpc_front}}</span>
                            </div>
                        </div>

                        <div style="text-align: center;"  *ngIf="driver?.driver_category_id && (driver?.driver_category_id=='2' || driver?.driver_category_id=='3' || driver?.driver_category_id=='4') && licenceRequired?.cpc_back">
                            <label class="myLabel">

                                <input formControlName="cpc_back" id="cpc_back" type="file" class="form-control"
                                    (change)="onFileChange($event, 'cpc_back')">
                                <span>
                                    <i class="fa fa-cloud-upload" aria-hidden="true"></i>
                                    Upload back side
                                </span>
                            </label>


                            <div *ngIf="cpc_back" style="color: #969696;">
                                {{cpc_back.name}}
                            </div>
                            <div class="validation_error" style="text-align: center;height: 100%;">
                                <span *ngIf="validationErrorsLicenceDetails.cpc_back">{{validationErrorsLicenceDetails.cpc_back}}</span>
                            </div>
                        </div>



                        <div style="font-size: 14px;font-family: Lato-Regular;margin-top: 10px;text-align: left" *ngIf="driver?.driver_category_id && (driver?.driver_category_id=='2' || driver?.driver_category_id=='3' || driver?.driver_category_id=='4') && (licenceRequired?.digicard_front || licenceRequired?.digicard_back)">
                            Digi Card
                        </div>
                        <div style="text-align: center;" *ngIf="driver?.driver_category_id && (driver?.driver_category_id=='2' || driver?.driver_category_id=='3' || driver?.driver_category_id=='4') && licenceRequired?.digicard_front">
                            <label class="myLabel">

                                <input formControlName="digicard_front" id="digicard_front" type="file" class="form-control"
                                    (change)="onFileChange($event, 'digicard_front')">
                                <span>
                                    <i class="fa fa-cloud-upload" aria-hidden="true"></i>
                                    Upload front side
                                </span>
                            </label>


                            <div *ngIf="digicard_front" style="color: #969696;">
                                {{digicard_front.name}}
                            </div>
                            <div class="validation_error" style="text-align: center;height: 100%;">
                                <span *ngIf="validationErrorsLicenceDetails.digicard_front">{{validationErrorsLicenceDetails.digicard_front}}</span>
                            </div>
                        </div>

                        <div style="text-align: center;" *ngIf="driver?.driver_category_id && (driver?.driver_category_id=='2' || driver?.driver_category_id=='3' || driver?.driver_category_id=='4') && licenceRequired?.digicard_back">
                            <label class="myLabel">

                                <input formControlName="digicard_back" id="digicard_back" type="file" class="form-control"
                                    (change)="onFileChange($event, 'digicard_back')">
                                <span>
                                    <i class="fa fa-cloud-upload" aria-hidden="true"></i>
                                    Upload back side
                                </span>
                            </label>


                            <div *ngIf="digicard_back" style="color: #969696;">
                                {{digicard_back.name}}
                            </div>
                            <div class="validation_error" style="text-align: center;height: 100%;">
                                <span *ngIf="validationErrorsLicenceDetails.digicard_back">{{validationErrorsLicenceDetails.cpc_back}}</span>
                            </div>
                        </div>

                        <div class="form-group" style="margin-top: 35px;text-align: center;">
                            <button type="submit"
                                style="background-color: #337777;border-color: #337777;font-family: Lato-Regular; padding-left: 22px;padding-right: 22px;"
                                (click)="submitLicenceDetails()" class="btn bbtn-info">Submit</button>
                        </div>

                    </form>
                </div>
            </div>

            <div [hidden]="!licenceSaving">
                <div style="text-align: center;
                            margin-top: 62px;
                            min-height: 100px; ">

                    <img src="../../assets/images/loader.gif"
                        style="margin-top: 10px;   width: 70px;padding-left: 20px;" />
                </div>
            </div>


            <div class="text-center" [hidden]="!licenceResultView" style="min-height: 115px;margin-top: 48px;">

                <span class="font-weight-normal" style="font-size:18px;color: #337777;font-family: Lato-Regular;">
                    Your request to verify driver licence is now under review. We will notify you by email once
                    approved.
                </span>


            </div>

            <div class="button-group" style="text-align: right;margin-top: -25px;">
                <button type="button" id="cloLicenceModelPopup" class="btn btn-default"
                    data-dismiss="modal">Close</button>
            </div>
        </div>

    </div>
</div>


<div id="bankDetailModal" class="modal fade" role="dialog" data-keyboard="false" data-backdrop="static">
    <div class="modal-dialog">
        <!-- Modal content-->
        <div class="modal-content" style="min-height: 200px;">
            <div class="modal-header">
                <h5 class="modal-title" style="color: #337777;font-family: Lato-Regular;text-align: center;">Bank Detail</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body" [hidden]="bankDetailResultView || bankDetailSaving" style="margin:auto;">

                <div class="gray-icon  col-lg-12">

                    <form name="form" [formGroup]="bankDetailform" autocomplete="off">

                        <div class="form-group">
                            <div class="input-group">
                                <span class="input-group-addon"><i class="fa fa-id-badge"></i></span>
                                <input type="text" formControlName="account_number" class="form-control"
                                    placeholder="Account Number">
                            </div>
                            <div class="validation_error">
                                <span
                                    *ngIf="validationErrorsBankDetail.account_number">{{validationErrorsBankDetail.account_number}}</span>
                            </div>
                        </div>

                        <div class="form-group">
                            <div class="input-group">
                                <span class="input-group-addon"><i class="fa fa-lock"></i></span>
                                <input type="text" formControlName="name" class="form-control" placeholder="Name">
                            </div>
                            <div class="validation_error">
                                <span *ngIf="validationErrorsBankDetail.name">{{validationErrorsBankDetail.name}}</span>
                            </div>
                        </div>

                        <div class="form-group">
                            <div class="input-group">
                                <span class="input-group-addon"><i class="fa fa-lock"></i></span>
                                <input type="text" formControlName="sortcode" class="form-control"
                                    placeholder="Sortcode">
                            </div>
                            <div class="validation_error">
                                <span
                                    *ngIf="validationErrorsBankDetail.sortcode">{{validationErrorsBankDetail.sortcode}}</span>
                            </div>
                        </div>

                        <div class="form-group" style="margin-top: 35px;text-align: center;">
                            <button type="submit"
                                style="background-color: #337777;border-color: #337777;font-family: Lato-Regular; padding-left: 22px;padding-right: 22px;"
                                (click)="submitBankDetail()" class="btn bbtn-info">Submit</button>
                        </div>

                    </form>
                </div>
            </div>

            <div [hidden]="!bankDetailSaving">
                <div style="text-align: center;
                            margin-top: 62px;
                            min-height: 100px; ">

                    <img src="../../assets/images/loader.gif"
                        style="margin-top: 10px;   width: 70px;padding-left: 20px;" />
                </div>
            </div>


            <div class="text-center" [hidden]="!bankDetailResultView" style="min-height: 115px;margin-top: 48px;">

                <span class="font-weight-normal" style="font-size:18px;color: #337777;font-family: Lato-Regular;">
                    Bank detail added successfully
                </span>


            </div>

            <div class="button-group" style="text-align: right;margin-top: -25px;">
                <button type="button" id="closeBankDetailModelPopup" class="btn btn-default"
                    data-dismiss="modal">Close</button>
            </div>
        </div>

    </div>
</div>


<div id="healthSafetyModal" class="modal fade" role="dialog">
    <div class="modal-dialog" style="max-width: 800px;">
        <!-- Modal content-->
        <div class="modal-content" style="min-height: 200px;">
            <div class="modal-header">
                <h4 class="modal-title" style="color: #337777;font-family: Lato-Regular;text-align: center;">Health & Safety Questionnaire</h4>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body" [hidden]="healthSafetyResultView || healthSafetySaving" style="margin:auto;">
                <div class="gray-icon  col-lg-12">

                    <form name="form" [formGroup]="healthSafetyform" autocomplete="off">
                        <h5>Client Information</h5>
                        <div class="form-group">
                            <div class="input-group">
                                <input type="text" formControlName="client_name" class="form-control"
                                    placeholder="Client Name">
                            </div>
                            <div class="validation_error">
                                <span *ngIf="validationErrorsHealthSafety.client_name">{{validationErrorsHealthSafety.client_name}}</span>
                                <span *ngIf="healthSafetyform.get('client_name').hasError('required') && healthSafetyform.controls['client_name']?.touched">This field is required.</span>
                            </div>
                        </div>

                        <div class="form-group">
                            <div class="input-group">
                                <input type="text" formControlName="client_location" class="form-control"
                                    placeholder="Client Location">
                            </div>
                            <div class="validation_error">
                                <span *ngIf="validationErrorsHealthSafety.client_location">{{validationErrorsHealthSafety.client_location}}</span>
                                <span *ngIf="healthSafetyform.get('client_location').hasError('required') && healthSafetyform.controls['client_location']?.touched">This field is required.</span>
                            </div>
                        </div>

                        <div class="form-group">
                            <div class="input-group">
                                <input type="text" formControlName="client_contact" class="form-control"
                                    placeholder="Client Contact">
                            </div>
                            <div class="validation_error">
                                <span *ngIf="validationErrorsHealthSafety.client_contact">{{validationErrorsHealthSafety.client_contact}}</span>
                                <span *ngIf="healthSafetyform.get('client_contact').hasError('required') && healthSafetyform.controls['client_contact']?.touched">This field is required.</span>
                            </div>
                        </div>

                        <div class="form-group">
                            <div class="input-group">
                                <input type="text" formControlName="contact_detail" class="form-control"
                                    placeholder="Contact Detail">
                            </div>
                            <div class="validation_error">
                                <span *ngIf="validationErrorsHealthSafety.contact_detail">{{validationErrorsHealthSafety.contact_detail}}</span>
                                <span *ngIf="healthSafetyform.get('contact_detail').hasError('required') && healthSafetyform.controls['contact_detail']?.touched">This field is required.</span>
                            </div>
                        </div>

                        <p style="font-weight: 500;">Client H&S Manager</p>

                        <div class="form-group">
                            <div class="input-group">
                                <input type="text" formControlName="manager_name" class="form-control"
                                    placeholder="Name">
                            </div>
                            <div class="validation_error">
                                <span *ngIf="validationErrorsHealthSafety.manager_name">{{validationErrorsHealthSafety.manager_name}}</span>
                                <span *ngIf="healthSafetyform.get('manager_name').hasError('required') && healthSafetyform.controls['manager_name']?.touched">This field is required.</span>
                            </div>
                        </div>

                        <div class="form-group">
                            <div class="input-group">
                                <input type="text" formControlName="manager_telephone" class="form-control"
                                    placeholder="Telephone">
                            </div>
                            <div class="validation_error">
                                <span *ngIf="validationErrorsHealthSafety.manager_telephone">{{validationErrorsHealthSafety.manager_telephone}}</span>
                                <span *ngIf="healthSafetyform.get('manager_telephone').hasError('required') && healthSafetyform.controls['manager_telephone']?.touched">This field is required.</span>
                            </div>
                        </div>

                        <hr>

                        <h5>General Legal Requirements</h5>
                        
                        <li style="font-weight: 500;">Do you have suitable current insurance cover for: -</li>
                        <div class="form-group">
                            <div class="input-group checkbox">
                                <label for="current_insurance_employers_liability" class="control-label col-12 col-sm-12 col-md-4">Employers Liability?
                                </label>
                                <label class="checkbox-inline">
                                    <input style="width: 30px; height: 30px;" type="radio" formControlName="current_insurance_employers_liability" value="1"> Yes 
                                </label>
                                <label class="checkbox-inline" style="margin-left: 10px;">
                                    <input  style="width: 30px; height: 30px;" type="radio" formControlName="current_insurance_employers_liability" value="0"> No
                                </label>
                            </div>
                            <div class="validation_error">
                                <span *ngIf="validationErrorsHealthSafety.current_insurance_employers_liability">{{validationErrorsHealthSafety.current_insurance_employers_liability}}</span>
                                <span *ngIf="healthSafetyform.get('current_insurance_employers_liability').hasError('required') && healthSafetyform.controls['current_insurance_employers_liability']?.touched">This field is required.</span>
                            </div>
                        </div>

                        <div class="form-group">
                            <div class="input-group">
                                <label for="current_insurance_public_liability" class="control-label col-12 col-sm-12 col-md-4">Public Liability?
                                </label>
                                <label class="checkbox-inline">
                                    <input style="width: 30px; height: 30px;" type="radio" formControlName="current_insurance_public_liability" value="1"> Yes 
                                </label>
                                <label class="checkbox-inline" style="margin-left: 10px;">
                                    <input  style="width: 30px; height: 30px;" type="radio" formControlName="current_insurance_public_liability" value="0"> No
                                </label>
                            </div>
                            <div class="validation_error">
                                <span *ngIf="validationErrorsHealthSafety.current_insurance_public_liability">{{validationErrorsHealthSafety.current_insurance_public_liability}}</span>
                                <span *ngIf="healthSafetyform.get('current_insurance_public_liability').hasError('required') && healthSafetyform.controls['current_insurance_public_liability']?.touched">This field is required.</span>
                            </div>
                        </div>

                        <div class="form-group">
                            <div class="input-group">
                                <label for="current_insurance_motor_vehicle" class="control-label col-12 col-sm-12 col-md-4">Motor Vehicle (if Applicable)?
                                </label>
                                <label class="checkbox-inline">
                                    <input style="width: 30px; height: 30px;" type="radio" formControlName="current_insurance_motor_vehicle" value="1"> Yes 
                                </label>
                                <label class="checkbox-inline" style="margin-left: 10px;">
                                    <input  style="width: 30px; height: 30px;" type="radio" formControlName="current_insurance_motor_vehicle" value="0"> No
                                </label>
                            </div>
                            <div class="validation_error">
                                <span *ngIf="validationErrorsHealthSafety.current_insurance_motor_vehicle">{{validationErrorsHealthSafety.current_insurance_motor_vehicle}}</span>
                                <span *ngIf="healthSafetyform.get('current_insurance_motor_vehicle').hasError('required') && healthSafetyform.controls['current_insurance_motor_vehicle']?.touched">This field is required.</span>
                            </div>
                        </div>
                        <div class="form-group bullet">
                            <div class="input-group">
                                <label for="policy_health_safety" class="control-label col-12 col-sm-12 col-md-8">Do you have a policy on Health & Safety at Work available on request?
                                </label>
                                <label class="checkbox-inline">
                                    <input style="width: 30px; height: 30px;" type="radio" formControlName="policy_health_safety" value="1"> Yes 
                                </label>
                                <label class="checkbox-inline" style="margin-left: 10px;">
                                    <input  style="width: 30px; height: 30px;" type="radio" formControlName="policy_health_safety" value="0"> No
                                </label>
                            </div>
                            <div class="validation_error">
                                <span *ngIf="validationErrorsHealthSafety.policy_health_safety">{{validationErrorsHealthSafety.policy_health_safety}}</span>
                                <span *ngIf="healthSafetyform.get('policy_health_safety').hasError('required') && healthSafetyform.controls['policy_health_safety']?.touched">This field is required.</span>
                            </div>
                        </div>
                        

                        <div class="form-group bullet">
                            <div class="input-group">
                                <label for="health_safety_law_poster" class="control-label col-12 col-sm-12 col-md-8">Do you have the ‘Health & Safety Law’ posters displayed?
                                </label>
                                <label class="checkbox-inline">
                                    <input style="width: 30px; height: 30px;" type="radio" formControlName="health_safety_law_poster" value="1"> Yes 
                                </label>
                                <label class="checkbox-inline" style="margin-left: 10px;">
                                    <input  style="width: 30px; height: 30px;" type="radio" formControlName="health_safety_law_poster" value="0"> No
                                </label>
                            </div>
                            <div class="validation_error">
                                <span *ngIf="validationErrorsHealthSafety.health_safety_law_poster">{{validationErrorsHealthSafety.health_safety_law_poster}}</span>
                                <span *ngIf="healthSafetyform.get('health_safety_law_poster').hasError('required') && healthSafetyform.controls['health_safety_law_poster']?.touched">This field is required.</span>
                            </div>
                        </div>

                        <hr>

                        <h5>Supervision</h5>
                        <p style="font-weight: 500;">Can you confirm that you will:</p>
                        <div class="form-group bullet">
                            <div class="input-group">
                                <label for="supervision_temporary_workers_suitable_information" class="control-label col-12 col-sm-12 col-md-8">Give temporary workers suitable information, instructions and supervision?
                                </label>
                                <label class="checkbox-inline">
                                    <input style="width: 30px; height: 30px;" type="radio" formControlName="supervision_temporary_workers_suitable_information" value="1"> Yes 
                                </label>
                                <label class="checkbox-inline" style="margin-left: 10px;">
                                    <input  style="width: 30px; height: 30px;" type="radio" formControlName="supervision_temporary_workers_suitable_information" value="0"> No
                                </label>
                            </div>
                            <div class="validation_error">
                                <span *ngIf="validationErrorsHealthSafety.supervision_temporary_workers_suitable_information">{{validationErrorsHealthSafety.supervision_temporary_workers_suitable_information}}</span>
                                <span *ngIf="healthSafetyform.get('supervision_temporary_workers_suitable_information').hasError('required') && healthSafetyform.controls['supervision_temporary_workers_suitable_information']?.touched">This field is required.</span>
                            </div>
                        </div>
                        <div class="form-group bullet">
                            <div class="input-group">
                                <label for="supervision_someone_supervising_temporary_workers" class="control-label col-12 col-sm-12 col-md-8">Appoint someone to be responsible for supervising temporary workers?
                                </label>
                                <label class="checkbox-inline">
                                    <input style="width: 30px; height: 30px;" type="radio" formControlName="supervision_someone_supervising_temporary_workers" value="1"> Yes 
                                </label>
                                <label class="checkbox-inline" style="margin-left: 10px;">
                                    <input  style="width: 30px; height: 30px;" type="radio" formControlName="supervision_someone_supervising_temporary_workers" value="0"> No
                                </label>
                            </div>
                            <div class="validation_error">
                                <span *ngIf="validationErrorsHealthSafety.supervision_someone_supervising_temporary_workers">{{validationErrorsHealthSafety.supervision_someone_supervising_temporary_workers}}</span>
                                <span *ngIf="healthSafetyform.get('supervision_someone_supervising_temporary_workers').hasError('required') && healthSafetyform.controls['supervision_someone_supervising_temporary_workers']?.touched">This field is required.</span>
                            </div>
                        </div>
                        <div class="form-group bullet">
                            <div class="input-group">
                                <label for="supervision_standards_of_supervision" class="control-label col-12 col-sm-12 col-md-8">Do your standards of supervision take into account the experience of temporary workers and the risks associated with the tasks they are carrying out?                                
                                </label>
                                <label class="checkbox-inline">
                                    <input style="width: 30px; height: 30px;" type="radio" formControlName="supervision_standards_of_supervision" value="1"> Yes 
                                </label>
                                <label class="checkbox-inline" style="margin-left: 10px;">
                                    <input  style="width: 30px; height: 30px;" type="radio" formControlName="supervision_standards_of_supervision" value="0"> No
                                </label>
                            </div>
                            <div class="validation_error">
                                <span *ngIf="validationErrorsHealthSafety.supervision_standards_of_supervision">{{validationErrorsHealthSafety.supervision_standards_of_supervision}}</span>
                                <span *ngIf="healthSafetyform.get('supervision_standards_of_supervision').hasError('required') && healthSafetyform.controls['supervision_standards_of_supervision']?.touched">This field is required.</span>
                            </div>
                        </div>

                        <hr>

                        <h5>Health & Safety Training – Specific Hazards</h5>

                        <div class="form-group bullet">
                            <div class="input-group">
                                <label for="health_safety_instructions" class="control-label col-12 col-sm-12 col-md-8">Are arrangements in place to give temporary workers suitable information, instructions and supervision?                                
                                </label>
                                <label class="checkbox-inline">
                                    <input style="width: 30px; height: 30px;" type="radio" formControlName="health_safety_instructions" value="1"> Yes 
                                </label>
                                <label class="checkbox-inline" style="margin-left: 10px;">
                                    <input  style="width: 30px; height: 30px;" type="radio" formControlName="health_safety_instructions" value="0"> No
                                </label>
                            </div>
                            <div class="validation_error">
                                <span *ngIf="validationErrorsHealthSafety.health_safety_instructions">{{validationErrorsHealthSafety.health_safety_instructions}}</span>
                                <span *ngIf="healthSafetyform.get('health_safety_instructions').hasError('required') && healthSafetyform.controls['health_safety_instructions']?.touched">This field is required.</span>
                            </div>
                        </div>

                        <div class="form-group bullet">
                            <div class="input-group">
                                <label for="health_safety_briefing" class="control-label col-12 col-sm-12 col-md-8">Can you confirm that temporary workers will receive a Health & Safety briefing to cover all activities they will be involved in relating to the tasks assigned?                                
                                </label>
                                <label class="checkbox-inline">
                                    <input style="width: 30px; height: 30px;" type="radio" formControlName="health_safety_briefing" value="1"> Yes 
                                </label>
                                <label class="checkbox-inline" style="margin-left: 10px;">
                                    <input  style="width: 30px; height: 30px;" type="radio" formControlName="health_safety_briefing" value="0"> No
                                </label>
                            </div>
                            <div class="validation_error">
                                <span *ngIf="validationErrorsHealthSafety.health_safety_briefing">{{validationErrorsHealthSafety.health_safety_briefing}}</span>
                                <span *ngIf="healthSafetyform.get('health_safety_briefing').hasError('required') && healthSafetyform.controls['health_safety_briefing']?.touched">This field is required.</span>
                            </div>
                        </div>

                        <div class="form-group bullet">
                            <div class="input-group">
                                <label for="health_safety_personal_responsibilities" class="control-label col-12 col-sm-12 col-md-8">Will you make each Temporary Worker aware of their personal responsibilities in relation to health & safety?                                
                                </label>
                                <label class="checkbox-inline">
                                    <input style="width: 30px; height: 30px;" type="radio" formControlName="health_safety_personal_responsibilities" value="1"> Yes 
                                </label>
                                <label class="checkbox-inline" style="margin-left: 10px;">
                                    <input  style="width: 30px; height: 30px;" type="radio" formControlName="health_safety_personal_responsibilities" value="0"> No
                                </label>
                            </div>
                            <div class="validation_error">
                                <span *ngIf="validationErrorsHealthSafety.health_safety_personal_responsibilities">{{validationErrorsHealthSafety.health_safety_personal_responsibilities}}</span>
                                <span *ngIf="healthSafetyform.get('health_safety_personal_responsibilities').hasError('required') && healthSafetyform.controls['health_safety_personal_responsibilities']?.touched">This field is required.</span>
                            </div>
                        </div>
                        
                        <div class="form-group bullet">
                            <div class="input-group">
                                <label for="health_safety_arrangements_for_customers" class="control-label col-12 col-sm-12 col-md-8">Will you ensure that arrangements are in place for checking that your customers	meet all the above Health & Safety requirements?                                
                                </label>
                                <label class="checkbox-inline">
                                    <input style="width: 30px; height: 30px;" type="radio" formControlName="health_safety_arrangements_for_customers" value="1"> Yes 
                                </label>
                                <label class="checkbox-inline" style="margin-left: 10px;">
                                    <input  style="width: 30px; height: 30px;" type="radio" formControlName="health_safety_arrangements_for_customers" value="0"> No
                                </label>
                            </div>
                            <div class="validation_error">
                                <span *ngIf="validationErrorsHealthSafety.health_safety_arrangements_for_customers">{{validationErrorsHealthSafety.health_safety_arrangements_for_customers}}</span>
                                <span *ngIf="healthSafetyform.get('health_safety_arrangements_for_customers').hasError('required') && healthSafetyform.controls['health_safety_arrangements_for_customers']?.touched">This field is required.</span>
                            </div>
                        </div>

                        <hr> 

                        <h5>First Aid Provision & Emergency Procedures</h5>
                        <div class="form-group bullet">
                            <div class="input-group">
                                <label for="first_aiders_available" class="control-label col-12 col-sm-12 col-md-8">Do you have sufficient suitably Qualified First Aiders?
                                </label>
                                <label class="checkbox-inline">
                                    <input style="width: 30px; height: 30px;" type="radio" formControlName="first_aiders_available" value="1"> Yes 
                                </label>
                                <label class="checkbox-inline" style="margin-left: 10px;">
                                    <input  style="width: 30px; height: 30px;" type="radio" formControlName="first_aiders_available" value="0"> No
                                </label>
                            </div>
                            <div class="validation_error">
                                <span *ngIf="validationErrorsHealthSafety.first_aiders_available">{{validationErrorsHealthSafety.first_aiders_available}}</span>
                                <span *ngIf="healthSafetyform.get('first_aiders_available').hasError('required') && healthSafetyform.controls['first_aiders_available']?.touched">This field is required.</span>
                            </div>
                        </div>
                        <div class="form-group">
                            <div class="input-group">
                                <input type="text" formControlName="first_aiders_name1" class="form-control"
                                    placeholder="Name">
                            </div>
                            <div class="validation_error">
                                <span *ngIf="validationErrorsHealthSafety.first_aiders_name1">{{validationErrorsHealthSafety.first_aiders_name1}}</span>
                            </div>
                        </div>
                        <div class="form-group">
                            <div class="input-group">
                                <input type="text" formControlName="first_aiders_name2" class="form-control"
                                    placeholder="Name">
                            </div>
                            <div class="validation_error">
                                <span *ngIf="validationErrorsHealthSafety.first_aiders_name2">{{validationErrorsHealthSafety.first_aiders_name2}}</span>
                            </div>
                        </div>

                        <hr> 
                        <h5>Personal Welfare and Protective Equipment – Specific Risks</h5>
                        <div class="form-group bullet">
                            <div class="input-group">
                                <label for="personal_protective_equipment" class="control-label col-12 col-sm-12 col-md-8">Will you provide the temporary worker with suitable protective equipment and clothing of the correct size?</label>
                                <label class="checkbox-inline">
                                    <input style="width: 30px; height: 30px;" type="radio" formControlName="personal_protective_equipment" value="1"> Yes 
                                </label>
                                <label class="checkbox-inline" style="margin-left: 10px;">
                                    <input  style="width: 30px; height: 30px;" type="radio" formControlName="personal_protective_equipment" value="0"> No
                                </label>
                            </div>
                            <div class="validation_error">
                                <span *ngIf="validationErrorsHealthSafety.personal_protective_equipment">{{validationErrorsHealthSafety.personal_protective_equipment}}</span>
                                <span *ngIf="healthSafetyform.get('personal_protective_equipment').hasError('required') && healthSafetyform.controls['personal_protective_equipment']?.touched">This field is required.</span>
                            </div>
                        </div>

                        <div class="form-group bullet">
                            <div class="input-group">
                                <label for="personal_protective_make_sure" class="control-label col-12 col-sm-12 col-md-8">Will you make sure that the temporary workers use all the necessary protective equipment and clothing properly?</label>
                                <label class="checkbox-inline">
                                    <input style="width: 30px; height: 30px;" type="radio" formControlName="personal_protective_make_sure" value="1"> Yes 
                                </label>
                                <label class="checkbox-inline" style="margin-left: 10px;">
                                    <input  style="width: 30px; height: 30px;" type="radio" formControlName="personal_protective_make_sure" value="0"> No
                                </label>
                            </div>
                            <div class="validation_error">
                                <span *ngIf="validationErrorsHealthSafety.personal_protective_make_sure">{{validationErrorsHealthSafety.personal_protective_make_sure}}</span>
                                <span *ngIf="healthSafetyform.get('personal_protective_make_sure').hasError('required') && healthSafetyform.controls['personal_protective_make_sure']?.touched">This field is required.</span>
                            </div>
                        </div>
                        
                        <div class="form-group bullet">
                            <div class="input-group">
                                <label for="personal_toilets_and_wash" class="control-label col-12 col-sm-12 col-md-8">Do you have toilets and washing facilities, including soap and drying facilities?</label>
                                <label class="checkbox-inline">
                                    <input style="width: 30px; height: 30px;" type="radio" formControlName="personal_toilets_and_wash" value="1"> Yes 
                                </label>
                                <label class="checkbox-inline" style="margin-left: 10px;">
                                    <input  style="width: 30px; height: 30px;" type="radio" formControlName="personal_toilets_and_wash" value="0"> No
                                </label>
                            </div>
                            <div class="validation_error">
                                <span *ngIf="validationErrorsHealthSafety.personal_toilets_and_wash">{{validationErrorsHealthSafety.personal_toilets_and_wash}}</span>
                                <span *ngIf="healthSafetyform.get('personal_toilets_and_wash').hasError('required') && healthSafetyform.controls['personal_toilets_and_wash']?.touched">This field is required.</span>
                            </div>
                        </div>

                        <hr>

                        <h5>Specific Hazards</h5>

                        <div class="form-group bullet">
                            <div class="input-group">
                                <label for="hazard_assess_risk" class="control-label col-12 col-sm-12 col-md-8">Have you assessed the specific risks to the Health & Safety of workers and others  affected  by  your	work  (including  arrangements  for  the  safety  of  new temporary workers while in your organisation?</label>
                                <label class="checkbox-inline">
                                    <input style="width: 30px; height: 30px;" type="radio" formControlName="hazard_assess_risk" value="1"> Yes 
                                </label>
                                <label class="checkbox-inline" style="margin-left: 10px;">
                                    <input  style="width: 30px; height: 30px;" type="radio" formControlName="hazard_assess_risk" value="0"> No
                                </label>
                            </div>
                            <div class="validation_error">
                                <span *ngIf="validationErrorsHealthSafety.hazard_assess_risk">{{validationErrorsHealthSafety.hazard_assess_risk}}</span>
                                <span *ngIf="healthSafetyform.get('hazard_assess_risk').hasError('required') && healthSafetyform.controls['hazard_assess_risk']?.touched">This field is required.</span>
                            </div>
                        </div>

                        <div class="form-group bullet">
                            <div class="input-group">
                                <label for="finding_of_risk_assessment" class="control-label col-12 col-sm-12 col-md-5">Please provide details of any specific findings of your Risk Assessment that a temporary worker may be exposed to. [e.g. noise, manual handling, hazardous substances, fire, work equipment etc]</label>
                                <textarea type="textarea" formControlName="finding_of_risk_assessment" class="form-control"></textarea>
                            </div>
                            <div class="validation_error">
                                <span *ngIf="validationErrorsHealthSafety.finding_of_risk_assessment">{{validationErrorsHealthSafety.finding_of_risk_assessment}}</span>
                            </div>
                        </div>


                        <h5>Are there any specific instructions for workers? If so please detail below:</h5>
                        <textarea type="textarea" formControlName="instructions_for_workers" class="form-control"></textarea>

                        <p style="margin:20px;">The Client shall advise the Employment Business of any special health and safety matters about which the Employment Business is required to inform the Temporary Worker and about any requirements imposed by law by any professional body, which must be satisfied if the Temporary Worker is to fill the Assignment. The Client shall also inform the Employment Business of any updates or changes to the information supplied on this form which may affect the Health & Safety of any workers supplied to the client</p>
                        
                        <div class="form-group">
                            <div class="input-group">
                                <label class="control-label col-12 col-sm-12 col-md-5">Signature</label>
                                <canvas #canvas></canvas>

                                <button (click)="clearPad()">Clear</button>
                            </div>
                            <div class="validation_error">
                                <span *ngIf="validationErrorsHealthSafety.signature">{{validationErrorsHealthSafety.signature}}</span>
                            </div>
                        </div>


                        <div class="form-group" style="margin-top: 35px;text-align: center;">
                            <button type="submit"
                                style="background-color: #337777;border-color: #337777;font-family: Lato-Regular; padding-left: 22px;padding-right: 22px;"
                                (click)="submitHealthSafety()" class="btn bbtn-info">Submit</button>
                        </div>
                        
                        <!-- <div class="signature-container">
                            <signature-pad [options]="signaturePadOptions" (onBeginEvent)="drawStart()" (onEndEvent)="drawComplete()"></signature-pad>
                            div class="buttons">
                        <button (click)="clearSignature()">Clear signature pad</button>
                        <button (click)="savePad()">Save signature</button>
                        </div>

                        <div class="signature-image" *ngIf="signatureImg">
                            <img src='{{ signatureImg }}' />
                        </div> -->
                    </form>
                </div>
            </div>

            <div [hidden]="!healthSafetySaving">
                <div style="text-align: center;
                            margin-top: 62px;
                            min-height: 100px; ">

                    <img src="../../assets/images/loader.gif"
                        style="margin-top: 10px;   width: 70px;padding-left: 20px;" />
                </div>
            </div>


            <div class="text-center" [hidden]="!healthSafetyResultView" style="min-height: 115px;margin-top: 48px;">

                <span class="font-weight-normal" style="font-size:18px;color: #337777;font-family: Lato-Regular;">
                    Health & Safety Information saved successfully
                </span>


            </div>

            <div class="button-group" style="text-align: right;margin-top: -25px;">
                <button type="button" id="closeHealthSafetyModal" class="btn btn-default"
                    data-dismiss="modal">Close</button>
            </div>
        </div>

    </div>
</div>

<div id="changePasswordModal" class="modal fade" role="dialog" data-keyboard="false" data-backdrop="static">
    <div class="modal-dialog">
        <!-- Modal content-->
        <div class="modal-content" style="min-height: 200px;">
            <div class="modal-header">
                <h5 class="modal-title" style="color: #337777;font-family: Lato-Regular;text-align: center;">Change Your Password</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body" [hidden]="changePasswordResultView || changePasswordSaving" style="margin:auto;width:80%;">
                <!-- <h5 style="color: #337777;font-family: Lato-Regular;text-align: center;"></h5> -->
                <div class="gray-icon  col-lg-12">
                    <form name="form" [formGroup]="changePasswordform" autocomplete="off">
                        <div class="form-group">
                            <div class="input-group">
                                <span class="input-group-addon"><i class="fa fa-lock"></i></span>
                                <input type="password" formControlName="password" class="form-control" placeholder="Current Password">
                            </div>
                            <div class="validation_error">
                                <span *ngIf="validationErrorsChangePassword.password">{{validationErrorsChangePassword.password}}</span>
                            </div>
                        </div>
                        <div class="form-group">
                            <div class="input-group">
                                <span class="input-group-addon"><i class="fa fa-lock"></i></span>
                                <input type="password" formControlName="new_password" class="form-control" placeholder="New Password">
                            </div>
                            <div class="validation_error">
                                <span *ngIf="validationErrorsChangePassword.new_password">{{validationErrorsChangePassword.new_password}}</span>
                            </div>
                        </div>
                        <div class="form-group">
                            <div class="input-group">
                                <span class="input-group-addon"><i class="fa fa-lock"></i></span>
                                <input type="password" formControlName="cnfpass" class="form-control" placeholder="Confirm New Password">
                            </div>
                            <div class="validation_error">
                                <span *ngIf="validationErrorsChangePassword.cnfpass">{{validationErrorsChangePassword.cnfpass}}</span>
                            </div>
                        </div>
                        <div class="form-group" style="margin-top: 35px;text-align: center;">
                            <button type="submit" style="background-color: #337777;border-color: #337777;font-family: Lato-Regular; padding-left: 22px;padding-right: 22px;" 
                                (click)="submitChangePassword()" class="btn bbtn-info">Submit</button>
                        </div>
                    </form>
                </div>
            </div>
            <div [hidden]="!changePasswordSaving">
                <div style="text-align: center;margin-top: 62px;min-height: 100px; ">
                    <img src="../../assets/images/loader.gif"
                        style="margin-top: 10px;   width: 70px;padding-left: 20px;" />
                </div>
            </div>
            <div class="text-center" [hidden]="!changePasswordResultView" style="min-height: 115px;margin-top: 48px;">
                <span class="font-weight-normal" style="font-size:18px;color: #337777;font-family: Lato-Regular;">
                    Password changed successfully
                </span>
            </div>
            <div class="button-group" style="text-align: right;margin-top: -25px;">
                <button type="button" id="closeChangePasswordModelPopup" class="btn btn-default"
                    data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>


<div id="changeEmailModal" class="modal fade" role="dialog" data-keyboard="false" data-backdrop="static">
    <div class="modal-dialog">
        <!-- Modal content-->
        <div class="modal-content" style="min-height: 200px;">
            <div class="modal-header">
                <h5 class="modal-title" style="color: #337777;font-family: Lato-Regular;text-align: center;">Change Your Email</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body" [hidden]="changeEmailResultView || changeEmailSaving" style="margin:auto;width:80%;">
                <div class="gray-icon  col-lg-12">
                    <form name="form" [formGroup]="changeEmailform" autocomplete="off">
                        <div class="form-group">
                            <div class="input-group">
                                <span class="input-group-addon"><i class="fa fa-envelope"></i></span>
                                <input type="text" formControlName="new_email" class="form-control" placeholder="Enter your new email">
                            </div>
                            <div class="validation_error">
                                <span *ngIf="validationErrorsChangeEmail.new_email">{{validationErrorsChangeEmail.new_email}}</span>
                            </div>
                        </div>
                        <div class="form-group">
                            <div class="input-group">
                                <span class="input-group-addon"><i class="fa fa-lock"></i></span>
                                <input type="password" formControlName="password" class="form-control" placeholder="Enter your password">
                            </div>
                            <div class="validation_error">
                                <span *ngIf="validationErrorsChangeEmail.password">{{validationErrorsChangeEmail.password}}</span>
                            </div>
                        </div>
                        <div class="form-group" style="margin-top: 35px;text-align: center;">
                            <button type="submit" style="background-color: #337777;border-color: #337777;font-family: Lato-Regular; padding-left: 22px;padding-right: 22px;" 
                                (click)="submitChangeEmail()" class="btn bbtn-info">Submit</button>
                        </div>
                    </form>
                </div>
            </div>
            <div [hidden]="!changeEmailSaving">
                <div style="text-align: center;margin-top: 62px;min-height: 100px; ">
                    <img src="../../assets/images/loader.gif"
                        style="margin-top: 10px;   width: 70px;padding-left: 20px;" />
                </div>
            </div>
            <div class="text-center" [hidden]="!changeEmailResultView" style="min-height: 115px;margin-top: 48px;">
                <span class="font-weight-normal" style="font-size:18px;color: #337777;font-family: Lato-Regular;">
                    Email changed successfully
                </span>
            </div>
            <div class="button-group" style="text-align: right;margin-top: -25px;">
                <button type="button" id="closeChangeEmailModelPopup" class="btn btn-default"
                    data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>

<div id="changeMobileModal" class="modal fade" role="dialog" data-keyboard="false" data-backdrop="static">
    <div class="modal-dialog">
        <!-- Modal content-->
        <div class="modal-content" style="min-height: 200px;">
            <div class="modal-header">
                <h5 class="modal-title" style="color: #337777;font-family: Lato-Regular;text-align: center;">Change Your Mobile</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body" [hidden]="changeMobileResultView || changeMobileSaving" style="margin:auto;width:80%;">
                <div class="gray-icon  col-lg-12">
                    <form name="form" [formGroup]="changeMobileform" autocomplete="off">
                        <div class="form-group">
                            <div class="input-group">
                                <span class="input-group-addon"><i class="fa fa-phone"></i></span>
                                <input type="text" formControlName="new_mobile" class="form-control" placeholder="Enter your new mobile">
                            </div>
                            <div class="validation_error">
                                <span *ngIf="validationErrorsChangeMobile.new_mobile">{{validationErrorsChangeMobile.new_mobile}}</span>
                            </div>
                        </div>
                        <div class="form-group">
                            <div class="input-group">
                                <span class="input-group-addon"><i class="fa fa-lock"></i></span>
                                <input type="password" formControlName="password" class="form-control" placeholder="Enter your password">
                            </div>
                            <div class="validation_error">
                                <span *ngIf="validationErrorsChangeMobile.password">{{validationErrorsChangeMobile.password}}</span>
                            </div>
                        </div>
                        <div class="form-group" style="margin-top: 35px;text-align: center;">
                            <button type="submit" style="background-color: #337777;border-color: #337777;font-family: Lato-Regular; padding-left: 22px;padding-right: 22px;" 
                                (click)="submitChangeMobile()" class="btn bbtn-info">Submit</button>
                        </div>
                    </form>
                </div>
            </div>
            <div [hidden]="!changeMobileSaving">
                <div style="text-align: center;margin-top: 62px;min-height: 100px; ">
                    <img src="../../assets/images/loader.gif"
                        style="margin-top: 10px;   width: 70px;padding-left: 20px;" />
                </div>
            </div>
            <div class="text-center" [hidden]="!changeMobileResultView" style="min-height: 115px;margin-top: 48px;">
                <span class="font-weight-normal" style="font-size:18px;color: #337777;font-family: Lato-Regular;">
                    Mobile number changed successfully
                </span>
            </div>
            <div class="button-group" style="text-align: right;margin-top: -25px;">
                <button type="button" id="closeChangeMobileModelPopup" class="btn btn-default"
                    data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>