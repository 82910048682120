import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { JwtService } from '../services/jwt.service';
import { DataSharingService } from '../services/data-sharing.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  @ViewChild('openCookieModal') openCookieModal: ElementRef<HTMLElement>;

  langs = ['en', 'ar'];
  loggedIn: Boolean = false;
  isDriver: Boolean = false;

  selectedLanguage: string;
  constructor(private router: Router,
    private dataSharingService: DataSharingService,
    private translateService: TranslateService,
    private jwtService: JwtService) { }

  ngOnInit(): void {

    let lang = localStorage.getItem('lang');
    if (lang && this.langs.indexOf(lang) > -1) {
      this.translateService.setDefaultLang(lang);
    } else {
      let browserLang = this.translateService.getBrowserLang();
      if (this.langs.indexOf(browserLang) > -1) {
        this.translateService.setDefaultLang(browserLang);
        lang = browserLang;
      } else {
        this.translateService.setDefaultLang('en');
        lang = 'en';
      }
      localStorage.setItem('lang', lang);
    }
    this.selectedLanguage = lang;


    this.isDriver = this.jwtService.isDriver;
    this.loggedIn = this.jwtService.loggedIn;
    this.dataSharingService.isLoggedIn.subscribe(value => {
      this.loggedIn = value;
      this.isDriver = this.jwtService.isDriver;
    });
  }

  ngAfterViewInit():void {
    let value_or_null = (document.cookie.match(/^(?:.*;)?\s*cookieConcent\s*=\s*([^;]+)(?:.*)?$/)||[,null])[1]
    if(!value_or_null) {
      this.openCookieModal.nativeElement.click();
    }
  }



  logout() {
    this.jwtService.logout();
    this.dataSharingService.isLoggedIn.next(false);
    this.router.navigate(['']);

  }




  public useLanguage(lang: string): void {
    this.translateService.setDefaultLang(lang);
    this.selectedLanguage = lang;
    localStorage.setItem('lang', lang);
  }

  acceptCookies() {
    let days = 365
    var date = new Date();
    date.setTime(date.getTime() + (days*24*60*60*1000));
    let expires = "; expires=" + date.toUTCString();
    document.cookie = 'cookieConcent' + "=" + (1 || "")  + expires + "; path=/";
    this.openCookieModal.nativeElement.click();
  }


}
