/// <reference  types="@types/googlemaps"  />
import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { SecuredService } from '../services/secured.service';
import { JwtService } from '../services/jwt.service';
import { TranslateService } from '@ngx-translate/core';
import { Booking } from '../interfaces/Booking';
import { Driver } from '../interfaces/Driver';
import { Company } from '../interfaces/Company';

@Component({
  selector: 'app-manage-booking',
  templateUrl: './manage-booking.component.html',
  styleUrls: ['./manage-booking.component.css']
})
export class ManageBookingComponent implements OnInit {

  constructor(private jwtService: JwtService, private securedService: SecuredService
    ,
    private translate: TranslateService) { }

  @ViewChild('gmap') gmapElement:  any;
  booking_type: string = 'UPCOMING';
  bookings: Booking[] = [];
  loading: boolean = false;
  viewMoreUrl: string;
  isDriver: Boolean = false;
  isCompany: Boolean = false;
  driver: Driver;
  company: Company;
  stars: number[] = [1, 2, 3, 4, 5];

  ngOnInit(): void {
  	this.isDriver = this.jwtService.isDriver;
    this.isCompany = this.jwtService.isCompany;
    if(this.isDriver) {
      this.driver = this.jwtService.getLoggedInData();
    } else {
      this.company = this.jwtService.getLoggedInData();
    }
  	this.loadBooking();
  }

  loadBooking() {
    this.loading = true;
    let data: string = "";
    if (this.booking_type) {
      data += "&booking_type=" + this.booking_type;
    }

    this.securedService.getBookings("", data).subscribe(
      result => {
        this.loading = false;
        this.bookings = result.items;
        this.viewMoreUrl = result?._links?.next?.href;
      },
      error => {
        this.loading = false;
        if (error.status == 429 || error.status == 500) {
          alert(this.translate.instant('COMMON.500'));

        }

      }
    );

  }

  viewMore() {
    if (!this.viewMoreUrl) {
      return;
    }

    this.securedService.getBookings(this.viewMoreUrl, "").subscribe(
      result => {
        for (let i = 0; i < result.items.length; i++) {
          let booking = result.items[i];
          this.bookings.push(booking);
        }
        this.viewMoreUrl = result?._links?.next?.href;
      },
      error => {
        if (error.status == 429 || error.status == 500) {
          alert(this.translate.instant('COMMON.500'));
        }
      }
    );
  }

  setBookingType(type) {
  	this.booking_type = type;
  	this.loadBooking();
  }

  acceptRow(booking) {
    if(confirm("You are accepting the booking for this date?")) {
      this.loading = true;
      const formData = new FormData();
      formData.append(booking.id, "ACCEPTED");

      this.securedService.confirmBooking(formData)
        .subscribe(result => {
          booking.booking_status = "ACCEPTED"
          this.loading = false;
        },
          error => {
            this.loading = false;
            alert(error);
          }
        );
    }
  }

  loadMap(booking: any) {
    const  myLatlng  =  {lat: parseFloat(booking.location_latitude), lng: parseFloat(booking.location_longitude)};
            
    const  mapOptions  = {
      zoom:  15,
      center:  myLatlng
    };
    const  map  =  new  google.maps.Map(this.gmapElement.nativeElement, mapOptions);
    const  marker  =  new  google.maps.Marker({
      position:  myLatlng,
      title:  booking.location_address
    });
    marker.setMap(map);
  }

  setStar(star, booking) {
      booking.rating = star;
      this.securedService.setBookingRating(booking.id, booking.rating).subscribe(
        result => {

        },
        error => {
          booking.rating = false;
          if (error.status == 429 || error.status == 500) {
            alert(this.translate.instant('COMMON.500'));

          }
        }
      );
  }

}
