<div class="map-wrapper-container">
  <div id="about-component" class="component_inner container about-container">
    <div>
      <!-- Start English Content -->
      <div class="text-content">
        <div class="title-text"><span>About Us</span></div>

        <div class="subtitle-text"><span>We are an agency that is revolutionising and digitalizing the way skilled drivers and businesses come together. Our main aim is to provide an efficient booking system for both parties involved, whilst allowing the company to personally choose a skilled driver based on their experience and profile.</span></div>
        <div class="subtitle-text">We realise that in this line of work a skilled, confindent and experienced driver is key to getting the job done, after all driving a vehicle all day isn't for everyone! That's why our profiling and feedback system is the perfect tool for companies that rely on prompt and professional delivery of their products.</div>
        <div class="subtitle-text">Our service is set to change the way last minute drivers are booked, with our email notification system (soon to also use SMS), our location service and easy to use website. You can soon forget about that unreliable driver that let you down last minute, or that employee that's had too much to drink the night before and decided to pull a quick sickie! Our drivers are at hand to step in. 
        </div>
        <div class="subtitle-text">So what do you say? Lets not pickup the phone to another agency and get the new guy, browse our eager driver profiles today!</div>
      </div>
      <!-- End English Content -->
    </div>
  </div>

  <!-- <app-footer></app-footer> -->
  <hr>
  <div id="contact" class="container" style="padding-top: 0px;padding-bottom: 50px; ">

    <div class="row">
        <div class="col-md-12 text-center">
            <div class="text-center font-weight-bold"
                style="margin-top:50px;height: 60px;font-size:18px;font-family: Lato-Regular;">

                <span>
                    <span class="" style="color: #337777;">
                        CONTACT
                    </span>
                    
                </span>
                <br />
                <div
                    style="width: 50px;height: 2px;background-color: rgb(243, 115, 42);margin-left: auto;margin-right: auto;">
                </div>
            </div>
            <div class="col-md-12 " style="margin-bottom: 20px;">
                <div class="under_line ">
                    <div></div>
                </div>

            </div>

            <div class="text_content">
                <p>Ready to start your with us? <br>That's great! Give us a call or send us an email
                    and we will get back to you as soon as possible!</p>
            </div>
        </div>
        <div class="col-md-12">
            <div class="row">
                <div class="col-lg-4 text-center  text_content">
                    <i class="fa fa-phone fa-3x sr-contact" data-sr-id="7"
                        style="; visibility: visible;  -webkit-transform: scale(1); opacity: 1;transform: scale(1); opacity: 1;-webkit-transition: -webkit-transform 0.6s cubic-bezier(0.6, 0.2, 0.1, 1) 0s, opacity 0.6s cubic-bezier(0.6, 0.2, 0.1, 1) 0s; transition: transform 0.6s cubic-bezier(0.6, 0.2, 0.1, 1) 0s, opacity 0.6s cubic-bezier(0.6, 0.2, 0.1, 1) 0s; "></i>
                    <p>
                        <b>0151 272 0464</b>
                    </p>
                </div>
                
                <div class="col-lg-4 text-center text_content">
                    <i class="fa fa-envelope-o fa-3x sr-contact" data-sr-id="8"
                        style="; visibility: visible;  -webkit-transform: scale(1); opacity: 1;transform: scale(1); opacity: 1;-webkit-transition: -webkit-transform 0.6s cubic-bezier(0.6, 0.2, 0.1, 1) 0s, opacity 0.6s cubic-bezier(0.6, 0.2, 0.1, 1) 0s; transition: transform 0.6s cubic-bezier(0.6, 0.2, 0.1, 1) 0s, opacity 0.6s cubic-bezier(0.6, 0.2, 0.1, 1) 0s; "></i>
                    <p>

                        <b>contactus@drivers2day.co.uk</b>


                    </p>
                </div>

                <div class="col-lg-4 text-center  text_content">
                    <a href= "https://www.facebook.com/drivers2day"><i class="fa fa-facebook fa-3x sr-contact" data-sr-id="7"
                        style="; visibility: visible;  -webkit-transform: scale(1); opacity: 1;transform: scale(1); opacity: 1;-webkit-transition: -webkit-transform 0.6s cubic-bezier(0.6, 0.2, 0.1, 1) 0s, opacity 0.6s cubic-bezier(0.6, 0.2, 0.1, 1) 0s; transition: transform 0.6s cubic-bezier(0.6, 0.2, 0.1, 1) 0s, opacity 0.6s cubic-bezier(0.6, 0.2, 0.1, 1) 0s; "></i></a>
                    <p>
                        <b>@drivers2day</b>
                    </p>
                </div>
            </div>
        </div>
    </div>


  </div>
</div>

