import { Component, OnInit, ChangeDetectorRef, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { passValidator } from '../validator';
import { JwtService } from '../services/jwt.service';
import { DataSharingService } from '../services/data-sharing.service'
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CommonService } from '../services/common.service';
import * as $ from 'jquery';
import * as Globals from '../global';
@Component({
  selector: 'app-company-signup',
  templateUrl: './company-signup.component.html',
  styleUrls: ['./company-signup.component.css']
})
export class CompanySignupComponent implements OnInit {

  loading: boolean = false;
  success: boolean = false;
  validationErrors = {
    email: 'Invalid Email.',
    mobile: 'Invalid phone.',
    city: 'Invalid city.',
    company: 'Company name should be minimum 4 characters long.',
    contact_name: 'Contact name should be minimum 4 characters long.',
    company_contact_number: 'Company contact number is required.',
    password: 'Password should be minimum 6 characters long.',
    cnfpass: this.translate.instant('SIGNUP.confirm_password_not_matching'),
  };
  validationErrorsServer = {
    email: '',
    mobile: '',
    company: '',
    contact_name: '',
    company_contact_number: '',
    city: '',
    password: '',
    cnfpass: ''
  };

  /*fbSignup = {
    status: false,
    access_token: "",
    id: ""


  }

  onFbSignUp($event) {


    this.form.controls.email.setValue($event.email);
    //let company = $event.name.replace(/\s/g, "").toLowerCase();
    this.form.controls.company.setValue($event.company);
    this.form.controls.mobile.setValue($event.mobile);

    this.fbSignup.access_token = $event.accessToken;
    this.fbSignup.id = $event.id;
    this.fbSignup.status = true;
    this.ref.detectChanges();
  }*/

  @ViewChild('otp') otpinput: ElementRef;
  otpText: string = "";
  otpLoading: Boolean = false;
  otpResultView: Boolean = false;
  validationErrorsOtp: string = "";
  otpVerified: Boolean = false;
  otpEmail: string = "";
  otpResponseMessage = {
    status: "",
    text: ""
  }
  resetOTPVerification() {
    this.otpText = "";
    this.otpLoading = false;
    this.otpResultView = false;
    this.otpVerified = false;
    this.validationErrorsOtp = "";
    this.otpEmail = "";
    this.otpResponseMessage = {
      status: "",
      text: ""
    }
  }
  onOtpKeyup(event: KeyboardEvent) {

    if (this.otpText) {
      let stringOtp = this.otpText.toString();
      if (stringOtp.length >= 6) {
        this.otpText = stringOtp.substring(0, 6);
      }
    }


  }


resendOtpMsg="";
resendOtp(){
  this.validationErrorsOtp = ""
  if (!this.otpEmail) {
    return;
  }
  this.otpLoading = true;
  this.commonService.resendOtp(this.otpEmail)
    .subscribe(result => {
      this.otpLoading = false;

      this.resendOtpMsg=this.translate.instant('SIGNUP.RESEND_OTP_SUCCESS');
      
      setTimeout(() => {
        this.resendOtpMsg=""
      },  10000);


      

    },
      error => {
        this.otpLoading = false;
        //console.log(error);
        if (error.status == 429 || error.status == 500) {
          alert(this.translate.instant('COMMON.500'));

        }
        if (error.status == 422) {
          for (var i = 0; i < error.error.length; i++) {
            let element = error.error[i];



            if (element.field == 'code' || element.field == 'email') {
              this.validationErrorsOtp = element.message;
            }


          }
        }


      }
    );
}

  submitOtp() {


    this.validationErrorsOtp = ""
    if (!this.otpText || !this.otpEmail) {
      return;
    }
    this.otpLoading = true;
    this.commonService.submitEmailOtp(this.otpEmail, this.otpText)
      .subscribe(result => {
        this.otpLoading = false;

        this.otpResultView = true;
        this.otpResponseMessage.status =  this.translate.instant('SIGNUP.success');
        this.otpResponseMessage.text = this.translate.instant('SIGNUP.email_verified_success') ;

        
        setTimeout(() => {
          document.getElementById("closeOTPModelPopup").click();

          this.loading = false;
          this.success = true;
          this.jwtService.setLoggedInData(result);
          this.dataSharingService.isLoggedIn.next(true);
          this.router.navigate(['profile']);
        },  2000);


        

      },
        error => {
          this.otpLoading = false;
          //console.log(error);
          if (error.status == 429 || error.status == 500) {
            alert(this.translate.instant('COMMON.500'));

          }
          if (error.status == 422) {
            for (var i = 0; i < error.error.length; i++) {
              let element = error.error[i];



              if (element.field == 'code' || element.field == 'email') {
                this.validationErrorsOtp = element.message;
              }


            }
          }



          else {
            this.validationErrorsOtp = this.translate.instant('SIGNUP.wrong_OTP');
          }

        }
      );
  }


  ngOnInit(): void {
    $(document).ready(function () {

      $('#city-select2').select2({
        disabled: false,
        placeholder: 'City',
        minimumInputLength: 2,
        ajax: {
          url: function (params) {
            var country = 232;
            var url = Globals.API_ENDPOINT + 'general/city/' + country + '?';
            return url + params.term;
          },
          data: function (params) {
            var query = {
              search: params.term,
              type: 'public'
            }
            // Query parameters will be ?search=[term]&type=public
            return query;
          },
          processResults: function (data) {
            // Transforms the top-level key of the response object from 'items' to 'results'
            let resultsData: any = [];
            for (let i = 0; i < data.length; i++) {
              resultsData.push({ id: data[i].id, text: data[i].city });
            }
            return {
              results: resultsData
            };
          }
        }
      });
    });

  }

  form: FormGroup;
  constructor(private ref: ChangeDetectorRef,
    private translate: TranslateService, private commonService: CommonService,

    public router: Router, private dataSharingService: DataSharingService, private fb: FormBuilder, private jwtService: JwtService) {
    this.form = this.fb.group({
      company: [''],
      contact_name: [''],
      company_contact_number: [''],
      city: [''],
      password: [''],
      cnfpass: ['', passValidator],
      email: [''],
      mobile: ['']
    });


    this.form.controls.password.valueChanges
      .subscribe(
        x => this.form.controls.cnfpass.updateValueAndValidity()
      )
  }


  onTouch() {
    this.form.markAsTouched();
    this.form.controls.company.markAsTouched();
    this.form.controls.contact_name.markAsTouched();
    this.form.controls.company_contact_number.markAsTouched();
    this.form.controls.password.markAsTouched();
    this.form.controls.email.markAsTouched();
    this.form.controls.mobile.markAsTouched();
    this.form.controls.city.markAsTouched();
  }

  onSubmit() {
    //console.log(this.form.controls);
    this.validationErrorsServer = {
      email: '',
      company: '',
      contact_name: '',
      company_contact_number: '',
      mobile: '',
      password: '',
      cnfpass: '',
      city: ''
    };

    if ((this.form.controls.cnfpass.value || this.form.controls.password.value) && this.form.controls.cnfpass.value != this.form.controls.password.value) {
      this.validationErrorsServer.cnfpass = this.translate.instant('SIGNUP.confirm_password_not_matching');
      return;
    }

    let selectedCity = $('#city-select2').select2('data');
    if (!selectedCity[0]) {
      this.validationErrorsServer.city = this.translate.instant('BECOME_A_DRIVER.city_required');
      return;
    }

    this.loading = true;
    this.jwtService.registerCompany(this.form.controls.company.value, this.form.controls.contact_name.value, this.form.controls.company_contact_number.value,  this.form.controls.email.value, this.form.controls.mobile.value, $('#city-select2').select2('data')[0].id, this.form.controls.password.value)
      .subscribe(result => {

        this.loading = false;
        this.otpEmail = result.email.toString();
        document.getElementById("openModalButton").click();

      },
        error => {
          this.loading = false;
          //console.log(error);
          if (error.status == 422) {
            for (var i = 0; i < error.error.length; i++) {
              let element = error.error[i];

              if (element.field == 'name') {
                this.validationErrorsServer.company = element.message;
              }
              if (element.field == 'contact_name') {
                this.validationErrorsServer.contact_name = element.message;
              }
              if (element.field == 'company_contact_number') {
                this.validationErrorsServer.company_contact_number = element.message;
              }
              if (element.field == 'email') {
                this.validationErrorsServer.email = element.message;
              }
              if (element.field == 'mobile') {
                this.validationErrorsServer.mobile = element.message;
              }
              if (element.field == 'city_id') {
                this.validationErrorsServer.city = element.message;
              }
              if (element.field == 'password') {
                this.validationErrorsServer.password = element.message;
              }

            }
          }

        }
      );



    this.form.markAsTouched();
  }


}