import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Signup } from '../interfaces/Signup';
import { User } from '../interfaces/User';
import * as Globals from '../global';


@Injectable({
  providedIn: 'root'
})
export class JwtService {

  constructor(private httpClient: HttpClient) { }
  readonly baseAppUrl: string = Globals.API_ENDPOINT + 'auth/';
  readonly headers = new HttpHeaders({
    'content-type': 'application/json'
  });
  login(username: string, password: string) {
    const body = JSON.stringify({
      username: username,
      password: password,
      "remember_me": true

    });

    return this.httpClient.post<User>(`${this.baseAppUrl}login`, body, { 'headers': this.headers });

  }
  fbLogin(facebook_access_token: string, facebook_id: string) {
    const body = JSON.stringify({
      facebook_access_token: facebook_access_token,
      facebook_id: facebook_id,
    });

    return this.httpClient.post<User>(`${this.baseAppUrl}facebook-login`, body, { 'headers': this.headers });

  }
  


  resetPassword(password: string,code:string) {  

    
    const body = JSON.stringify({
      password: password,
      code:code
    });

    return this.httpClient.post<Signup>(`${this.baseAppUrl}reset-password`, body, { 'headers': this.headers });

  
  }

  registerDriver(data: any) {  
    return this.httpClient.post<Signup>(`${this.baseAppUrl}register-driver`, data, { 'headers': this.headers });
  }

  registerOwnerDriver(data: any) {  
    return this.httpClient.post<Signup>(`${this.baseAppUrl}register-owner-driver`, data, { 'headers': this.headers });
  }

  registerCompany(company: string, contact_name: string, company_contact_number:string, email: string, mobile: string, city: string, password: string) {

    const body = JSON.stringify({
      name: company,
      contact_name: contact_name,
      company_contact_number: company_contact_number,
      email: email,
      mobile: mobile,
      city_id: city,
      password: password
    });

    return this.httpClient.post<Signup>(`${this.baseAppUrl}register-company`, body, { 'headers': this.headers });
  }

  register(username: string, email: string, password: string, fbSignup: any) {  

    if (fbSignup.status) {

      const body = JSON.stringify({
        username: username,
        email: email,
        password: password,
        facebook_access_token: fbSignup.access_token,
        facebook_id: fbSignup.id

      });

      return this.httpClient.post<Signup>(`${this.baseAppUrl}facebook-register`, body, { 'headers': this.headers });


    } else {
      const body = JSON.stringify({
        username: username,
        email: email,
        password: password

      });

      return this.httpClient.post<Signup>(`${this.baseAppUrl}register`, body, { 'headers': this.headers });
    }

  }
  public logout() {
    localStorage.removeItem('loggedindata');
  }

  public get loggedIn(): boolean {
    return this.getAuthToken() !== null;
  }

  public setLoggedInData(data: any) {
    localStorage.setItem('loggedindata', JSON.stringify(data));

  }
  public getLoggedInData() {
    let jsondata = localStorage.getItem('loggedindata');
    let data = JSON.parse(jsondata);
    return data;
  }

  public get isPaye():Boolean{
    let data = this.getLoggedInData();
    if (data && data.user_type == "DRIVER" && data.driver_type == "PAYE") {
      return true;
    }
    else {
      return false;
    }
  }

  public get isLtd():Boolean{
    let data = this.getLoggedInData();
    if (data && data.user_type == "DRIVER" && data.driver_type == "LTD") {
      return true;
    }
    else {
      return false;
    }
  }

  public get driverCategory():any{
    let data = this.getLoggedInData();
    if (data && data.user_type == "DRIVER") {
      return data.driver_category_id;
    }
    else {
      return false;
    }
  }

  public get driverLicence():any{
    let data = this.getLoggedInData();
    if (data && (data.licence_verified == "2" || data.licence_verified == 2)) {
      return true;
    }
    else {
      return false;
    }
  }

  public get isDriver():Boolean{
    let data = this.getLoggedInData();
    if (data && data.user_type == "DRIVER") {
      return true;
    }
    else {
      return false;
    }
  }

  public get isOwnerDriver():Boolean{
    let data = this.getLoggedInData();
    if (data && data.user_type == "OWNER_DRIVER") {
      return true;
    }
    else {
      return false;
    }
  }

  public get isCompany():Boolean{
    let data = this.getLoggedInData();
    if (data && data.user_type == "COMPANY") {
      return true;
    }
    else {
      return false;
    }
  }
  /*public setDriver(isDriver:Boolean){
    let data = this.getLoggedInData();
    if(isDriver){
      data.driver="1";
    }else{
      data.driver="0";
    }
    this.setLoggedInData(data);
  }*/

  getAuthToken() {
    let userJson = localStorage.getItem('loggedindata');
    if (!userJson) {
      return null;
    }
    let user = JSON.parse(userJson);
    if (user) {

      return user.access_token;
    }
    else {
      return null;
    }
  }
}
