<div [hidden]="loading" class="row col-md-12" style="     margin: 20px 0 35px 0;
padding: 0;">
    <div class="col-12">

        <h2 style="font-size: 23px;
        color: #337777;
        font-family: Lato-Regular;
      
        width: 100%;
        margin-bottom: 10px;
        text-align: center;
       ">
            Active Booking(s)</h2>

        <div style="width:100%;height: 1px; background-color: #e6e6e6;">

        </div>
    </div>
    <ng-container *ngFor="let booking of bookings">
        <div style="border: dashed 1px #ffb9a0;
        background-color: #fefaf8;
        padding: 20px;
        margin-top: 27px;
       
       " class="col-md-12">
            <div class="row" style="margin-top: 25px;">
                <div class="col-12 col-sm-1 col-lg-1">
                    <label for="booking_date" class="control-label" style="font-family: Lato-Regular;">#ID</label>
                    <p class="form-control-static">{{ booking.id }}</p>
                </div>
                <div class="col-12 col-sm-2 col-lg-2">
                    <label for="booking_date" class="control-label"
                        style="font-family: Lato-Regular;">{{isDriver?"Company Name":"Driver Name"}}</label>
                    <p class="form-control-static">{{isDriver?booking.company_name:booking.driver_name}}</p>
                </div>
                <!-- <div class="col-12 col-sm-2 col-lg-2">
                    <label for="booking_date" class="control-label" style="font-family: Lato-Regular;">Booking
                        Date</label>
                    <p class="form-control-static">{{ booking.start_time | date : 'mediumDate' }}</p>
                </div> -->
                <div class="col-12 col-sm-9 col-lg-9">
                    <div class="row">
                        <div class="col-6 col-sm-3 col-lg-3">
                            <label for="start_time" class="control-label" style="font-family: Lato-Regular;">Start
                                time</label>
                            <p class="form-control-static">{{ booking.start_time | date : 'MMM d, y h:mm a' }}</p>
                        </div>
                        <div class="col-6 col-sm-3 col-lg-3">
                            <label for="end_time" class="control-label" style="font-family: Lato-Regular;">End
                                time</label>
                            <p class="form-control-static">{{ booking.end_time | date : 'MMM d, y h:mm a' }}</p>
                        </div>
                        <div class="col-6 col-sm-3 col-lg-3">
                            <label for="end_time" class="control-label" style="font-family: Lato-Regular;">Hourly
                                Rate</label>
                            <p class="form-control-static"><i class="fa fa-gbp" style="font-size: 14px;"></i>{{
                                booking.hourly_rate }}</p>
                        </div>
                        <div class="col-6 col-sm-3 col-lg-3">
                            <label for="end_time" class="control-label"
                                style="font-family: Lato-Regular;">Status</label>
                            <p [hidden]="!loading && isDriver && booking.booking_status == 'REQUESTED'"
                                class="form-control-static">{{ booking.booking_status }}</p>
                            <p *ngIf="!loading && isDriver && booking.booking_status == 'REQUESTED'"
                                class="form-control-static">
                                <button class="btn btn-sm"
                                    style="color: #fff; background-color: #337777;border-color: #337777;"
                                    (click)="todayAcceptRow(booking)"><i class="fa fa-check"></i> Accept</button>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <hr>

            <div class="row">

                <div style="text-align: left;" class="col-md-6">



                    <label class="control-label " style="Lato-Regular;margin-bottom: 0px;">Location
                    </label>
                    <address>
                        <strong>{{booking.location_title}}</strong><br>
                        {{booking.location_address}}<br>
                        <a data-toggle="modal" data-target="#bookingMapModal" style="color: rgb(51 119 119);
                            text-decoration: underline;
                            font-weight: bold;
                            cursor: pointer;" (click)="todayLoadMap(booking)">View Map</a>
                    </address>
                    <div *ngIf="booking.booking_status == 'COMPLETED' && booking.hirer_note" class="form-group">
                        <label class="control-label " style="font-family: Lato-Regular;margin-bottom: 0px;">Hirer Note:
                        </label>
                        <p>{{booking.hirer_note}}</p>
                    </div>
                    <div *ngIf="booking.booking_status == 'COMPLETED'" class="form-group">
                        <label class="control-label " style="font-family: Lato-Regular;margin-bottom: 0px;">Rating:
                        </label>
                        <div *ngIf="booking.rating" class="form-control-static">
                            <ul class="list-inline rating-list" *ngFor="let star of stars"
                                style="display: inline-block">
                                <li [ngClass]="{'selected': (star <= booking.rating)}">
                                    <i class="fa fa-star"></i>
                                </li>
                            </ul>
                        </div>
                        <div *ngIf="!booking.rating" class="form-control-static">
                            <ul class="list-inline rating-list not-set-rating" *ngFor="let star of stars"
                                style="display: inline-block">
                                <li (click)="todaySetStar(star, booking)"
                                    [ngClass]="{'selected': (star <= booking.rating)}">
                                    <i class="fa fa-star"></i>
                                </li>
                            </ul>
                        </div>
                    </div>

                </div>

                <div class="col-md-6 text-right">

                    <button *ngIf="!loading && isDriver && booking.booking_status == 'ACCEPTED'" class="btn btn-sm"
                        style="    color: #fff;
                        background-color: #ff4400;
                        border-color: #e84002;
                        border-radius: 4px;
                        width: 100px;
                        font-weight: bold;" data-toggle="modal" data-target="#startOTPModal"
                        (click)="startOtpBooking=booking">

                        Entry &nbsp;&nbsp;<i class="fa fa-play"></i>
                    </button>
                    <button *ngIf="!loading && isDriver && booking.booking_status == 'STARTED'" class="btn btn-sm"
                        style="color: #fff;
                        background-color: #24a200;
                        border-color: #217f06;
                        border-radius: 4px;
                        width: 100px;
                        font-weight: bold;
                        " data-toggle="modal" data-target="#endOTPModal" (click)="endOtpBooking=booking">Exit
                        &nbsp;&nbsp;<i class="fa fa-check"></i></button>


                    <p class="form-control-static"
                        *ngIf="!loading && isCompany && booking.booking_status == 'ACCEPTED'">
                        <b>ENTRY Code : {{ booking.start_code}}</b>
                    </p>
                    <button *ngIf="!loading && isCompany && booking.booking_status == 'ACCEPTED'" class="btn btn-sm"
                        style="  color: #fff;
                        background-color: #ff4400;
                        border-color: #e84002;
                        border-radius: 4px;
                        width: 100px;
                        font-weight: bold;" (click)="startBooking(booking)">Entry &nbsp;&nbsp; <i
                            class="fa fa-play"></i></button>
                    <p class="form-control-static" *ngIf="!loading && isCompany && booking.booking_status == 'STARTED'">
                        <b>EXIT Code : {{ booking.end_code}}</b>
                    </p>
                    <button *ngIf="!loading && isCompany && booking.booking_status == 'STARTED'" class="btn btn-sm"
                        style="color: #fff;
                        background-color: #24a200;
                        border-color: #217f06;
                        border-radius: 4px;
                        width: 100px;
                        font-weight: bold;" (click)="finishBooking(booking)">Exit &nbsp;&nbsp;<i
                            class="fa fa-check"></i></button>


                </div>
            </div>
            <div style="    width: 100%;
            height: 100%;
            position: absolute;
            top: 0px;
            left: 0px;
            background-color: #ffffffcc;
            z-index: 1;" *ngIf="!loading && isCompany && (isKeySet(startStopSuccess, booking.id)||isKeySet(startStopError, booking.id) || isKeySet(startStopLoading, booking.id))"   >
      



                    <div style="text-align: center;
                    margin-top: 100px;
                    padding-top: 30px;
                    color: #00c52b;
                    font-weight: bold;
                    min-height: 100px;" class="form-control-static"
                        *ngIf="!loading && isCompany && isKeySet(startStopSuccess, booking.id)">
                        {{
                        startStopSuccess[booking.id] }}</div>
                    <div style="text-align: center;
                    margin-top: 100px;
                    padding-top: 30px;
                    color: #ff2323;
                    font-weight: bold;
                    min-height: 100px;" class="form-control-static"
                        *ngIf="!loading && isCompany && isKeySet(startStopError, booking.id)">{{
                        startStopError[booking.id] }} </div>

                    <div *ngIf="!loading && isCompany && isKeySet(startStopLoading, booking.id)" style="text-align: center;
                                margin-top: 100px;
                                min-height: 100px; ">
                        <img src="../../assets/images/loader.gif" style="margin-top: 27px;
                        width: 42px;" />
                    </div>
            
            </div>

        </div>







    </ng-container>
    <div *ngIf='bookings.length == 0'>
        No booking to show for today
    </div>
    <div *ngIf='viewMoreUrl' class="view-more-button" (click)="todayViewMore()">
        View More
    </div>
</div>



<div id="startOTPModal" class="modal fade" role="dialog" data-keyboard="false" data-backdrop="static">
    <div class="modal-dialog">
        <!-- Modal content-->
        <div class="modal-content" style="min-height: 200px;;">
            <div class="modal-body  otp" [hidden]="startOtpLoading|| startOtpResultView " style="padding: 37px 15px;">
                <p><b>Get the ENTRY Code from the person booked you.</b></p>
                <div style="text-align: center;">
                    <input max="999999" type="number" [(ngModel)]="startOtpText" (keyup)="onStartOtpKeyup($event)"
                        placeholder="" style="
                      padding-left: 15px;
                        caret-color: #ffffff;
                        letter-spacing: 33px;
                        border: 0;
                        background-image: url(../../assets/images/otp_bg_email.jpg);
                        background-repeat: no-repeat;
                        width: 280px;
                        height: 50px;">
                </div>
                <div class="validation_error">
                    <span *ngIf="validationErrorsOtp">{{validationErrorsOtp}}</span>
                </div>
            </div>
            <div [hidden]="!startOtpLoading || startOtpResultView ">
                <div style="text-align: center;
                            margin-top: 62px;
                            min-height: 100px; ">
                    <img src="../../assets/images/loader.gif"
                        style="margin-top: 10px;   width: 70px;padding-left: 20px;" />
                </div>
            </div>
            <div class="text-center" [hidden]="startOtpLoading || !startOtpResultView"
                style=" height: 50px;margin-top: 48px;">
                <span class="font-weight-normal" style="font-size:18px;color: #337777;font-family: Lato-Regular;">
                    {{startOtpResponseMessage.status}}
                </span>
                <br />
                <div
                    style="width: 50px;height: 2px;background-color: rgb(243, 115, 42);margin-left: auto;margin-right: auto;">
                </div>
                <div style="margin-top: 10px;">
                    {{startOtpResponseMessage.text}}
                </div>
            </div>
            <div [hidden]="startOtpLoading || startOtpResultView" class="button-group" style="    text-align: right;">
                <button type="button" id="closeOTPModelPopup" [hidden]="true" class="btn btn-default"
                    data-dismiss="modal">Close</button>
                <button type="button" style="background-color: rgb(243, 115, 42);;
                border-color: rgb(243, 115, 42);" (click)="submitStartOtp()" class="btn btn-info"> Start </button>
            </div>
        </div>
    </div>
</div>
<div id="endOTPModal" class="modal fade" role="dialog" data-keyboard="false" data-backdrop="static">
    <div class="modal-dialog">
        <!-- Modal content-->
        <div class="modal-content" style="min-height: 200px;;">
            <div class="modal-body  otp" [hidden]="endOtpLoading|| endOtpResultView " style="padding: 37px 15px;">
                <p><b>Get the EXIT Code from the person booked you.</b></p>
                <div style="text-align: center;">
                    <input max="999999" type="number" [(ngModel)]="endOtpText" (keyup)="onEndOtpKeyup($event)"
                        placeholder="" style="
                      padding-left: 15px;
                        caret-color: #ffffff;
                        letter-spacing: 33px;
                        border: 0;
                        background-image: url(../../assets/images/otp_bg_email.jpg);
                        background-repeat: no-repeat;
                        width: 280px;
                        height: 50px;">
                </div>
                <div class="validation_error">
                    <span *ngIf="validationErrorsOtp">{{validationErrorsOtp}}</span>
                </div>
            </div>
            <div [hidden]="!endOtpLoading || endOtpResultView ">
                <div style="text-align: center;
                            margin-top: 62px;
                            min-height: 100px; ">
                    <img src="../../assets/images/loader.gif"
                        style="margin-top: 10px;   width: 70px;padding-left: 20px;" />
                </div>
            </div>
            <div class="text-center" [hidden]="endOtpLoading || !endOtpResultView"
                style=" height: 50px;margin-top: 48px;">
                <span class="font-weight-normal" style="font-size:18px;color: #337777;font-family: Lato-Regular;">
                    {{endOtpResponseMessage.status}}
                </span>
                <br />
                <div
                    style="width: 50px;height: 2px;background-color: rgb(243, 115, 42);margin-left: auto;margin-right: auto;">
                </div>
                <div style="margin-top: 10px;">
                    {{endOtpResponseMessage.text}}
                </div>
            </div>
            <div [hidden]="endOtpLoading || endOtpResultView" class="button-group" style="    text-align: right;">
                <button type="button" id="closeEndOTPModelPopup" [hidden]="true" class="btn btn-default"
                    data-dismiss="modal">Close</button>
                <button type="button" style="background-color: rgb(243, 115, 42);;
                border-color: rgb(243, 115, 42);" (click)="submitEndOtp()" class="btn btn-info"> End </button>
            </div>
        </div>
    </div>
</div>