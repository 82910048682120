<div class="container_ ">




    <div class="messaging">
        <div class="inbox_msg">
            <div [hidden]="!showInboxPeople" class="inbox_people col-md-12">
                <div class="headind_srch">
                    <div class="back" routerLink="/profile">
                        <i class="fa fa-chevron-left" aria-hidden="true"></i>{{'MESSAGES.profile' | translate }}
                    </div>
                    <div class="recent_heading">
                        <h4>{{'MESSAGES.messages' | translate }}</h4>
                    </div>
                    <!-- <div class="srch_bar">
                        <div class="stylish-input-group">
                            <input type="text" class="search-bar" placeholder="Search">
                        </div>
                    </div> -->
                </div>
                <div class="inbox_chat scroll"  [style.height.px]="messageContainerHeight+50">

                    <div style="text-align: center;margin-top: 100px;min-height: 300px; " [hidden]="!contactLoading">

                        <img src="../../assets/images/loader.gif"
                            style="margin-top: 10px;   width: 70px;padding-left: 20px;" />
                    </div>

                    <div class="chat_list" [hidden]="contactLoading"
                        (click)="onContactSelect(contact.id,contact.first_name?contact.first_name:contact.username)"
                        *ngFor="let contact of contacts">
                        <div class="chat_people">
                            <div class="chat_img">

                                <img [src]="contact.profile_image?contact.profile_image:'../../assets/images/no-dp-2.png'"
                                    alt="{{contact.first_name}}"> </div>
                            <div class="chat_ib">
                                <h5>{{contact.first_name?contact.first_name:contact.username}} <span
                                        class="chat_date">
                                        {{contact.updated_at}}    
                                        <!-- Dec 25 -->
                                    
                                    </span></h5>
                                <p>{{contact.message}}</p>
                            </div>
                        </div>
                    </div>


                </div>
            </div>
            <div [hidden]="showInboxPeople" class="mesgs  col-md-12">
                <div class="headind_srch" >
                    <div class="back" (click)="backToContacts()">
                        <i class="fa fa-chevron-left" aria-hidden="true"></i> {{'MESSAGES.back' | translate }} 
                    </div>
                    <div class="recent_heading">
                        <h4>{{selectedContactName}}</h4>
                    </div>
                    <!-- <div class="srch_bar">
                       <div class="stylish-input-group">
                           <input type="text" class="search-bar" placeholder="Search">
                       </div>
                   </div> -->
                </div>
                <div class="msg_history" id="msg_history"  #messageCont   [style.height.px]="messageContainerHeight">

                    <div *ngIf='viewMoreMessages' [hidden]="loadMoreLoading" class="view-more-button" (click)="loadMessages(viewMoreMessages)">
                         {{'MESSAGES.load_more' | translate }} 
                    </div>
                    <div *ngIf='viewMoreMessages' style="text-decoration: none;" [hidden]="!loadMoreLoading" class="view-more-button" (click)="loadMessages(viewMoreMessages)">
                        {{'MESSAGES.loading' | translate }}
                    </div>
                    <div style="text-align: center;margin-top: 100px;min-height: 300px; " [hidden]="!messageLoading">

                        <img src="../../assets/images/loader.gif"
                            style="margin-top: 10px;   width: 70px;padding-left: 20px;" />
                    </div>

                    <div [hidden]="messageLoading">

                        <div [ngClass]="user.id==message.from_id ? 'outgoing_msg' : 'incoming_msg'"
                            *ngFor="let message of messages">
                           
                           
                            <div [hidden]="user.id==message.from_id" class="incoming_msg_img"> <img src="../../assets/images/no-dp-2.png" alt="sunil">
                            </div>
                            <div [hidden]="user.id==message.from_id" class="received_msg">
                                <div class="received_withd_msg">
                                    <p>{{message.message}}</p>
                                    <span class="time_date"> 
                                        <!-- 11:01 AM | June 9 -->
                                        {{message.created_at}}
                                    </span>
                                </div>
                            </div>

                            <div [hidden]="user.id!=message.from_id"  class="sent_msg">
                                <p>{{message.message}}</p>
                                <span class="time_date"> 
                                    <!-- 11:01 AM | June 9 -->
                                    {{message.created_at}}
                                </span>
                            </div>

                        </div>


                    </div>
                </div>
                <div class="type_msg">
                    <div class="input_msg_write">
                        <input type="text" class="write_msg" (keydown.enter)="sendMessage()"  [(ngModel)]="messageToSend" placeholder="Type a message" />
                        <button (click)="sendMessage()"class="msg_send_btn" type="button"><i class="fa fa-chevron-right" 
                                aria-hidden="true"></i></button>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>