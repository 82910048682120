

<div style="    margin-top: 65px;
height: 35px;">
    <div style="height: 20px;
    color: #a29c9c;
    float: right;
    font-size: 31px;margin-right: 20px;">
        <span routerLink="/" routerLinkActive="active"><i class="fa fa-times" aria-hidden="true"></i></span>
    </div>


</div>
<div class="contentHome">
    <div class="text-center font-weight-bold"
    style="margin-top:60px;">

        <span class="font-weight-normal"
            style="font-size:22px;color: #337777;font-family: Lato-Regular;">FIND THE DRIVER</span><br />
        <span class="font-weight-normal">THAT MEETS THE NEEDS OF </span><br />
    </div>


    <div class="text-center" style=" margin-top:10px;margin-bottom: 20px;">

        <span class="font-weight-normal"
            style="font-size:22px;color: #337777;font-family: Lato-Regular;">YOUR BUSINESS</span><br />
        <span class="font-weight-normal">TODAY</span><br />
        <!--<span class="font-weight-normal"
            style="font-size:22px;color: #337777;font-family: Lato-Regular;">BROWSE OUR DRIVER PROFILES</span><br />
        <span class="font-weight-normal"
            style="font-size:22px;color: #337777;font-family: Lato-Regular;">IN YOUR AREA <span 
            style="color: #de7149;">FOR FREE</span>
        </span><br /><br />-->

    </div>
    <!--<div class="text-center" style=" margin-top:20px;
 /* font-family: GE-SS;*/
  
  ">
         <span class="font-weight-normal">{{'HOME.intro_line3' | translate }} </span><br /><br />

        <span class="font-weight-normal">{{'HOME.intro_line4' | translate }}
            <span class="font-weight-normal" style="color: #337777;font-size: 16px;font-family: Lato-Regular;">
                {{'HOME.intro_line4.1' | translate }}</span>
            {{'HOME.intro_line4.2' | translate }}</span><br />
        <span class="font-weight-normal">{{'HOME.intro_line5' | translate }}</span> <br />


    </div>-->

    <!-- <div class="text-center" style=" margin-top:50px;height: 50px;">

        <span class="font-weight-normal"
            style="font-size:18px;color: #337777;font-family: Lato-Regular;">{{'HOME.our_drivers' | translate }}</span><br />
        <div style="width: 50px;height: 2px;background-color: rgb(243, 115, 42);margin-left: auto;margin-right: auto;">
        </div>

    </div> -->

    <!-- <app-home-video></app-home-video> --><!-- <app-filter-home-video></app-filter-home-video> -->

    <!--<div class="text-center" style=" margin-top:20px;">


        <span class="font-weight-normal">{{'HOME.intro_line6' | translate }}</span><br />
        <span class="font-weight-normal">{{'HOME.intro_line7' | translate }} 
            </span><br /><br />

    </div>-->
<div class="text-center" style="height: 60px;margin-top:20px;">

    <span class="font-weight-bold" style="font-size:18px;color: #337777;">{{'SIGNUP.signup' | translate }}</span><br />
    <div style="width: 50px;height: 2px;background-color: rgb(243, 115, 42);margin-left: auto;margin-right: auto;">
    </div>

</div>
<app-company-signup></app-company-signup>
    
    

