import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as Globals from '../global';

import { Country } from '../interfaces/Country';
@Injectable({
  providedIn: 'root'
})
export class CommonService {
  constructor(private httpClient: HttpClient) { }
  readonly baseAppUrl: string = Globals.API_ENDPOINT + 'general/';
  readonly headers = new HttpHeaders({
    'content-type': 'application/json'
  });
  getCountries() {
    return this.httpClient.get<Country[]>(`${this.baseAppUrl}country`);
  }

  getPricingDetail() {
    return this.httpClient.get<any[]>(`${this.baseAppUrl}pricing-detail`);
  }

  verifyMobile(mobile: string) {
    return this.httpClient.get<any>(`${this.baseAppUrl}mobile-verify/${mobile}`);
  }
  submitOtp(verfication_id: string, verfication_code: string) {
    return this.httpClient.get<any>(`${this.baseAppUrl}mobile-validate/${verfication_id}/${verfication_code}`);
  }

  resendOtp(email: string) {
    const body = JSON.stringify({
      email: email
    });
    return this.httpClient.post<any>(`${Globals.API_ENDPOINT}auth/resend-activation-email`, body, { 'headers': this.headers });
  }
  submitForgotPassword(email: string) {
    const body = JSON.stringify({
      email: email
    });
    return this.httpClient.post<any>(`${Globals.API_ENDPOINT}auth/forget-password`, body, { 'headers': this.headers });
  }

  submitEmailOtp(email: string, code: string) {
    const body = JSON.stringify({
      email: email,
      code:code
    });
    return this.httpClient.post<any>(`${Globals.API_ENDPOINT}auth/activate-account`, body, { 'headers': this.headers });
  }


  getStarProfile(id: number) {
    return this.httpClient.get<any>(`${Globals.API_ENDPOINT}profile/${id}`);
  }

}
