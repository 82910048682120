<div class="payout-landing" style=" margin-top: 65px;min-height: 700px;padding-top: 30px;;">

    <div class="container">
        <div class="row" style="padding: 0 15px;text-align: center;">
            <div [hidden]="loading" class="col-12 col-sm-4" style="background-color: #efefef;min-height: 204px;">
                <div
                    style="display: none; height: 20px;color: #a29c9c;float: right;font-size: 31px;margin-right: 10px;">
                    <span><i class="fa fa-cog" aria-hidden="true"></i></span>
                </div>
                <div class="col-12" style="margin: 10px;font-family: Lato-Regular;">
                    {{driver.full_name}}
                </div>
                <div style="width: 100%;float: left;">
                    <div [hidden]="!driver.profile_image" style="
                        background-image: url({{driver.profile_image}});
                        height: 100px;   
                        margin: 10px auto;
                        width: 100px;
                        background-size: cover;
                        border-radius: 63px;
                        background-position: center;
                        ">
                    </div>
                    <img [hidden]="driver.profile_image" style=" width: 100px;margin: 10px auto;"
                        src="../../assets/images/no-dp-2.png" />
                </div>
                
                <div class="form-group" style="margin-top:5px;">
                    <a class="btn btn-success btn-md" style="padding-left: 40px;padding-right: 40px;background-color: #337777;border-color: #337777;" [routerLink]="['/manage-booking']">Go to Bookings</a>
                </div>

                <div class="form-group" style="margin-top:20px;">
                    <button [routerLink]="['/profile']" class="btn btn-success btn-md" style="padding-left: 40px;padding-right: 40px;background-color: #337777;border-color: #337777;">Go to Profile</button>
                </div>
            </div>
            <div class="row" [hidden]="loading" class="col-12 col-sm-7" style="margin-left: 20px; margin-top: 20px;">
                <h2 style="font-size: 25px;color: #337777;font-family: Lato-Regular;text-decoration: underline;width: 100%;">Payments</h2>
                <ng-container *ngFor="let payout of payouts">
                    <hr>
                    <div class="row" style ="margin-top: 25px;">
                        <div class="col-12 col-sm-1 col-lg-1"> 
                            <label for="id" class="control-label" style="font-family: Lato-Regular;">#ID</label>
                            <p class="form-control-static">{{ payout.id }}</p>
                        </div>
                        
                        <div class="col-12 col-sm-3 col-lg-3"> 
                            <label for="created_at" class="control-label" style="font-family: Lato-Regular;">Payment Date</label>
                            <p class="form-control-static">{{ payout.created_at | date : 'mediumDate' }}</p>
                        </div>

                        <div class="col-12 col-sm-3 col-lg-3"> 
                            <label for="pay_by_date" class="control-label" style="font-family: Lato-Regular;">Pay By Date</label>
                            <p class="form-control-static">{{ payout.pay_by_date | date : 'mediumDate' }}</p>
                        </div>

                        <div class="col-12 col-sm-2 col-lg-2"> 
                            <label for="total_amount" class="control-label" style="font-family: Lato-Regular;">Amount</label>
                            <p class="form-control-static"><i class="fa fa-gbp" style="font-size: 14px;"></i> {{ payout.total_amount }}</p>
                        </div>

                        <div class="col-12 col-sm-2 col-lg-2"> 
                            <label for="payout_status" class="control-label" style="font-family: Lato-Regular;">Status</label>
                            <p class="form-control-static">{{ payout.payout_status }}</p>
                            
                        </div>

                        <div class="col-12 col-sm-1 col-lg-1"> 
                        	<label for="payout_status" class="control-label" style="font-family: Lato-Regular;"> </label>
                        	<p><button class="btn btn-sm" style="color: #fff; background-color: #337777;border-color: #337777;" [routerLink]="['/payout', payout.id]" >Detail</button></p>
                        </div>
                    </div>
                </ng-container>
                <div *ngIf='payouts.length == 0' style="margin-top: 30px;">
                   No payments to show
                </div>
                <div *ngIf='viewMoreUrl' class="view-more-button" (click)="viewMore()">
                   View More
                </div>
            </div>
            <div [hidden]="!loading" style="width: 100%;background-color: #efefef;min-height: 204px;">
                <div class="ion-padding" style="text-align: center;margin-top: 71px;min-height: 100px;">
                    <img src="../../assets/images/loader.gif"
                        style=" margin-top: 10px;   width: 70px;padding-left: 20px;" />
                </div>
            </div>
        </div>
    </div>
</div>
