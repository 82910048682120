import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { SecuredService } from '../services/secured.service';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';
import { JwtService } from '../services/jwt.service';
import { User } from '../interfaces/User';

@Component({
  selector: 'app-messages',
  templateUrl: './messages.component.html',
  styleUrls: ['./messages.component.css']
})
export class MessagesComponent implements OnInit {

  @ViewChild('messageCont') private messageContainer: ElementRef;



  constructor(private securedService: SecuredService,
    private route: ActivatedRoute, public router: Router,
    private jwtService: JwtService,
    private translate: TranslateService) { }

  user: User;
  backPage: string = "";
  messageContainerHeight: number;
  ngOnInit(): void {


    let innerWidth = window.innerWidth;

    let innerHeight = window.innerHeight;

    if (innerWidth < 767) {
      this.messageContainerHeight = innerHeight - 174;
    }
    else {
      this.messageContainerHeight = innerHeight - 300;
    }



    this.user = this.jwtService.getLoggedInData();
    let id = this.route.snapshot.paramMap.get('id');
    let name = this.route.snapshot.paramMap.get('name');
    this.backPage = this.route.snapshot.paramMap.get('page');
    if (id != '0') {
      this.onContactSelect(Number(id), name);
    }
    this.loadContacts(null);

  }

  contacts: any = [];
  viewMoreContacts: string = null;

  messages: any = [];
  viewMoreMessages: string = null;

  showInboxPeople: boolean = true;
  selectedContactId: number = null;
  selectedContactName: string = null;
  messageToSend: string = null;

  messageLoading: Boolean = false;
  contactLoading: Boolean = false;

  loadMoreLoading: Boolean = false;


  onKeydown(event) {
    //console.log(event);
  }

  
  sendMessage() {

    if (!this.selectedContactId || !this.messageToSend) {
      return;
    }
    this.messageLoading = true;
    this.securedService.sendMessage(this.messageToSend, this.selectedContactId).subscribe(
      result => {

        this.messageLoading = false;
        this.onContactSelect(this.selectedContactId, this.selectedContactName)
      },
      error => {

        //console.log(error);
        this.messageLoading = false;
        if (error.status == 429 || error.status == 500) {
          alert(this.translate.instant('COMMON.500'));

        }

      }
    );
    this.messageToSend = "";
  }


  backToContacts() {

    if (this.backPage && this.backPage != "no-back") {
      this.router.navigate([this.backPage]);
      return;
    }

    this.showInboxPeople = true;
    this.selectedContactId = null;
    this.loadContacts(null);
  }

  onContactSelect(selectedContactId: number, selectedContactName: string) {
    this.showInboxPeople = false;
    this.selectedContactId = selectedContactId;
    this.selectedContactName = selectedContactName;
    this.loadMessages(null);
  }

  loadContacts(viewMoreContacts: string) {
    this.contactLoading = true;
    this.securedService.getMessageContacts(viewMoreContacts).subscribe(
      result => {
        this.contacts = result.items;
        this.viewMoreContacts = result?._links?.next?.href;


        for (let i = 0; i < this.contacts.length; i++) {
           
          let date=   new Date(this.contacts[i].updated_at+" UTC");
          this.contacts[i].updated_at=date.toLocaleString();
         
        }


        this.contactLoading = false;
      },
      error => {
        this.contactLoading = false;
        //console.log(error);
        if (error.status == 429 || error.status == 500) {
          alert(this.translate.instant('COMMON.500'));

        }

      }
    );

  }
  loadMessages(viewMoreMessages: string) {
    if (!this.selectedContactId) {
      return;
    }
    if (viewMoreMessages) {
      this.loadMoreLoading = true;
    } else {
      this.messageLoading = true;
    }
    this.securedService.getMessages(viewMoreMessages, this.selectedContactId).subscribe(
      result => {


        if (viewMoreMessages) {
          this.loadMoreLoading = false;
          this.viewMoreMessages = result?._links?.next?.href;

          for (let i = 0; i < result.items.length; i++) {
            let message = result.items[i];

              let date=   new Date(message.created_at+" UTC");
              message.created_at=date.toLocaleString();
             
        
            this.messages.unshift(message);
          }

        } else {

          for (let i = 0; i < result.items.length; i++) {
            let message = result.items[i];
              let date=   new Date(message.created_at+" UTC");
              message.created_at=date.toLocaleString();
          }


          this.messages = result.items.reverse();
          this.messageLoading = false;
          this.viewMoreMessages = result?._links?.next?.href;
        }
        this.messageContainer.nativeElement.scrollTop = this.messageContainer.nativeElement.scrollHeight;
        this.messageContainer.nativeElement.scroll({

          bottom: this.messageContainer.nativeElement.scrollHeight,

          left: 0,

          behavior: 'smooth'

        });
      },
      error => {

        //console.log(error);
        this.messageLoading = false;
        if (error.status == 429 || error.status == 500) {
          alert(this.translate.instant('COMMON.500'));

        }

      }
    );

  }

}
