import { Component, OnInit } from '@angular/core';
import { JwtService } from '../services/jwt.service';
import { DataSharingService } from '../services/data-sharing.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-policies',
  templateUrl: './policies.component.html',
  styleUrls: ['./policies.component.css']
})
export class PoliciesComponent implements OnInit {

  constructor(private router: Router,
    private dataSharingService: DataSharingService,
    private jwtService: JwtService) { }

  ngOnInit(): void {
  }

  deleteCookies() {
    document.cookie.split(";").forEach(function(c) { document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/"); });
    this.jwtService.logout();
    this.dataSharingService.isLoggedIn.next(false);
    this.router.navigate(['']);
    window.location.reload();
  }

}
