import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { SecuredService } from '../services/secured.service';
import { JwtService } from '../services/jwt.service';
import { Payout } from '../interfaces/Payout';
import { Driver } from '../interfaces/Driver';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-manage-payout',
  templateUrl: './manage-payout.component.html',
  styleUrls: ['./manage-payout.component.css']
})
export class ManagePayoutComponent implements OnInit {

  constructor(private jwtService: JwtService, private securedService: SecuredService,private route: ActivatedRoute,
    private router: Router,
    private translate: TranslateService) { }

  loading: boolean = true;
  driver: Driver;
  payout: Payout;
  id: number;
  bookings: any;

  ngOnInit(): void {
  	if (this.jwtService.isCompany) {
	  this.router.navigate(['manage-booking']);
	  return;
	}
	this.driver = this.jwtService.getLoggedInData();

	this.route.params.subscribe(params => {
      let id = params['id'];
      this.id = id;
      this.loading = true;
      this.securedService.getPayout(id).subscribe(
        result => {
          this.payout = result.payout;
          this.bookings = result.bookings;
          this.loading = false;
        },
        error => {
          this.loading = false;
          if (error.status == 429 || error.status == 500) {
            alert(this.translate.instant('COMMON.500'));
          }
        }
      );
    });
  }

}
