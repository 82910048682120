import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { SecuredService } from '../services/secured.service';

@Component({
  selector: 'app-activate',
  templateUrl: './activate.component.html',
  styleUrls: ['./activate.component.css']
})
export class ActivateComponent implements OnInit {

  loading: boolean = false;
  responseMessage: string = "Please wait.";

  constructor(
  	private route: ActivatedRoute,
    private router: Router,
    private securedService: SecuredService
  ) { }

  ngOnInit(): void {
    let code = '';
    this.route.queryParams.subscribe(params => {
        code = params['code'];
    });

    this.loading = true;
    this.responseMessage = "";
    
    if (!code) {
      return;
    }
    this.securedService.activateAccount(code)
      .subscribe(result => {
        this.loading = false;
        this.responseMessage = "Your email is verified and drivers2day account is active now."
      }, error => {
        this.loading = false;
        if (error.status == 422) {
          for (var i = 0; i < error.error.length; i++) {
            let element = error.error[i];
            this.responseMessage = element.message;
          }
        }

      }
    );
  }

}
