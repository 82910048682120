import { Component, OnInit, EventEmitter, Output } from '@angular/core';
declare var FB: any;

@Component({
  selector: 'app-fb-login',
  templateUrl: './fb-login.component.html',
  styleUrls: ['./fb-login.component.css']
})
export class FbLoginComponent implements OnInit {

  @Output() messageEvent = new EventEmitter<string>();
  constructor() { }

  ngOnInit(): void {
    (window as any).fbAsyncInit = function () {
      FB.init({
        appId: '358312951026746',
        cookie: true,
        xfbml: true,
        version: 'v3.1'
      });
      FB.AppEvents.logPageView();
    };

    (function (d, s, id) {
      var js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) { return; }
      js = d.createElement(s); js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'facebook-jssdk'));
  }

  submitLogin() {
    let mainThis = this;
    FB.login((response) => {
      //console.log('submitLogin', response);
      if (response.authResponse) {
        //login success
        //get email
        mainThis.messageEvent.emit(response.authResponse);

      }
      else {
        console.log('User login failed');
      }
    }, { scope: 'email' });

  }

}
