import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
//import { CompanySignupComponent } from './company-signup/signup.component';
import { BecomeDriverComponent } from './become-driver/become-driver.component';
import { SignupPageComponent } from './signup-page/signup-page.component';
import { ProfileComponent } from './profile/profile.component';
import { AuthGuardService } from './auth/auth-guard.service';
import { DriversComponent } from './drivers/drivers.component';
import { DriverComponent } from './driver/driver.component';
import { BookingComponent } from './booking/booking.component';
import { ManageBookingComponent } from './manage-booking/manage-booking.component';
import { BecomeDriverIntroComponent } from './become-driver-intro/become-driver-intro.component';
import { ActivateComponent } from './activate/activate.component';
import { MessagesComponent } from './messages/messages.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { TermsOfBusinessComponent } from './terms-of-business/terms-of-business.component';
import { CompleteBookingComponent } from './complete-booking/complete-booking.component';
import { InvoiceComponent } from './invoice/invoice.component';
import { ManageInvoiceComponent } from './manage-invoice/manage-invoice.component';
import { PayoutComponent } from './payout/payout.component';
import { ManagePayoutComponent } from './manage-payout/manage-payout.component';
import { AboutComponent } from './about/about.component';
import { PoliciesComponent } from './policies/policies.component';
import { FaqComponent } from './faq/faq.component';
import { BecomeOwnerDriverComponent } from './become-owner-driver/become-owner-driver.component';
const routes: Routes = [
  { path: '', component: HomeComponent },
  {
    path: 'profile', component: ProfileComponent,canActivate: [AuthGuardService]
  },
  {
    path: 'drivers', component: DriversComponent,
    canActivate: [AuthGuardService]
  },
  { path: 'driver/:id', component: DriverComponent,
    canActivate: [AuthGuardService] 
  },
  { path: 'booking/:id', component: BookingComponent,
    canActivate: [AuthGuardService] 
  },
  { path: 'complete-booking/:id', component: CompleteBookingComponent,
    canActivate: [AuthGuardService] 
  },
  { path: 'invoice', component: InvoiceComponent,
    canActivate: [AuthGuardService] 
  },
  { path: 'invoice/:id', component: ManageInvoiceComponent,
    canActivate: [AuthGuardService] 
  },
  { path: 'payout', component: PayoutComponent,
    canActivate: [AuthGuardService] 
  },
  { path: 'payout/:id', component: ManagePayoutComponent,
    canActivate: [AuthGuardService] 
  },
  { path: 'manage-booking', component: ManageBookingComponent,
    canActivate: [AuthGuardService] 
  },
  { path: 'login', component: LoginComponent },
  { path: 'about', component: AboutComponent },
  { path: 'login/:from', component: LoginComponent },
  { path: 'become-driver', component: BecomeDriverComponent },
  { path: 'become-driver-intro', component: BecomeDriverIntroComponent },
  { path: 'become-owner-driver', component: BecomeOwnerDriverComponent },
  //{ path: 'company-signup', component: CompanySignupComponent },
  { path: 'signup', component: SignupPageComponent },
  { path: 'reset-password/:code', component: ResetPasswordComponent },
  { path: 'business-terms', component: TermsOfBusinessComponent },
  { path: 'policies', component: PoliciesComponent },
  { path: 'activate', component: ActivateComponent },
  { path: 'faq', component: FaqComponent },

  { path: 'messages/:id/:name/:page', component: MessagesComponent ,canActivate: [AuthGuardService]},

  //{ path: '', redirectTo: '/home', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
