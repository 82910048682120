import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import * as Globals from '../global';
import * as $ from 'jquery';
import { SecuredService } from '../services/secured.service';
import { Driver } from '../interfaces/Driver';
import { JwtService } from '../services/jwt.service';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { DataSharingService } from '../services/data-sharing.service';

@Component({
  selector: 'app-drivers',
  templateUrl: './drivers.component.html',
  styleUrls: ['./drivers.component.css']
})
export class DriversComponent implements OnInit {

  constructor(public router: Router, private jwtService: JwtService, private dataSharingService: DataSharingService, private securedService: SecuredService
    ,
    private translate: TranslateService) { }
  categories: any[] = [];
  drivers: Driver[] = [];
  loadingDrivers: boolean = false;

  @ViewChild('panel', { read: ElementRef }) public panel: ElementRef<any>;

  driverCategories = {1:'Van Driver', 2:'LGV/7.5TONNE Driver', 3: 'CLASS2 Driver', 4: 'CLASS1 Driver', 5: 'FLT Driver', 6: 'Warehouse worker / Drivers Mate'};
  stars: number[] = [1, 2, 3, 4, 5];
  scrollCategoryLeft() {
    this.panel.nativeElement.scrollLeft -= 50;

  }
  scrollCategoryRight() {
    this.panel.nativeElement.scrollLeft += 50;
  }

  loggedInUserLocation: any;
  company_location: any;
  radius: number = 25;
  locations: any;
  @ViewChild('closebutton') closebutton;
  ngOnInit(): void {

    if (this.jwtService.isDriver) {
      this.router.navigate(['profile']);
      return;
    }
    this.loadLocations();
    /*   let data = this.jwtService.getLoggedInData();
   
       if (data) {*/
    //this.loggedInUserLocation = "";/* { id: data.city_id, city: data.city };*/
    /*  }*/

    /*$('#city-select2').select2({
      disabled: false,
      placeholder: (this.loggedInUserLocation && this.loggedInUserLocation.id && this.loggedInUserLocation?.city) ? this.loggedInUserLocation?.city : 'Select your location',

      //theme: "classic",
      minimumInputLength: 2,
      ajax: {
        url: function (params) {
          var country = 232;
          var url = Globals.API_ENDPOINT + 'general/city/' + country + '?';
          return url + params.term;
        },
        data: function (params) {
          var query = {
            search: params.term,
            type: 'public'
          }
          // Query parameters will be ?search=[term]&type=public
          return query;
        },
        processResults: function (data) {
          // Transforms the top-level key of the response object from 'items' to 'results'
          let resultsData: any = [];
          for (let i = 0; i < data.length; i++) {
            resultsData.push({ id: data[i].id, text: data[i].city });
          }
          return {
            results: resultsData
          };
        }
      }
    });*/


    /* var mainThis = this;
    $('#city-select2').on('select2:select', function (e) {

      mainThis.loadDrivers(); mainThis.closebutton.nativeElement.click();
    }); */


    //this.loadKeywords();
    
  }


  loadLocations() {
    this.securedService.getCompanyLocations("").subscribe(
      result => {
        this.locations = result.items;
        if(this.locations.length>0) {
          this.company_location = this.locations[0].latitude+','+this.locations[0].longitude;
          this.loadDrivers();
        } else {
          this.router.navigate(['profile']);
          return;
        }
      },
      error => {
        if (error.status == 401) {
          this.jwtService.logout();
          this.dataSharingService.isLoggedIn.next(false);
          this.router.navigate(['login']);
        }
        if (error.status == 429 || error.status == 500) {
          alert(this.translate.instant('COMMON.500'));
        }

      }
    );
  }

  viewMoreUrl: string;

  viewMore() {
    if (!this.viewMoreUrl) {
      return;
    }

    this.securedService.getDrivers(this.viewMoreUrl, "").subscribe(
      result => {
        for (let i = 0; i < result.items.length; i++) {
          let driver = result.items[i];
          this.drivers.push(driver);
        }


        this.viewMoreUrl = result?._links?.next?.href;
      },
      error => {
        if (error.status == 429 || error.status == 500) {
          alert(this.translate.instant('COMMON.500'));

        }

      }
    );
  }

  searchKey = "";
  sortedBy = "RecentlyAdded";

  sortby(sortedBy: string) {
    this.sortedBy = sortedBy;
    this.loadDrivers();

  }
  selectedCategory:any;
  selectCategory(category: any) {
    this.selectedCategory = category
    this.loadDrivers();
  }

  removeLocation() {
    this.loggedInUserLocation.id = '';
    this.loggedInUserLocation.city = '';
    $('#city-select2').val(null).trigger('change');
    this.loadDrivers();
  }

  loadDrivers() {
    this.loadingDrivers = true;
    let data: string = "";
    if (this.searchKey) {
      data += "&search=" + this.searchKey;
    }

    if (this.company_location) {
      data += "&company_location=" + this.company_location;
    }

    if (this.radius) {
      data += "&radius=" + this.radius;
    }
    

    if (this.sortedBy == "Popularity") {
      data += "&top=true";
    }

    if(this.selectedCategory) {
      data += "&driver_category="+this.selectedCategory;
    }

    /* let selectedCity = $('#city-select2').select2('data');
    if (selectedCity && selectedCity[0]) {
      data += "&city=" + selectedCity[0].id;

      this.loggedInUserLocation = { id: selectedCity[0].id, city: selectedCity[0].text }

    } else if (this.loggedInUserLocation && this.loggedInUserLocation.id && this.loggedInUserLocation.city) {
      data += "&city=" + this.loggedInUserLocation.id;
    } */


    this.securedService.getDrivers("", data).subscribe(
      result => {
        this.loadingDrivers = false;
        this.drivers = result.items;
        this.viewMoreUrl = result?._links?.next?.href;
      },
      error => {
        this.loadingDrivers = false;
        //console.log(error);
        if (error.status == 429 || error.status == 500) {
          alert(this.translate.instant('COMMON.500'));

        }

      }
    );

  }

}
