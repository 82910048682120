<div class=" fixed-top top-container" style="
background-color: #ffffff!important;
border-bottom: solid 1px #eaeaea;">


</div>

<nav class="navbar navbar-expand-md bg-light navbar-light  fixed-top container" style="height:82px;background-color: #ffffff!important;    
">

    <div class="top_head">
        <div class="container" style="    background-color: #282222;
         ">
            <div class="row col-md-12" style="padding: 0px;">
                <div class="col-md-4 col-lg-6" style="padding: 0px;">
                    <a href="https://www.facebook.com/drivers2day" target="_blank">
                        <div class="social-icons " style="background-position:0px 0px; "> </div>
                    </a>
                    <!-- <a href="#">
                        <div class="social-icons" style="background-position: -39px 0px;"></div>
                    </a>
                    <a href="#">
                        <div class="social-icons" style="background-position: -77px 0px;"></div>
                    </a> -->

                    <a href="https://www.linkedin.com/company/drivers2day" target="_blank">
                        <div class="social-icons" style="background-position: -115px 0px;"></div>
                    </a>

                </div>
                <div class="col-md-8 col-lg-6 email_phone_top" style="opacity: 0.7;padding: 0px;">
                    Email: <a href="mailto:contactus@drivers2day.co.uk" style="color: #fff;text-decoration: none;">contactus@drivers2day.co.uk</a> &nbsp;&nbsp;&nbsp;&nbsp;<span
                        style="opacity: 0.4">|</span>&nbsp;&nbsp;&nbsp;&nbsp;
                    Phone : 0151 272 0464

                </div>

            </div>

        </div>
    </div>

    <a class="navbar-brand" routerLink="/">
        <i class="logo-drivers2day"></i>
    </a>


    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#myNavbar7"
        aria-controls="myNavbar7" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
    </button>



    <div class="collapse navbar-collapse flex-grow-1 text-right" id="myNavbar7" style="background-color: #ffffff;
    text-transform: uppercase;">
        <ul class="navbar-nav ml-auto flex-nowrap text-center">
            <li class="nav-item">
                <a class="nav-link" routerLink="/" data-toggle="collapse" data-target=".navbar-collapse.show">
                    {{'HEADER.home' | translate }}

                </a>
            </li>

            <li class="nav-item">
                <a class="nav-link" routerLink="/about" data-toggle="collapse" data-target=".navbar-collapse.show">
                    About

                </a>
            </li>

            <li class="nav-item">
                <a class="nav-link" routerLink="/faq" data-toggle="collapse" data-target=".navbar-collapse.show">
                    Help
                </a>
            </li>

            <li class="nav-item" [hidden]="loggedIn">
                <a class="nav-link" data-toggle="collapse" data-target=".navbar-collapse.show">

                    <div style=" background-color: #006ab1;
                    padding: 10px 20px;
                    margin-top: -10px;
                    border-radius: 5px;
                    color: #fff;">

                        <span routerLink="/login">
                            {{'HEADER.login' | translate }}

                        </span>

                    </div>


                </a>

            </li>
            <li class="nav-item" [hidden]="!loggedIn">
                <a class="nav-link" routerLink="/profile" data-toggle="collapse"
                    data-target=".navbar-collapse.show">{{'HEADER.profile' | translate }}</a>
            </li>

            <li class="nav-item" [hidden]="!loggedIn">
                <a class="nav-link" (click)="logout()" data-toggle="collapse"
                    data-target=".navbar-collapse.show">{{'HEADER.logout' | translate }}</a>
            </li>


            <!--<li class="nav-item">
                <a class="nav-link">


                    <span [hidden]="selectedLanguage=='ar'" (click)="useLanguage('ar')">عربي</span>
                    <span [hidden]="selectedLanguage=='en'" (click)="useLanguage('en')">English</span>
                </a>
            </li>-->
        </ul>
        <a #openCookieModal data-toggle="modal" data-target="#cookiesModal" [hidden]="1">Cookies</a>
        <!--<a #closeCookieModal data-dismiss="modal" data-target="#cookiesModal" [hidden]>Cookies</a>-->
    </div>
</nav>

<div id="cookiesModal" class="modal fade" role="dialog" data-keyboard="false" data-backdrop="static">
    <div class="modal-dialog">

        <!-- Modal content-->
        <div class="modal-content">

            <div class="modal-body" style="padding: 20px 20px;">
                <h5 style="color: #337777;font-family: Lato-Regular;text-align: center;">Drivers2day.co.uk</h5>

                <div style="text-align: center;">
                    <p>We use cookies to provide the best possible experience for our users. They help us provide
                        essential functionality and improve site performance, and allow us to offer a more personalised
                        experience when using the site.</p>
                    <p>For more information: <a href="policies#cookies">Сookie policy</a></p>
                    <p style="text-align: right;"><button type="button" style="background-color: rgb(243, 115, 42);;
                border-color: rgb(243, 115, 42);" (click)="acceptCookies()" class="btn btn-info"> Accept cookies
                        </button></p>
                </div>

            </div>
        </div>

    </div>
</div>