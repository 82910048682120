<div class="invoice-landing" style=" margin-top: 65px;min-height: 700px;padding-top: 30px;;">

    <div class="container">
        <div [hidden]="loading" class="col-md-12"
            style="margin-top: 20px;background-color: #efefef;min-height: 170px; border: solid 1px #ccc;">

            <div class="row">

                <div class="col-md-6" style="text-align: center;">
                    <div
                        style="display: none; height: 20px;color: #a29c9c;float: right;font-size: 31px;margin-right: 20px;">
                        <span><i class="fa fa-cog" aria-hidden="true"></i></span>
                    </div>
                    <div class="col-12" style="margin: 10px;font-family: Lato-Regular;">
                        {{company.company_name}}
                    </div>
                    <div style="width: 100%;float: left;">
                        <div [hidden]="!company.company_logo" style="
                                background-image: url({{company.company_logo}});
                                height: 100px;   
                                margin: 10px auto;
                                width: 100px;
                                background-size: cover;
                                border-radius: 63px;
                                background-position: center;
                                ">
                        </div>
                        <img [hidden]="company.company_logo"
                            style=" width: 100px;margin: 10px auto;" src="../../assets/images/no-dp-2.png" />
                    </div>


                </div>
                <div class="col-md-6" style="text-align: center;">


                    <div class="form-group" style="margin-top:20px;">
                        <a class="btn btn-success btn-md"
                            style="padding-left: 40px;padding-right: 40px;background-color: #337777;border-color: #337777;width: 200px;"
                            [routerLink]="['/manage-booking']">Go to Bookings</a>
                    </div>

                    <div class="form-group" style="margin-top:20px;">
                        <a class="btn btn-success btn-md"
                            style="padding-left: 40px;padding-right: 40px;background-color: #337777;border-color: #337777;width: 200px;"
                            [routerLink]="['/profile']">Go to Profile</a>
                    </div>

                </div>

            </div>

        </div>
        <div  [hidden]="loading" class="row col-md-12" style="    margin: 20px 0 35px 0;
        padding: 0;">
            <h2 style="font-size: 25px;color: #337777;font-family: Lato-Regular;text-decoration: underline;width: 100%;text-align: center;">Invoice #{{ invoice?.id }}</h2>
            <div class="text-center alert alert-success" role="alert" [hidden]="!invoiceMarkedPaid" style="min-height: 25px;margin-top: 48px;">
                <span class="font-weight-normal" style="font-size:18px;color: #337777;font-family: Lato-Regular;">
                    Invoice marked as Paid successfully. <a routerLink="/invoice">Manage Invoices</a>
                </span>
            </div>
            <hr>
            <div style="border: dashed 1px #ccc;background-color: #fdfdfd;padding: 20px;-top: 27px;" class="col-md-12">
                
                <div class="row" style="margin-top: 25px;">
                    <div class="col-12 col-sm-1 col-lg-1"> 
                        <label for="id" class="control-label" style="font-family: Lato-Regular;">#ID</label>
                        <p class="form-control-static">{{ invoice?.id }}</p>
                    </div>
                    
                    <div class="col-12 col-sm-3 col-lg-3"> 
                        <label for="created_at" class="control-label" style="font-family: Lato-Regular;">Invoice Date</label>
                        <p class="form-control-static">{{ invoice?.created_at | date : 'mediumDate' }}</p>
                    </div>

                    <div class="col-12 col-sm-3 col-lg-3"> 
                        <label for="pay_by_date" class="control-label" style="font-family: Lato-Regular;">Pay By Date</label>
                        <p class="form-control-static">{{ invoice?.pay_by_date | date : 'mediumDate' }}</p>
                    </div>

                    <div class="col-12 col-sm-3 col-lg-3"> 
                        <label for="invoice_status" class="control-label" style="font-family: Lato-Regular;">Status</label>
                        <p class="form-control-static">{{ invoice?.invoice_status }}</p>
                    </div>

                    <div class="col-12 col-sm-2 col-lg-2"> 
                        <label for="total_amount" class="control-label" style="font-family: Lato-Regular;">Amount</label>
                        <p class="form-control-static"><i class="fa fa-gbp" style="font-size: 14px;"></i> {{ invoice?.total_sum }}</p>
                    </div>
                </div>
            </div>
            <h2 style="font-size: 20px;margin-top: 30px;color: #337777;font-family: Lato-Regular;text-decoration: underline;width: 100%; text-align: center;">Bookings</h2>
            <div style="border: dashed 1px #ccc;background-color: #fdfdfd;padding: 20px;-top: 27px;" class="col-md-12">
            <ng-container *ngFor="let booking of bookings">
                <div class="row" style ="margin-top: 25px;">
                    <div class="col-6 col-sm-1 col-lg-1"> 
                        <label for="id" class="control-label" style="font-family: Lato-Regular;">#ID</label>
                        <p class="form-control-static">{{ booking.id }}</p>
                    </div>
                    <div class="col-6 col-sm-2 col-lg-2"> 
                        <label for="booking_date" class="control-label" style="font-family: Lato-Regular;">Booking Date</label>
                        <p class="form-control-static">{{ booking.start_time | date : 'mediumDate' }}</p>
                    </div>
                    <div class="col-6 col-sm-2 col-lg-2"> 
                        <label for="driver_name" class="control-label" style="font-family: Lato-Regular;">Driver</label>
                        <p class="form-control-static">{{ booking.driver_name }}</p>
                    </div>
                    <div class="col-12 col-sm-7 col-lg-7">
                        <div class="row">
                            <div class="col-6 col-sm-3 col-lg-3"> 
                                <label for="start_time" class="control-label" style="font-family: Lato-Regular;" >Start time</label>
                                <p class="form-control-static">{{ booking.start_time | date : 'MMM d, y h:mm a' }}</p>
                            </div>
                            <div *ngIf="!booking.hours_drived" class="col-6 col-sm-3 col-lg-3"> 
                                <label for="end_time" class="control-label" style="font-family: Lato-Regular;" >End time</label>
                                <p class="form-control-static">{{ booking.end_time | date : 'MMM d, y h:mm a' }}</p>
                            </div>
                            <div *ngIf="booking.hours_drived" class="col-6 col-sm-3 col-lg-3"> 
                                <label for="end_time" class="control-label" style="font-family: Lato-Regular;" >Hours Drived</label>
                                <p class="form-control-static">{{ booking.hours_drived }}</p>
                            </div>
                            <div class="col-6 col-sm-3 col-lg-3"> 
                                <label for="end_time" class="control-label" style="font-family: Lato-Regular;" > &nbsp;</label>
                                <p class="form-control-static" style="text-align: left;">{{ booking.booking_status }}</p>
                            </div>
                            <div class="col-6 col-sm-3 col-lg-3"> 
                                <label for="total_amount" class="control-label" style="font-family: Lato-Regular;" >Amount</label>
                                <p class="form-control-static"><i class="fa fa-gbp" style="font-size: 14px;"></i>{{ booking.total_amount }}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <hr>
            </ng-container>
            </div>
            <h2 style="margin-top:20px;font-size: 20px;color: #337777;font-family: Lato-Regular;text-decoration: underline;width: 100%; text-align: center;">Bank Details</h2>
            <div style="border: dashed 1px #ccc;background-color: #fdfdfd;padding: 20px;-top: 27px;" class="col-md-12">
                <div class="row" style ="margin-top: 12px;">
                    <div class="col-12 col-sm-12 col-lg-12">
                        <address>
                            <strong>DRIVERS2DAY ONLINE LTD</strong><br>
                            <!--<strong>Lloyds Bank</strong><br>-->
                            <strong>Account No: 16757705</strong><br>
                            <strong>Sort Code: 04-03-70</strong><br>
                        </address>
                    </div>
                </div>
            </div>
            <div [hidden]="invoice?.invoice_status!='Pending'" class="form-group" style="margin-top:5px;text-align: center;width: 100%;">
                <button *ngIf="!loading"
                    class="btn btn-success btn-lg" style="padding-left: 40px;padding-right: 40px;background-color: #337777;border-color: #337777;"  (click)="markPaid()">Mark As Paid</button>
            </div>
            
            <div *ngIf='!invoice' style="margin-top: 30px;">
               No invoices to show
            </div>

        </div>
        <div [hidden]="!loading" style="width: 100%;background-color: #efefef;min-height: 204px;margin-top: 20px;">
            <div class="ion-padding" style="text-align: center;min-height: 100px;padding-top: 50px;">
                <img src="../../assets/images/loader.gif"
                    style=" margin-top: 10px;   width: 70px;padding-left: 20px;" />
            </div>
        </div>
    </div>
</div>