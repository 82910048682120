<div style="    margin-top: 100px;
height: 50px;">
    <div style="height: 20px;
    color: #a29c9c;
    float: right;
    font-size: 31px;margin-right: 20px;">
        <span routerLink="/" routerLinkActive="active"><i class="fa fa-times" aria-hidden="true"></i></span>
    </div>


</div>
<div [hidden]='currentStep >= 5' class="text-center" style="height: 60px;">

    <span class="font-weight-bold"
        style="font-size:18px;color: #337777;font-family: Lato-Regular;">BECOME AN AGENCY DRIVER</span><br />
    <div style="width: 50px;height: 2px;background-color: rgb(243, 115, 42);margin-left: auto;margin-right: auto;">
    </div>

    <div style="    width: 100px;
    margin: 12px auto;
    height: 5px;">
        <div [ngClass]="{'bubble_dark': currentStep == 1}" class="bubble"></div>
        <div [ngClass]="{'bubble_dark': currentStep == 2}" class="bubble "></div>
        <div [ngClass]="{'bubble_dark': currentStep == 3}" class="bubble "></div>
        <div [ngClass]="{'bubble_dark': currentStep == 4}" class="bubble "></div>
    </div>
</div>
<button id="openModalButtonOTPEmail" [hidden]="true" data-toggle="modal" data-target="#emailOTPModal">Open
    Modal</button>   



<div style="margin-top:50px;" [hidden]="(!(currentStep==1))||loading">
    <div class="signup-form  col-lg-5" style="margin-bottom: 20px;">
        <span style="  font-family: Lato-Regular;">{{'BECOME_A_DRIVER.STEP' | translate }}
            <span>1 </span>
            : </span> {{'BECOME_A_DRIVER.signup' | translate }}

    </div>
    <div class="signup-form gray-icon  col-lg-5" [hidden]="loading">

        <form name="form" [formGroup]="form">
            <div class="form-group" style="margin-top: 20px;padding-left: 25px;">
                <label for="driver_type" class="control-label" style="font-size: 1rem; margin-bottom: 1rem;">Select the mode of enrollment</label>
                <br>
                <div style="float: left;margin-right: 15px;" [hidden]="hide_ltd_driver_type">
                    <input id="LTD" type="radio" value="LTD" name="driver_type" formControlName="driver_type"  (change)="showPricingDetail()" />
                    <label for="LTD" style="font-size: 1rem;"> &nbsp;
                        LTD (Company registration number needed)</label>
                </div>

                <div style="float: left;">
                    <input id="PAYE" type="radio" value="PAYE" name="driver_type" formControlName="driver_type"  (change)="showPricingDetail()" />
                    <label for="PAYE" style="font-size: 1rem;"> &nbsp;
                        PAYE</label>
                </div>
                <br *ngIf="validationErrorsServer.driver_type">
                <div class="validation_error">
                    <span *ngIf="validationErrorsServer.driver_type">{{validationErrorsServer.driver_type}}</span>
                </div>
            </div>
            <br>
            <hr>
            <div class="form-group" style="margin-bottom: 15px; margin-top: 20px;padding-left: 25px;">
                <label for="driver_category" class="control-label" style="font-size: 1rem;">What do you drive?</label>
                <select formControlName="driver_category" id="driver_category" class="form-control" placeholder="Select what you drive" style="width: 70%;border: 1px solid #ced4da;" (change)='showPricingDetail()'>
                    <option value="">Select your licence category</option>
                    <option value="1">Van Driver</option>
                    <option value="2">LGV/7.5TONNE Driver</option>
                    <option value="3">CLASS2 Driver</option>
                    <option value="4">CLASS1 Driver</option>
                    <option value="5">FLT Driver</option>
                    <option value="6">Warehouse worker / Drivers Mate</option>
                </select>

                <div class="validation_error">
                    <span *ngIf="validationErrorsServer.driver_category">{{validationErrorsServer.driver_category}}</span>
                </div>
            </div>
            <hr style="margin-top: 0px;">
            <div class="form-group" *ngIf="showPriceSection">
                <div class="alert alert-info" role="alert">
                    Your rate of pay is set at <b>£{{houly_rate==houly_rate_night?houly_rate:houly_rate+" (Night £"+houly_rate_night+")"}}</b> per hour, but we allow hirers to submit offers to you.
                  </div>
            </div>
            <div class="form-group" *ngIf="ltdDriver">
                <div class="input-group">
                    <span class="input-group-addon"><i class="fa fa-registered"
                            [ngStyle]="{'color': (validationErrorsServer.company_registration)?'firebrick':'#bbbbbb'}"></i></span>
                    <input type="text" formControlName="company_registration" class="form-control"
                        placeholder="Company Registration Number">

                </div>
                <div class="validation_error">
                    <span *ngIf="validationErrorsServer.company_registration">{{validationErrorsServer.company_registration}}</span>
                </div>
            </div>
            <div class="form-group" *ngIf="payeDriver">
                <div class="input-group">
                    <span class="input-group-addon"><i class="fa fa-id-card-o"
                            [ngStyle]="{'color': (validationErrorsServer.national_insurance_number)?'firebrick':'#bbbbbb'}"></i></span>
                    <input type="text" formControlName="national_insurance_number" class="form-control"
                        placeholder="National Insurance Number">

                </div>
                <div class="validation_error">
                    <span *ngIf="validationErrorsServer.national_insurance_number">{{validationErrorsServer.national_insurance_number}}</span>
                </div>
            </div>
            <div class="form-group">
                <div class="input-group">
                    <span class="input-group-addon"><i class="fa fa-user"
                            [ngStyle]="{'color': (validationErrorsServer.full_name)?'firebrick':'#bbbbbb'}"></i></span>
                    <input type="text" formControlName="full_name" class="form-control"
                        placeholder="{{'BECOME_A_DRIVER.full_name' | translate }}">

                </div>
                <div class="validation_error">
                    <span *ngIf="validationErrorsServer.full_name">{{validationErrorsServer.full_name}}</span>

                </div>
            </div>

            <div class="form-group">
                <div class="input-group">
                    <span class="input-group-addon"><i style="font-size: 15px;"
                            [ngStyle]="{'color': (validationErrorsServer.email)?'firebrick':'#bbbbbb'}"
                            class="fa fa-envelope"></i></span>
                    <input type="text" formControlName="email" class="form-control"
                        placeholder="{{'BECOME_A_DRIVER.email' | translate }}">

                </div>
                <div class="validation_error">
                    <span *ngIf="validationErrorsServer.email">
                        {{validationErrorsServer.email}}</span>
                </div>
            </div>

            <div class="form-group">
                <div class="input-group">
                    <span class="input-group-addon"><i class="fa fa-phone"
                            [ngStyle]="{'color': (validationErrorsServer.mobile)?'firebrick':'#bbbbbb'}"></i></span>
                    <input type="text" formControlName="mobile" class="form-control"
                        placeholder="{{'SIGNUP.mobile' | translate }}">

                </div>
                <div class="validation_error">
                    <span *ngIf="validationErrorsServer.mobile">{{validationErrorsServer.mobile}}</span>

                </div>
            </div>

            <div class="form-group">
                <div class="input-group">
                    <span class="input-group-addon"><i
                        [ngStyle]="{'color': (validationErrorsServer.city)?'firebrick':'#bbbbbb'}"
                        class="fa fa-building"></i></span>
                    <select formControlName="city" id="city-select2" class="form-control" placeholder="City">


                    </select>
                </div>
                <div class="validation_error">
                    <span *ngIf="validationErrorsServer.city">{{validationErrorsServer.city}}</span>
                </div>
            </div>

            <div class="form-group">
                <div class="input-group">
                    <span class="input-group-addon"><i
                            [ngStyle]="{'color': (validationErrorsServer.password)?'firebrick':'#bbbbbb'}"
                            class="fa fa-lock"></i></span>
                    <input type="password" formControlName="password" class="form-control" name="password"
                        placeholder="{{'BECOME_A_DRIVER.password' | translate }}">
                </div>
                <div class="validation_error">
                    <span *ngIf="validationErrorsServer.password">
                        {{validationErrorsServer.password}}</span>
                </div>
            </div>

            <div class="form-group">
                <div class="input-group">
                    <span class="input-group-addon">
                        <i class="fa fa-lock"
                            [ngStyle]="{'color': (validationErrorsServer.cnfpass)?'firebrick':'#bbbbbb'}"></i>
                        <i class="fa fa-check"
                            [ngStyle]="{'color': (validationErrorsServer.cnfpass)?'firebrick':'#bbbbbb'}"></i>
                    </span>
                    <input type="password" formControlName="cnfpass" class="form-control" name="confirm_password"
                        placeholder="{{'BECOME_A_DRIVER.confirm_password' | translate }}">

                </div>
                <div class="validation_error">
                    <!-- <i class="fa fa-times-circle" style="color:firebrick;"></i> -->
                    <span
                        *ngIf="validationErrorsServer.cnfpass">{{validationErrorsServer.cnfpass}}</span>
                </div>
            </div>



            <div class="form-group" style="margin-top: 45px;font-family: Lato-Regular;">
                <button type="submit" (click)="onSignupSubmit()"
                    
                    class="btn btn-primary btn-block btn-lg">{{'BECOME_A_DRIVER.signup' | translate }}</button>
            </div>

            <div class="text-center">{{'BECOME_A_DRIVER.already_have_account' | translate }}?
                <a routerLink="/login" routerLinkActive="active">
                    {{'BECOME_A_DRIVER.login_here' | translate }}
                </a>.
            </div>

        </form>

    </div>
</div>

<div class="ion-padding" style="text-align: center;
margin-top: 100px;
min-height: 300px;" [hidden]="!loading">
    <!-- <span>Please wait.</span><br/>   -->
    <img src="../../assets/images/loader.gif" style="     margin-top: 10px;   width: 70px;
  padding-left: 20px;" />
</div>

<div style="margin-top:50px;" class="become-driver-step2" [hidden]="loading||!(currentStep==2)">
    <div class="signup-form  col-lg-5" style="margin-bottom: 20px;">
        <span style="   font-family: Lato-Regular;">
            {{'BECOME_A_DRIVER.STEP' | translate }}
            <span>2 </span>
            :
        </span>{{'BECOME_A_DRIVER.tell_abt_urself' | translate }}
    </div>
    <div class="signup-form gray-icon  col-lg-5" style="min-height: 400px;;">

        <form name="form" [formGroup]="tuayform" autocomplete="off">
            <div class="form-group">
                <div class="input-group">
                    <span class="input-group-addon"><i class="fa fa-car"></i></span>
                    <select formControlName="years_driving" class="form-control" placeholder="Years of driving">
                        <option [ngValue]="null">Years of driving</option>
                        <option *ngFor="let year of years_driving" [ngValue]="year">{{year}}</option>
                    </select>
                </div>
                <div class="validation_error">
                    <span *ngIf="validationErrorsUserDetails.years_driving">{{validationErrorsUserDetails.years_driving}}</span>
                </div>
            </div>

            <!-- <div class="form-group">
                <div class="input-group">
                    <span class="input-group-addon"><i class="fa fa-gbp"></i></span>
                    <input type="number"  min="8" max="11" step="0.5" value="10.5" formControlName="hourly_rate" class="form-control"
                        placeholder="Set your hourly rate">


                </div>
                <span style="margin-left: 25px;font-size: 13px;">The lower you offer, the higher you will appear in searches(max:11)</span>
                <div class="validation_error">
                    <span *ngIf="validationErrorsUserDetails.hourly_rate">{{validationErrorsUserDetails.hourly_rate}}</span>
                </div>
            </div> -->
            
            <div class="form-group">
                <div class="input-group">
                    <span class="input-group-addon"><i class="fa fa-bus"></i></span>
                    <input type="text" formControlName="previous_work" class="form-control"
                        placeholder="Previous work">

                </div>
                <div class="validation_error">
                    <span *ngIf="validationErrorsUserDetails.previous_work">{{validationErrorsUserDetails.previous_work}}</span>
                </div>
            </div>

            <div class="form-group">
                <div class="input-group">
                    <span class="input-group-addon"><i class="fa fa-calendar"></i></span>
                    <input type="text" formControlName="dateofbirth" class="form-control"
                        placeholder="{{'BECOME_A_DRIVER.dob' | translate }}" onfocus="(this.type='date')"
                        onblur="(this.type='text')" value="" min="1997-1-1">

                </div>
                <div class="validation_error">
                    <span
                        *ngIf="validationErrorsUserDetails.dateofbirth">{{validationErrorsUserDetails.dateofbirth}}</span>
                </div>
            </div>

            <div class="form-group">
                <div style="float: left;">
                    <input id="male" type="radio" value="male" name="gender" formControlName="gender">
                    <label for="male"> &nbsp;
                        {{'BECOME_A_DRIVER.male' | translate }}</label>
                </div>

                <div style="float: left;    margin-left: 15px;">
                    <input id="female" type="radio" value="female" name="gender" formControlName="gender">
                    <label for="female"> &nbsp;
                        {{'BECOME_A_DRIVER.female' | translate }}</label>
                </div>

                <div style="float: left;    margin-left: 15px;">
                    <input id="other" type="radio" value="other" name="gender" formControlName="gender">
                    <label for="female"> &nbsp; Other</label>
                </div>

                <div class="validation_error">
                    <span *ngIf="validationErrorsUserDetails.gender">{{validationErrorsUserDetails.gender}}</span>
                </div>
            </div>

            <div class="form-group">
                <div class="input-group">
                    <span class="input-group-addon"><i class="fa fa-file-text"></i></span>
                    <textarea class="form-control"  formControlName="aboutme" rows="5" placeholder="Detailed profile information"></textarea>
                </div>
                <div class="validation_error">
                    <span *ngIf="validationErrorsUserDetails.aboutme">{{validationErrorsUserDetails.aboutme}}</span>
                </div>
            </div>

            <div class="form-group" style="margin-top: 35px;;">
                <button type="submit" (click)="submitUserDetails()"
                    class="btn btn-primary btn-block btn-lg">{{'BECOME_A_DRIVER.next' | translate }}</button>

                <!-- <button id="openModalButton" [hidden]="true" data-toggle="modal" data-target="#myModal">Open
                    Modal</button> -->

            </div>

        </form>
    </div>
</div>


<div style="margin-top:50px;" class="become-driver-step3" [hidden]="loading||!(currentStep==3)">
    <div class="signup-form  col-lg-5" style="margin-bottom: 20px;">
        <span style="   font-family: Lato-Regular;">
            {{'BECOME_A_DRIVER.STEP' | translate }}
            <span>3 </span>
            :
        </span><span *ngIf="driver_category && driver_category!=5 && driver_category!=6">Verify Your Driving Licence</span>
    </div>
    <div class="alert alert-info " role="alert" style="margin: auto;"  *ngIf="driver_category && driver_category!=5 && driver_category!=6">
      To generate the check code visit <a href="https://www.gov.uk/view-driving-licence" target="_blank"> www.gov.uk/view-driving-licence </a> 
    </div>
    <br>
    <div class="signup-form gray-icon  col-lg-5" style="min-height: 400px;;">

        <form name="form" [formGroup]="licenceform" autocomplete="off">

            <div class="form-group" *ngIf="driver_category && driver_category!=5 && driver_category!=6">
                <div class="input-group">
                    <span class="input-group-addon"><i class="fa fa-id-badge"></i></span>
                    <input type="text" formControlName="licence_number" class="form-control"
                        placeholder="Last 8 characters of your licence">
                </div>
                <div class="validation_error">
                    <span *ngIf="validationErrorsLicenceDetails.licence_number">{{validationErrorsLicenceDetails.licence_number}}</span>
                </div>
            </div>

            <div class="form-group" *ngIf="driver_category && driver_category!=5 && driver_category!=6">
                <div class="input-group">
                    <span class="input-group-addon"><i class="fa fa-lock"></i></span>
                    <input type="text" formControlName="check_code" class="form-control"
                        placeholder="Check Code">
                </div>
                <span style="margin-left: 25px;font-size: 13px;">To generate the check code visit <a href="https://www.gov.uk/view-driving-licence" target="_blank"> www.gov.uk/view-driving-licence </a></span>
                <div class="validation_error">
                    <span *ngIf="validationErrorsLicenceDetails.check_code">{{validationErrorsLicenceDetails.check_code}}</span>
                </div>
            </div>

            <div class="form-group" style="margin-top: 35px;;">
                <button type="submit" (click)="submitLicenceDetails()"
                    class="btn btn-primary btn-block btn-lg">{{'BECOME_A_DRIVER.next' | translate }}</button>

                <!-- <button id="openModalButton" [hidden]="true" data-toggle="modal" data-target="#myModal">Open
                    Modal</button> -->

            </div>

        </form>
    </div>
</div>


<div style="margin-top:50px;" class="become-driver-step3" [hidden]="loading||!(currentStep==4)">
    <div class="signup-form  col-lg-5" style="margin-bottom: 20px;">
        <span style="   font-family: Lato-Regular;">
            {{'BECOME_A_DRIVER.STEP' | translate }}
            <span> {{driver_category==5?'3':'4'}} </span>
            :
        </span><span>Upload Documents</span>
    </div>

    <div class="alert alert-info " role="alert" style="margin: auto;"  *ngIf="driver_category && driver_category!=6">
        If you are having issues uploading your documents please email them to <a href="mailto:contactus@drivers2day.co.uk" target="_blank"> contactus@drivers2day.co.uk </a> 
    </div>
    <br>
    
    <div class="signup-form gray-icon  col-lg-5" style="min-height: 400px;;">

        <form name="form" [formGroup]="documentUploadForm" autocomplete="off">
            <div style="font-size: 1rem;font-family: Lato-Regular;margin-top: 30px;margin-bottom:20px;text-align: left" *ngIf="driver_category && driver_category!=5 && driver_category!=6">
                Upload below documents:
            </div>

            <div style="font-size: 14px;font-family: Lato-Regular;margin-top: 10px;text-align: left" *ngIf="driver_category && driver_category==5">
                FLT cerificate of completion
            </div>
            <div style="text-align: center;" *ngIf="driver_category && driver_category==5">
                <label class="myLabel">

                    <input formControlName="flt_certificate" id="flt_certificate" type="file" class="form-control"
                        (change)="onFileChange($event, 'flt_certificate')">
                    <span>
                        <i class="fa fa-cloud-upload" aria-hidden="true"></i>
                        Upload FLT cerificate
                    </span>
                </label>


                <div *ngIf="flt_certificate" style="color: #969696;">
                    {{flt_certificate.name}}
                </div>
                <div class="validation_error" style="text-align: center;height: 100%;">
                    <span *ngIf="validationErrorsDocumentUpload.flt_certificate">{{validationErrorsDocumentUpload.flt_certificate}}</span>
                </div>
            </div>


            <div style="font-size: 14px;font-family: Lato-Regular;margin-top: 10px;text-align: left" *ngIf="driver_category && driver_category!=5 && driver_category!=6">
                Driving Licence
            </div>

            <div style="text-align: center;" *ngIf="driver_category && driver_category!=5 && driver_category!=6">
                <label class="myLabel">

                    <input formControlName="licence_front" id="licence_front" type="file" class="form-control"
                        (change)="onFileChange($event, 'licence_front')">
                    <span>
                        <i class="fa fa-cloud-upload" aria-hidden="true"></i>
                        Upload front side
                    </span>
                </label>


                <div *ngIf="licence_front" style="color: #969696;">
                    {{licence_front.name}}
                </div>
                <div class="validation_error" style="text-align: center;height: 100%;">
                    <span *ngIf="validationErrorsDocumentUpload.licence_front">{{validationErrorsDocumentUpload.licence_front}}</span>
                </div>
            </div>

            <div style="text-align: center;" *ngIf="driver_category && driver_category!=5 && driver_category!=6">
                <label class="myLabel">

                    <input formControlName="licence_back" id="licence_back" type="file" class="form-control"
                        (change)="onFileChange($event, 'licence_back')">
                    <span>
                        <i class="fa fa-cloud-upload" aria-hidden="true"></i>
                        Upload back side
                    </span>
                </label>


                <div *ngIf="licence_back" style="color: #969696;">
                    {{licence_back.name}}
                </div>
                <div class="validation_error" style="text-align: center;height: 100%;">
                    <span *ngIf="validationErrorsDocumentUpload.licence_back">{{validationErrorsDocumentUpload.licence_back}}</span>
                </div>
            </div>


            <div style="font-size: 14px;font-family: Lato-Regular;margin-top: 10px;text-align: left" *ngIf="driver_category && (driver_category==2 || driver_category==3 || driver_category==4)">
                CPC Card
            </div>
            <div style="text-align: center;" *ngIf="driver_category && (driver_category==2 || driver_category==3 || driver_category==4)">
                <label class="myLabel">

                    <input formControlName="cpc_front" id="cpc_front" type="file" class="form-control"
                        (change)="onFileChange($event, 'cpc_front')">
                    <span>
                        <i class="fa fa-cloud-upload" aria-hidden="true"></i>
                        Upload front side
                    </span>
                </label>


                <div *ngIf="cpc_front" style="color: #969696;">
                    {{cpc_front.name}}
                </div>
                <div class="validation_error" style="text-align: center;height: 100%;">
                    <span *ngIf="validationErrorsDocumentUpload.cpc_front">{{validationErrorsDocumentUpload.cpc_front}}</span>
                </div>
            </div>

            <div style="text-align: center;"  *ngIf="driver_category && (driver_category==2 || driver_category==3 || driver_category==4)">
                <label class="myLabel">

                    <input formControlName="cpc_back" id="cpc_back" type="file" class="form-control"
                        (change)="onFileChange($event, 'cpc_back')">
                    <span>
                        <i class="fa fa-cloud-upload" aria-hidden="true"></i>
                        Upload back side
                    </span>
                </label>


                <div *ngIf="cpc_back" style="color: #969696;">
                    {{cpc_back.name}}
                </div>
                <div class="validation_error" style="text-align: center;height: 100%;">
                    <span *ngIf="validationErrorsDocumentUpload.cpc_back">{{validationErrorsDocumentUpload.cpc_back}}</span>
                </div>
            </div>



            <div style="font-size: 14px;font-family: Lato-Regular;margin-top: 10px;text-align: left" *ngIf="driver_category && (driver_category==2 || driver_category==3 || driver_category==4)">
                Digi Card
            </div>
            <div style="text-align: center;" *ngIf="driver_category && (driver_category==2 || driver_category==3 || driver_category==4)">
                <label class="myLabel">

                    <input formControlName="digicard_front" id="digicard_front" type="file" class="form-control"
                        (change)="onFileChange($event, 'digicard_front')">
                    <span>
                        <i class="fa fa-cloud-upload" aria-hidden="true"></i>
                        Upload front side
                    </span>
                </label>


                <div *ngIf="digicard_front" style="color: #969696;">
                    {{digicard_front.name}}
                </div>
                <div class="validation_error" style="text-align: center;height: 100%;">
                    <span *ngIf="validationErrorsDocumentUpload.digicard_front">{{validationErrorsDocumentUpload.digicard_front}}</span>
                </div>
            </div>

            <div style="text-align: center;" *ngIf="driver_category && (driver_category==2 || driver_category==3 || driver_category==4)">
                <label class="myLabel">

                    <input formControlName="digicard_back" id="digicard_back" type="file" class="form-control"
                        (change)="onFileChange($event, 'digicard_back')">
                    <span>
                        <i class="fa fa-cloud-upload" aria-hidden="true"></i>
                        Upload back side
                    </span>
                </label>


                <div *ngIf="digicard_back" style="color: #969696;">
                    {{digicard_back.name}}
                </div>
                <div class="validation_error" style="text-align: center;height: 100%;">
                    <span *ngIf="validationErrorsDocumentUpload.digicard_back">{{validationErrorsDocumentUpload.digicard_back}}</span>
                </div>
            </div>


            <div class="form-group" style="margin-top: 35px;;">
                <button type="submit" (click)="submitDocumentUpload()"
                    class="btn btn-primary btn-block btn-lg">{{'BECOME_A_DRIVER.next' | translate }}</button>

            </div>

        </form>
    </div>
</div>


<div style="margin-top:50px;min-height: 424px;" [hidden]="loading||!(currentStep==5)">
    <div class="signup-form  col-lg-5" style="margin-bottom: 20px;">

        <div class="text-center" style="height: 60px;">

            <span class="font-weight-bold"
                style="font-size:18px;color: #337777;">{{'BECOME_A_DRIVER.THANK_YOU' | translate }}</span><br />
            <div
                style="width: 50px;height: 2px;background-color: rgb(243, 115, 42);margin-left: auto;margin-right: auto;">


            </div>
            <div style="padding: 36px 40px;">



                {{'BECOME_A_DRIVER.your_request_under_review' | translate }}

            </div>

            <div class="form-group" style="margin-top: 35px;;">
                <button type="submit" routerLink="/" class="btn btn-primary btn-block btn-lg">
                    {{'BECOME_A_DRIVER.return_to_Home' | translate }}</button>
            </div>




        </div>
    </div>
</div>

<div id="emailOTPModal" class="modal fade" role="dialog"  data-keyboard="false" data-backdrop="static">
    <div class="modal-dialog">

        <!-- Modal content-->
        <div class="modal-content" style="min-height: 200px;;">

            <div class="modal-body  otp" [hidden]="otpLoadingEmail|| otpResultViewEmail " style="padding: 37px 15px;">
                <p>{{'BECOME_A_DRIVER.email_otp_sent' | translate }} <b>{{otpEmail}}</b></p>

                <div style="text-align: center;">

                    <input max="999999" type="number" [(ngModel)]="otpTextEmail" (keyup)="onOtpKeyupEmail($event)" placeholder="" style="
                      padding-left: 15px;
                        caret-color: #ffffff;
                        letter-spacing: 33px;
                        border: 0;
                        background-image: url(../../assets/images/otp_bg_email.jpg);
                        background-repeat: no-repeat;
                        width: 280px;
                        height: 50px;
            ">

                </div>
                <div class="validation_error" style="color: rgb(3, 126, 3);text-align: center;">
                    <span *ngIf="resendOtpMsg">{{resendOtpMsg}}</span>
                </div>


                <div class="validation_error">
                    <span *ngIf="validationErrorsOtp">{{validationErrorsOtp}}</span>
                </div>

            </div>

            <div [hidden]="!otpLoadingEmail || otpResultViewEmail ">
                <div style="text-align: center;
                            margin-top: 62px;
                            min-height: 100px; ">

                    <img src="../../assets/images/loader.gif"
                        style="margin-top: 10px;   width: 70px;padding-left: 20px;" />
                </div>
            </div>


            <div class="text-center" [hidden]="otpLoadingEmail || !otpResultViewEmail" style=" height: 50px;margin-top: 48px;">

                <span class="font-weight-normal" style="font-size:18px;color: #337777;font-family: Lato-Regular;">
                    {{otpResponseMessageEmail.status}}
                </span>
                <br />
                <div
                    style="width: 50px;height: 2px;background-color: rgb(243, 115, 42);margin-left: auto;margin-right: auto;">
                </div>

                <div style="margin-top: 10px;">


                    {{otpResponseMessageEmail.text}}
                </div>


            </div>



            <div [hidden]="otpLoadingEmail || otpResultViewEmail" class="button-group" style="    text-align: right;">
                <button type="button" id="closeOTPModelPopup"  [hidden]="true" class="btn btn-default"
                    data-dismiss="modal">Close</button>
                    <button type="button" [hidden]="resendOtpMsg" (click)="resendOtp()" class="btn btn-default"> {{'BECOME_A_DRIVER.RESEND_OTP' | translate }} </button>
                <button type="button" style="background-color: rgb(243, 115, 42);;
                border-color: rgb(243, 115, 42);" (click)="submitOtpEmail()"
                    class="btn btn-info"> {{'BECOME_A_DRIVER.submit_otp' | translate }}  </button>

            </div>
        </div>

    </div>
</div>