import { Component, OnInit } from '@angular/core';
import { SecuredService } from '../services/secured.service';
import { JwtService } from '../services/jwt.service';
import { Invoice } from '../interfaces/Invoice';
import { Company } from '../interfaces/Company';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-invoice',
  templateUrl: './invoice.component.html',
  styleUrls: ['./invoice.component.css']
})
export class InvoiceComponent implements OnInit {

  constructor(private jwtService: JwtService, private securedService: SecuredService,
    private router: Router, private translate: TranslateService) { }
  loading: boolean = false;
  viewMoreUrl: string;
  invoices: Invoice[] = [];
  company: Company;

  ngOnInit(): void {
  	if (this.jwtService.isDriver) {
	  this.router.navigate(['manage-booking']);
	  return;
	}
	this.company = this.jwtService.getLoggedInData();
	this.loading = true;
    let data: string = "";
    this.securedService.getInvoices("", data).subscribe(
      result => {
        this.loading = false;
        this.invoices = result.items;
        this.viewMoreUrl = result?._links?.next?.href;
      },
      error => {
        this.loading = false;
        if (error.status == 429 || error.status == 500) {
          alert(this.translate.instant('COMMON.500'));

        }

      }
    );
  }


  viewMore() {
    if (!this.viewMoreUrl) {
      return;
    }

    this.securedService.getInvoices(this.viewMoreUrl, "").subscribe(
      result => {
        for (let i = 0; i < result.items.length; i++) {
          let invoice = result.items[i];
          this.invoices.push(invoice);
        }
        this.viewMoreUrl = result?._links?.next?.href;
      },
      error => {
        if (error.status == 429 || error.status == 500) {
          alert(this.translate.instant('COMMON.500'));
        }
      }
    );
  }

}
