import { Component, OnInit, ViewChild } from '@angular/core';
import { JwtService } from '../services/jwt.service'
import { DataSharingService } from '../services/data-sharing.service';
import { Router } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  loggedIn: Boolean = false;
  public iphone: boolean = false;
  @ViewChild('homeVideo') homeVideo: any;

  constructor(
    public router: Router,
    private jwtService: JwtService,
    private dataSharingService: DataSharingService,
    private deviceService: DeviceDetectorService) {
    var deviceInfo = this.deviceService.getDeviceInfo();
    if (deviceInfo.device == 'iphone') {
      this.iphone = true;
    }
  }

  // videoHeight = 650;
  // videoTop = 65;

  ngOnInit(): void {

    // let innerWidth = window.innerWidth;
    // this.videoTop = 65 - (innerWidth * 0.08035353535353535353535353535354);
    // this.videoHeight = (innerWidth * 0.50) - this.videoTop;


 

    this.loggedIn = this.jwtService.loggedIn;
    this.dataSharingService.isLoggedIn.subscribe(value => {
      this.loggedIn = value;
    });

    if (this.loggedIn) {
      if (this.jwtService.isDriver) {
        if(this.jwtService.driverLicence) {
          this.router.navigate(['manage-booking']);
        } else {
          this.router.navigate(['profile']);
        }
        
        return;
      }
      if (this.jwtService.isCompany) {
        this.router.navigate(['drivers']);
        return;
      }
      if (this.jwtService.isOwnerDriver) {
        this.router.navigate(['profile']);
        return;
      }
    }


  }

}
