
import { Component, OnInit, EventEmitter, Output } from '@angular/core';
declare var FB: any;
@Component({
  selector: 'app-fb-signup',
  templateUrl: './fb-signup.component.html',
  styleUrls: ['./fb-signup.component.css']
})
export class FbSignupComponent implements OnInit {

  constructor() { }

  @Output() messageEvent = new EventEmitter<string>();



  ngOnInit(): void {
    (window as any).fbAsyncInit = function () {
      FB.init({
        appId: '358312951026746',
        cookie: true,
        xfbml: true,
        version: 'v3.1'
      });
      FB.AppEvents.logPageView();
    };

    (function (d, s, id) {
      var js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) { return; }
      js = d.createElement(s); js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'facebook-jssdk'));
  }

  submitLogin() {
    let mainThis=this;
    FB.login((response) => {
      //console.log('submitLogin', response);
      if (response.authResponse) {
        //login success
        //get email
        let accessToken=response.authResponse.accessToken;
        FB.api('/me', { fields: 'name, email' }, function (response) {
          //console.log('User details, ', response);
          response.accessToken=accessToken;
          mainThis.messageEvent.emit(response);

        });
      }
      else {
        console.log('User login failed');
      }
    }, { scope: 'email' });

  }

}
