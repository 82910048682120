import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { CommonService } from '../services/common.service';
import { JwtService } from '../services/jwt.service';
import { SecuredService } from '../services/secured.service';
import { TranslateService } from '@ngx-translate/core';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common'

@Component({
  selector: 'app-complete-booking',
  templateUrl: './complete-booking.component.html',
  styleUrls: ['./complete-booking.component.css']
})
export class CompleteBookingComponent implements OnInit {

  loading: boolean = true;
  bookingResultView: boolean = false;
  driver: any;
  id: number;
  booking: any;
  hourly_rate: number;
  start_time: string;
  end_time: string;
  total_amount: number;

  constructor(private route: ActivatedRoute,
    private router: Router,
    private commonService: CommonService,
    private jwtService: JwtService,
    private securedService: SecuredService,
    private translate: TranslateService,
    public datepipe: DatePipe
    ) { }

  ngOnInit(): void {
  	if (this.jwtService.isDriver) {
    	this.router.navigate(['profile']);
    }

  	this.route.params.subscribe(params => {
      let id = params['id'];
      this.id = id;
      this.loading = true;
      this.securedService.getBookingToComplete(id).subscribe(
        result => {
          this.booking = result.booking;
          this.driver = result.profile;
          this.hourly_rate = result.hourly_rate;
          this.loading = false;
          let start_time=new Date(this.booking.start_time);
          this.start_time = this.datepipe.transform(start_time, 'HH:mm');

          if(this.booking.end_time) {
            let end_time=new Date(this.booking.end_time);
            this.end_time = this.datepipe.transform(end_time, 'HH:mm');
          }
          if(this.booking.booking_status=='COMPLETED') {
            this.total_amount = result.total_amount;
          } else if(this.booking.booking_status=='ACCEPTED') {
            this.calculateTotalAmount();
          }
        },
        error => {
          this.loading = false;
          if (error.status == 429 || error.status == 500) {
            alert(this.translate.instant('COMMON.500'));
          }
        }
      );
    });
  }

  noShowBooking() {
    if(confirm("Are you sure to mark this booking NO SHOW?")) {
      this.booking.booking_status = "NO SHOW";
      this.updateBooking();
    }
  }

  completeBooking() {
    if(this.start_time>this.end_time) {
      alert('End time should be after start time');
      return;      
    }
  	if(confirm("Are you sure to mark this booking COMPLETED?")) {
      this.booking.booking_status = "COMPLETED";
      let booking_date_string = new Date(this.booking.start_time);
      let booking_date = this.datepipe.transform(booking_date_string, 'yyyy-MM-dd');
      
      this.booking.start_time = booking_date+" "+this.start_time+":00";
    
      this.booking.end_time = booking_date+" "+this.end_time+":00";
      this.updateBooking();
    }
  }

  updateBooking() {
  	this.loading = true;
  	const formData = new FormData();
  	formData.append(this.booking.id+"[status]", this.booking.booking_status);
    formData.append(this.booking.id+"[start_time]", this.booking.start_time);
    formData.append(this.booking.id+"[end_time]", this.booking.end_time);
  	this.securedService.updateFinished(formData)
  	.subscribe(result => {
  	  this.loading = false;
  	  this.bookingResultView = true;
  	},
  	  error => {
  	    this.loading = false;
  	    alert(error);
  	  }
  	);
  }

  calculateTotalAmount() {
    if(this.end_time && this.start_time) {
      let booking_date_string = new Date(this.booking.start_time);
      let booking_date = this.datepipe.transform(booking_date_string, 'yyyy-MM-dd');
        
      let start_time_string = booking_date+" "+this.start_time+":00";
      let end_time_string = booking_date+" "+this.end_time+":00";
      let start_time:any = new Date(start_time_string);
      let end_time:any = new Date(end_time_string);
      let minutes = Math.abs(end_time.getTime() - start_time.getTime()) / 60000;
      let hours = +(minutes*(1/60)).toFixed(2);           
      this.total_amount = +(this.hourly_rate*hours).toFixed(2);
    } else {
      this.total_amount = 0;
    }
    
  }
}
