import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { SecuredService } from '../services/secured.service';
import { JwtService } from '../services/jwt.service';
import { Invoice } from '../interfaces/Invoice';
import { Company } from '../interfaces/Company';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-manage-invoice',
  templateUrl: './manage-invoice.component.html',
  styleUrls: ['./manage-invoice.component.css']
})
export class ManageInvoiceComponent implements OnInit {

  constructor(private jwtService: JwtService, private securedService: SecuredService,private route: ActivatedRoute,
    private router: Router,
    private translate: TranslateService) { }

  loading: boolean = true;
  company: Company;
  invoice: Invoice;
  id: number;
  bookings: any;
  invoiceMarkedPaid: boolean = false;

  ngOnInit(): void {
  	if (this.jwtService.isDriver) {
	  this.router.navigate(['manage-booking']);
	  return;
	}
	this.company = this.jwtService.getLoggedInData();

	this.route.params.subscribe(params => {
      let id = params['id'];
      this.id = id;
      this.loading = true;
      this.securedService.getInvoice(id).subscribe(
        result => {
          this.invoice = result.invoice;
          this.bookings = result.bookings;
          this.loading = false;
        },
        error => {
          this.loading = false;
          if (error.status == 429 || error.status == 500) {
            alert(this.translate.instant('COMMON.500'));
          }
        }
      );
    });
  }

  markPaid() {
  	if(confirm("Are you sure to mark this invoice as paid?")) {
  	  this.loading = true;
      this.securedService.markInvoicePaid(this.id)
		.subscribe(result => {
		  this.loading = false;
		  this.invoice.invoice_status = 'Paid';
		  this.invoiceMarkedPaid = true;
		},
		error => {
		  this.loading = false;
		  alert(error);
		}
	  );
    }
  }

}
