import { Injectable } from '@angular/core';
import { Router, CanActivate, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { JwtService } from '../services/jwt.service';
@Injectable()
export class AuthGuardService implements CanActivate {
  constructor(public jwtService: JwtService, public router: Router) { }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (!this.jwtService.loggedIn) {
      this.router.navigate(['login',state.url]);
      return false;
    }
    return true;
  }
}