<div class="hero_image" style="background-image: url(./assets/images/banner_new.jpg);
">
    <div class="container" style="margin-top: 10px;">



        <div class="col-md-6 hero_desc">
            <div>
                Welcome to Drivers2day. The driver agency revolutionising the way drivers and businesses come together. <br>
                Our driver classes range from CLASS1 TO Van Drivers.
            </div>

        </div>

        <br>

        <div class="row " style="padding:15px;">

            <button class="nav-link become-driver-link" routerLink="/become-driver-intro"
                style="font-family: Lato-Regular;margin-left: 10px;margin-top: 5px;" data-toggle="collapse"
                data-target=".navbar-collapse.show">{{'HEADER.become_a_driver' | translate }}
            </button>
            <button class="nav-link hire-driver-link" routerLink="/signup"
                style="font-family: Lato-Regular;margin-left: 10px;margin-top: 5px;" data-toggle="collapse"
                data-target=".navbar-collapse.show">HIRE A DRIVER
            </button>


        </div>


    </div>

</div>



<div class="contentHome">

    <div class="text-center font-weight-bold"
        style="margin-top:50px;height: 60px;font-size:18px;font-family: Lato-Regular;">

        <span>
            <span class="" style="color: #337777;">
                BECOME A DRIVER
            </span>
            WITH US TODAY
        </span>
        <br />
        <div style="width: 70px;height: 2px;background-color: rgb(243, 115, 42);margin-left: auto;margin-right: auto;">
        </div>
    </div>

    <div class="container ">
        <div class="row" style="">
            <div style="margin: 15px auto;" class=" col-md-6">

                <div class="col-md-12" style="float: left;min-height: 151px;">

                    <div style="width: 60%;
            background-repeat: no-repeat;
            float: left;
            background-image: url(./assets/images/one.jpg);
            background-size: 60px;
            padding-left: 35px;
            padding-top: 25px;
            background-position: -15px -5px;
        ">
                        <div style="font-size: 16px;font-family: Lato-Regular;">
                            FLEXIBILITY
                        </div>
                        <div style="font-size: 14px;">
                            Choose jobs on your availability, no commitment to a 5 or 7 day working contract.
                        </div>
                    </div>
                    <div style="width: 40%;float: left;text-align: center;">

                        <img style="width:120px;" src="./assets/images/intro1.jpg">
                    </div>

                </div>

                <div class="col-md-12" style="float: left;min-height: 151px;">
                    <div style="width: 40%;text-align: center;
            float: left;">

                        <img style="width: 120px;" src="./assets/images/intro2.jpg">
                    </div>
                    <div style="width: 60%;
            background-repeat: no-repeat;
            float: left;
            background-image: url(./assets/images/two.png);
            background-size: 60px;
            padding-left: 50px;
            padding-top: 25px;
            background-position: -8px -5px;
        ">
                        <div style="font-size: 16px;font-family: Lato-Regular;">
                            PROFILING
                        </div>
                        <div style="font-size: 14px;">
                            Listing your past skills and experiences within your profile will increase your chance of
                            being booked by many of our companies.
                        </div>
                    </div>


                </div>


            </div>
            <div style="margin: 15px auto;margin: 15px auto;" class=" col-md-6 border-lft">

                <div class="col-md-12" style="float: left;min-height: 151px;">

                    <div style="width: 60%;
            background-repeat: no-repeat;
            float: left;
            background-image: url(./assets/images/three.jpg);
            background-size: 60px;
            padding-left: 50px;
            padding-top: 25px;
            background-position: -10px -5px;
        ">
                        <div style="font-size: 16px;font-family: Lato-Regular;">
                            {{'BECOME_A_DRIVER_INTRO.VIDEO_CV' | translate }}
                        </div>
                        <div style="font-size: 14px;">
                            {{'BECOME_A_DRIVER_INTRO.VIDEO_CV_TEXT' | translate }}.
                        </div>
                    </div>
                    <div style="width: 40%;float: left;text-align: center;">

                        <img style="width:120px;" src="./assets/images/intro3.jpg">
                    </div>

                </div>

                <div class="col-md-12" style="float: left;min-height: 151px;">
                    <div style="width: 40%;text-align: center;
            float: left;">

                        <img style="width: 120px;" src="./assets/images/intro4.jpg">
                    </div>
                    <div style="width: 60%;
            background-repeat: no-repeat;
            float: left;
            background-image: url('./assets/images/four.png');
            background-size: 60px;
            padding-left: 50px;
            padding-top: 25px;
            background-position: -8px -5px;
        ">
                        <div style="font-size: 16px;font-family: Lato-Regular;">
                            {{'BECOME_A_DRIVER_INTRO.CONNECTIONS' | translate }}
                        </div>
                        <div style="font-size: 14px;">
                            {{'BECOME_A_DRIVER_INTRO.CONNECTIONS_TEXT' | translate }}.
                        </div>
                    </div>


                </div>


            </div>
        </div>
    </div>

    <div style="min-height: 120px;text-align: center;">
        <div>
            <button type="button" routerLink="/become-driver" class="btn btn-default" style="background-color: rgb(243, 115, 42);color: #fff;
            margin-top: 15px;
            border-color:rgb(243, 115, 42);">BECOME AN AGENCY DRIVER</button>
        </div>
        <div>
            <button type="button" routerLink="/become-owner-driver" class="btn btn-default" style="background-color: rgb(243, 115, 42);color: #fff;
            margin-top: 15px;
            border-color:rgb(243, 115, 42);">BECOME A OWNER DRIVER</button>
        </div>
    </div>


    <hr>

    <div class="text-center font-weight-bold" style="margin-top:50px;">

        <span class="font-weight-normal" style="font-size:22px;color: #337777;font-family: Lato-Regular;">FIND THE
            DRIVER</span><br />
        <span class="font-weight-normal">THAT MEETS THE NEEDS OF </span><br />

    </div>

    <!--<div class="text-center" style=" margin-top:50px;
 /* font-family: GE-SS;*/
  
  ">


         <span class="font-weight-normal">{{'HOME.intro_line1' | translate }}</span><br />
        <span class="font-weight-normal"
            style="font-size:22px;color: #337777;font-family: Lato-Regular;">{{'HOME.intro_line2' | translate
            }}</span><br /> 



    </div>-->



    <div class="text-center" style=" margin-top:10px;margin-bottom: 20px;">

        <span class="font-weight-normal" style="font-size:22px;color: #337777;font-family: Lato-Regular;">YOUR
            BUSINESS</span><br />
        <span class="font-weight-normal">TODAY</span><br />
        <!--<span class="font-weight-normal"
            style="font-size:22px;color: #337777;font-family: Lato-Regular;">BROWSE OUR DRIVER PROFILES</span><br />
        <span class="font-weight-normal"
            style="font-size:22px;color: #337777;font-family: Lato-Regular;">IN YOUR AREA <span 
            style="color: #de7149;">FOR FREE</span>
        </span><br /><br />-->

    </div>
    <div class="text-center" style="height: 90px;">

        <span class="font-weight-normal"
            style="font-size:18px;color: #337777;font-family: Lato-Regular;">{{'HOME.signup' | translate }}
        </span><br />

        <div style="margin-top:10px;">
            <i class="fa fa-long-arrow-down" style="font-size:30px;color: #337777;" aria-hidden="true"></i>
        </div>
    </div>
    <app-company-signup></app-company-signup>
</div>