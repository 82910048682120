<div class="container-fluid">
    <!-- Row -->
    <div class="row">
        <div class="col-xl-12 pa-0">
            <div class="faq-search-wrap bg-teal-light-3">
                <div class="container">
                    <h1 class="display-5 text-white mb-20">Browse by category below or contact us.</h1>
                    <!-- <div class="form-group w-100 mb-0">
                        <div class="input-group">
                            <input class="form-control form-control-lg filled-input bg-white" placeholder="Search by keywords" type="text">
                            <div class="input-group-append">
                                <span class="input-group-text"><span class="feather-icon"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-right"><line x1="5" y1="12" x2="19" y2="12"></line><polyline points="12 5 19 12 12 19"></polyline></svg></span></span>
                            </div>
                        </div>
                    </div> -->
                </div>
            </div>
            <div class="container mt-sm-60 mt-30">
                <div class="hk-row">
                    <!-- <div class="col-xl-4">
                        <div class="card">
                            <h6 class="card-header">
                                            Category
                                        </h6>
                            <ul class="list-group list-group-flush">
                                <li class="list-group-item d-flex align-items-center active">
                                    <i class="ion ion-md-sunny mr-15"></i>FAQ<span class="badge badge-light badge-pill ml-15">12</span>
                                </li>
                                <li class="list-group-item d-flex align-items-center">
                                    <i class="ion ion-md-unlock mr-15"></i>Privacy policy<span class="badge badge-light badge-pill ml-15">14</span>
                                </li>
                                <li class="list-group-item d-flex align-items-center">
                                    <i class="ion ion-md-bookmark mr-15"></i>Terms of use<span class="badge badge-light badge-pill ml-15">10</span>
                                </li>
                                <li class="list-group-item d-flex align-items-center">
                                    <i class="ion ion-md-filing mr-15"></i>Documentation<span class="badge badge-light badge-pill ml-15">27</span>
                                </li>
                            </ul>
                        </div>
                    </div> -->
                    <div class="col-xl-12">
                        <div class="card card-lg" style="padding-bottom: 10px;">
                            <h3 class="card-header border-bottom-0">
                                            FAQ
                                        </h3>
                            <div class="accordion accordion-type-2 accordion-flush" id="accordion_2">
                                <div class="card">
                                    <div class="card-header d-flex justify-content-between activestate">
                                        <a role="button" data-toggle="collapse" href="#collapse_1i" aria-expanded="true"><i class="fa fa-arrow-right"></i><span>What is it you do?</span></a>
                                    </div>
                                    <div id="collapse_1i" class="collapse show" data-parent="#accordion_2" role="tabpanel">
                                        <div class="card-body pa-15">We provide drivers from all over the UK, from van drivers to CLASS1 drivers we accept all signups.</div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header d-flex justify-content-between">
                                        <a class="collapsed" role="button" data-toggle="collapse" href="#collapse_2i" aria-expanded="false"><i class="fa fa-arrow-right"></i><span>How do i signup as a driver?</span></a>
                                    </div>
                                    <div id="collapse_2i" class="collapse" data-parent="#accordion_2">
                                        <div class="card-body pa-15">Simply just click " Become a driver " from our homepage, Enter your information and fill out a profile with
                                            as much information as possible.</div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header d-flex justify-content-between">
                                        <a class="collapsed" role="button" data-toggle="collapse" href="#collapse_3i" aria-expanded="false"><i class="fa fa-arrow-right"></i><span>How do you signup as a hirer?</span></a>
                                    </div>
                                    <div id="collapse_3i" class="collapse" data-parent="#accordion_2">
                                        <div class="card-body pa-15">Simply just click " Find a driver" from our homepage, Enter your information and you are ready to begin
                                            searching for a driver!.</div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header d-flex justify-content-between">
                                        <a class="collapsed" role="button" data-toggle="collapse" href="#collapse_4i" aria-expanded="false"><i class="fa fa-arrow-right"></i><span>Why have you asked for copies of my driving licence?</span></a>
                                    </div>
                                    <div id="collapse_4i" class="collapse" data-parent="#accordion_2">
                                        <div class="card-body pa-15">We need to validate licence details, It's as simple as that. We are GDPR registered and we do store your
                                            information securely.</div>
                                    </div>
                                </div>
                                <!-- <div class="card">
                                    <div class="card-header d-flex justify-content-between">
                                        <a class="collapsed" role="button" data-toggle="collapse" href="#collapse_5i" aria-expanded="false"><i class="fa fa-arrow-right"></i><span>Limitation of liability of your products</span></a>
                                    </div>
                                    <div id="collapse_5i" class="collapse" data-parent="#accordion_2">
                                        <div class="card-body pa-15">No matter what kind of goods you sell, best practices direct you to present any warranties you are disclaiming and liabilities you are limiting in a way that your customers will notice.</div>
                                    </div>
                                </div> -->
                                <div class="card">
                                    <div class="card-header d-flex justify-content-between">
                                        <a class="collapsed" role="button" data-toggle="collapse" href="#collapse_6i" aria-expanded="false"><i class="fa fa-arrow-right"></i><span>I created a booking request for a driver, How long will it take to confirm?</span></a>
                                    </div>
                                    <div id="collapse_6i" class="collapse" data-parent="#accordion_2">
                                        <div class="card-body pa-15">Our drivers have 2 hours to accept or reject the booking before it times out. We believe this is enough
                                            time for a driver to make a decision.</div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header d-flex justify-content-between">
                                        <a class="collapsed" role="button" data-toggle="collapse" href="#collapse_7i" aria-expanded="false"><i class="fa fa-arrow-right"></i><span>A driver hasn't turned up, What do i do?</span></a>
                                    </div>
                                    <div id="collapse_7i" class="collapse" data-parent="#accordion_2">
                                        <div class="card-body pa-15">Whilst we do our best, unfortunately this happens with all agencies. Our drivers are on a 2 strike rule, 2 strikes and they are suspended from our platform.</div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header d-flex justify-content-between">
                                        <a class="collapsed" role="button" data-toggle="collapse" href="#collapse_8i" aria-expanded="false"><i class="fa fa-arrow-right"></i><span>How long until i get paid from driving?</span></a>
                                    </div>
                                    <div id="collapse_8i" class="collapse" data-parent="#accordion_2">
                                        <div class="card-body pa-15">Payments will be made 7-10 days after the work has been completed from the hirer. We aim to pay you when we get paid to avoid you waiting.
                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header d-flex justify-content-between">
                                        <a class="collapsed" role="button" data-toggle="collapse" href="#collapse_9i" aria-expanded="false"><i class="fa fa-arrow-right"></i><span>How will i be paid?</span>
                                        </a>
                                    </div>
                                    <div id="collapse_9i" class="collapse" data-parent="#accordion_2">
                                        <div class="card-body pa-15">You can add your account number & sortcode within your profile and we will notify you when your payment has been sent. We may send your details to a 3rd party payroll provider to comply with IR35 and HMRC regulations
                                        </div>
                                    </div>
                                </div>



                                <div class="card">
                                    <div class="card-header d-flex justify-content-between">
                                        <a class="collapsed" role="button" data-toggle="collapse" href="#collapse_10i" aria-expanded="false"><i class="fa fa-arrow-right"></i><span>How do i pay my invoice and how long do i have to settle it?</span></a>
                                    </div>
                                    <div id="collapse_10i" class="collapse" data-parent="#accordion_2">
                                        <div class="card-body pa-15">Invoices are produced and sent out the following monday from a last booking. They are due within 7 days. We accept bank transfers and also credit/debit card payments.
                                            Please note late payments will result in us factoring your invoice, which will incur a % increate on the original value.
                                            </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header d-flex justify-content-between">
                                        <a class="collapsed" role="button" data-toggle="collapse" href="#collapse_11i" aria-expanded="false"><i class="fa fa-arrow-right"></i><span>How do i contact you?</span>
                                        </a>
                                    </div>
                                    <div id="collapse_11i" class="collapse" data-parent="#accordion_2">
                                        <div class="card-body pa-15">You can call us anytime on <b>0151 272 0464</b> via telephone or whatsapp, or email us at <b><a href="mailto:contactus@drivers2day.co.uk
                                            ">contactus@drivers2day.co.uk</a></b>
                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header d-flex justify-content-between">
                                        <a class="collapsed" role="button" data-toggle="collapse" href="#collapse_12i" aria-expanded="false"><i class="fa fa-arrow-right"></i><span>I have another question which remains unanswered</span>
                                        </a>
                                    </div>
                                    <div id="collapse_12i" class="collapse" data-parent="#accordion_2">
                                        <div class="card-body pa-15">Please contact us with any questions

                                        </div>
                                    </div>
                                </div>



                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- /Row -->
</div>