import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { JwtService } from '../services/jwt.service';
import { Injectable } from '@angular/core';

@Injectable()
export class TokenInterceptorService implements HttpInterceptor {

    // We inject a LoginService
    constructor(private jwtService: JwtService) { }
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        //console.log('INTERCEPTOR');
        // We retrieve the token, if any
        const token = this.jwtService.getAuthToken();
        let newHeaders = req.headers;
        if (token) {
            // If we have a token, we append it to our new headers
            newHeaders = newHeaders.append('Authorization', 'Bearer ' + token);
        }

        let lang = localStorage.getItem('lang');
        if(lang){
            // we append language to our new headers
            newHeaders = newHeaders.append('Accept-Language', lang);
        }

        // Finally we have to clone our request with our new headers
        // This is required because HttpRequests are immutable
        const authReq = req.clone({ headers: newHeaders });
        // Then we return an Observable that will run the request
        // or pass it to the next interceptor if any
        return next.handle(authReq);
    }
}