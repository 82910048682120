<div class="profile-section"
    style=" margin-top: 65px;min-height: 700px;padding-top: 30px;">
    <div class="container">
        <div class="row" style="padding: 0 15px;text-align: center;">
            <div [hidden]="!loading" style="width: 100%;background-color: #efefef;min-height: 204px;">
                <div class="ion-padding" style="text-align: center;margin-top: 71px;min-height: 100px;">
                    <img src="../../assets/images/loader.gif"
                        style=" margin-top: 10px;   width: 70px;padding-left: 20px;" />
                </div>
            </div>
            
            <div *ngIf="!loading" class="col-12 col-sm-8" style="margin-top: 5px;">
            	<h2 style="font-size: 25px;color: #337777;font-family: Lato-Regular;text-decoration: underline;">Booking Detail</h2>
            	<div class="text-center alert alert-success" role="alert" [hidden]="!bookingResultView" style="min-height: 25px;margin-top: 48px;">
	                <span class="font-weight-normal" style="font-size:18px;color: #337777;font-family: Lato-Regular;">
	                    Booking status updated to {{ booking.booking_status }} successfully. <a routerLink="/manage-booking">Manage Booking</a>
	                </span>
	            </div>
                <div class="text-center alert alert-info" role="alert" *ngIf="!loading && booking.booking_status=='COMPLETED'" >
                    <span  style="margin: auto;">The invoice will be due on next following monday from today. Payment must be made within 7 days</span>
                </div>
                
                <div class="row" style ="margin-top: 25px;" *ngIf="!loading && booking.booking_status=='ACCEPTED'">
                	<div class="col-12 col-sm-1 col-lg-1"> 
                        <label for="id" class="control-label col-12" style="font-family: Lato-Regular;">#ID</label>
                        <p class="form-control-static">{{ booking.id }}</p>
                    </div>
                    <div class="col-12 col-sm-4 col-lg-3"> 
                        <label for="booking_date" class="control-label col-12" style="font-family: Lato-Regular;">Booking Date</label>
                        <p class="form-control-static">{{ booking.start_time | date : 'mediumDate' }}</p>
                    </div>
                    <div class="col-12 col-sm-7 col-lg-8">
                        <div class="row">
                            <div class="col-4 col-sm-4 col-lg-4"> 
                                <label for="start_time" class="control-label col-12" style="font-family: Lato-Regular;" >Start time</label>
                                <input class="form-control" type="time" [(ngModel)]="start_time" (ngModelChange)="calculateTotalAmount()">
                                <!--<p class="form-control-static">{{ booking.start_time | date : 'HH:mm' }}</p>-->
                            </div>
                            <div class="col-4 col-sm-4 col-lg-4"> 
                                <label for="end_time" class="control-label col-12" style="font-family: Lato-Regular;" >End time</label>
                                <input class="form-control" type="time" [(ngModel)]="end_time" (ngModelChange)="calculateTotalAmount()">
                                <!--<p class="form-control-static">{{ booking.end_time | date : 'HH:mm' }}</p>-->
                            </div>
                            
                            <div  class="col-4 col-sm-4 col-lg-4"> 
                                <label for="hourly_rate" class="control-label col-12" style="font-family: Lato-Regular;" >Hourly Rate</label>
                                <p class="form-control-static"><i class="fa fa-gbp"></i> {{ hourly_rate }}</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row" style ="margin-top: 25px;" *ngIf="!loading && booking.booking_status!='ACCEPTED'">
                	<div class="col-12 col-sm-1 col-lg-1"> 
                        <label for="id" class="control-label col-12" style="font-family: Lato-Regular;">#ID</label>
                        <p class="form-control-static">{{ booking.id }}</p>
                    </div>
                    <div class="col-12 col-sm-3 col-lg-3"> 
                        <label for="booking_date" class="control-label col-12" style="font-family: Lato-Regular;">Booking Date</label>
                        <p class="form-control-static">{{ booking.start_time | date : 'mediumDate' }}</p>
                    </div>
                    <div class="col-12 col-sm-8 col-lg-8">
                        <div class="row" *ngIf="!loading && booking.booking_status!='ACCEPTED'">
                            <div class="col-6 col-sm-3 col-lg-3"> 
                                <label for="start_time" class="control-label col-12" style="font-family: Lato-Regular;" >Start time</label>
                                <p class="form-control-static">{{ booking.start_time | date : 'HH:mm' }}</p>
                            </div>
                            <div class="col-6 col-sm-3 col-lg-3"> 
                                <label for="end_time" class="control-label col-12" style="font-family: Lato-Regular;" >End time</label>
                                <p class="form-control-static">{{ booking.end_time | date : 'HH:mm' }}</p>
                            </div>
                            
                            <div  class="col-6 col-sm-3 col-lg-3"> 
                                <label for="hourly_rate" class="control-label col-12" style="font-family: Lato-Regular;" >Hourly Rate</label>
                                <p class="form-control-static"><i class="fa fa-gbp"></i> {{ hourly_rate }}</p>
                            </div>
                            <div  class="col-6 col-sm-3 col-lg-3"> 
                                <label for="booking_status" class="control-label col-12" style="font-family: Lato-Regular;" >Status</label>
                                <p class="form-control-static" style="text-align: left;">{{ booking.booking_status }}</p>
                            </div>
                        </div> 

                    </div>
                </div>
            	<div class="col-12" [hidden]="!total_amount">
                    <label for="hourly_rate" class="control-label col-12" style="font-family: Lato-Regular; margin-top: 25px;margin-bottom: 0px;" >Total Amount</label>
                    <p class="form-control-static" style="margin-bottom:0px; "><i class="fa fa-gbp"></i> {{ total_amount }}</p>
                    
                </div>
                <div *ngIf="!loading && booking.booking_status=='ACCEPTED'" class="form-group" style="margin-top:25px;">
                    <button
                        class="btn btn-success btn-md" style="padding-left: 40px;padding-right: 40px;background-color: #337777;border-color: #337777; margin-right: 10px;"  (click)="completeBooking()">Mark as Completed</button>
                    <button (click)="noShowBooking()"
                        class="btn btn-success btn-md" style="padding-left: 40px;padding-right: 40px;background-color: rgb(243, 115, 42);color: #fff; border-color:rgb(243, 115, 42);">Mark as No Show</button>
                </div>
                <div class="divider"></div>

            </div>
            <div *ngIf="!loading" class="col-12 col-sm-4" style="background-color: #efefef;min-height: 204px; padding-bottom: 20px;">
                <div style="width: 100%; margin-top: 20px;">
                    <div [hidden]="!driver?.profile_image" style="
                        background-image: url({{driver?.profile_image}});
                        height: 100px;   
                        margin: 10px auto;
                        width: 100px;
                        background-size: cover;
                        border-radius: 63px;
                        background-position: center;
                        ">
                    </div>
                    <img [hidden]="driver?.profile_image" style=" width: 100px;margin: 10px auto;"
                        src="../../assets/images/no-dp-2.png" />
                </div>
                <div class="col-12" style="font-family: Lato-Regular;">
                    {{driver?.full_name}}
                </div>
                <div class="col-12" style="font-family: Lato-Regular;">
                    <i class="fa fa-map-marker"></i> {{driver?.city}}
                </div>
            </div>
        </div>
    </div>
</div>