<div class="drivers" style="min-height: 700px;padding-top: 30px;;">
    
    <div class="container">
        <div class="row filter" 
        
        
        [ngStyle]="{'background-color':'#36698c'}"
        
        style="background-color: #36698c;
        padding-top: 20px;color: #fff;">
            <div class="col-md-12" style="margin: 0px auto;">
                <!--Grid column-->
                <div class="form-group searchDiv" >

                    <div class="input-group md-form form-sm form-1 pl-0">
                        <!-- <div class="input-group-prepend" style="height: 28px;">
                            <span class="input-group-text purple lighten-3" id="basic-text1" style="background-color: #fff;
                             width: 25px;
                             padding-left: 7px;">

                                <i class="fa fa-search" aria-hidden="true"></i></span>
                        </div> -->
                        <select *ngIf="locations && locations?.length>0" style="height: 35px;
                        padding: 2px 2px;
                        border-left: none;" class="form-control my-0 py-1" (change)="loadDrivers()" 
                            [(ngModel)]="company_location" aria-label="Search">
                            <option *ngFor="let location of locations"
                            [value]="location.latitude+','+location.longitude">
                                {{location.address_title}} - {{location.address}}
                            </option>
                        </select>
                        <!--<input style="height: 28px;
                        padding: 2px 2px;
                        border-left: none;" class="form-control my-0 py-1" (change)="loadDrivers()" type="text"
                            [(ngModel)]="searchKey" placeholder="{{'VIDEO_LANDING.search' | translate }} " aria-label="Search">-->
                    </div>
                </div>

                <div class="form-group radiusDiv" >

                    <div class="input-group md-form form-sm form-1 pl-0">
                        <span style="padding-top: 5px;">
                            &nbsp;&nbsp;
                            Show drivers within &nbsp;&nbsp;
                        </span>
                        <input style="height: 35px;
                        border-radius:.25rem!important; flex: none;min-width: 60px;" class="form-control my-0 py-1" (change)="loadDrivers()" type="number"
                            [(ngModel)]="radius" placeholder="Miles " aria-label="Miles" min="1" max="100" > <span style="padding-top: 5px;">miles</span>
                       
                    </div>
                </div>

                <!-- <div class="locationDiv" 
                data-toggle="modal" 
                data-target="#selectCityModel">
                    <div style="float: left;">
                        <span
                            [hidden]="(!this.loggedInUserLocation || !this.loggedInUserLocation?.id || !this.loggedInUserLocation?.city)">
                            &nbsp;&nbsp;In
                            <span style="color: #fff; 
                        cursor: pointer;">
                                {{this.loggedInUserLocation?.city}}&nbsp;</span>
                        </span>


                        <span
                            [hidden]="!(!this.loggedInUserLocation || !this.loggedInUserLocation.id || !this.loggedInUserLocation.city)"
                            style=" cursor: pointer;">
                            &nbsp;&nbsp;
                              Select your location
                        </span>
                    </div>
                    <div style="    float: left;
                    margin-top: -2px;
                    margin-left: 3px;">
                        <i class="fa fa-sort-desc" aria-hidden="true"></i>
                    </div>

                </div> -->
                <div class="sortDiv" data-toggle="modal" data-target="#selectSortByModel">


                    <div style="    float: right;
                    margin-top: -2px;
                    margin-left: 3px;">
                        <i class="fa fa-sort-desc" aria-hidden="true"></i>
                    </div>

                    <div style="font-family: Lato-Regular;float: right;cursor: pointer;">

                          Sort


                        <!-- <span [hidden]="sortedBy == 'Popularity'" style="text-decoration: underline;cursor: pointer;"
                            (click)="sortby('Popularity')">
                            Popularity
                        </span>
                        <span [hidden]="sortedBy =='RecentlyAdded'" style="text-decoration: underline;cursor: pointer;"
                            (click)="sortby('RecentlyAdded')">
                            Recently Added
                        </span> -->


                    </div>
                </div>

                <!--Grid column
            <div class="form-group" style="width: 30%;">
                <div class="input-group">
                    <select style="height: 10px;" id="city-select2" class="form-control" placeholder="City">
                    </select>
                </div>


            </div>-->
                <!-- 
            <div class="form-group" style="width: 30%;">
                <div class="input-group">

                    <select [(ngModel)]="sortedBy"  (change)="loadVideos()" class="form-control" style="height: 28px;padding: 2px;">
<option value="" selected>Sort by</option>
                        <option value="Popularity">Popularity</option>

                        <option value="RecentlyAdded" selected>Recently Added</option>
                    </select>
                </div>


            </div> -->

            </div>
        </div>
    </div>

    <div class="container" style="    font-size: 40px;">

        <div (click)="scrollCategoryLeft()" style="min-height:25px;width: 5%;float: left; text-align: left;">
            <i class="fa fa-sort-desc fa-rotate-90" aria-hidden="true"></i>
        </div>
        <div #panel class="scroll-div" style="min-height: 25px;
        width: 90%;
        float: left;
         margin-top: 14px;">

            <div style="width: max-content;    margin: 0px auto;">
                <div  [ngStyle]="{'color': '#377'}" class="driver_category" (click)="selectCategory(1)"
                    [ngClass]="selectedCategory==1 ? 'driver_category_selected' : ''">
                    Van Driver
                </div>
                <div  [ngStyle]="{'color': '#377'}" class="driver_category" (click)="selectCategory(2)"
                    [ngClass]="selectedCategory==2 ? 'driver_category_selected' : ''">
                    LGV/7.5TONNE Driver
                </div>
                <div  [ngStyle]="{'color': '#377'}" class="driver_category" (click)="selectCategory(3)"
                    [ngClass]="selectedCategory==3 ? 'driver_category_selected' : ''">
                    CLASS2 Driver
                </div>
                <div  [ngStyle]="{'color': '#377'}" class="driver_category" (click)="selectCategory(4)"
                    [ngClass]="selectedCategory==4 ? 'driver_category_selected' : ''">
                    CLASS1 Driver
                </div>
                <div  [ngStyle]="{'color': '#377'}" class="driver_category" (click)="selectCategory(5)"
                    [ngClass]="selectedCategory==5 ? 'driver_category_selected' : ''">
                    FLT Driver
                </div>
                <div  [ngStyle]="{'color':  '#377'}" class="driver_category" (click)="selectCategory(6)"
                    [ngClass]="selectedCategory==6 ? 'driver_category_selected' : ''">
                    Warehouse worker / Drivers Mate
                </div>
            </div>

        </div>
        <div (click)="scrollCategoryRight()" style="min-height:25px;width: 5%;float: left;text-align: right;">
            <i class="fa fa-sort-desc fa-rotate-270" aria-hidden="true"></i>
        </div>

    </div>

    <div class="container" style="margin-top: 80px;">
        <div class="row" [hidden]="loadingDrivers" style=" min-height: 300px;margin-bottom: 15px;margin-top: 20px">
                
            
            <ng-container *ngFor="let driver of drivers">
                <div class="col-md-4 col-sm-4">
                    <div class="manage-cndt">
                        <div class="cndt-status available">{{ driver.city_name }}</div>
                            <h4 class="flc-rate"><i class="fa fa-gbp"
                                aria-hidden="true"> </i> {{ driver.hourly_rate }}/hr</h4>
                        <div class="cndt-caption">
                            <div class="cndt-pic">
                                <img style="height: 100%;width:100%;" src="{{(driver.profile_image?driver.profile_image:'../../assets/images/no-dp-2.png')}}" class="img-responsive" alt="">
                            </div>
                            <h4>{{ driver.full_name }}</h4>
                            <span> {{driverCategories[driver.driver_category_id]}}</span>
                            <p>This driver costs <span style="color:#377">£{{ driver.hourly_rate }}</span> per hour</p>
                            <p>Age : {{ driver.dob?driver.dob:'--' }}</p>
                            <p>{{ driver.years_driving?driver.years_driving:0 }} Years Driving</p>
                            
                            <div class="star-rating" style="line-height: 2;">
                                <ng-container  *ngFor="let star of stars">
                                <span *ngIf="driver.rating>=star" class="fa fa-star fill"></span>
                                <span  *ngIf="(driver.rating>star && driver.rating<star+1)" class="fa fa-star-half fill"></span>
                                <span *ngIf="!driver.rating">&nbsp;</span>
                                </ng-container>
                            </div>
                            
                        </div>
                        <a [routerLink]="['/driver', driver.id]" title="" class="cndt-profile-btn">View Profile</a>
                    </div>
                </div>
                <!-- <div class="col-12 col-sm-4 col-md-3"
                    [ngStyle]="{'margin-bottom': '10px', 'outline': 'none', 'cursor': 'pointer'}" [routerLink]="['/driver', driver.id]">

                    <div class="driver_thumbnail"
                        [ngStyle]="{'background-image': 'url(' + (driver.profile_image?driver.profile_image:'../../assets/images/no-dp-2.png') + ')'}">
                    </div>

                    <div class="font-weight-normal driver_desc">
                        <div style="float: left;"><span> {{ driver.full_name }} |</span> <span
                                style="color: #f2773a;font-family: Lato-Regular;">
                                {{ driver.years_driving }} Years Driving</span></div>
                        <div style="float: right;color: #616161;font-size: 14px;">
                            <i class="fa fa-gbp" style=""
                                aria-hidden="true"> </i> {{ driver.hourly_rate }}/h 

                        </div>
                    </div>
                </div> -->
            </ng-container>
            <div class="alert alert-warning" role="alert" *ngIf="drivers?.length<1" style="height: 100%;">
                Unfortunately we dont have any drivers in your area! Dont worry, drivers are signing up everyday so check back again soon!
            </div>
            <div *ngIf='viewMoreUrl' class="view-more-button" (click)="viewMore()">
                VIEW MORE
            </div>
        </div>
        <div style="text-align: center;margin-top: 250px;min-height: 700px; " [hidden]="!loadingDrivers">

            <img src="../../assets/images/loader.gif"
                style="     margin-top: 10px;   width: 70px;padding-left: 20px;" />
        </div>

    </div>



</div>


<!-- <div id="selectCityModel" class="modal fadein" role="dialog">
    <div class="modal-dialog" style=" margin-top: 75px;">

        <div class="modal-content drivers">

            <div class="text-center" style=" height: 50px;margin-top: 0px;">
                <div style="margin-top: 6px;text-align: center;">
                    <div class="form-group" style="width: 100%;">
                        <div class="input-group" style="padding: 5px 10px;">
                            <select style="height: 10px;" id="city-select2" class="form-control" placeholder="City">
                            </select>
                        </div>
                    </div>
                </div>
            </div>

            <div style="text-align: center;"   [hidden]="(!this.loggedInUserLocation || !this.loggedInUserLocation.id || !this.loggedInUserLocation.city)">
                <button type="button" style="background-color: rgb(255 255 255);
                color: #377777;
                margin-top: -7px;
                text-decoration: underline;
                border-color: rgb(255 255 255);" 
                    class="btn btn-default"  (click)="removeLocation()"  data-dismiss="modal" >
                    {{'VIDEO_LANDING.remove_loc_filter' | translate }} </button>
            </div>
            <div class="modal-footer" style="display: none;">
                <button type="button" #closebutton class="btn btn-default" style="border: solid 1px #ccc;"
                    data-dismiss="modal" id="closeModal"> {{'VIDEO_LANDING.close' | translate }}</button>
            </div>
        </div>
    </div>
</div> -->


<div id="selectSortByModel" class="modal fadein" role="dialog">
    <div class="modal-dialog" style=" margin-top: 75px;">
        <!-- Modal content-->
        <div class="modal-content">
            <div class="text-center sortedBy" style=" height: 50px;margin-top: 0px;">
                <div data-dismiss="modal" style="cursor: pointer;" (click)="sortby('Popularity')">
                    <i [hidden]="sortedBy == 'RecentlyAdded'" class="fa fa-check" style="font-size: 16px;"
                        aria-hidden="true"> </i> {{'VIDEO_LANDING.popularity' | translate }}
                </div>
                <div data-dismiss="modal" style="cursor: pointer;" (click)="sortby('RecentlyAdded')">
                    <i [hidden]="sortedBy == 'Popularity'" class="fa fa-check" style="font-size: 16px;"
                        aria-hidden="true"> </i>  {{'VIDEO_LANDING.recently_added' | translate }}
                </div>
            </div>
            <div class="modal-footer" style="display: none;">
                <button type="button" #closebutton class="btn btn-default" style="border: solid 1px #ccc;"
                    data-dismiss="modal" id="closeModal">{{'VIDEO_LANDING.close' | translate }}</button>
            </div>
        </div>
    </div>
</div>