import { Component, OnInit, ChangeDetectorRef, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { JwtService } from '../services/jwt.service';
import { DataSharingService } from '../services/data-sharing.service';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CommonService } from '../services/common.service';
import { User } from '../interfaces/User';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  constructor(private ref: ChangeDetectorRef, public router: Router,


    private translate: TranslateService, private commonService: CommonService,
    private route: ActivatedRoute, private fb: FormBuilder, private jwtService: JwtService, private dataSharingService: DataSharingService,) {
    this.form = this.fb.group({
      username: ['', [Validators.required]],
      password: ['', [Validators.required]]

    });
  }
  loading: boolean = false;

  validationErrorsServer = {

    username: '',
    password: '',

  };

  form: FormGroup;
  fromPage: string;
  ngOnInit(): void {

    let from = this.route.snapshot.paramMap.get('from');
    if (from) {
      this.fromPage = from;
    }

  }
  onTouch() {
    this.form.markAsTouched();
    this.form.controls.username.markAsTouched();
    this.form.controls.password.markAsTouched();
  }

  facebook_access_token: string = "";
  facebook_id: string = "";
  onFbLogin($event) {


    this.facebook_access_token = $event.accessToken;
    this.facebook_id = $event.userID;

    this.ref.detectChanges();
    document.getElementById("fbLoginButton").click();
  }



  onFbLoginSubmit() {

    this.loading = true;
    this.jwtService.fbLogin(this.facebook_access_token, this.facebook_id)
      .subscribe(result => {
        if (result.activate) {
          this.otpEmail = result.email.toString();
          document.getElementById("openModalButton").click();
        }
        else {
          this.onLogginSuccess(result);
        }


      },
        error => {
          this.loading = false;
          //console.log(error);
          if (error.status == 422) {
            for (var i = 0; i < error.error.length; i++) {
              let element = error.error[i];

              if (element.field == 'username') {
                this.validationErrorsServer.username = element.message;
              }

              if (element.field == 'password') {
                this.validationErrorsServer.password = element.message;
              }

            }
          }


        }
      );



  }

  onSubmit() {
    //console.log(this.form.controls);
    this.loading = true;
    this.jwtService.login(this.form.controls.username.value, this.form.controls.password.value)
      .subscribe(result => {
        if (result.activate) {
          this.loading = false;
          this.otpEmail = result.email.toString();
          document.getElementById("openModalButton").click();
        }
        else {
          this.onLogginSuccess(result);
        }

      },
        error => {
          this.loading = false;
          //console.log(error);
          if (error.status == 422) {
            for (var i = 0; i < error.error.length; i++) {
              let element = error.error[i];

              if (element.field == 'username') {
                this.validationErrorsServer.username = element.message;
              }

              if (element.field == 'password') {
                this.validationErrorsServer.password = element.message;
              }

            }
          }

        }
      );




  }

  onLogginSuccess(result: User) {
    this.loading = false;
    this.jwtService.setLoggedInData(result);
    this.dataSharingService.isLoggedIn.next(true);
    if (this.fromPage) {
      this.router.navigate([this.fromPage]);
    } else { this.router.navigate(['drivers']); }
  }



//FORGOT PASSWORD


forgotPasswordEmail: string = "";
forgotPasswordLoading: Boolean = false;
forgotPasswordResultView: Boolean = false;
validationErrorsForgotPassword: string = "";

forgotPasswordResponseMessage = {
    status: "",
    text: ""
  }
  submitForgotPassword() {
    this.validationErrorsForgotPassword = ""
    if (!this.forgotPasswordEmail) {
      return;
    }
    this.forgotPasswordLoading = true;
    this.commonService.submitForgotPassword(this.forgotPasswordEmail)
      .subscribe(result => {
        this.forgotPasswordLoading = false;

        this.forgotPasswordResultView = true;
        this.forgotPasswordResponseMessage.status =  this.translate.instant('LOGIN.EMAIL_SENT');
        this.forgotPasswordResponseMessage.text = this.translate.instant('LOGIN.RESET_LINK_SENT');
      },
        error => {
          this.forgotPasswordLoading = false;
          //console.log(error);
          if (error.status == 429 || error.status == 500) {
            alert(this.translate.instant('COMMON.500'));
          }
          if (error.status == 422) {
            for (var i = 0; i < error.error.length; i++) {
              let element = error.error[i];
              if (element.field == 'code' || element.field == 'email') {
                this.validationErrorsForgotPassword = element.message;
              }
            }
          }



          else {
            this.validationErrorsOtp =  this.translate.instant('LOGIN.wrong_OTP');
          }

        }
      );
  }


//OTP
  @ViewChild('otp') otpinput: ElementRef;
  otpText: string = "";
  otpLoading: Boolean = false;
  otpResultView: Boolean = false;
  validationErrorsOtp: string = "";
  otpVerified: Boolean = false;
  otpEmail: string = "";
  otpResponseMessage = {
    status: "",
    text: ""
  }
  resetOTPVerification() {
    this.otpText = "";
    this.otpLoading = false;
    this.otpResultView = false;
    this.otpVerified = false;
    this.validationErrorsOtp = "";
    this.otpEmail = "";
    this.otpResponseMessage = {
      status: "",
      text: ""
    }
  }
  onOtpKeyup(event: KeyboardEvent) {

    if (this.otpText) {
      let stringOtp = this.otpText.toString();
      if (stringOtp.length >= 6) {
        this.otpText = stringOtp.substring(0, 6);
      }
    }


  }

  submitOtp() {


    this.validationErrorsOtp = ""
    if (!this.otpText || !this.otpEmail) {
      return;
    }
    this.otpLoading = true;
    this.commonService.submitEmailOtp(this.otpEmail, this.otpText)
      .subscribe(result => {
        this.otpLoading = false;

        this.otpResultView = true;
        this.otpResponseMessage.status =  this.translate.instant('LOGIN.success');
        this.otpResponseMessage.text = this.translate.instant('LOGIN.email_verified_success');


        setTimeout(() => {
          document.getElementById("closeOTPModelPopup").click();
          this.onLogginSuccess(result);
        }, 2000);




      },
        error => {
          this.otpLoading = false;
          //console.log(error);
          if (error.status == 429 || error.status == 500) {
            alert(this.translate.instant('COMMON.500'));

          }
          if (error.status == 422) {
            for (var i = 0; i < error.error.length; i++) {
              let element = error.error[i];



              if (element.field == 'code' || element.field == 'email') {
                this.validationErrorsOtp = element.message;
              }


            }
          }



          else {
            this.validationErrorsOtp =  this.translate.instant('LOGIN.wrong_OTP');
          }

        }
      );
  }



  resendOtpMsg="";
  resendOtp(){
    this.validationErrorsOtp = ""
    if (!this.otpEmail) {
      return;
    }
    this.otpLoading = true;
    this.commonService.resendOtp(this.otpEmail)
      .subscribe(result => {
        this.otpLoading = false;
  
        this.resendOtpMsg=this.translate.instant('LOGIN.RESEND_OTP_SUCCESS');
        
        setTimeout(() => {
          this.resendOtpMsg=""
        },  10000);
  
  
        
  
      },
        error => {
          this.otpLoading = false;
          //console.log(error);
          if (error.status == 429 || error.status == 500) {
            alert(this.translate.instant('COMMON.500'));
  
          }
          if (error.status == 422) {
            for (var i = 0; i < error.error.length; i++) {
              let element = error.error[i];
  
  
  
              if (element.field == 'code' || element.field == 'email') {
                this.validationErrorsOtp = element.message;
              }
  
  
            }
          }
  
  
        }
      );
  }

}
