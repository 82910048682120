<div class="invoice-landing" style=" margin-top: 65px;min-height: 700px;padding-top: 30px;;">

    <div class="container">
        <div [hidden]="loading" class="col-md-12"
            style="margin-top: 20px;background-color: #efefef;min-height: 170px; border: solid 1px #ccc;">

            <div class="row">

                <div class="col-md-6" style="text-align: center;">
                    <div
                        style="display: none; height: 20px;color: #a29c9c;float: right;font-size: 31px;margin-right: 20px;">
                        <span><i class="fa fa-cog" aria-hidden="true"></i></span>
                    </div>
                    <div class="col-12" style="margin: 10px;font-family: Lato-Regular;">
                        {{company.company_name}}
                    </div>
                    <div style="width: 100%;float: left;">
                        <div [hidden]="!company.company_logo" style="
                                background-image: url({{company.company_logo}});
                                height: 100px;   
                                margin: 10px auto;
                                width: 100px;
                                background-size: cover;
                                border-radius: 63px;
                                background-position: center;
                                ">
                        </div>
                        <img [hidden]="company.company_logo"
                            style=" width: 100px;margin: 10px auto;" src="../../assets/images/no-dp-2.png" />
                    </div>


                </div>
                <div class="col-md-6" style="text-align: center;">


                    <div class="form-group" style="margin-top:20px;">
                        <a class="btn btn-success btn-md"
                            style="padding-left: 40px;padding-right: 40px;background-color: #337777;border-color: #337777;width: 200px;"
                            [routerLink]="['/manage-booking']">Go to Bookings</a>
                    </div>

                    <div class="form-group" style="margin-top:20px;">
                        <a class="btn btn-success btn-md"
                            style="padding-left: 40px;padding-right: 40px;background-color: #337777;border-color: #337777;width: 200px;"
                            [routerLink]="['/profile']">Go to Profile</a>
                    </div>

                </div>

            </div>

        </div>

        <div  [hidden]="loading" class="row col-md-12" style="    margin: 20px 0 35px 0;
        padding: 0;">
            <h2 style="font-size: 25px;color: #337777;font-family: Lato-Regular;text-decoration: underline;width: 100%;">Invoices</h2>
            <ng-container *ngFor="let invoice of invoices">
                <div style="border: dashed 1px #ccc;background-color: #fdfdfd;padding: 20px;-top: 27px;" class="col-md-12">
                    <div class="row" style="margin-top: 25px;">
                        <div class="col-12 col-sm-1 col-lg-1"> 
                            <label for="id" class="control-label" style="font-family: Lato-Regular;">#ID</label>
                            <p class="form-control-static">{{ invoice.id }}</p>
                        </div>
                        
                        <div class="col-12 col-sm-2 col-lg-2"> 
                            <label for="created_at" class="control-label" style="font-family: Lato-Regular;">Invoice Date</label>
                            <p class="form-control-static">{{ invoice.created_at | date : 'mediumDate' }}</p>
                        </div>

                        <div class="col-12 col-sm-2 col-lg-2"> 
                            <label for="pay_by_date" class="control-label" style="font-family: Lato-Regular;">Pay By Date</label>
                            <p class="form-control-static">{{ invoice.pay_by_date | date : 'mediumDate' }}</p>
                        </div>
                        <div class="col-12 col-sm-5 col-lg-5"> 
                            <div class="row">
                                <div class="col-12 col-sm-4 col-lg-4"> 
                                    <label for="total_amount" class="control-label" style="font-family: Lato-Regular;">Amount</label>
                                    <p class="form-control-static"><i class="fa fa-gbp" style="font-size: 14px;"></i> {{ invoice.total_amount }}</p>
                                </div>

                                <div class="col-12 col-sm-4 col-lg-4"> 
                                    <label for="total_amount" class="control-label" style="font-family: Lato-Regular;">VAT Amount</label>
                                    <p class="form-control-static"><i class="fa fa-gbp" style="font-size: 14px;"></i> {{ invoice.vat_amount }}</p>
                                </div>

                                <div class="col-12 col-sm-4 col-lg-4"> 
                                    <label for="total_amount" class="control-label" style="font-family: Lato-Regular;">Toal Amount</label>
                                    <p class="form-control-static"><i class="fa fa-gbp" style="font-size: 14px;"></i> {{ invoice.total_sum }}</p>
                                </div>
                            </div>
                        </div>

                        <div class="col-12 col-sm-2 col-lg-2"> 
                            <label for="invoice_status" class="control-label" style="font-family: Lato-Regular;">Status</label>
                            <p class="form-control-static">{{ invoice.invoice_status }}</p>
                            
                        </div>

                        <div class="col-12 col-sm-1 col-lg-1"> 
                        	<label for="invoice_status" class="control-label" style="font-family: Lato-Regular;"> </label>
                        	<p><button class="btn btn-sm" style="color: #fff; background-color: #337777;border-color: #337777;" [routerLink]="['/invoice', invoice.id]" >Detail</button></p>
                        </div>
                    </div>
                    <hr>
                </div>
            </ng-container>
            <div *ngIf='invoices.length == 0' style="margin-top: 30px;">
                No invoices to show
             </div>
             <div *ngIf='viewMoreUrl' class="view-more-button" (click)="viewMore()">
                View More
             </div>
        </div>
        <div [hidden]="!loading" style="width: 100%;background-color: #efefef;min-height: 204px;margin-top: 20px;">
            <div class="ion-padding" style="text-align: center;min-height: 100px;padding-top: 50px;">
                <img src="../../assets/images/loader.gif"
                    style=" margin-top: 10px;   width: 70px;padding-left: 20px;" />
            </div>
        </div>
    </div>
</div>
