import { Component, OnInit, ChangeDetectorRef, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { passValidator } from '../validator';
import { JwtService } from '../services/jwt.service';
import { DataSharingService } from '../services/data-sharing.service'
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CommonService } from '../services/common.service';
@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {

 
  loading: boolean = false;
  success: boolean = false;
 
  validationErrorsServer = {
    password: '',
    cnfpass: ''
  };

  code:string="";
  ngOnInit(): void {
    this.code = this.route.snapshot.paramMap.get('code');
  }
  form: FormGroup;
  constructor(private ref: ChangeDetectorRef,
    private translate: TranslateService, private commonService: CommonService,
    private route: ActivatedRoute, 
    public router: Router, private dataSharingService: DataSharingService, private fb: FormBuilder, private jwtService: JwtService) {
    this.form = this.fb.group({
      password: [''],
      cnfpass: ['', passValidator]
    });


    this.form.controls.password.valueChanges
      .subscribe(
        x => this.form.controls.cnfpass.updateValueAndValidity()
      )
  }


  onTouch() {
    this.form.markAsTouched();
    this.form.controls.username.markAsTouched();
    this.form.controls.password.markAsTouched();
    this.form.controls.email.markAsTouched();
  }

  onSubmit() {
    //console.log(this.form.controls);
    this.validationErrorsServer = {
    
      password: '',
      cnfpass: ''
    };

    if ((this.form.controls.cnfpass.value || this.form.controls.password.value) && this.form.controls.cnfpass.value != this.form.controls.password.value) {
      this.validationErrorsServer.cnfpass = this.translate.instant('SIGNUP.confirm_password_not_matching');
      return;
    }

    this.loading = true;
    this.jwtService.resetPassword(this.form.controls.password.value, this.code)
      .subscribe(result => {

        this.loading = false;
        this.success =true;

      },
        error => {
          this.loading = false;
          //console.log(error);
          if (error.status == 422) {
            for (var i = 0; i < error.error.length; i++) {
              let element = error.error[i];

             
              if (element.field == 'password'||element.field == 'code') {
                this.validationErrorsServer.password = element.message;
              }

            }
          }

        }
      );



    this.form.markAsTouched();
  }

}
