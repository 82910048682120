<div class="map-wrapper-container">
<div id="policies-component" class="component_inner container policies-container">
<main>
    <style>
    	h2 {color:#e25b89;}

    	.unordered-list {
    		margin: 0 0 15px;
    		list-style: none;
    	}
    </style>

    <h3>Website</h3>
    <ul class="unordered-list">
    	<li>
    		<a href="policies#termsConditions">Terms and Conditions</a>
    	</li>
    	<li>
    		<a href="policies#privacyPolicy">Privacy Policy</a>
    	</li>
    	<li>
    		<a href="policies#yourSecurity">Your security</a>
    	</li>
    	<li>
    		<a href="policies#cookies">Cookies</a>
    	</li>
    </ul>

    <!-- <h3>Mobile app</h3>
    <ul class="unordered-list">
    	<li>
    		<a href="policies#mobileAppTermsConditions">Terms and Conditions</a>
    	</li>
    	<li>
    		<a href="policies#mobileAppPrivacyPolicy">Privacy Policy</a>
    	</li>
    </ul> -->

    <div class="inner-content">
        <h1>Policies</h1>

        <h2 id="termsConditions">Website Terms and Conditions</h2>
        <p>The following terms and conditions apply if you access our services via a web browser. To view the terms and conditions for the Mobile App please <a href="policies#mobileAppTermsConditions">go here</a></p>
        <p>Each time you access or use <a href="https://www.drivers2day.co.uk/" target="_blank">www.drivers2day.co.uk</a> and/or our mobile device application (collectively the "Website"), you are deemed to accept these terms and conditions.</p>
        <p>"we" means DRIVERS2DAY ONLINE LTD, and "our" shall be construed accordingly. "you" means the person,driver, firm, company or organisation browsing and/or using the Website, and "your" shall be construed accordingly. "Drivers2Day Family" means all companies connected with us. A company is connected with us if it is: (i) a subsidiary or holding company of us; (ii) controlled by the same person(s) who control us or our holding company; (iii) a subsidiary or holding company of any company in (i) or (ii) above; or (iv) in the same group as any company under (i), (ii) or (iii) above. "subsidiary" and "holding company" shall be as defined in section 1159 of the Companies Act 2006. The term "control" shall have the same meaning as defined in Section 416 of the Income and Corporation Taxes Act 1988. Two companies are in the same group if they share the same ultimate holding company.</p>

        <h3>Interruptions and Omissions in Service</h3>
        <p>Whilst we try to ensure that the standard of the Website remains high and to maintain the continuity of it, the internet is not an inherently stable medium, and errors, omissions, interruptions of service and delays may occur at any time. We do not accept any liability arising from any such errors, omissions, interruptions or delays or any ongoing obligation or responsibility to operate the Website (or any particular part of it) or to provide the service offered on the Website. We may vary the specification of this site from time to time without notice.</p>

        <h3>Links to other Sites</h3>
        <p>On this site you will be offered automatic links to other sites which we hope will be of interest to you. We do not accept any responsibility for or liability in respect of the content of those sites, the owners of which do not necessarily have any connection, commercial or otherwise, with us. Using automatic links to gain access to such sites is entirely at your own risk.</p>

        <h3>Information on this Site</h3>
        <p>Whilst we make every effort to ensure that the information on our Website is accurate and complete, some of the information is supplied to us by third parties and we are not able to check the accuracy or completeness of that information. We do not accept any liability arising from any inaccuracy or omission in any of the information on our Website or any liability in respect of information on the Website supplied by you, any other website user or any other person.</p>

        <h3>Your Use of this Site</h3>
        <p>You may only use the Website for lawful purposes when seeking employment or when recruiting drivers. You must not under any circumstances seek to undermine the security of the Website or any information submitted to or available through it. In particular, but without limitation, you must not seek to access, alter or delete any information to which you do not have authorised access, seek to overload the system via spamming or flooding, take any action or use any device, routine or software to crash, delay, damage or otherwise interfere with the operation of the Website or attempt to decipher, disassemble or modify any of the software, coding or information comprised in the Website.</p>
        <p>You are solely responsible for any information submitted by you to the Website. You are responsible for ensuring that all information supplied by you is true, accurate, up-to-date and not misleading or likely to mislead or deceive and that it is not discriminatory, obscene, offensive, defamatory or otherwise illegal, unlawful or in breach of any applicable legislation, regulations, guidelines or codes of practice or the copyright, trademark or other intellectual property rights of any person in any jurisdiction. You are also responsible for ensuring that all information, data and files are free of viruses or other routines or engines that may damage or interfere with any system or data prior to being submitted to the Website. We reserve the right to remove any information supplied by you from the Website at our sole discretion, at any time and for any reason without being required to give any explanation.</p>

        <h3>Information Submitted by You</h3>
        <p>We will use information supplied by you to aid the employment process and associated administrative functions as well as to facilitate the staffing of drivers made through this website. We will process any data which you provide in completing the online registration or application forms and any further forms, assessments or personal details which you complete or provide to us when using the Website in accordance with relevant data protection legislation. We explain more about your personal data in our Privacy Policy.</p>
        <p>Please note that all businesses have agreed to our Terms and Conditions and should they be found to be in breach of the Terms and Conditions, they will be prevented from using our services. <a href="./assets/terms-of-business.pdf" target="_blank">Further details of our Business terms and conditions</a>.</p>
        
        <h3>Terms of Business</h3>
        <p>Recruitment:  All prospective drivers and businesses will be provided with applicable terms and conditions before they can access the services we make available through the Website.</p>
        <!-- <p>Sqaure are an independent payment provider and Drivers2Day has no influence over whether your payment with Square is accepted or not. If you have any trouble with your order, please contact square Customer Service.</p> -->

        <h3>Content Rights</h3>
        <p>The rights in material on the Website are protected by international copyright, software and trademark laws and you agree to use the Website in a way which does not infringe these rights. You may copy material on the Website for your own private or domestic purposes, but no copying for any commercial or business use is permitted.</p>

        <h3>Security and Passwords</h3>
        <p>In order to register with the Website and to sign in when you visit the Website, you will need to use a username(email) and password. You are solely responsible for the security and proper use of your password, which should be kept confidential at all times and not disclosed to any other person. You must notify us immediately if you believe that your password is known to someone else or if it may be used in an unauthorised way. We accept no liability for any unauthorised or improper use or disclosure of any password.</p>

        <h3>Termination</h3>
        <p>We may terminate your registration and/or deny you access to the Website or any part of it (including any services or information available on or through the Website) at any time in our absolute discretion and without any explanation or notification.</p>

        <h3>Liability</h3>
        <p>We accept no liability for any loss (whether direct or indirect, for loss of business, revenue or profits, wasted expenditure, corruption or destruction of data or for any other indirect or consequential loss whatsoever) arising from your use of the Website and we hereby exclude any such liability, whether in contract, tort (including for negligence) or otherwise. We hereby exclude all representations, warranties and conditions relating to the Website and your use of it to the maximum extent permitted by law.</p>
        <p>You agree to indemnify us and keep us indemnified against all costs, expenses, claims, losses, liabilities or proceedings arising from use or misuse by you of the Website.</p>
        <p>You must notify us immediately if anyone makes or threatens to make any claim against you relating to your use of the Website.</p>

        <h3>Choice of Law and Jurisdiction</h3>
        <p>The use of the Website and any agreements entered into through the Website are to be governed by and construed in accordance with English law. The courts of England are to have exclusive jurisdiction to settle any dispute arising out of or in connection with the use of the Website or any agreement made through the Website.</p>
        <!-- <p>Some of the services offered through the Website may not be lawful or may otherwise not be permitted in certain countries outside the United Kingdom. If you attempt to order, receive, purchase or otherwise benefit from any such goods or services, we do not accept any liability for any losses suffered by you in using the Website which you would not have suffered had you been accessing the Website as a United Kingdom resident (including as a result of us being prevented from dealing with any application or enquiry by any law, regulation or other ruling applicable in any country).</p>
 -->
        <h3>Modern Slavery Act statement</h3>
        <p>We are committed to ensuring that there is no modern slavery or human trafficking in our supply chains or in any part of our business.</p>

        <h3>Changes to Terms and Conditions and Invalidity</h3>
        <p>These terms and conditions may be changed by us at any time. You will be deemed to accept the terms and conditions (as amended) when you next use the Website following any amendment.</p>
        <p>If any provision of these terms and conditions is held to be invalid by a court of competent jurisdiction, such invalidity shall not affect the validity of the remaining provisions, which shall remain in full force and effect.</p>

        <h3>Registered Office</h3>
        <ul class="unordered-list">
            <li>DRIVERS2DAY ONLINE LTD</li>
            <li>124 City Road</li>
            <li>London</li>
            <li>England</li>
            <li>EC1V 2NX</li>
        </ul>
        <p>Company Number 15101133. Registered in the UK.</p>

        <h2 id="privacyPolicy">Website Privacy policy</h2>
        <p>The following privacy policy applies if you access our services via a web browser.<!-- To view the privacy policy for the Mobile App please <a href="policies#mobileAppPrivacyPolicy">go here</a>.--></p>
        <p><a href="https://www.drivers2day.co.uk/">www.Drivers2day.co.uk</a> ("the Website") is a drivers listing site operated by Drivers2Day Limited.</p>
        <p>This privacy policy explains how we use the personal information we collect about you when you use the Website.</p>
        <p>If you register on the Website as a driver or business and provide us with personal data, we will process such personal data on the basis that it is necessary to do so in order to perform the contract you enter into with us. We may also contact you via email to invite you to review any services and/or employment you received from us in order to collect your feedback and improve our services and products (the "Purpose"). </p>

        <h3>What we do</h3>
        <p>Registration with enable you the use of the Website for Drivers to find jobs and those looking drivers. </p>
        <p>If you do not want to receive that information from us, we give you the opportunity to opt out both as part of the registration process and at any time by updating your contact preferences in your account or by clicking on the "unsubscribe" link within those emails.</p>

        <h3>Lawful basis for processing of your personal data</h3>
        <p>Legitimate Interest:</p>
        <p>We process your personal data when it is in our legitimate interests to do this in order to provide our services. Our legitimate interests include (but are not limited to) the following: introducing of job for drivers and hire a driver; the measures we take in order to ensure the security and integrity of the services we provide; promoting, marketing and advertising of our services; analysis of our usersâ€™ behaviour, activities, preferences and requirements; understanding of existing services and the development of new products and services; the handling of customer contacts, queries and complaints; making available the services through the Website to our users (drivers and those intending to hire drivers or those just expressing an interest in learning more about the products offered by us; the sharing of personal data with our third party suppliers (e.g. communication providers) who act as our data processors to enable us to provide our services; the provision of your contact details, name, IP Address, country of residence and payment confirmation (if relevant) to any service providers through the Website.</p>
        <p>Consent:</p>
        <p>On some occasions, we may process your personal data with your consent. When your consent is required, we will clearly indicate this (e.g. when we share your licence with external review partners). You have the right to withdraw your consent at any time. When you do so, we will cease to process such data.</p>
        <p>The processing is required under legal obligation:</p>
        <p>We may process your personal data to comply with a legal or regulatory obligation e.g. detecting, preventing or investigating crime or fraud including working with law enforcement agencies.</p>

        <h3>What Information do we collect about you?</h3>
        <p>The personal information we collect about you may include the following:</p>
        <ul>
            <li>The information set out in your registration,</li>
            <li>Information to enable us to provide our services e.g. your date of birth and location, your licence infromation and the kind of work you are interested in,</li>
            <li>General identification and contact information e.g. your name, IP address, address, email and telephone details,</li>
            <li>Marketing preferences,</li>
            <li>Statistical information e.g. aggregate statistical information about site visits,</li>
        </ul>
        <p>We collect the personal data about you when you register with the Website. We do so in order to provide you with the services that are available through the Website as well as for administrative purposes. If you decide to upload your licence information, CPC card,DIGI card, SLT certificate or other similar document or information we will keep that information for as long as you want to keep your account with the Website.</p>
        

        <h3>What do we do with your personal data?</h3>
        <p>Drivers:</p>
        <p>By registering with the Website, it is understood that drivers are actively soliciting contact from prospective businesses about opportunities which match the drivers interest which may improve a drivers emplyemnt prospects. If you are a driver, we will therefore make your personal data available to businesses who use the Website to find suitable drivers for specific roles that they are seeking to fill.</p>
        <p>Although the primary purpose of the Website is to allow drivers, prospective businesses to find each other, drivers have the ability to control the extent to which your personal data is shared with other recruiters and prospective employers through the contact preferences section of the "your account" section on the Website.</p>
        <h3>Marketing</h3>
        <p>From time to time, we would like to tell you more about the other goods and services available through the Website. We will do this by sending you marketing emails if you subscribe to them when you sign up to the website (by ticking the relevant box where indicated). You can subsequently unsubscribe by clicking the unsubscribe link in every subsequent marketing email.</p>
        <p>You can also amend the contact preferences in your account at any time to stop us from contacting you in connection with the recruitment services, for which you register when you set up your account.</p>
        
        <h3>Data Sharing and Transfers</h3>
        <p>Drivers:</p>
        <p>As part of the recruitment services we provide to help you find a suitable job, we may make your personal data available to third parties such as recruiters, direct employers and their agents or other business partners. We attempt to verify the identity of these third parties when they sign up with us, however we do not make any warranties as to their authenticity. This would normally happen when business book through the Website or when a recruiter search for drivers as part of the services they purchase from us.  These parties will also be data controllers of your personal data. The amount of personal data we share with these parties depends on the options you select when you create or update your account.</p>
        
        <p>All users</p>
        <p>We may share your personal data with third party service providers who assist us in carrying out our business activities. These third parties act as our data processors and are bound by strict contractual provisions to only process your personal data under our instructions and for the limited purposes we permit them. These third parties include (but are not limited to) our accountants; auditors; external legal advisors; external IT providers; support and hosting service providers; printing, advertising, marketing and market research and analysis service providers, technical engineers, data storage and cloud providers and all other outsourced service providers (such as communication providers) that assist us in the provision of our services.</p>
        <p>We may also share your personal data with courts, tribunals, regulators and other governmental and public authorities (such as the police or tax authorities) to: (i) allow us to pursue legal remedies, (ii) to enforce our terms and conditions and protect our operations, (iii) to comply with legal process, (iv) to comply with applicable law, and (v) to respond to requests from public and governmental bodies.</p>
        <p>Your personal data is stored on our servers which are in the European Economic Area.</p>

        <h3>Access to your information and correction</h3>
        <p>We want to make sure that your information is accurate and up-to-date and will delete or amend any information that you think is inaccurate. Should you wish to delete your account please email us <a href="mailto:contactus@drivers2day.co.uk">contactus@drivers2day.co.uk</a>. Amendments to your account can be made online by <a href="https://www.drivers2day.co.uk/profile">signing in to your account</a> or by <a href="mailto:contactus@drivers2day.co.uk">contacting us</a>.</p>
        <p>We aim to provide you with useful services. However, you have the right to close your account at any time and have us delete all of your personal information unless we are in the process of responding to any complaint that you make.</p>

        <h3>Cookies</h3>
        <p>Cookies are text files placed on your computer to collect standard internet log and visitor behaviour information. This information is used to track visitor use of the Website, to compile statistical reports on Website activity and to help us make recommendations that are relevant to you.</p>
        <p>We also use third party advertising companies to serve ads when you visit the Website. Those companies may use information about your visits to this and other websites in order to provide you with advertisements about other goods and services that may be of interest to you.</p>
        <p>If you would like more information about this practice, including how to prevent it, please visit <a target="_blank" href="https://www.aboutcookies.org/">www.aboutcookies.org</a>, <a target="_blank" href="https://www.allaboutcookies.org/">www.allaboutcookies.org</a> or <a target="_blank" href="https://optout.networkadvertising.org/">optout.networkadvertising.org</a></p>
        <p>More information about all of the cookies on the Website is available on our <a href="https://www.drivers2day.co.uk/policies#cookies">cookies page</a>.</p>

        <h3>Other websites</h3>
        <p>Our Website contains links to other websites. This privacy policy only applies to this Website, so when you link to other websites you should review their own privacy policies.</p>

        <h3>Changes to our Privacy Policy</h3>
        <p>We keep our privacy policy under regular review and we place any updates on this web page. This policy was last updated on 01 Aug 2021.</p>

        <h3>How to contact us</h3>
        <p>Please contact us if you have any questions about our privacy policy or information we hold about you:</p>
        <p>by email <a href="mailto:contactus@drivers2day.co.uk">contactus@drivers2day.co.uk</a></p>

        <h3>How do you remove your name from the database?</h3>
        <p>You can email<a href="mailto:contactus@drivers2day.co.uk">contactus@drivers2day.co.uk</a> to request for your account to be closed at any time, which will be confirmed by Drivers2day.</p>

        <h3>How can you obtain information held about you?</h3>
        <p>You have the right to request a copy of the information that we hold about you. If you would like a copy of some or all of your personal information please email us at <a href="mailto:contactus@drivers2day.co.uk">contactus@drivers2day.co.uk</a>.</p>

        <h3>Complaints</h3>
        <p>If you would like to make a complaint about how your information is being used by us, or how your complaint has been handled, you have the right to lodge a complaint directly with the Information Commissionerâ€™s Office or our Data Protection Officer. You can write to us at <a href="mailto:contactus@drivers2day.co.uk">contactus@drivers2day.co.uk</a>, or to the Information Commissionerâ€™s Office at: Wycliffe House, Water Lane, Wilmslow, Cheshire, SK9 5AF (or by email to casework@ico.org,uk).</p>

        <h3>Registered Office</h3>
        <ul class="unordered-list">
            <li>DRIVERS2DAY ONLINE LTD</li>
            <li>124 City Road</li>
            <li>London</li>
            <li>England</li>
            <li>EC1V 2NX</li>
        </ul>
        <p>Company Number 15101133. Registered in the UK.</p>

        <h2 id="yourSecurity">Your security on Drivers2day.co.uk</h2>
        <p>At Drivers2day.co.uk, we're committed to helping you find the right job in a safe and secure environment.</p>
        <p>On these pages, you can learn more about some of the most common internet security threats, what you can do to protect yourself, and what we do to ensure your details remain secure.</p>

        <h3>Common security threats</h3>

        <h4>Phishing:</h4>
        <p>Phishing emails are an increasingly common method used to try and gain access to personal details in order to carry out fraud or <a href="https://www.drivers2day.co.uk/policies#identityTheft">identity theft</a>.</p>
        <p>Typically, a phishing email will claim to have been sent by a reputable organisation and ask that you respond to the email with your username and/or password or that you enter your sign in details over a 'secure' connection.</p>
        <p>Whilst phishing emails can seem very professional, there are usually a number of clues to alert you to the fraudulent nature of the emails. When reading an email claiming to be sent from Drivers2day.co.uk, you should always keep the following questions in mind:</p>
        <ul>
            <li><span class="text-medium">Are you being asked to provide your password?</span> Drivers2day.co.uk will <span class="text-medium">never</span> ask you to provide your password in response to an email or phone call.</li>
            <li><span class="text-medium">Are there spelling and grammar mistakes in the email?</span> Phishing emails often originate from outside the UK and spelling and grammar mistakes are common.</li>
            <li><span class="text-medium">Are you being asked to download something?</span> If you don't recognise a file, don't download it. All Drivers2day.co.uk account details are stored online, so we'll never ask you to download anything to use the service.</li>
            <li><span class="text-medium">Are you being threatened with losing access to your account?</span> Phishing emails sometimes make it seem like you'll lose access to your account unless you download something or provide your sign in details. Drivers2day.co.uk will never ask you to do anything like this in order to keep your account open.</li>
        </ul>
        <p>If you're suspicious of any email you receive from Drivers2day.co.uk, please contact us <a href="mailto:contactus@drivers2day.co.uk">contactus@drivers2day.co.uk</a> with the subject line of the email, and the email address it was sent from.</p>

        <h4>Spoofing:</h4>
        <p>Spoofing occurs when a criminal creates an exact copy of a website in order to make a fraudulent company look legitimate. A spoofed website will usually look exactly the same as the website of a legitimate company, but with a slightly different web address (e.g., '.biz' instead of '.com' or '.co.uk'). If you're suspicious of any website you're directed to as a result of your dealings with Drivers2day.co.uk, please contact us <a href="mailto:contactus@drivers2day.co.uk">contactus@drivers2day.co.uk</a> and we'll investigate.</p>

        <h4>Identity theft</h4>
        <p>Identity theft occurs when an individual steals personal details from someone else, and uses the information to fraudulently impersonate that individual. Victims of identity theft often find that their details are used to open bank accounts and obtain credit in their name.</p>
        <p>Identity theft can only take place where a criminal has been able to gain access to enough personal details to create false documents in another person's name. In order to avoid becoming a victim of identity theft:</p>
        <ul>
            <li>Never give out sensitive personal information such as your bank details, date of birth, National Insurance number or a copy of your passport in relation to an email</li>
            <li>Look out for spelling and grammar mistakes in emails you receive</li>
            <li>Never give out your sign in details in response to an email</li>
        </ul>
        <p>If you suspect that you've been a victim of identity theft, you should contact the police as soon as possible. Details on how to report a fraud can be found <a href="http://www.actionfraud.police.uk/" target="_blank">here</a>.</p>

        <h3>What you can do to protect yourself</h3>

        <h4>Account security</h4>
        <p>Online security risks can be minimised simply by ensuring that your password is always kept secure. We recommend you follow the password security advice below:</p>
        <ul>
            <li>Always use a password which is personal and does not contain your name, email address or the word 'password'</li>
            <li>Ensure your password contains both letters and numbers</li>
            <li>Never give your password out to anyone - we will <span class="text-medium">never</span> ask you for your password</li>
            <li>Change your password at least once every three months</li>
            <li>Take a moment to memorise your password so you don't need to write it down</li>
            <li>Try and use a different password for each different website you use</li>
        </ul>

        <h4>General advice</h4>
        <p>It's always important to be mindful of your security and safety online. Look at our top 10 tips to staying safe online to make sure you're not putting yourself at risk.</p>
        <ul>
            <li><span class="text-medium">If it looks too good to be true, it probably is.</span> Be wary of offers for jobs paying a high salary for working from home.</li>
            <li><span class="text-medium">Never provide personal details when looking for a job.</span> There's no need for a recruiter to request your bank details before you've been through the interview process and been offered a role.</li>
            <li><span class="text-medium">Never agree to process funds through your personal bank account on behalf of a company.</span> Any legitimate company will have corporate accounts, so would never ask you to do this.</li>
            <li><span class="text-medium">Never provide your personal details over a non-secure connection.</span> If you're being asked to enter login credentials, look for the "https://" at the beginning of the website address.</li>
            <li><span class="text-medium">Don't put personal information on your CV.</span> Recruiters don't need to see your date of birth or national insurance number on your CV.</li>
            <li><span class="text-medium">Look out for bad spelling and grammar.</span> Email scams often originate from outside the UK so may not contain perfect English.</li>
            <li><span class="text-medium">Never provide your username and password in an email purporting to be from Drivers2day.co.uk.</span> We'll never ask you to provide this information in an email.</li>
            <li><span class="text-medium">Be wary of recruiters with no personal contact.</span> It's very unlikely that a recruiter would offer you a job without a telephone and/or face to face interview.</li>
            <li><span class="text-medium">Keep your password secure and up-to-date.</span> Passwords should be changed at least once every three months and shouldn't contain your name, email address or the word 'password'.</li>
            <li><span class="text-medium">If in doubt, report it.</span> If you're suspicious about any contact you receive related to Drivers2day.co.uk, <a href="mailto:contactus@drivers2day.co.uk">contact us</a> and our Customer Support Team will investigate.</li>
        </ul>
        <p>If you think you have been the victim of online fraud, we recommend you report it to Action Fraud, the national fraud and internet crime reporting centre, who will be able to advise you further.</p>
        <p>You can contact Action Fraud on 0300 123 2040, or visit their website for advice <a href="http://www.actionfraud.police.uk/" target="_blank">http://www.actionfraud.police.uk/</a></p>

        <h4>What we're doing to protect you</h4>
        <p>We've partnered with several industry leaders to ensure you receive the most secure levels of service possible whilst using the site.</p>

        <h4>Encryption</h4>
        <p>To protect your personal information Drivers2day.co.uk uses industry standards such as TLS (Transport Layer Security) encryption to encrypt and secure your data.</p>

        <h4>Virus protection</h4>
        <p>Drivers2day.co.uk automatically scans, detects, and quarantines new viruses, ensuring that all documents uploaded to or downloaded from Drivers2day.co.uk, are safe and virus-free.</p>

        <h2 id="cookies">Drivers2day.co.uk cookies</h2>

        <h3>What are cookies?</h3>
        <p>A cookie is a piece of information in the form of a very small text file that is placed on an internet user's computer. It is generated by a web page server (which is basically the computer that operates the web site) and can be used by that server whenever the user visits the site. A cookie can be thought of as an internet user's identification card, which tells a web site when the user has returned.</p>
        <p>Cookies can't harm your computer and we don't store any personally identifiable information about you on any of our cookies.</p>

        <h3>Why do we use cookies on Drivers2day.co.uk?</h3>
        <p>Drivers2day.co.uk uses two types of cookies: cookies set by Drivers2day.co.uk and cookies set by third parties (i.e. other websites or services).</p>
        <p>Drivers2day.co.uk cookies enable us to keep you signed in to your account throughout your visit and to tailor the information displayed on the site to your preferences.</p>
        <p>We also use third party cookies to help deliver best services on Drivers2day.co.uk, to integrate content with social networks, such as Facebook and Google+, and to better understand how the features and functions of the site are used in order that we can improve our service.</p>
        <p>If you would like more information about the cookies we use, please see below.</p>

        <h3>What cookies do we use on Drivers2day.co.uk?</h3>
        <p>Below is a list of the main cookies set by Drivers2day.co.uk, and what each is used for:</p>
        <table class="cookies-table" id="Drivers2dayCoUkCookies">
            <tbody>
                <tr>
                    <th class="left-col">Cookie name</th>
                    <th class="right-col">Purpose</th>
                </tr>
                <tr>
                    <td class="left-col">Drivers2day.co.ukV1Auth</td>
                    <td class="right-col">Keeps you signed in to your account once you've signed in</td>
                </tr>
                
                <tr>
                    <td class="left-col">cookieLegislation</td>
                    <td class="right-col">Remembers we have shown users the Cookie banner</td>
                </tr>
                <!-- <tr>
                    <td class="left-col">YiiApplicationCookie</td>
                    <td class="right-col">Providers site sign in cookie</td>
                </tr> -->
            </tbody>
        </table>

        <h3>Cookies set by third parties</h3>
        <p>Drivers2day.co.uk works with a number of third parties to provide services which help us to keep the website tailored to our users' needs. Some of these partners use cookies to help them deliver these services. A list of third party cookies can be found below:</p>
        <table class="cookies-table" id="thirdPartyCookies">
            <tbody>
                <tr>
                    <th class="left-col">Cookie name</th>
                    <th class="right-col">Purpose</th>
                </tr>
                <tr>
                    <td class="left-col">Google Analytics </td>
                    <td class="right-col">This website uses Google Analytics, a web analytics service provided by Google, Inc. ("Google"). Google Analytics uses "cookies", which are text files placed on your computer to help the website analyse how visitors use the site. The information generated by the cookie about your use of the website (including your IP address) will be transmitted to and stored by Google on servers in the United States . Google will use this information for the purpose of evaluating your use of the website, compiling reports on website activity for website operators and providing other services relating to website activity and internet usage. Google may also transfer this information to third parties where required to do so by law, or where such third parties process the information on Google's behalf. Google will not associate your IP address with any other data held by Google. You may refuse the use of cookies by selecting the appropriate settings on your browser, however please note that if you do this you may not be able to use the full functionality of this website. By using this website, you consent to the processing of data about you by Google in the manner and for the purposes set out above. <span class="text-medium">To opt out of Google Analytics please go to the following link:</span> <a href="https://tools.google.com/dlpage/gaoptout" target="_blank" title="link opens a new window">https://tools.google.com/dlpage/gaoptout</a></td>
                </tr>
                <!--<tr>
                    <td class="left-col">Twitter</td>
                    <td class="right-col">If you use a 'Tweet' link on Drivers2day.co.uk to share content on twitter, twitter sets a cookie to remember that you've already shared this information. <span class="text-medium">To reject or delete these cookies:</span> <a href="http://twitter.com/privacy" target="_blank" title="link opens a new window">http://twitter.com/privacy</a>. Twitter tags allow us to show relevant adverts on Twitter to users that have previously visited our website. <span class="text-medium">To reject these cookies:</span> <a href="https://support.twitter.com/articles/20170405%20" target="_blank" title="link opens a new window">https://support.twitter.com/articles/20170405%20</a></td>
                </tr>
                <tr>
                    <td class="left-col">Google reCAPTCHA<br>(Google Ireland Limited)</td>
                    <td class="right-col">
                        We use Google reCAPTCHA which helps us detect abusive traffic without any user friction. This type of service collects and analyses your device and behaviour data, potentially containing your personal data, with the purpose of filtering it from parts of traffic, messages and content that are recognised as SPAM. In doing so, Google reCAPTCHA enables us to provide services through the Website, comply with our legal obligations, respond to enforcement requests, protect our and your respective rights and interests, and detect any malicious or fraudulent activity.
                        <br><br>
                        Google reCAPTCHA is a SPAM protection service provided by Google Ireland Limited. The use of reCAPTCHA is subject to the Google privacy policy and terms of use. Personal Data processed: Cookies; Usage Data. Usage Data is collected automatically. Place of processing: Ireland â€“ privacy policy. Privacy Shield participant.
                    </td>
                </tr>
                <tr>
                    <td class="left-col">Google +</td>
                    <td class="right-col">If you use a '+1' link on Drivers2day.co.uk to share content on Google +, Google sets a cookie to remember that you've already shared this information. <span class="text-medium">To reject or delete these cookies:</span> <a href="http://www.google.co.uk/intl/en/policies/privacy/" target="_blank" title="link opens a new window">http://www.google.co.uk/intl/en/policies/privacy/</a></td>
                </tr>
                <tr>
                    <td class="left-col">Facebook</td>
                    <td class="right-col">If you use a 'like' or 'send' link on Drivers2day.co.uk to share content on Facebook, Facebook sets a cookie to remember that you've already shared this information. <span class="text-medium">To reject or delete these cookies:</span> <a href="http://www.facebook.com/about/privacy/" target="_blank" title="link opens a new window">http://www.facebook.com/about/privacy/</a></td>
                </tr>
                <tr>
                    <td class="left-col">Facebook Custom Audience </td>
                    <td class="right-col">This is a feature that enables Drivers2day to capture anonymized data from individuals who are registered users of both Drivers2day.co.uk and Facebook. This data is transmitted and stored by Facebook's servers in the United States. This data captures information about your browsing history and patterns through the Drivers2day.co.uk website. When you browse the Drivers2day.co.uk website and then visit your Facebook account your browsing history on the Drivers2day.co.uk website shall be used to provide you with suitable targeted advertising on your Facebook page. This data is stored for 180 days; if you do not visit the Drivers2day.co.uk website for 180 days such data shall be removed unless you revisit the Drivers2day.co.uk website during this time.</td>
                </tr>
                <tr>
                    <td class="left-col">Doubleclick</td>
                    <td class="right-col">Doubleclick 'Spotlight Tags' report on the success of marketing campaigns for Drivers2day.co.uk <span class="text-medium">To reject or delete these cookies:</span> <a href="http://www.doubleclick.com/privacy/dart_adserving.aspx" target="_blank" title="link opens a new window">http://www.doubleclick.com/privacy/dart_adserving.aspx</a></td>
                </tr>-->
            </tbody>
        </table>

        <h3>How can I manage my cookies on Drivers2day.co.uk?</h3>
        <p>If you wish to stop accepting cookies, you can do so through the Privacy Settings option in your browser.</p>
        <p><strong>Please be aware that by not accepting cookies you will not be able to use some of the key functions of Drivers2day.co.uk, including:</strong></p>
        <ul>
            <li>Registering with Drivers2day.co.uk</li>
            <li>Signing in to Drivers2day.co.uk</li>
            <li>Booking drivers on Drivers2day.co.uk</li>
        </ul>
        <p><strong>To delete all cookies stored by Drivers2day.co.uk for your browser, please click on the button below:</strong></p>
        <span id="termsConditions"></span>
        <div class="clear-cookies-container">
            <span class="btn btn-secondary" (click)="deleteCookies()" id="confirmClearCookiesButton">Clear Drivers2day.co.uk cookies</span>
            <p class="clear-cookie-caution">Clicking this button will sign you out from Drivers2day.co.uk.</p>
        </div>
    </div>
</main>
</div>
</div>