<div class="ion-padding" style="text-align: center;
margin-top: 150px;
min-height: 300px;" [hidden]="!loading">

    <img src="../../assets/images/loader.gif" style="     margin-top: 10px;   width: 70px; padding-left: 20px;" />
</div>
<div class="ion-padding" style="text-align: center;
margin-top: 150px;
padding: 20px;
min-height: 300px;" [hidden]="!success">
    <div class="alert alert-success">
        <strong> {{'SIGNUP.success' | translate }}!</strong>
        {{'SIGNUP.success_msg' | translate }}
        <a routerLink="/login" style="text-decoration: underline;">
            {{'SIGNUP.success_msg1' | translate }}
            {{'SIGNUP.success_msg2' | translate }}
        </a>
    </div>
</div>
<button id="openModalButton" [hidden]="true" data-toggle="modal" data-target="#myModal">Open
    Modal</button>
<div class="main-block">
    <div class="signup-form gray-icon"  [hidden]="loading" *ngIf="!success">

        <form name="form" [formGroup]="form" #myForm="ngForm" (ngSubmit)="onSubmit()">

            <div class="form-group">
                <div class="input-group">
                    <span class="input-group-addon"><i style="font-size: 15px;"
                            [ngStyle]="{'color': (validationErrorsServer.company)?'firebrick':'#bbbbbb'}"
                            class="fa fa-briefcase"></i></span>
                    <input type="text" formControlName="company" class="form-control"
                        placeholder="{{'SIGNUP.company' | translate }} ">

                </div>
                <div class="validation_error">
                    <span *ngIf="validationErrorsServer.company">
                        {{validationErrorsServer.company}}</span>
                </div>
            </div>

            <div class="form-group">
                <div class="input-group">
                    <span class="input-group-addon"><i style="font-size: 15px;"
                            [ngStyle]="{'color': (validationErrorsServer.contact_name)?'firebrick':'#bbbbbb'}"
                            class="fa fa-user"></i></span>
                    <input type="text" formControlName="contact_name" class="form-control"
                        placeholder="Contact Name">

                </div>
                <div class="validation_error">
                    <span *ngIf="validationErrorsServer.contact_name">
                        {{validationErrorsServer.contact_name}}</span>
                </div>
            </div>

            <div class="form-group">
                <div class="input-group">
                    <span class="input-group-addon"><i style="font-size: 15px;"
                            [ngStyle]="{'color': (validationErrorsServer.company_contact_number)?'firebrick':'#bbbbbb'}"
                            class="fa fa-user"></i></span>
                    <input type="text" formControlName="company_contact_number" class="form-control"
                        placeholder="Company Contact Number">

                </div>
                <div class="validation_error">
                    <span *ngIf="validationErrorsServer.company_contact_number">
                        {{validationErrorsServer.company_contact_number}}</span>
                </div>
            </div>

            <div class="form-group">
                <div class="input-group">
                    <span class="input-group-addon"><i style="font-size: 15px;"
                            [ngStyle]="{'color': (validationErrorsServer.email)?'firebrick':'#bbbbbb'}"
                            class="fa fa-envelope"></i></span>
                    <input type="text" formControlName="email" class="form-control"
                        placeholder="{{'SIGNUP.email' | translate }} ">

                </div>
                <div class="validation_error">
                    <span *ngIf="validationErrorsServer.email">
                        {{validationErrorsServer.email}}</span>
                </div>
            </div>
            <div class="form-group">
                <div class="input-group">
                    <span class="input-group-addon"><i style="font-size: 15px;"
                            [ngStyle]="{'color': (validationErrorsServer.mobile)?'firebrick':'#bbbbbb'}"
                            class="fa fa-phone"></i></span>
                    <input type="text" formControlName="mobile" class="form-control"
                        placeholder="{{'SIGNUP.mobile' | translate }} ">

                </div>
                <div class="validation_error">
                    <span *ngIf="validationErrorsServer.mobile">
                        {{validationErrorsServer.mobile}}</span>
                </div>
            </div>
            
            <div class="form-group">
                <div class="input-group">
                    <span class="input-group-addon"><i
                        [ngStyle]="{'color': (validationErrorsServer.city)?'firebrick':'#bbbbbb'}"
                        class="fa fa-building"></i></span>
                    <select formControlName="city" id="city-select2" class="form-control" placeholder="City">


                    </select>
                </div>
                <div class="validation_error">
                    <span *ngIf="validationErrorsServer.city">{{validationErrorsServer.city}}</span>
                </div>

            </div>
            <div class="form-group">
                <div class="input-group">
                    <span class="input-group-addon"><i
                            [ngStyle]="{'color': (validationErrorsServer.password)?'firebrick':'#bbbbbb'}"
                            class="fa fa-lock"></i></span>
                    <input type="password" formControlName="password" class="form-control" name="password"
                        placeholder="{{'SIGNUP.password' | translate }}">


                </div>
                <div class="validation_error">
                    <span *ngIf="validationErrorsServer.password">
                        {{validationErrorsServer.password}}</span>
                </div>
            </div>
            <div class="form-group">
                <div class="input-group">
                    <span class="input-group-addon">
                        <i class="fa fa-lock"
                            [ngStyle]="{'color': (validationErrorsServer.cnfpass)?'firebrick':'#bbbbbb'}"></i>
                        <i class="fa fa-check"
                            [ngStyle]="{'color': (validationErrorsServer.cnfpass)?'firebrick':'#bbbbbb'}"></i>
                    </span>
                    <input type="password" formControlName="cnfpass" class="form-control" name="confirm_password"
                        placeholder="{{'SIGNUP.confirm_password' | translate }}">

                </div>
                <div class="validation_error">
                    <!-- <i class="fa fa-times-circle" style="color:firebrick;"></i> -->
                    <span *ngIf="validationErrorsServer.cnfpass">{{validationErrorsServer.cnfpass}}</span>
                </div>
            </div>



            <div class="form-group" style="margin-top: 45px;font-family: Lato-Regular;">
                <button type="submit"
                    class="btn btn-primary btn-block btn-lg">{{'SIGNUP.signup' | translate }}</button>

            </div>

            <div class="text-center">{{'SIGNUP.already_have_account' | translate }}?
                <a routerLink="/login" routerLinkActive="active">
                    {{'SIGNUP.login_here' | translate }}
                </a>.
            </div>
            <br>
            <!-- <div class="row" [hidden]="fbSignup.status">
                <div class="col-lg-8 mx-auto">

                    <div class="mb-4">

                        <hr data-content="OR" class="hr-text">
                    </div>

                </div>
            </div>


            <app-fb-signup [hidden]="fbSignup.status" (messageEvent)="onFbSignUp($event)"></app-fb-signup> -->

        </form>

    </div>
</div>





<div id="myModal" class="modal fade" role="dialog" data-keyboard="false" data-backdrop="static">
    <div class="modal-dialog">

        <!-- Modal content-->
        <div class="modal-content" style="min-height: 200px;;">

            <div class="modal-body  otp" [hidden]="otpLoading|| otpResultView " style="padding: 37px 15px;">
                <p>{{'SIGNUP.email_otp_sent' | translate }}<b>{{form.controls.email.value}}</b></p>

                <div style="text-align: center;">

                    <input max="999999" type="number" [(ngModel)]="otpText" #otp (keyup)="onOtpKeyup($event)"
                        placeholder="" style="
                      padding-left: 15px;
                        caret-color: #ffffff;
                        letter-spacing: 33px;
                        border: 0;
                        background-image: url(../../assets/images/otp_bg_email.jpg);
                        background-repeat: no-repeat;
                        width: 280px;
                        height: 50px;
            ">

                </div>

                <div class="validation_error" style="color: rgb(3, 126, 3);text-align: center;">
                    <span *ngIf="resendOtpMsg">{{resendOtpMsg}}</span>
                </div>

                <div class="validation_error">
                    <span *ngIf="validationErrorsOtp">{{validationErrorsOtp}}</span>
                </div>

            </div>

            <div [hidden]="!otpLoading || otpResultView ">
                <div style="text-align: center;
                            margin-top: 62px;
                            min-height: 100px; ">

                    <img src="../../assets/images/loader.gif"
                        style="margin-top: 10px;   width: 70px;padding-left: 20px;" />
                </div>
            </div>


            <div class="text-center" [hidden]="otpLoading || !otpResultView" style=" height: 50px;margin-top: 48px;">

                <span class="font-weight-normal" style="font-size:18px;color: #337777;font-family: Lato-Regular;">
                    {{otpResponseMessage.status}}
                </span>
                <br />
                <div
                    style="width: 50px;height: 2px;background-color: rgb(243, 115, 42);margin-left: auto;margin-right: auto;">
                </div>

                <div style="margin-top: 10px;">


                    {{otpResponseMessage.text}}
                </div>



                <!-- <button type="button" style="background-color: rgb(243, 115, 42);color: #fff;
                        margin-top: 15px;
                        border-color:rgb(243, 115, 42);" class="btn btn-default" data-dismiss="modal">OK </button> -->

            </div>



            <div [hidden]="otpLoading || otpResultView" class="button-group"
                style="    text-align: right;text-decoration: underline;">
                <button type="button" [hidden]="resendOtpMsg" (click)="resendOtp()" class="btn btn-default">{{'SIGNUP.RESEND_OTP' | translate }}</button>
                <button type="button" id="closeOTPModelPopup" [hidden]="true" class="btn btn-default"
                    data-dismiss="modal"> Resend OTP</button>
                <button type="button" style="background-color: rgb(243, 115, 42);;
                border-color: rgb(243, 115, 42);" (click)="submitOtp()"
                    class="btn btn-info">{{'SIGNUP.submit_otp' | translate }}</button>

            </div>
        </div>

    </div>