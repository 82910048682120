import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { JwtModule } from '@auth0/angular-jwt';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { AuthGuardService } from './auth/auth-guard.service';

import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { AppRoutingModule } from './app-routing.module';
import { LoginComponent } from './login/login.component';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CompanySignupComponent } from './company-signup/company-signup.component';
import { DataSharingService } from './services/data-sharing.service';
import { ProfileComponent } from './profile/profile.component';
import { TokenInterceptorService } from './auth/token-interceptor.service';
import { SignupPageComponent } from './signup-page/signup-page.component';
import { BecomeDriverComponent } from './become-driver/become-driver.component';
import { DriversComponent } from './drivers/drivers.component';
import { FbLoginComponent } from './fb-login/fb-login.component';
import { FbSignupComponent } from './fb-signup/fb-signup.component';
import { ActivateComponent } from './activate/activate.component';
import { BecomeDriverIntroComponent } from './become-driver-intro/become-driver-intro.component';
import { MessagesComponent } from './messages/messages.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { DriverComponent } from './driver/driver.component';
import { CompanyComponent } from './company/company.component';
import { BookingComponent } from './booking/booking.component';
import { ManageBookingComponent } from './manage-booking/manage-booking.component';
import { TermsOfBusinessComponent } from './terms-of-business/terms-of-business.component';
import { CompleteBookingComponent } from './complete-booking/complete-booking.component';
import { DatePipe } from '@angular/common';
import { InvoiceComponent } from './invoice/invoice.component';
import { ManageInvoiceComponent } from './manage-invoice/manage-invoice.component';
import { ProfileDetailComponent } from './profile-detail/profile-detail.component';
import { PayoutComponent } from './payout/payout.component';
import { ManagePayoutComponent } from './manage-payout/manage-payout.component';
import { AboutComponent } from './about/about.component';
import { TodayBookingComponent } from './today-booking/today-booking.component';
import { PoliciesComponent } from './policies/policies.component';
import { FaqComponent } from './faq/faq.component';
import { BecomeOwnerDriverComponent } from './become-owner-driver/become-owner-driver.component';
export function TranslationLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HeaderComponent,
    FooterComponent,
    LoginComponent,
    CompanySignupComponent,
    ProfileComponent,
    SignupPageComponent,
    BecomeDriverComponent,
    DriversComponent,
    FbLoginComponent,
    FbSignupComponent,
    ActivateComponent,
    BecomeDriverIntroComponent,
    MessagesComponent,
    ResetPasswordComponent,
    DriverComponent,
    CompanyComponent,
    BookingComponent,
    ManageBookingComponent,
    TermsOfBusinessComponent,
    CompleteBookingComponent,
    InvoiceComponent,
    ManageInvoiceComponent,
    ProfileDetailComponent,
    PayoutComponent,
    ManagePayoutComponent,
    AboutComponent,
    TodayBookingComponent,
    PoliciesComponent,
    FaqComponent,
    BecomeOwnerDriverComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: TranslationLoaderFactory,
        deps: [HttpClient]
      }
    }),
    JwtModule.forRoot({
      config: {
        tokenGetter: function tokenGetter() {
          return "";//localStorage.getItem('access_token');
        },
        whitelistedDomains: ['https://api.drivers2day.co.uk/', 'https://drivers2day.co.uk/', 'https://www.drivers2day.co.uk/', 'http://api.drivers2day.com/'],
        /*blacklistedRoutes: ['http://localhost:4200/login', 'http://localhost:4200/signup']*/
      }
    })
  ],
  providers: [DataSharingService, AuthGuardService,
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptorService, multi: true }, DatePipe],
  bootstrap: [AppComponent]
})
export class AppModule { }
