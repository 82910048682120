import { Component, OnInit } from '@angular/core';
import { SecuredService } from '../services/secured.service';
import { JwtService } from '../services/jwt.service';
import { Payout } from '../interfaces/Payout';
import { Driver } from '../interfaces/Driver';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-payout',
  templateUrl: './payout.component.html',
  styleUrls: ['./payout.component.css']
})
export class PayoutComponent implements OnInit {

  constructor(private jwtService: JwtService, private securedService: SecuredService,
    private router: Router, private translate: TranslateService) { }
  loading: boolean = false;
  viewMoreUrl: string;
  payouts: Payout[] = [];
  driver: Driver;

  ngOnInit(): void {
  	if (this.jwtService.isCompany) {
	  this.router.navigate(['manage-booking']);
	  return;
	}
	this.driver = this.jwtService.getLoggedInData();
	this.loading = true;
    let data: string = "";
    this.securedService.getPayouts("", data).subscribe(
      result => {
        this.loading = false;
        this.payouts = result.items;
        this.viewMoreUrl = result?._links?.next?.href;
      },
      error => {
        this.loading = false;
        if (error.status == 429 || error.status == 500) {
          alert(this.translate.instant('COMMON.500'));

        }

      }
    );
  }

  viewMore() {
    if (!this.viewMoreUrl) {
      return;
    }

    this.securedService.getPayouts(this.viewMoreUrl, "").subscribe(
      result => {
        for (let i = 0; i < result.items.length; i++) {
          let payout = result.items[i];
          this.payouts.push(payout);
        }
        this.viewMoreUrl = result?._links?.next?.href;
      },
      error => {
        if (error.status == 429 || error.status == 500) {
          alert(this.translate.instant('COMMON.500'));
        }
      }
    );
  }

}
