<div style="text-align: center;
margin-top: 250px;
min-height: 700px; " [hidden]="!loading">

    <img src="../../assets/images/loader.gif" style="     margin-top: 10px;   width: 70px;
  padding-left: 20px;" />
</div>
<div style="margin-top:50px;min-height: 424px;" [hidden]="loading">
    <div class="col-lg-6 col-12 col-md-8  offset-lg-3 offset-md-2" style="margin-bottom: 20px;">

        <div class="text-center" style="height: 100px;">
            <div style="padding: 36px 40px;">

                {{ responseMessage }}

            </div>
        </div>
        <div class="form-group col-md-6 offset-md-3 col-12" style="margin-top: 35px;;">
            <button type="submit" routerLink="/" class="btn btn-primary btn-block btn-lg">Return to
                Home</button>
        </div>
    </div>
</div>