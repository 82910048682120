<div style="    margin-top: 65px;
height: 100px;">
    <div style="height: 20px;
    color: #a29c9c;
    float: right;
    font-size: 31px;margin-right: 20px;">
        <span routerLink="/" routerLinkActive="active"><i class="fa fa-times" aria-hidden="true"></i></span>
    </div>


</div>
<div class="text-center" style="height: 60px;"[hidden]="success">

    <span class="font-weight-bold" style="font-size:18px;color: #337777;">
        {{'RESET_PASSWORD.RESET_PASSWORD' | translate }} </span><br />
    <div style="width: 50px;height: 2px;background-color: rgb(243, 115, 42);margin-left: auto;margin-right: auto;">
    </div>

</div>


<div class="ion-padding" style="text-align: center;
margin-top: 150px;
min-height: 300px;" [hidden]="!loading">

    <img src="../../assets/images/loader.gif" style="     margin-top: 10px;   width: 70px; padding-left: 20px;" />
</div>
<div class="col-md-4" style="text-align: center;
margin-top: 100px; margin: 0 auto;
padding: 20px;
min-height: 300px;" [hidden]="!success">

    <div class="text-center" 
        style=" height: 50px;margin-top: 48px;">

        <span class="font-weight-normal" style="font-size:18px;color: #337777;font-family: Lato-Regular;">
            {{'RESET_PASSWORD.DONE' | translate }}
        </span>
        <br />
        <div style="width: 50px;height: 2px;background-color: rgb(243, 115, 42);margin-left: auto;margin-right: auto;">
        </div>

        <div style="margin-top: 30px;">


            {{'RESET_PASSWORD.CHANGE_PASSWORD_SUCCES' | translate }}
        </div>



        <button type="button" style="background-color: rgb(243, 115, 42);color: #fff;
            margin-top: 15px;
            border-color:rgb(243, 115, 42);" routerLink="/login" class="btn btn-default" >
            
            {{'RESET_PASSWORD.LOGIN' | translate }}
        
        
        
        </button>

    </div>
</div>


<div class="signup-form gray-icon col-md-4" *ngIf="!loading&&!success">
    <div style="margin-top: 10px;
    margin-bottom: 20px;">
        <span>{{'RESET_PASSWORD.ENTER_NEW_PASSWORD' | translate }}</span>
    </div>
    <form name="form" [formGroup]="form">



        <div class="form-group">
            <div class="input-group">
                <span class="input-group-addon"><i
                        [ngStyle]="{'color': (validationErrorsServer.password)?'firebrick':'#bbbbbb'}"
                        class="fa fa-lock"></i></span>
                <input type="password" formControlName="password" class="form-control" name="password"
                    placeholder="{{'SIGNUP.password' | translate }}">


            </div>
            <div class="validation_error">
                <span *ngIf="validationErrorsServer.password">
                    {{validationErrorsServer.password}}</span>
            </div>
        </div>
        <div class="form-group">
            <div class="input-group">
                <span class="input-group-addon">
                    <i class="fa fa-lock"
                        [ngStyle]="{'color': (validationErrorsServer.cnfpass)?'firebrick':'#bbbbbb'}"></i>
                    <i class="fa fa-check"
                        [ngStyle]="{'color': (validationErrorsServer.cnfpass)?'firebrick':'#bbbbbb'}"></i>
                </span>
                <input type="password" formControlName="cnfpass" class="form-control" name="confirm_password"
                    placeholder="{{'SIGNUP.confirm_password' | translate }}">

            </div>
            <div class="validation_error">
                <span *ngIf="validationErrorsServer.cnfpass">{{validationErrorsServer.cnfpass}}</span>
            </div>
        </div>



        <div class="form-group" style="margin-top: 45px;font-family: Lato-Regular;">
            <button type="submit" (click)="onSubmit()" class="btn btn-primary btn-block btn-lg">

                {{'RESET_PASSWORD.SUBMIT' | translate }}

            </button>

        </div>




    </form>

</div>