import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { User } from '../interfaces/User';
import { Driver } from '../interfaces/Driver';
import { Comment } from '../interfaces/Comment';
import { catchError } from 'rxjs/operators';
import * as Globals from '../global';

@Injectable({
  providedIn: 'root'
})
export class SecuredService {
  constructor(private httpClient: HttpClient) { }
  readonly baseAppUrl: string = Globals.API_ENDPOINT;
  readonly headers = new HttpHeaders({
    'content-type': 'application/json'
  });

  getDriverSignupInfo(code:string) {
    return this.httpClient.get<any>(`${this.baseAppUrl}auth/driver-detail/${code}`, { 'headers': this.headers });
  }
  userDetails() {
    return this.httpClient.get<any>(`${this.baseAppUrl}user`, { 'headers': this.headers });
  }

  updateDriverDetails(data: any) {
    return this.httpClient.put<any>(`${this.baseAppUrl}user/driver-profile`, data, { 'headers': this.headers });
  }
  updateCompanyDetails(data: any) {
    return this.httpClient.put<any>(`${this.baseAppUrl}user/company-profile`, data, { 'headers': this.headers });
  } 
  updateUserDetails(data: any) {
    return this.httpClient.put<any>(`${this.baseAppUrl}user`, data, { 'headers': this.headers });
  }

  addLicenceDetails(data: any) {
    //console.log(this.headers)
    return this.httpClient.post<any>(`${this.baseAppUrl}driver/licence`, data, { 'headers': this.headers });
  }

  addLicenceDetailsUpload(formData: FormData) {
    return this.httpClient.post(`${this.baseAppUrl}driver/document`, formData, { 'headers': {} , reportProgress: true,observe: 'events'});
  }

  uploadLicenceDocuments(formData: FormData) {
    return this.httpClient.post(`${this.baseAppUrl}driver/document`, formData, { 'headers': {} , reportProgress: true,observe: 'events'});
  }

  addBankDetail(data: any) { 
    return this.httpClient.post<any>(`${this.baseAppUrl}driver/bank`, data, { 'headers': this.headers });
  }

  healthSafety(data: any) { 
    return this.httpClient.post<any>(`${this.baseAppUrl}company/health-safety`, data, { 'headers': this.headers });
  }

  getHealthSafety() {
    return this.httpClient.get<any>(`${this.baseAppUrl}company/health-safety`);
  }

  changePassword(data: any) { 
    return this.httpClient.post<any>(`${this.baseAppUrl}user/change-password`, data, { 'headers': this.headers });
  }

  changeEmail(data: any) { 
    return this.httpClient.post<any>(`${this.baseAppUrl}user/change-email`, data, { 'headers': this.headers });
  }

  changeMobile(data: any) { 
    return this.httpClient.post<any>(`${this.baseAppUrl}user/change-mobile`, data, { 'headers': this.headers });
  }

  bookDriver(formData: FormData, id: number) {
    return this.httpClient.post(`${this.baseAppUrl}booking/${id}`, formData, { 'headers': {} });
  }
  
  confirmBooking(formData: FormData) {
    return this.httpClient.post(`${this.baseAppUrl}booking/confirm`, formData, { 'headers':{} });
  }

  updateFinished(formData: FormData) {
    return this.httpClient.post(`${this.baseAppUrl}booking/update-finished`, formData, { 'headers':{} });
  }

  uploadProfileImage(formData: FormData) {
    let headers = new HttpHeaders({
      'content-type': 'multipart/form-data'
    })
    return this.httpClient.post<any>(`${this.baseAppUrl}user/profile-image`, formData, { 'headers': {} });
  }

  saveComment(comment: string, id: number) {
    const body = JSON.stringify({
      comment: comment
    });
    return this.httpClient.post<any>(`${this.baseAppUrl}driver/${id}/comment`, body, { 'headers': this.headers });
  }

  saveReply(comment: string, id: number) {
    const body = JSON.stringify({
      comment: comment
    });
    return this.httpClient.post<any>(`${this.baseAppUrl}video-comment/${id}/reply`, body, { 'headers': this.headers });
  }


  reportComment(reason: string, id: number) {
    const body = JSON.stringify({
      reason: reason
    });
    return this.httpClient.post<any>(`${this.baseAppUrl}video-comment/${id}/report`, body, { 'headers': this.headers });
  }

  blockUser(reason: string, id: number) {
    const body = JSON.stringify({
      reason: reason
    });
    return this.httpClient.post<any>(`${this.baseAppUrl}profile/${id}/report`, body, { 'headers': this.headers });
  }
  deleteComment(id: number) {

    return this.httpClient.delete<any>(`${this.baseAppUrl}video-comment/${id}`, { 'headers': this.headers });
  }

  getComments(id: number, url: string) {
    if (!url) {
      url = `${Globals.API_ENDPOINT}driver/${id}/comment?page=1&per-page=10`;
    }
    return this.httpClient.get<any>(url);
  }

  getFewComments(id: number, url: string) {
    if (!url) {
      url = `${Globals.API_ENDPOINT}driver/${id}/comment?page=1&per-page=2`;
    }
    return this.httpClient.get<any>(url);
  }
  
  getDrivers(viewMoreUrl: string, data: string) {
    if (!viewMoreUrl) {
      viewMoreUrl = `${this.baseAppUrl}driver?page=1&per-page=10${data}`;
    }
    return this.httpClient.get<any>(viewMoreUrl);
  }

  getDriver(id: string) {
    return this.httpClient.get<Driver>(`${Globals.API_ENDPOINT}driver/${id}`);
  }

  getBooking(id: string) {
    return this.httpClient.get<any>(`${Globals.API_ENDPOINT}booking/${id}`);
  }

  getBookingToComplete(id: string) {
    return this.httpClient.get<any>(`${Globals.API_ENDPOINT}booking/to-complete/${id}`);
  }

  getBookings(viewMoreUrl: string, data: string) {
    if (!viewMoreUrl) {
      viewMoreUrl = `${this.baseAppUrl}booking?page=1&per-page=10${data}`;
    }
    return this.httpClient.get<any>(viewMoreUrl);
  }

  getTodaysBookings(viewMoreUrl: string, data: string) {
    if (!viewMoreUrl) {
      viewMoreUrl = `${this.baseAppUrl}booking/today?page=1&per-page=10${data}`;
    }
    return this.httpClient.get<any>(viewMoreUrl);
  }

  startOtpBooking(id: number, startCode: string) {
    const body = JSON.stringify({
      start_code: startCode
    });
    return this.httpClient.post<any>(`${this.baseAppUrl}booking/otp-start/${id}`, body, { 'headers': this.headers });
  }

  endOtpBooking(id: number, endCode: string) {
    const body = JSON.stringify({
      end_code: endCode
    });
    return this.httpClient.post<any>(`${this.baseAppUrl}booking/otp-end/${id}`, body, { 'headers': this.headers });
  }

  startBooking(id: number) {
    const body = JSON.stringify({
    });
    return this.httpClient.post<any>(`${this.baseAppUrl}booking/start/${id}`, body, { 'headers': this.headers });
  }

  endBooking(id: number) {
    const body = JSON.stringify({
    });
    return this.httpClient.post<any>(`${this.baseAppUrl}booking/end/${id}`, body, { 'headers': this.headers });
  }

  setBookingRating(id: string, rating: number) {
    let body = JSON.stringify({

    });
    return this.httpClient.post<any>(`${this.baseAppUrl}booking/${id}/rating/${rating}`, body, { 'headers': this.headers });
  }

  getPayouts(viewMoreUrl: string, data: string) {
    if (!viewMoreUrl) {
      viewMoreUrl = `${this.baseAppUrl}payout?page=1&per-page=10${data}`;
    }
    return this.httpClient.get<any>(viewMoreUrl);
  }

  getPayout(id: number) {
    return this.httpClient.get<any>(`${Globals.API_ENDPOINT}payout/${id}`);
  }

  getInvoices(viewMoreUrl: string, data: string) {
    if (!viewMoreUrl) {
      viewMoreUrl = `${this.baseAppUrl}invoice?page=1&per-page=10${data}`;
    }
    return this.httpClient.get<any>(viewMoreUrl);
  }

  getInvoice(id: number) {
    return this.httpClient.get<any>(`${Globals.API_ENDPOINT}invoice/${id}`);
  }

  markInvoicePaid(id: number) {
    const body = JSON.stringify({
    });
    return this.httpClient.post<any>(`${this.baseAppUrl}invoice/mark-paid/${id}`, body, { 'headers': this.headers });
    
  }

  activateAccount(code: string) {
    const body = JSON.stringify({
      code: code
    });
    return this.httpClient.post<any>(`${this.baseAppUrl}auth/activate-account`, body, { 'headers': this.headers });
  }

  getMessageContacts(viewMoreUrl: string) {
    if (!viewMoreUrl) {
      viewMoreUrl = `${this.baseAppUrl}message?page=1&per-page=10`;
    }
    return this.httpClient.get<any>(viewMoreUrl);
  }

  getMessages(viewMoreUrl: string, contactId: number) {
    if (!viewMoreUrl) {
      viewMoreUrl = `${this.baseAppUrl}message/${contactId}?page=1&per-page=5`;
    }
    return this.httpClient.get<any>(viewMoreUrl);
  }

  sendMessage(message: string, id: number) {
    const body = JSON.stringify({
      message: message
    });
    return this.httpClient.post<any>(`${this.baseAppUrl}message/${id}`, body, { 'headers': this.headers });
  }

  getCompanyLocations(viewMoreUrl: string) {
    if (!viewMoreUrl) {
      viewMoreUrl = `${this.baseAppUrl}company-location?page=1&per-page=20`;
    }
    return this.httpClient.get<any>(viewMoreUrl);
  }

  getDriverLocations() {
    return this.httpClient.get<any>(`${this.baseAppUrl}driver-location`);
  }

  getBankDetail() {
    return this.httpClient.get<any>(`${this.baseAppUrl}driver/bank`);
  }
  getLicenceDetail() {
    return this.httpClient.get<any>(`${this.baseAppUrl}driver/licence`);
  }
  addLocation(data: any) {
    return this.httpClient.post<any>(`${this.baseAppUrl}company-location`, data, { 'headers': this.headers });
  }

  addDriverLocation(data: any) {
    return this.httpClient.post<any>(`${this.baseAppUrl}driver-location`, data, { 'headers': this.headers });
  }

  deleteCompanyLocation(id: number) {
    return this.httpClient.delete<any>(`${this.baseAppUrl}company-location/${id}`, { 'headers': this.headers });
  }

  getVehicleDetail(registraion: string) {
    return this.httpClient.get<any>(`${Globals.API_ENDPOINT}owner-driver/vehicle/${registraion}`);
  }

  addVehicleDetail(data: any) {
    return this.httpClient.post<any>(`${this.baseAppUrl}owner-driver/vehicle`, data, { 'headers': this.headers });
  }

  uploadDocuments(formData: FormData) {
    return this.httpClient.post(`${this.baseAppUrl}owner-driver/document`, formData, { 'headers': {} , reportProgress: true,observe: 'events'});
  }

  addOwnerDriverLicenceDetails(data: any) {
    return this.httpClient.post<any>(`${this.baseAppUrl}owner-driver/licence`, data, { 'headers': this.headers });
  }

  uploadOwnerDriverLicenceDocuments(formData: FormData) {
    return this.httpClient.post(`${this.baseAppUrl}owner-driver/licence-document`, formData, { 'headers': {} , reportProgress: true,observe: 'events'});
  }

}
