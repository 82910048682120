<div style="    margin-top: 65px;
height: 100px;">
    <div style="height: 20px;
    color: #a29c9c;
    float: right;
    font-size: 31px;margin-right: 20px;">
        <span routerLink="/" routerLinkActive="active"><i class="fa fa-times" aria-hidden="true"></i></span>
    </div>


</div>
<div class="text-center" style="height: 90px;">

    <span class="font-weight-bold" style="font-size:18px;color: #337777;">{{'LOGIN.login' | translate }}</span><br />
    <div style="width: 50px;height: 2px;background-color: rgb(243, 115, 42);margin-left: auto;margin-right: auto;">
    </div>

</div>

<button id="openModalButton" [hidden]="true" data-toggle="modal" data-target="#myModal">Open
    Modal</button>

<div class="ion-padding" style="text-align: center;
margin-top: 50px;
min-height: 300px;" [hidden]="!loading">

    <img src="../../assets/images/loader.gif" style="     margin-top: 10px;   width: 70px;
  padding-left: 20px;" />
</div>


<div [hidden]="loading" class="signup-form gray-icon  col-md-4" style="min-height: 300px;">
    <form name="login-form" [formGroup]="form">

        <div class="form-group">
            <div class="input-group">
                <span class="input-group-addon"><i
                        [ngStyle]="{'color': (validationErrorsServer.username)?'firebrick':'#bbbbbb'}"
                        class="fa fa-user"></i></span>
                <input type="text" formControlName="username" class="form-control"
                    placeholder="{{'LOGIN.username' | translate }}">
            </div>
            <div class="validation_error">
                <span *ngIf="validationErrorsServer.username">{{validationErrorsServer.username}}</span>
            </div>
        </div>

        <div class="form-group">
            <div class="input-group">
                <span class="input-group-addon"><i class="fa fa-lock"
                        [ngStyle]="{'color': (validationErrorsServer.password)?'firebrick':'#bbbbbb'}"></i></span>
                <input type="password" formControlName="password" class="form-control" name="password"
                    placeholder="{{'LOGIN.password' | translate }}">
            </div>
            <div class="validation_error">
                <span *ngIf="validationErrorsServer.password">
                    {{validationErrorsServer.password}}</span> </div>
        </div>
        <div class="text-center" style="margin-bottom: 20px;">
            <span>{{'LOGIN.FORGOT_PASSWORD' | translate }}</span>
            <span style=" cursor: pointer;    color: #337777;" data-toggle="modal"
                data-target="#forgotPasswordModel">

                {{'LOGIN.CLICK_HERE' | translate }}

            </span>

           
        </div>

        <div class="form-group">
            <button type="submit" (click)="form.touched?onSubmit():onTouch()" [disabled]="form.touched && form.invalid"
                class="btn btn-primary btn-block btn-lg">{{'LOGIN.log_in' | translate }}</button>
        </div>
    

        <div class="row">
            <div class="col-lg-8 mx-auto">

                <div class="mb-4">

                    <!--<hr data-content="OR" class="hr-text">-->
                </div>

            </div>
        </div>


        <!-- <app-fb-login (messageEvent)="onFbLogin($event)"></app-fb-login>


        <button type="button" id="fbLoginButton" (click)="onFbLoginSubmit()" [hidden]="true">FBLOGIN</button> -->

        <!-- <div class="text-center">{{'LOGIN.dont_have_account' | translate }}? <a routerLink="/signup"
                routerLinkActive="active">{{'LOGIN.sign_up' | translate }}</a>
        </div> -->
    </form>
</div>


<div id="forgotPasswordModel" class="modal fade" role="dialog" data-keyboard="false" data-backdrop="static">
    <div class="modal-dialog">

        <!-- Modal content-->
        <div class="modal-content" style="min-height: 200px;;">
            <div class="text-center" style="height: 20px;
            margin-top: 20px;" [hidden]="forgotPasswordLoading|| forgotPasswordResultView " >

                <span class="font-weight-bold" style="font-size:18px;color: #337777;">{{'LOGIN.FORGOT_YOUR_PASSWORD' | translate }}</span><br />
                <div style="width: 50px;height: 2px;background-color: rgb(243, 115, 42);margin-left: auto;margin-right: auto;">
                </div>
            
            </div>
            <div class="modal-body  otp" [hidden]="forgotPasswordLoading|| forgotPasswordResultView "
                style="padding: 37px 15px;">
                <p>
                    {{'LOGIN.ENTER_EMAIL' | translate }}
                </p>

                <div style="text-align: center;">

                    <input type="text" [(ngModel)]="forgotPasswordEmail" placeholder="  {{'LOGIN.EMAIL' | translate }}" class="form-control">

                </div>



                <div class="validation_error">
                    <span *ngIf="validationErrorsForgotPassword">{{validationErrorsForgotPassword}}</span>
                </div>

            </div>

            <div [hidden]="!forgotPasswordLoading || forgotPasswordResultView ">
                <div style="text-align: center;
                            margin-top: 62px;
                            min-height: 100px; ">

                    <img src="../../assets/images/loader.gif"
                        style="margin-top: 10px;   width: 70px;padding-left: 20px;" />
                </div>
            </div>


            <div class="text-center" [hidden]="forgotPasswordLoading || !forgotPasswordResultView"
                style=" height: 50px;margin-top: 48px;">

                <span class="font-weight-normal" style="font-size:18px;color: #337777;font-family: Lato-Regular;">
                    {{forgotPasswordResponseMessage.status}}
                </span>
                <br />
                <div
                    style="width: 50px;height: 2px;background-color: rgb(243, 115, 42);margin-left: auto;margin-right: auto;">
                </div>

                <div style="margin-top: 10px;">


                    {{forgotPasswordResponseMessage.text}}
                </div>



                <button type="button" style="background-color: rgb(243, 115, 42);color: #fff;
                        margin-top: 15px;
                        border-color:rgb(243, 115, 42);" class="btn btn-default" data-dismiss="modal">{{'LOGIN.OK' | translate }} </button>

            </div>



            <div [hidden]="forgotPasswordLoading || forgotPasswordResultView" class="button-group"
                style="    text-align: right;">


                <button type="button" class="btn btn-default" data-dismiss="modal">{{'LOGIN.CLOSE' | translate }}</button>

                <button type="button" style="background-color: rgb(243, 115, 42);;
                border-color: rgb(243, 115, 42);" (click)="submitForgotPassword()" class="btn btn-info">
                    {{'LOGIN.SEND' | translate }}</button>

            </div>
        </div>

    </div>
</div>

<div id="myModal" class="modal fade" role="dialog" data-keyboard="false" data-backdrop="static">
    <div class="modal-dialog">

        <!-- Modal content-->
        <div class="modal-content" style="min-height: 200px;;">

            <div class="modal-body  otp" [hidden]="otpLoading|| otpResultView " style="padding: 37px 15px;">
                <p>
                    {{'LOGIN.email_otp_sent' | translate }}
                    <b>{{otpEmail}}</b></p>

                <div style="text-align: center;">

                    <input max="999999" type="number" [(ngModel)]="otpText" #otp (keyup)="onOtpKeyup($event)"
                        placeholder="" style="
                      padding-left: 15px;
                        caret-color: #ffffff;
                        letter-spacing: 33px;
                        border: 0;
                        background-image: url(../../assets/images/otp_bg_email.jpg);
                        background-repeat: no-repeat;
                        width: 280px;
                        height: 50px;
            ">

                </div>

                <div class="validation_error" style="color: rgb(3, 126, 3);text-align: center;">
                    <span *ngIf="resendOtpMsg">{{resendOtpMsg}}</span>
                </div>

                <div class="validation_error">
                    <span *ngIf="validationErrorsOtp">{{validationErrorsOtp}}</span>
                </div>

            </div>

            <div [hidden]="!otpLoading || otpResultView ">
                <div style="text-align: center;
                            margin-top: 62px;
                            min-height: 100px; ">

                    <img src="../../assets/images/loader.gif"
                        style="margin-top: 10px;   width: 70px;padding-left: 20px;" />
                </div>
            </div>


            <div class="text-center" [hidden]="otpLoading || !otpResultView" style=" height: 50px;margin-top: 48px;">

                <span class="font-weight-normal" style="font-size:18px;color: #337777;font-family: Lato-Regular;">
                    {{otpResponseMessage.status}}
                </span>
                <br />
                <div
                    style="width: 50px;height: 2px;background-color: rgb(243, 115, 42);margin-left: auto;margin-right: auto;">
                </div>

                <div style="margin-top: 10px;">


                    {{otpResponseMessage.text}}
                </div>



                <!-- <button type="button" style="background-color: rgb(243, 115, 42);color: #fff;
                        margin-top: 15px;
                        border-color:rgb(243, 115, 42);" class="btn btn-default" data-dismiss="modal">OK </button> -->

            </div>



            <div [hidden]="otpLoading || otpResultView" class="button-group" style="    text-align: right;">

                <button type="button" [hidden]="resendOtpMsg" (click)="resendOtp()"
                    class="btn btn-default">{{'LOGIN.RESEND_OTP' | translate }}</button>
                <button type="button" id="closeOTPModelPopup" [hidden]="true" (click)="resetOTPVerification()"
                    class="btn btn-default" data-dismiss="modal">{{'LOGIN.CLOSE' | translate }}</button>
                <button type="button" style="background-color: rgb(243, 115, 42);;
                border-color: rgb(243, 115, 42);" (click)="submitOtp()" class="btn btn-info">
                    {{'LOGIN.submit_otp' | translate }}</button>

            </div>
        </div>

    </div>
</div>