/// <reference  types="@types/googlemaps"  />
import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { SecuredService } from '../services/secured.service';
import { JwtService } from '../services/jwt.service';
import { TranslateService } from '@ngx-translate/core';
import { Booking } from '../interfaces/Booking';
import { Driver } from '../interfaces/Driver';
import { Company } from '../interfaces/Company';


@Component({
  selector: 'app-today-booking',
  templateUrl: './today-booking.component.html',
  styleUrls: ['./today-booking.component.css']
})
export class TodayBookingComponent implements OnInit {

  constructor(private jwtService: JwtService, private securedService: SecuredService
    ,
    private translate: TranslateService) { }

  @ViewChild('gmap') gmapElement:  any;
  bookings: Booking[] = [];
  loading: boolean = false;
  viewMoreUrl: string;
  isDriver: Boolean = false;
  isCompany: Boolean = false;
  driver: Driver;
  company: Company;
  stars: number[] = [1, 2, 3, 4, 5];

  startOtpText: string = "";
  startOtpLoading: Boolean = false;
  startOtpResultView: Boolean = false;
  validationErrorsOtp: string = "";
  startOtpBooking: Booking;
  startOtpResponseMessage = {
    status: "",
    text: ""
  }

  endOtpText: string = "";
  endOtpLoading: Boolean = false;
  endOtpResultView: Boolean = false;
  endOtpBooking: Booking;
  endOtpResponseMessage = {
    status: "",
    text: ""
  }

  startStopError:any=[];
  startStopSuccess:any=[];
  startStopLoading:any=[];



  ngOnInit(): void {
  	this.isDriver = this.jwtService.isDriver;
    this.isCompany = this.jwtService.isCompany;
    if(this.isDriver) {
      this.driver = this.jwtService.getLoggedInData();
    } else {
      this.company = this.jwtService.getLoggedInData();
    }
  	this.loadTodaysBooking();
  }

  loadTodaysBooking() {
    this.loading = true;
    let data: string = "";

    this.securedService.getTodaysBookings("", data).subscribe(
      result => {
        this.loading = false;
        this.bookings = result.items;
        this.viewMoreUrl = result?._links?.next?.href;
      },
      error => {
        this.loading = false;
        if (error.status == 429 || error.status == 500) {
          alert(this.translate.instant('COMMON.500'));

        }

      }
    );

  }

  todayViewMore() {
    if (!this.viewMoreUrl) {
      return;
    }

    this.securedService.getBookings(this.viewMoreUrl, "").subscribe(
      result => {
        for (let i = 0; i < result.items.length; i++) {
          let booking = result.items[i];
          this.bookings.push(booking);
        }
        this.viewMoreUrl = result?._links?.next?.href;
      },
      error => {
        if (error.status == 429 || error.status == 500) {
          alert(this.translate.instant('COMMON.500'));
        }
      }
    );
  }

  todayAcceptRow(booking) {
    if(confirm("You are accepting the booking for this date?")) {
      this.loading = true;
      const formData = new FormData();
      formData.append(booking.id, "ACCEPTED");

      this.securedService.confirmBooking(formData)
        .subscribe(result => {
          booking.booking_status = "ACCEPTED"
          this.loading = false;
        },
          error => {
            this.loading = false;
            alert(error);
          }
        );
    }
  }

  todayLoadMap(booking: any) {
    const  myLatlng  =  {lat: parseFloat(booking.location_latitude), lng: parseFloat(booking.location_longitude)};
            
    const  mapOptions  = {
      zoom:  15,
      center:  myLatlng
    };
    const  map  =  new  google.maps.Map(this.gmapElement.nativeElement, mapOptions);
    const  marker  =  new  google.maps.Marker({
      position:  myLatlng,
      title:  booking.location_address
    });
    marker.setMap(map);
  }

  todaySetStar(star, booking) {
    booking.rating = star;
    this.securedService.setBookingRating(booking.id, booking.rating).subscribe(
      result => {

      },
      error => {
        booking.rating = false;
        if (error.status == 429 || error.status == 500) {
          alert(this.translate.instant('COMMON.500'));

        }
      }
    );
  }

  onStartOtpKeyup(event: KeyboardEvent) {
    if (this.startOtpText) {
      let stringOtp = this.startOtpText.toString();
      if (stringOtp.length >= 6) {
        this.startOtpText = stringOtp.substring(0, 6);
      }
    }
  }

  submitStartOtp() {
    this.validationErrorsOtp = ""
    if (!this.startOtpText || !this.startOtpBooking?.id) {
      return;
    }
    this.startOtpLoading = true;
    this.securedService.startOtpBooking(this.startOtpBooking.id, this.startOtpText)
      .subscribe(result => {
        this.startOtpLoading = false;

        this.startOtpResultView = true;
        this.startOtpResponseMessage.status = "Started";
        this.startOtpResponseMessage.text = "Your booking started now";
        this.startOtpBooking.booking_status = "STARTED";
        setTimeout(() => {
          document.getElementById("closeOTPModelPopup").click();
          this.loading = false;
        }, 2000);
      },
        error => {
          this.startOtpLoading = false;
          if (error.status == 429 || error.status == 500) {
            alert(this.translate.instant('COMMON.500'));
          }
          if (error.status == 400) {
            this.validationErrorsOtp = error.error.error;
          }
          
        }
      );
  }

  onEndOtpKeyup(event: KeyboardEvent) {
    if (this.startOtpText) {
      let stringOtp = this.startOtpText.toString();
      if (stringOtp.length >= 6) {
        this.startOtpText = stringOtp.substring(0, 6);
      }
    }
  }

  submitEndOtp() {
    this.validationErrorsOtp = ""
    if (!this.endOtpText || !this.endOtpBooking?.id) {
      return;
    }
    this.endOtpLoading = true;
    this.securedService.endOtpBooking(this.endOtpBooking.id, this.endOtpText)
      .subscribe(result => {
        this.endOtpLoading = false;

        this.endOtpResultView = true;
        this.endOtpResponseMessage.status = "Finished";
        this.endOtpResponseMessage.text = "Your booking finished now";
        this.endOtpBooking.booking_status = "COMPLETED";
        setTimeout(() => {
          document.getElementById("closeEndOTPModelPopup").click();
          this.loading = false;
        }, 2000);
      },
        error => {

          this.endOtpLoading = false;
          if (error.status == 429 || error.status == 500) {
            alert(this.translate.instant('COMMON.500'));
          }
          if (error.status == 400) {
            
            this.validationErrorsOtp = error.error.error;
          }
          
        }
      );
  }


  startBooking(booking:Booking) {
    if(confirm("The booking is starting now?")) {
      this.startStopLoading[booking.id] = true;
      this.securedService.startBooking(booking.id)
        .subscribe(result => {
          this.startStopLoading[booking.id] = false;
          this.startStopSuccess[booking.id] = "Your booking started";
          booking.booking_status = "STARTED";
          setTimeout(() => {
            this.startStopSuccess[booking.id] = "";
          }, 2000);
        },
        error => {
          this.startStopLoading[booking.id] = false;
          if (error.status == 429 || error.status == 500) {
            alert(this.translate.instant('COMMON.500'));
          }
          if (error.status == 400) {
            this.startStopError[booking.id] = error.error.error;
          }
          
        }
      );
    }
  }

  finishBooking(booking:Booking) {
    if(confirm("The booking is finishing now?")) {
      this.startStopLoading[booking.id] = true;
      this.securedService.endBooking(booking.id)
        .subscribe(result => {
          this.startStopLoading[booking.id] = false;
          this.startStopSuccess[booking.id] = "Your booking finished";
          booking.booking_status = "COMPLETED";
          setTimeout(() => {
            this.startStopSuccess[booking.id] = "";
          }, 2000);
        },
        error => {
          this.startOtpLoading = false;
          if (error.status == 429 || error.status == 500) {
            alert(this.translate.instant('COMMON.500'));
          }
          if (error.status == 400) {
            this.startStopError[booking.id] = error.error.error;
          }
          
        }
      );
    }
  }

  isKeySet(obj: any, key: string): boolean {
    if(key in obj && obj[key])
      return true;
    return false;
  }

}
