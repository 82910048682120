/// <reference  types="@types/googlemaps"  />
import { Component, OnInit, ElementRef, ViewChild, AfterViewInit } from '@angular/core';
import { SecuredService } from '../services/secured.service';
import { JwtService } from '../services/jwt.service';
import { Driver } from '../interfaces/Driver';
import { Company } from '../interfaces/Company';
import { Location } from '../interfaces/Location';
import { DriverLocation } from '../interfaces/DriverLocation';
import { CommonService } from '../services/common.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Comment } from '../interfaces/Comment';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import * as $ from 'jquery';
import * as Globals from '../global';
import { TranslateService } from '@ngx-translate/core';
import { HttpEventType, HttpEvent } from '@angular/common/http';
import { DataSharingService } from '../services/data-sharing.service';
import SignaturePad from 'signature_pad';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {

  constructor(private securedService: SecuredService, private fb: FormBuilder,
    private commonService: CommonService,
    private jwtService: JwtService,
    private dataSharingService: DataSharingService,
    private route: ActivatedRoute,
    private router: Router,
    private translate: TranslateService

  ) { }

  @ViewChild('addresstext') addresstext:  ElementRef;
  @ViewChild('driverAddresstext') driverAddresstext:  ElementRef;
  @ViewChild('ownerDriverAddresstext') ownerDriverAddresstext:  ElementRef;
  @ViewChild('gmap') gmapElement:  any;
  @ViewChild('driverGmap') driverGmapElement:  any;

  years_driving = Array(35).fill(0).map((x,i)=>i);
  driver: Driver;
  company: Company;
  ownerDriver: any;
  loadingProfileSection: boolean = false;
  loading: boolean = false;
  isDriver: Boolean = false;
  isCompany: Boolean = false;
  isOwnerDriver: Boolean = false;
  profileEdit: boolean = false;
  showBooking: Boolean = false;
  locationLoading: Boolean = true;
  locationResultView: Boolean = false;
  locationSaving: Boolean = false;
  locationForm: FormGroup;
  driverLocationForm: FormGroup;
  ownerDriverLocationForm: FormGroup;
  companyLocations: Array<Location>;
  driverLocation: DriverLocation;
  locationListLoaded: Boolean = false;
  locationViewMoreUrl: string;
  licenceform: FormGroup;
  licenceSaving: Boolean = false;
  licenceResultView: Boolean = false;
  bankDetailform: FormGroup;
  bankDetailSaving: Boolean = false;
  bankDetailResultView: Boolean = false;
  bankDetailLoaded: Boolean = false;
  bankDetail: any;
  changePasswordform: FormGroup;
  changePasswordSaving: Boolean = false;
  changePasswordResultView: Boolean = false;
  changePasswordLoaded: Boolean = false;
  changePassword: any;
  changeEmailform: FormGroup;
  changeEmailSaving: Boolean = false;
  changeEmailResultView: Boolean = false;
  changeEmailLoaded: Boolean = false;
  changeEmail: any;
  changeMobileform: FormGroup;
  changeMobileSaving: Boolean = false;
  changeMobileResultView: Boolean = false;
  changeMobileLoaded: Boolean = false;
  changeMobile: any;
  healthSafetyform: FormGroup;
  healthSafetySaving: Boolean = false;
  healthSafetyResultView: Boolean = false;
  signaturePad: SignaturePad;
  @ViewChild('canvas') canvasEl: ElementRef;
  signatureImg: string;
  popupOpen: Boolean = false

  showEdit() {
    this.profileEdit=true;
    //$(".select2-container").show();
    $("#driver-city-select2").prop("disabled", false);
    $("#owner-driver-city-select2").prop("disabled", false);
    $("#company-city-select2").prop("disabled", false);
  }

  hideEdit() {
    this.profileEdit=false;
    //$(".select2-container").hide();
    $("#driver-city-select2").prop("disabled", true);
    $("#owner-driver-city-select2").prop("disabled", true);
    $("#company-city-select2").prop("disabled", true);
  }

  ownerDriverForm: FormGroup;
  validationErrorsOwnerDriverDetails = {
    years_driving: '',
    aboutme: '',
    previous_work: '',
    //hourly_rate: '',
    gender: '',
    dateofbirth: '',
    city: '',
    full_name: ''
  };
  
  submitOwnerDriverDetails() {
    if (!this.validateOwnerDriverDetailsForm()) {
      return;
    }
    let selectedCity = $('#owner-driver-city-select2').select2('data');
    if (!selectedCity[0]) {
      selectedCity = this.driver.city_id;
    } else {
      selectedCity = $('#owner-driver-city-select2').select2('data')[0].id;
      this.driver.city = $('#owner-driver-city-select2').select2('data')[0].text;
    }
    let body = JSON.stringify(
      {
        full_name: this.ownerDriverForm.controls.full_name.value,
        city_id: selectedCity,
        years_driving: this.ownerDriverForm.controls.years_driving.value,
        previous_work: this.ownerDriverForm.controls.previous_work.value,
        //hourly_rate: this.ownerDriverForm.controls.hourly_rate.value,
        gender: this.ownerDriverForm.controls.gender.value,
        dob: this.ownerDriverForm.controls.dateofbirth.value,
        aboutme: this.ownerDriverForm.controls.aboutme.value
      }
    );
    this.loading = true;
    /* this.securedService.updateOwnerDriverDetails(body)
      .subscribe(result => {
        this.loading = false;
        this.ownerDriver.full_name = this.ownerDriverForm.controls.full_name.value;
        this.ownerDriver.years_driving = this.ownerDriverForm.controls.years_driving.value;
        this.ownerDriver.previous_work = this.ownerDriverForm.controls.previous_work.value;
        //this.ownerDriver.hourly_rate = this.ownerDriverForm.controls.hourly_rate.value;
        this.ownerDriver.gender = this.ownerDriverForm.controls.gender.value;
        this.ownerDriver.dob = this.ownerDriverForm.controls.dateofbirth.value;
        this.ownerDriver.aboutme = this.ownerDriverForm.controls.aboutme.value;
        this.loadUserData();
        //this.jwtService.setLoggedInData(this.driver);
        this.profileEdit=false;
        $("#owner-driver-city-select2").prop("disabled", true);
      },
        error => {
          this.loading = false;
          //console.log(error);

          if (error.status == 422) {
            for (var i = 0; i < error.error.length; i++) {
              let element = error.error[i];
              if (element.field == 'full_name') {
                this.validationErrorsOwnerDriverDetails.full_name = element.message;
              }
              if (element.field == 'city_id') {
                this.validationErrorsOwnerDriverDetails.city = element.message;
              }
              if (element.field == 'years_driving') {
                this.validationErrorsOwnerDriverDetails.years_driving = element.message;
              }
              if (element.field == 'gender') {
                this.validationErrorsOwnerDriverDetails.gender = element.message;
              }
              
              if (element.field == 'previous_work') {
                this.validationErrorsOwnerDriverDetails.previous_work = element.message;
              }
              if (element.field == 'dob') {
                this.validationErrorsOwnerDriverDetails.dateofbirth = element.message;
              }
              if (element.field == 'aboutme') {
                this.validationErrorsOwnerDriverDetails.aboutme = element.message;
              }
            }
          }
          if (error.status == 429 || error.status == 500) {
            alert(this.translate.instant('COMMON.500'));
          }
        }
      ); */
  }

  validateOwnerDriverDetailsForm(): Boolean {
    this.resetValidationErrorsOwnerDriverDetails();
    if (!this.ownerDriverForm.controls.full_name.value) {
      this.validationErrorsOwnerDriverDetails.full_name = this.translate.instant('Full name required');
    }
    if (!this.ownerDriverForm.controls.years_driving.value) {
      if(this.ownerDriverForm.controls.years_driving.value===0) {
        this.validationErrorsOwnerDriverDetails.years_driving = '';
      } else {
        this.validationErrorsOwnerDriverDetails.years_driving = this.translate.instant('Year of driving required');
      }
      
    }
    if (!this.ownerDriverForm.controls.gender.value) {
      this.validationErrorsOwnerDriverDetails.gender = this.translate.instant('BECOME_A_DRIVER.gender_required');
    }
    if (this.validationErrorsOwnerDriverDetails.years_driving ||
      this.validationErrorsOwnerDriverDetails.full_name ||
      this.validationErrorsOwnerDriverDetails.gender) {
      return false;
    }
    return true;
  }

  resetValidationErrorsOwnerDriverDetails() {
    this.validationErrorsOwnerDriverDetails = {
      years_driving: '',
      aboutme: '',
      previous_work: '',
      //hourly_rate: '',
      gender: '',
      dateofbirth: '',
      city: '',
      full_name: ''
    };
  }

  driverForm: FormGroup;
  validationErrorsDriverDetails = {
    years_driving: '',
    aboutme: '',
    previous_work: '',
    //hourly_rate: '',
    gender: '',
    dateofbirth: '',
    city: '',
    full_name: ''
  };
  
  submitDriverDetails() {
    if (!this.validateDriverDetailsForm()) {
      return;
    }
    let selectedCity = $('#driver-city-select2').select2('data');
    if (!selectedCity[0]) {
      selectedCity = this.driver.city_id;
    } else {
      selectedCity = $('#driver-city-select2').select2('data')[0].id;
      this.driver.city = $('#driver-city-select2').select2('data')[0].text;
    }
    let body = JSON.stringify(
      {
        full_name: this.driverForm.controls.full_name.value,
        city_id: selectedCity,
        years_driving: this.driverForm.controls.years_driving.value,
        previous_work: this.driverForm.controls.previous_work.value,
        //hourly_rate: this.driverForm.controls.hourly_rate.value,
        gender: this.driverForm.controls.gender.value,
        dob: this.driverForm.controls.dateofbirth.value,
        aboutme: this.driverForm.controls.aboutme.value
      }
    );
    this.loading = true;
    this.securedService.updateDriverDetails(body)
      .subscribe(result => {
        this.loading = false;
        this.driver.full_name = this.driverForm.controls.full_name.value;
        this.driver.years_driving = this.driverForm.controls.years_driving.value;
        this.driver.previous_work = this.driverForm.controls.previous_work.value;
        //this.driver.hourly_rate = this.driverForm.controls.hourly_rate.value;
        this.driver.gender = this.driverForm.controls.gender.value;
        this.driver.dob = this.driverForm.controls.dateofbirth.value;
        this.driver.aboutme = this.driverForm.controls.aboutme.value;
        this.loadUserData();
        //this.jwtService.setLoggedInData(this.driver);
        this.profileEdit=false;
        $("#driver-city-select2").prop("disabled", true);
      },
        error => {
          this.loading = false;
          //console.log(error);

          if (error.status == 422) {
            for (var i = 0; i < error.error.length; i++) {
              let element = error.error[i];
              if (element.field == 'full_name') {
                this.validationErrorsDriverDetails.full_name = element.message;
              }
              if (element.field == 'city_id') {
                this.validationErrorsDriverDetails.city = element.message;
              }
              if (element.field == 'years_driving') {
                this.validationErrorsDriverDetails.years_driving = element.message;
              }
              if (element.field == 'gender') {
                this.validationErrorsDriverDetails.gender = element.message;
              }
              /* if (element.field == 'hourly_rate') {
                this.validationErrorsDriverDetails.hourly_rate = element.message;
              } */
              if (element.field == 'previous_work') {
                this.validationErrorsDriverDetails.previous_work = element.message;
              }
              if (element.field == 'dob') {
                this.validationErrorsDriverDetails.dateofbirth = element.message;
              }
              if (element.field == 'aboutme') {
                this.validationErrorsDriverDetails.aboutme = element.message;
              }
            }
          }
          if (error.status == 429 || error.status == 500) {
            alert(this.translate.instant('COMMON.500'));
          }
        }
      );
  }

  validateDriverDetailsForm(): Boolean {
    this.resetValidationErrorsDriverDetails();
    if (!this.driverForm.controls.full_name.value) {
      this.validationErrorsDriverDetails.full_name = this.translate.instant('Full name required');
    }
    if (!this.driverForm.controls.years_driving.value) {
      if(this.driverForm.controls.years_driving.value===0) {
        this.validationErrorsDriverDetails.years_driving = '';
      } else {
        this.validationErrorsDriverDetails.years_driving = this.translate.instant('Year of driving required');
      }
      
    }
    if (!this.driverForm.controls.gender.value) {
      this.validationErrorsDriverDetails.gender = this.translate.instant('BECOME_A_DRIVER.gender_required');
    }
    if (this.validationErrorsDriverDetails.years_driving ||
      this.validationErrorsDriverDetails.full_name ||
      this.validationErrorsDriverDetails.gender) {
      return false;
    }
    return true;
  }

  resetValidationErrorsDriverDetails() {
    this.validationErrorsDriverDetails = {
      years_driving: '',
      aboutme: '',
      previous_work: '',
      //hourly_rate: '',
      gender: '',
      dateofbirth: '',
      city: '',
      full_name: ''
    };
  }

  companyForm: FormGroup;
  validationErrorsCompanyDetails = {
    about_company: '',
    city: '',
    company_name: '',
    contact_name: '',
    company_contact_number: ''
  };

  submitCompanyDetails() {
    if (!this.validateCompanyDetailsForm()) {
      return;
    }
    let selectedCity = $('#company-city-select2').select2('data');
    if (!selectedCity[0]) {
      selectedCity = this.company.city_id;
    } else {
      selectedCity = $('#company-city-select2').select2('data')[0].id;
      this.company.city = $('#company-city-select2').select2('data')[0].text;
    }
    let body = JSON.stringify(
      {
        about_company: this.companyForm.controls.about_company.value,
        company_name: this.companyForm.controls.company_name.value,
        contact_name: this.companyForm.controls.contact_name.value,
        company_contact_number: this.companyForm.controls.company_contact_number.value,
        city_id: selectedCity,
      }
    );
    this.loading = true;
    this.securedService.updateCompanyDetails(body)
      .subscribe(result => {
        this.loading = false;
        this.company.company_name = this.companyForm.controls.company_name.value;
        this.company.contact_name = this.companyForm.controls.contact_name.value;
        this.company.company_contact_number = this.companyForm.controls.company_contact_number.value;
        this.company.about_company = this.companyForm.controls.about_company.value;
        this.loadUserData();
        //this.jwtService.setLoggedInData(this.company);
        this.profileEdit=false;
        $("#company-city-select2").prop("disabled", true);
      },
        error => {
          this.loading = false;
          if (error.status == 422) {
            for (var i = 0; i < error.error.length; i++) {
              let element = error.error[i];
              if (element.field == 'about_company') {
                this.validationErrorsCompanyDetails.about_company = element.message;
              }
              if (element.field == 'company_name') {
                this.validationErrorsCompanyDetails.company_name = element.message;
              }
              if (element.field == 'contact_name') {
                this.validationErrorsCompanyDetails.contact_name = element.message;
              }
              if (element.field == 'company_contact_number') {
                this.validationErrorsCompanyDetails.company_contact_number = element.message;
              }
              if (element.field == 'city_id') {
                this.validationErrorsCompanyDetails.city = element.message;
              }
            }
          }
          if (error.status == 429 || error.status == 500) {
            alert(this.translate.instant('COMMON.500'));
          }
        }
      );
  }

  validateCompanyDetailsForm(): Boolean {

    this.resetValidationErrorsCompanyDetails();
    
    if (!this.companyForm.controls.company_name.value) {
      this.validationErrorsCompanyDetails.company_name = this.translate.instant('Company name is required');
    }

    if (!this.companyForm.controls.contact_name.value) {
      this.validationErrorsCompanyDetails.contact_name = this.translate.instant('Contact name is required');
    }

    if (!this.companyForm.controls.company_contact_number.value) {
      this.validationErrorsCompanyDetails.company_contact_number = this.translate.instant('Company contact number is required');
    }
    

    if (this.validationErrorsCompanyDetails.company_name || this.validationErrorsCompanyDetails.contact_name || this.validationErrorsCompanyDetails.company_contact_number) {
      return false;
    }
    return true;
  }

  resetValidationErrorsCompanyDetails() {
    this.validationErrorsCompanyDetails = {
      about_company: '',
      city: '',
      company_name: '',
      contact_name: '',
      company_contact_number: ''
    };
  }

  

  ngOnInit(): void {
    this.isDriver = this.jwtService.isDriver;
    this.isCompany = this.jwtService.isCompany;
    this.isOwnerDriver = this.jwtService.isOwnerDriver;
    
    this.loadUserData();

    this.locationForm = this.fb.group({
        address_title: [''],
        name: [''],
        formatted_address: [''],
        address: [''],
        latitude: [''],
        longitude: ['']
    });

    this.ownerDriverLocationForm = this.fb.group({
        address_title: ['Home'],
        name: [''],
        formatted_address: [''],
        address: [''],
        latitude: [''],
        longitude: [''],
        distance_ready_to_travel: ['25']
    });

    this.driverLocationForm = this.fb.group({
        address_title: ['Home'],
        name: [''],
        formatted_address: [''],
        address: [''],
        latitude: [''],
        longitude: [''],
        distance_ready_to_travel: ['25']
    });

    this.licenceform = this.fb.group({
      licence_number: [''],
      check_code: [''],
      licence_front: [''],
      licence_back: [''],
      flt_certificate: [''],
      cpc_front: [''],
      cpc_back: [''],
      digicard_front: [''],
      digicard_back: ['']
    });

    this.healthSafetyform = this.fb.group({
      client_name: [this.company?.company_name, Validators.required],
      client_location: [this.company?.city, Validators.required],
      client_contact: [this.company?.contact_name, Validators.required],
      contact_detail: ['', Validators.required],
      manager_name: ['', Validators.required],
      manager_telephone: ['', Validators.required],
      current_insurance_employers_liability: [0, Validators.required],
      current_insurance_public_liability: [0, Validators.required],
      current_insurance_motor_vehicle: [0, Validators.required],
      policy_health_safety: [0, Validators.required],
      health_safety_law_poster: [0, Validators.required],
      supervision_temporary_workers_suitable_information: [0, Validators.required],
      supervision_someone_supervising_temporary_workers: [0, Validators.required],
      supervision_standards_of_supervision: [0, Validators.required],
      health_safety_instructions: [0, Validators.required],
      health_safety_briefing: [0, Validators.required],
      health_safety_personal_responsibilities: [0, Validators.required],
      health_safety_arrangements_for_customers: [0, Validators.required],
      first_aiders_available: [0, Validators.required],
      first_aiders_name1: [''],
      first_aiders_name2: [''],
      personal_protective_equipment: [0, Validators.required],
      personal_protective_make_sure: [0, Validators.required],
      personal_toilets_and_wash: [0, Validators.required],
      hazard_assess_risk: [0, Validators.required],
      finding_of_risk_assessment: [''],
      instructions_for_workers: ['']
    });
    

    this.bankDetailform = this.fb.group({
      account_number: [''],
      name: [''],
      sortcode: ['']
    });
    
    this.changePasswordform = this.fb.group({
      password: [''],
      new_password: [''],
      cnfpass: ['']
    });
    this.changeEmailform = this.fb.group({
      password: [''],
      new_email: ['']
    });
    this.changeMobileform = this.fb.group({
      password: [''],
      new_mobile: ['']
    });
  }

  loadUserData() {
    this.loading = true;
    this.securedService.userDetails().subscribe(
      result => {
        if(this.isDriver) {
          this.driver = result;
        } else if(this.isCompany) {
          this.company = result;
        }else if(this.isOwnerDriver) {
          this.ownerDriver = result;
        }
        this.loadDetails();
        this.loading = false;
        if(this.isDriver) {
          setTimeout(()=>{
            this.getDriverPlaceAutocomplete();
          }, 500);
        } else if(this.isOwnerDriver)  {
          setTimeout(()=>{
            this.getOwnerDriverPlaceAutocomplete();
          }, 500);
        }else {
          setTimeout(()=>{
            this.getPlaceAutocomplete();
          }, 500);
        }
      },
      error => {
        if (error.status == 401) {
          this.jwtService.logout();
          this.dataSharingService.isLoggedIn.next(false);
          this.router.navigate(['login']);
        }
        this.loading = false;
        if (error.status == 429 || error.status == 500) {
          alert(this.translate.instant('COMMON.500'));;

        }
      }
    );
  }

  loadDetails() {
    if(this.isDriver) {
      //this.driver = this.jwtService.getLoggedInData();
      this.driverForm = this.fb.group({
        years_driving: new FormControl(parseInt(this.driver.years_driving)),
        aboutme: [this.driver.aboutme],
        previous_work: [this.driver.previous_work],
        //hourly_rate: [this.driver.hourly_rate?this.driver.hourly_rate:'10.5'],
        gender: [this.driver.gender],
        dateofbirth: [this.driver.dob],
        full_name: [this.driver.full_name],
        city: [this.driver.city]
      });
      var mainThis = this;
      $(document).ready(function () {
        $('#driver-city-select2').select2({
          disabled: false,
          placeholder: mainThis.translate.instant(mainThis.driver.city),
          minimumInputLength: 2,
          ajax: {
            url: function (params) {
              var country = 232;
              var url = Globals.API_ENDPOINT + 'general/city/' + country + '?';
              return url + params.term;
            },
            data: function (params) {
              var query = {
                search: params.term,
                type: 'public'
              }
              // Query parameters will be ?search=[term]&type=public
              return query;
            },
            processResults: function (data) {
              // Transforms the top-level key of the response object from 'items' to 'results'
              let resultsData: any = [];
              for (let i = 0; i < data.length; i++) {
                resultsData.push({ id: data[i].id, text: data[i].city });
              }
              return {
                results: resultsData
              };
            }
          }
        });
        $("#driver-city-select2").prop("disabled", true);
        //$(".select2-container").hide();
      });
      this.loadLicenceDetail();
      this.loadBankDetail();
      this.loadDriverLocation();
    } else if(this.isOwnerDriver) {
      //this.driver = this.jwtService.getLoggedInData();
      this.ownerDriverForm = this.fb.group({
        years_driving: new FormControl(parseInt(this.ownerDriver.years_driving)),
        aboutme: [this.ownerDriver.aboutme],
        previous_work: [this.ownerDriver.previous_work],
        //hourly_rate: [this.ownerDriver.hourly_rate?this.driver.hourly_rate:'10.5'],
        gender: [this.ownerDriver.gender],
        dateofbirth: [this.ownerDriver.dob],
        full_name: [this.ownerDriver.full_name],
        city: [this.ownerDriver.city]
      });
      var mainThis = this;
      $(document).ready(function () {
        $('#owner-driver-city-select2').select2({
          disabled: false,
          placeholder: mainThis.translate.instant(mainThis.ownerDriver.city),
          minimumInputLength: 2,
          ajax: {
            url: function (params) {
              var country = 232;
              var url = Globals.API_ENDPOINT + 'general/city/' + country + '?';
              return url + params.term;
            },
            data: function (params) {
              var query = {
                search: params.term,
                type: 'public'
              }
              // Query parameters will be ?search=[term]&type=public
              return query;
            },
            processResults: function (data) {
              // Transforms the top-level key of the response object from 'items' to 'results'
              let resultsData: any = [];
              for (let i = 0; i < data.length; i++) {
                resultsData.push({ id: data[i].id, text: data[i].city });
              }
              return {
                results: resultsData
              };
            }
          }
        });
        $("#owner-driver-city-select2").prop("disabled", true);
        //$(".select2-container").hide();
      });
      /* this.loadLicenceDetail();
      this.loadBankDetail();
      this.loadDriverLocation(); */
    } else {
      //this.company = this.jwtService.getLoggedInData();
      this.companyForm = this.fb.group({
        about_company: [this.company.about_company],
        company_name: [this.company.company_name],
        contact_name: [this.company.contact_name],
        company_contact_number: [this.company.company_contact_number],
        city: [this.company.city]
      });
      
      var mainThis = this;
      $(document).ready(function () {
        $('#company-city-select2').select2({
          disabled: false,
          placeholder: mainThis.translate.instant(mainThis.company.city),
          minimumInputLength: 2,
          ajax: {
            url: function (params) {
              var country = 232;
              var url = Globals.API_ENDPOINT + 'general/city/' + country + '?';
              return url + params.term;
            },
            data: function (params) {
              var query = {
                search: params.term,
                type: 'public'
              }
              // Query parameters will be ?search=[term]&type=public
              return query;
            },
            processResults: function (data) {
              // Transforms the top-level key of the response object from 'items' to 'results'
              let resultsData: any = [];
              for (let i = 0; i < data.length; i++) {
                resultsData.push({ id: data[i].id, text: data[i].city });
              }
              return {
                results: resultsData
              };
            }
          }
        });
        $("#company-city-select2").prop("disabled", true);
        //$(".select2-container").hide();
      });

      this.loadLocations();
    }
  }

  profileImageError:string="";

  onProfileImageSelect(event) {
    event.target.files[0];
    let formData = new FormData();
    this.profileImageError="";
    formData.append("profile_image", event.target.files[0]);
    this.loadingProfileSection = true;
    this.securedService.uploadProfileImage(formData)
      .subscribe(
        result => {
          this.loadingProfileSection = false;
          if(this.isDriver) {
            this.driver.profile_image = result.profile_image;
            this.jwtService.setLoggedInData(this.driver);
          } else {
            this.company.company_logo = result.profile_image;
            this.jwtService.setLoggedInData(this.company);
          }
        },
        error => {
            this.loadingProfileSection = false;
            if (error.status == 422) {
              for (var i = 0; i < error.error.length; i++) {
                let element = error.error[i];
                if (element.field == 'profile_image') {
                  this.profileImageError = element.message;
                }
              }
            }
            if (error.status == 429 || error.status == 500) {
              alert(this.translate.instant('COMMON.500'));;
            }
        }
      );
  }

  showBookingDetail(value) {
    this.showBooking = value;
  }

  validationErrorsLocationDetails = {
    address_title: '',
    name: '',
    formatted_address: '',
    address: '',
    latitude: '',
    longitude: ''
  };

  validationErrorsOwnerDriverLocationDetails = {
    address_title: '',
    name: '',
    formatted_address: '',
    address: '',
    latitude: '',
    longitude: '',
    distance_ready_to_travel: ''
  };

  validationErrorsDriverLocationDetails = {
    address_title: '',
    name: '',
    formatted_address: '',
    address: '',
    latitude: '',
    longitude: '',
    distance_ready_to_travel: ''
  };

  addLocation() {
    if (!this.validateLocationDetailsForm()) {
      return;
    }
    let body = JSON.stringify(
      {
        address_title: this.locationForm.controls.address_title.value,
        name: this.locationForm.controls.name.value,
        formatted_address: this.locationForm.controls.formatted_address.value,
        address: this.locationForm.controls.address.value,
        latitude: this.locationForm.controls.latitude.value,
        longitude: this.locationForm.controls.longitude.value
      }
    );
    this.locationSaving = true;
    this.securedService.addLocation(body)
      .subscribe(result => {
        this.locationSaving = false;
        this.locationResultView = true;
        this.companyLocations.push(result);
      },
        error => {
          this.locationSaving = false;
          if (error.status == 422) {
            for (var i = 0; i < error.error.length; i++) {
              let element = error.error[i];
              if (element.field == 'address_title') {
                this.validationErrorsLocationDetails.address_title = element.message;
              }
              if (element.field == 'name') {
                this.validationErrorsLocationDetails.name = element.message;
              }
              if (element.field == 'formatted_address') {
                this.validationErrorsLocationDetails.formatted_address = element.message;
              }
              if (element.field == 'address') {
                this.validationErrorsLocationDetails.address = element.message;
              }
              if (element.field == 'latitude') {
                this.validationErrorsLocationDetails.latitude = element.message;
              }
              if (element.field == 'longitude') {
                this.validationErrorsLocationDetails.longitude = element.message;
              }
            }
          }
          if (error.status == 429 || error.status == 500) {
            alert(this.translate.instant('COMMON.500'));
          }
        }
      );
  }

  addOwnerDriverLocation() {
    if (!this.validateOwnerDriverLocationDetailsForm()) {
      return;
    }
    let body = JSON.stringify(
      {
        address_title: this.ownerDriverLocationForm.controls.address_title.value,
        name: this.ownerDriverLocationForm.controls.name.value,
        formatted_address: this.ownerDriverLocationForm.controls.formatted_address.value,
        address: this.ownerDriverLocationForm.controls.address.value,
        latitude: this.ownerDriverLocationForm.controls.latitude.value,
        longitude: this.ownerDriverLocationForm.controls.longitude.value,
        distance_ready_to_travel: this.ownerDriverLocationForm.controls.distance_ready_to_travel.value
      }
    );
    this.locationSaving = true;
    /* this.securedService.addOwnerDriverLocation(body)
      .subscribe(result => {
        this.locationSaving = false;
        this.locationResultView = true;
        this.driverLocation = result;
      },
        error => {
          this.locationSaving = false;
          if (error.status == 422) {
            for (var i = 0; i < error.error.length; i++) {
              let element = error.error[i];
              if (element.field == 'address_title') {
                this.validationErrorsOwnerDriverLocationDetails.address_title = element.message;
              }
              if (element.field == 'name') {
                this.validationErrorsOwnerDriverLocationDetails.name = element.message;
              }
              if (element.field == 'formatted_address') {
                this.validationErrorsOwnerDriverLocationDetails.formatted_address = element.message;
              }
              if (element.field == 'address') {
                this.validationErrorsOwnerDriverLocationDetails.address = element.message;
              }
              if (element.field == 'latitude') {
                this.validationErrorsOwnerDriverLocationDetails.latitude = element.message;
              }
              if (element.field == 'longitude') {
                this.validationErrorsOwnerDriverLocationDetails.longitude = element.message;
              }
              if (element.field == 'distance_ready_to_travel') {
                this.validationErrorsOwnerDriverLocationDetails.distance_ready_to_travel = element.message;
              }
            }
          }
          if (error.status == 429 || error.status == 500) {
            alert(this.translate.instant('COMMON.500'));
          }
        }
      ); */
  }


  
  addDriverLocation() {
    if (!this.validateDriverLocationDetailsForm()) {
      return;
    }
    let body = JSON.stringify(
      {
        address_title: this.driverLocationForm.controls.address_title.value,
        name: this.driverLocationForm.controls.name.value,
        formatted_address: this.driverLocationForm.controls.formatted_address.value,
        address: this.driverLocationForm.controls.address.value,
        latitude: this.driverLocationForm.controls.latitude.value,
        longitude: this.driverLocationForm.controls.longitude.value,
        distance_ready_to_travel: this.driverLocationForm.controls.distance_ready_to_travel.value
      }
    );
    this.locationSaving = true;
    this.securedService.addDriverLocation(body)
      .subscribe(result => {
        this.locationSaving = false;
        this.locationResultView = true;
        this.driverLocation = result;
      },
        error => {
          this.locationSaving = false;
          if (error.status == 422) {
            for (var i = 0; i < error.error.length; i++) {
              let element = error.error[i];
              if (element.field == 'address_title') {
                this.validationErrorsDriverLocationDetails.address_title = element.message;
              }
              if (element.field == 'name') {
                this.validationErrorsDriverLocationDetails.name = element.message;
              }
              if (element.field == 'formatted_address') {
                this.validationErrorsDriverLocationDetails.formatted_address = element.message;
              }
              if (element.field == 'address') {
                this.validationErrorsDriverLocationDetails.address = element.message;
              }
              if (element.field == 'latitude') {
                this.validationErrorsDriverLocationDetails.latitude = element.message;
              }
              if (element.field == 'longitude') {
                this.validationErrorsDriverLocationDetails.longitude = element.message;
              }
              if (element.field == 'distance_ready_to_travel') {
                this.validationErrorsDriverLocationDetails.distance_ready_to_travel = element.message;
              }
            }
          }
          if (error.status == 429 || error.status == 500) {
            alert(this.translate.instant('COMMON.500'));
          }
        }
      );
  }

  validateLocationDetailsForm(): Boolean {
    this.resetValidationErrorsLocationDetails();
    if (!this.locationForm.controls.address_title.value) {
      this.validationErrorsLocationDetails.address_title = 'Address saved as required';
    }
    if (!this.locationForm.controls.name.value) {
      this.validationErrorsLocationDetails.name = 'Select a valid address';
    }
    if (!this.locationForm.controls.formatted_address.value) {
      this.validationErrorsLocationDetails.formatted_address = 'Select a valid address';
    }
    if (!this.locationForm.controls.address.value) {
      this.validationErrorsLocationDetails.address = 'Select a valid address';
    }

    if (!this.locationForm.controls.latitude.value) {
      this.validationErrorsLocationDetails.latitude = 'Select a valid address';
    }
    if (!this.locationForm.controls.longitude.value) {
      this.validationErrorsLocationDetails.longitude = 'Select a valid address';
    }

    if (this.validationErrorsLocationDetails.address_title ||
      this.validationErrorsLocationDetails.name ||
      this.validationErrorsLocationDetails.address ||
      this.validationErrorsLocationDetails.formatted_address ||
      this.validationErrorsLocationDetails.latitude ||
      this.validationErrorsLocationDetails.longitude) {
      return false;
    }
    return true;
  }

  validateOwnerDriverLocationDetailsForm(): Boolean {
    this.resetValidationErrorsOwnerDriverLocationDetails();
    if (!this.ownerDriverLocationForm.controls.address_title.value) {
      this.validationErrorsOwnerDriverLocationDetails.address_title = 'Address saved as required';
    }
    if (!this.ownerDriverLocationForm.controls.name.value) {
      this.validationErrorsOwnerDriverLocationDetails.name = 'Select a valid address';
    }
    if (!this.ownerDriverLocationForm.controls.formatted_address.value) {
      this.validationErrorsOwnerDriverLocationDetails.formatted_address = 'Select a valid address';
    }
    if (!this.ownerDriverLocationForm.controls.address.value) {
      this.validationErrorsOwnerDriverLocationDetails.address = 'Select a valid address';
    }

    if (!this.ownerDriverLocationForm.controls.latitude.value) {
      this.validationErrorsOwnerDriverLocationDetails.latitude = 'Select a valid address';
    }
    if (!this.ownerDriverLocationForm.controls.longitude.value) {
      this.validationErrorsOwnerDriverLocationDetails.longitude = 'Select a valid address';
    }
    if (!this.ownerDriverLocationForm.controls.distance_ready_to_travel.value) {
      this.validationErrorsOwnerDriverLocationDetails.distance_ready_to_travel = 'Select a distance ready to travel';
    }

    if (this.validationErrorsOwnerDriverLocationDetails.address_title ||
      this.validationErrorsOwnerDriverLocationDetails.name ||
      this.validationErrorsOwnerDriverLocationDetails.address ||
      this.validationErrorsOwnerDriverLocationDetails.formatted_address ||
      this.validationErrorsOwnerDriverLocationDetails.latitude ||
      this.validationErrorsOwnerDriverLocationDetails.longitude ||
      this.validationErrorsOwnerDriverLocationDetails.distance_ready_to_travel) {
      return false;
    }
    return true;
  }

  validateDriverLocationDetailsForm(): Boolean {
    this.resetValidationErrorsDriverLocationDetails();
    if (!this.driverLocationForm.controls.address_title.value) {
      this.validationErrorsDriverLocationDetails.address_title = 'Address saved as required';
    }
    if (!this.driverLocationForm.controls.name.value) {
      this.validationErrorsDriverLocationDetails.name = 'Select a valid address';
    }
    if (!this.driverLocationForm.controls.formatted_address.value) {
      this.validationErrorsDriverLocationDetails.formatted_address = 'Select a valid address';
    }
    if (!this.driverLocationForm.controls.address.value) {
      this.validationErrorsDriverLocationDetails.address = 'Select a valid address';
    }

    if (!this.driverLocationForm.controls.latitude.value) {
      this.validationErrorsDriverLocationDetails.latitude = 'Select a valid address';
    }
    if (!this.driverLocationForm.controls.longitude.value) {
      this.validationErrorsDriverLocationDetails.longitude = 'Select a valid address';
    }
    if (!this.driverLocationForm.controls.distance_ready_to_travel.value) {
      this.validationErrorsDriverLocationDetails.distance_ready_to_travel = 'Select a distance ready to travel';
    }

    if (this.validationErrorsDriverLocationDetails.address_title ||
      this.validationErrorsDriverLocationDetails.name ||
      this.validationErrorsDriverLocationDetails.address ||
      this.validationErrorsDriverLocationDetails.formatted_address ||
      this.validationErrorsDriverLocationDetails.latitude ||
      this.validationErrorsDriverLocationDetails.longitude ||
      this.validationErrorsDriverLocationDetails.distance_ready_to_travel) {
      return false;
    }
    return true;
  }

  resetValidationErrorsOwnerDriverLocationDetails() {
    this.validationErrorsOwnerDriverLocationDetails = {
      address_title: '',
      name: '',
      formatted_address: '',
      address: '',
      latitude: '',
      longitude: '',
      distance_ready_to_travel: ''
    };
  }


  resetValidationErrorsLocationDetails() {
    this.validationErrorsLocationDetails = {
      address_title: '',
      name: '',
      formatted_address: '',
      address: '',
      latitude: '',
      longitude: ''
    };
  }

  resetValidationErrorsDriverLocationDetails() {
    this.validationErrorsDriverLocationDetails = {
      address_title: '',
      name: '',
      formatted_address: '',
      address: '',
      latitude: '',
      longitude: '',
      distance_ready_to_travel: ''
    };
  }

  ngAfterViewInit():  void {
    this.signaturePad = new SignaturePad(this.canvasEl.nativeElement);
    //this.getPlaceAutocomplete();
  }

  getOwnerDriverPlaceAutocomplete() {
    const  mapOptions  = {
      zoom:  15
    };
    const  map  =  new  google.maps.Map(this.gmapElement.nativeElement, mapOptions);
    const autocomplete  =  new  google.maps.places.Autocomplete(this.ownerDriverAddresstext.nativeElement,
    {
      componentRestrictions: { country:  'UK' },
      types: ['establishment', 'geocode'] 

    });
    google.maps.event.addListener(autocomplete, 'place_changed', () => {
      this.locationLoading = false;
      const  place  =  autocomplete.getPlace();
      const  myLatlng  =  place.geometry.location;
      /* const  mapOptions  = {
        zoom:  15,
        center:  myLatlng
      }; */
      map.setOptions({ center: myLatlng });
      const  marker  =  new  google.maps.Marker({
        position:  myLatlng,
        title:  place.name
      });
      marker.setMap(map);
      
      this.ownerDriverLocationForm.controls['address'].setValue(place.formatted_address);
      this.ownerDriverLocationForm.controls['formatted_address'].setValue(place.formatted_address);
      this.ownerDriverLocationForm.controls['name'].setValue(place.name);
      this.ownerDriverLocationForm.controls['latitude'].setValue(place.geometry.location.lat());
      this.ownerDriverLocationForm.controls['longitude'].setValue(place.geometry.location.lng());
      
    });
  }

  getDriverPlaceAutocomplete() {
    const  mapOptions  = {
      zoom:  15
    };
    const  map  =  new  google.maps.Map(this.driverGmapElement.nativeElement, mapOptions);
    const autocomplete  =  new  google.maps.places.Autocomplete(this.driverAddresstext.nativeElement,
    {
      componentRestrictions: { country:  'UK' },
      types: ['establishment', 'geocode'] 

    });
    google.maps.event.addListener(autocomplete, 'place_changed', () => {
      this.locationLoading = false;
      const  place  =  autocomplete.getPlace();
      const  myLatlng  =  place.geometry.location;
      /* const  mapOptions  = {
        zoom:  15,
        center:  myLatlng
      }; */
      map.setOptions({ center: myLatlng });
      const  marker  =  new  google.maps.Marker({
        position:  myLatlng,
        title:  place.name
      });
      marker.setMap(map);
      
      this.driverLocationForm.controls['address'].setValue(place.formatted_address);
      this.driverLocationForm.controls['formatted_address'].setValue(place.formatted_address);
      this.driverLocationForm.controls['name'].setValue(place.name);
      this.driverLocationForm.controls['latitude'].setValue(place.geometry.location.lat());
      this.driverLocationForm.controls['longitude'].setValue(place.geometry.location.lng());
      
    });
  }
  
  getPlaceAutocomplete() {
    const  mapOptions  = {
      zoom:  15
    };
    const  map  =  new  google.maps.Map(this.gmapElement.nativeElement, mapOptions);
    const autocomplete  =  new  google.maps.places.Autocomplete(this.addresstext.nativeElement,
    {
      componentRestrictions: { country:  'UK' },
      types: ['establishment', 'geocode'] 

    });
    google.maps.event.addListener(autocomplete, 'place_changed', () => {
      this.locationLoading = false;
      const  place  =  autocomplete.getPlace();
      const  myLatlng  =  place.geometry.location;
      /* const  mapOptions  = {
        zoom:  15,
        center:  myLatlng
      }; */
      map.setOptions({ center: myLatlng });
      const  marker  =  new  google.maps.Marker({
        position:  myLatlng,
        title:  place.name
      });
      marker.setMap(map);
      
      this.locationForm.controls['address'].setValue(place.formatted_address);
      this.locationForm.controls['formatted_address'].setValue(place.formatted_address);
      this.locationForm.controls['name'].setValue(place.name);
      this.locationForm.controls['latitude'].setValue(place.geometry.location.lat());
      this.locationForm.controls['longitude'].setValue(place.geometry.location.lng());
      
    });
  }


  loadLocations() {
    this.securedService.getCompanyLocations("").subscribe(
      result => {
        this.locationListLoaded = true;
        this.companyLocations = result.items;
        if(this.companyLocations.length === 0) {
          setTimeout(()=>{
            document.getElementById("company-location-button").click();
          }, 500);
        } else {
          this.loadHealthSafety();
        }
        this.locationViewMoreUrl = result?._links?.next?.href;
      },
      error => {
        this.locationListLoaded = false;
        if (error.status == 429 || error.status == 500) {
          alert(this.translate.instant('COMMON.500'));

        }

      }
    );
  }

  loadDriverLocation() {
    this.securedService.getDriverLocations().subscribe(
      result => {
        this.locationListLoaded = true;
        this.driverLocation = result;
        if(!this.driverLocation && !this.popupOpen) {
          this.popupOpen = true;
          setTimeout(()=>{
            document.getElementById("driver-location-button").click();
          }, 500);
          
        }
      },
      error => {
        this.locationListLoaded = false;
        if (error.status == 429 || error.status == 500) {
          alert(this.translate.instant('COMMON.500'));

        }

      }
    );
  }


  loadHealthSafety() {
    this.securedService.getHealthSafety().subscribe(
      result => {
        if(!result && !this.popupOpen) {
          this.popupOpen = true;
          setTimeout(()=>{
            document.getElementById("health-safety-button").click();
          }, 500);
          
        }
      },
      error => {
        if (error.status == 429 || error.status == 500) {
          alert(this.translate.instant('COMMON.500'));
        }
      }
    );
  }

  loadBankDetail() {
    this.securedService.getBankDetail().subscribe(
      result => {
        this.bankDetailLoaded = true;
        this.bankDetail = result;
      },
      error => {
        this.bankDetailLoaded = false;
        if (error.status == 429 || error.status == 500) {
          alert(this.translate.instant('COMMON.500'));

        }

      }
    );
  }
  
  licenceRequired:any;
  loadLicenceDetail() {
    this.securedService.getLicenceDetail().subscribe(
      result => {
        this.licenceRequired = result;
        if(this.driver.licence_verified=='0' || this.licenceRequired) {
          this.popupOpen = true;
          setTimeout(()=>{
            document.getElementById("verify-licence-button").click();
          }, 500);
          
        }
      },
      error => {
        if (error.status == 429 || error.status == 500) {
          alert(this.translate.instant('COMMON.500'));
        }
      }
    );
  }

  deleteLocation(location: any, index: number) {
    if(confirm("Are you sure to remove this location?")) {
      this.locationListLoaded = false;

      this.securedService.deleteCompanyLocation(location.id).subscribe(
        result => {
          this.locationListLoaded = true;
          this.companyLocations.splice(index, 1);
        },
        error => {
          if (error.status == 429 || error.status == 500) {
            alert(this.translate.instant('COMMON.500'));

          }

        }
      );
    }
  }

  editDriverLocation(location: any) {

  }

  licence_front: any;
  licence_back: any;
  flt_certificate: any;
  cpc_front: any;
  cpc_back: any;
  digicard_front: any;
  digicard_back: any;

  licence_front_link: any;
  licence_back_link: any;
  flt_certificate_link: any;
  cpc_front_link: any;
  cpc_back_link: any;
  digicard_front_link: any;
  digicard_back_link: any;
  fileUploadProgress: number = 0;
  onFileChange(event,file_type) {
    this[file_type] = event.target.files[0];
  }


  validationErrorsLicenceDetails = {
    licence_number: '',
    check_code: '',
    licence_front: '',
    licence_back: '',
    flt_certificate: '',
    cpc_front: '',
    cpc_back: '',
    digicard_front: '',
    digicard_back: ''
  };

  resetValidationErrorsLicenceDetails() {
    this.validationErrorsLicenceDetails = {
      licence_number: '',
      check_code: '',
      licence_front: '',
      licence_back: '',
      flt_certificate: '',
      cpc_front: '',
      cpc_back: '',
      digicard_front: '',
      digicard_back: ''
    };
  }

  validateLicenceDetailsForm(): Boolean {

    this.resetValidationErrorsLicenceDetails();
    if(this.driver.driver_category_id=='5' && this.licenceRequired?.flt_certificate){
      if (!this.flt_certificate) {
        this.validationErrorsLicenceDetails.flt_certificate = 'FLT certificate is required';
        return false;
      }
      return this.checkUploadValid(this.flt_certificate, 'flt_certificate');
    }
    if(this.licenceRequired?.licence_number || this.licenceRequired?.check_code) {
      if (!this.licenceform.controls.licence_number.value) {
        this.validationErrorsLicenceDetails.licence_number = 'Last 8 characters of licence is required';
      }
      let remText = this.licenceform.controls.licence_number.value.replace(/\s/g, "")
      let licence_length = remText.length;
      if(licence_length!=8) {
        this.validationErrorsLicenceDetails.licence_number = 'Must have last 8 characters of licence';
      }
      if (!this.licenceform.controls.check_code.value) {
        this.validationErrorsLicenceDetails.check_code = 'Check code is required'
      }
      if (this.validationErrorsLicenceDetails.licence_number ||
        this.validationErrorsLicenceDetails.check_code) {
        return false;
      }
    }
    
    if(this.driver.driver_category_id!='5' && this.driver.driver_category_id!='6'){
      if (this.licence_front) {
        let valid = this.checkUploadValid(this.licence_front, 'licence_front');
        if(!valid) {
          return false;
        }
      }
      if (this.licence_back) {
        let valid = this.checkUploadValid(this.licence_back, 'licence_back');
      }

      if(this.driver.driver_category_id!='1'){
        if (this.cpc_front) {
          let valid = this.checkUploadValid(this.cpc_front, 'cpc_front');
          if(!valid) {
            return false;
          }
        }
        if (this.cpc_back) {
          let valid = this.checkUploadValid(this.cpc_back, 'cpc_back');
          if(!valid) {
            return false;
          }
        }
        if (this.digicard_front) {
          let valid = this.checkUploadValid(this.digicard_front, 'digicard_front');
          if(!valid) {
            return false;
          }
        }
        if (this.digicard_back) {
          let valid = this.checkUploadValid(this.digicard_back, 'digicard_back');
          if(!valid) {
            return false;
          }
        }
      }
    }/* 
    
    if (!this.licenceform.controls.licence_number.value) {
      this.validationErrorsLicenceDetails.licence_number = 'Last 8 characters of licence is required';
    }
    if (!this.licenceform.controls.check_code.value) {
      this.validationErrorsLicenceDetails.check_code = 'Check code is required'
    }
    if (this.validationErrorsLicenceDetails.licence_number ||
      this.validationErrorsLicenceDetails.check_code) {
      return false;
    } */
    return true;
  }

  isAValidImageExtension(ext: any) {

    if (!ext) {
      return false;
    }
    if (ext == 'png' || ext == 'jpg' || ext == 'jpeg' || ext == 'PNG' || ext == 'JPG' || ext == 'JPEG') {
      return true;
    }
    return false;


  }

  checkUploadValid(file, field: string) {
    let ext = file?.name?.split('.')?.pop();
    if (ext && ext != '' && !this.isAValidImageExtension(ext)) {
      this.validationErrorsLicenceDetails[field] =  'Only files with these extensions are allowed: png,jpg,jpeg';
      return false;  
    }
    return true;
  }

  submitLicenceDetails() {
    if (!this.validateLicenceDetailsForm()) {
      return;
    }

    /* let body = JSON.stringify(
      {
        licence_number: this.licenceform.controls.licence_number.value,
        check_code: this.licenceform.controls.check_code.value
      }
    ); */
    
    this.licenceSaving = true;

    const formData = new FormData();
    if(this.driver.driver_category_id=='5'){
      if(this.flt_certificate) {
        formData.append("flt_certificate", this.flt_certificate);
      }
    }
    if(this.driver.driver_category_id!='5' && this.driver.driver_category_id!='6'){
      if(this.licenceRequired?.licence_number || this.licenceRequired?.check_code) {
        formData.append("licence_number", this.licenceform.controls.licence_number.value);
        formData.append("check_code", this.licenceform.controls.check_code.value);
      }
      

      if (this.licence_front) {
        formData.append("licence_front", this.licence_front);
      }
      if (this.licence_back) {
        formData.append("licence_back", this.licence_back);
      }

      if(this.driver.driver_category_id!='1'){
        if (this.cpc_front) {
          formData.append("cpc_front", this.cpc_front);
        }
        if (this.cpc_back) {
          formData.append("cpc_back", this.cpc_back);
        }
        if (this.digicard_front) {
          formData.append("digicard_front", this.digicard_front);
        }
        if (this.digicard_back) {
          formData.append("digicard_back", this.digicard_back);
        }
      }
    }


    this.securedService.addLicenceDetailsUpload(formData)
      .subscribe((event: HttpEvent<any>) => { 
        switch (event.type) {
          /* case HttpEventType.Sent:
            console.log('Request has been made!');
            break;
          case HttpEventType.ResponseHeader:
            console.log('Response header has been received!');
            break; */
          case HttpEventType.UploadProgress:
            this.fileUploadProgress = Math.round(event.loaded / event.total * 100);
            //console.log(`Uploaded! ${this.fileUploadProgress}%`);
            break;
          case HttpEventType.Response:
            this.licenceSaving = false;
            this.licenceResultView = true;
            if(this.driver.licence_verified=='0'){
              this.driver.licence_verified = '1';
            }
        }
      },
      result => {
        this.fileUploadProgress = 0;
        this.licenceSaving = false;
        if (result.ok) {
          this.licenceResultView = true;
          if(this.driver.licence_verified=='0'){
            this.driver.licence_verified = '1';
          }
        } else {
          if (result.status == 422) {
            for (var i = 0; i < result.error.length; i++) {
              let element = result.error[i];
              if (element.field == 'licence_number') {
                this.validationErrorsLicenceDetails.licence_number = element.message;
              }
              if (element.field == 'check_code') {
                this.validationErrorsLicenceDetails.check_code = element.message;
              }
            }
          }
          if (result.status == 429 || result.status == 500) {
            alert(this.translate.instant('COMMON.500'));
          }

        }
      },
    );


    /* this.securedService.addLicenceDetails(formData)
      .subscribe(result => {
        this.licenceSaving = false;
        this.licenceResultView = true;
        if(this.driver.licence_verified=='0'){
          this.driver.licence_verified = '1';
        }
      },
        error => {
          this.licenceSaving = false;
          if (error.status == 422) {
            for (var i = 0; i < error.error.length; i++) {
              let element = error.error[i];
              if (element.field == 'licence_number') {
                this.validationErrorsLicenceDetails.licence_number = element.message;
              }
              if (element.field == 'check_code') {
                this.validationErrorsLicenceDetails.check_code = element.message;
              }
            }
          }
          if (error.status == 429 || error.status == 500) {
            alert(this.translate.instant('COMMON.500'));
          }

        }
      ); */
  }


  validationErrorsBankDetail = {
    account_number: '',
    name: '',
    sortcode: ''
  };

  resetValidationErrorsBankDetail() {
    this.validationErrorsBankDetail = {
      account_number: '',
      name: '',
      sortcode: ''
    };
  }

  validateBankDetailForm(): Boolean {

    this.resetValidationErrorsBankDetail();
    
    if (!this.bankDetailform.controls.account_number.value) {
      this.validationErrorsBankDetail.account_number = 'Account Number is required';
    }
    if (!this.bankDetailform.controls.name.value) {
      this.validationErrorsBankDetail.name = 'Name is required'
    }
    if (!this.bankDetailform.controls.sortcode.value) {
      this.validationErrorsBankDetail.sortcode = 'Sortcode is required'
    }
    if (this.validationErrorsBankDetail.account_number ||
      this.validationErrorsBankDetail.name ||
      this.validationErrorsBankDetail.sortcode) {
      return false;
    }
    return true;
  }

  submitBankDetail() {
    if (!this.validateBankDetailForm()) {
      return;
    }

    let body = JSON.stringify(
      {
        account_number: this.bankDetailform.controls.account_number.value,
        name: this.bankDetailform.controls.name.value,
        sortcode: this.bankDetailform.controls.sortcode.value
      }
    );

    this.bankDetailSaving = true;
    this.securedService.addBankDetail(body)
      .subscribe(result => {
        this.bankDetailSaving = false;
        this.bankDetailResultView = true;
        this.bankDetailLoaded = true;
        this.bankDetail = result;
      },
        error => {
          this.bankDetailSaving = false;
          if (error.status == 422) {
            for (var i = 0; i < error.error.length; i++) {
              let element = error.error[i];
              if (element.field == 'account_number') {
                this.validationErrorsBankDetail.account_number = element.message;
              }
              if (element.field == 'name') {
                this.validationErrorsBankDetail.name = element.message;
              }
              if (element.field == 'sortcode') {
                this.validationErrorsBankDetail.sortcode = element.message;
              }
            }
          }
          if (error.status == 429 || error.status == 500) {
            alert(this.translate.instant('COMMON.500'));
          }

        }
      );
  }

  validationErrorsChangePassword = {
    password: '',
    new_password: '',
    cnfpass: ''
  };

  resetValidationErrorsChangePassword() {
    this.validationErrorsChangePassword = {
      password: '',
      new_password: '',
      cnfpass: ''
    };
  }

  validateChangePasswordForm(): Boolean {

    this.resetValidationErrorsChangePassword();
    
    if (!this.changePasswordform.controls.password.value) {
      this.validationErrorsChangePassword.password = 'Current password is required';
    }

    if (!this.changePasswordform.controls.new_password.value) {
      this.validationErrorsChangePassword.new_password = 'New password is required'
    }
    if (!this.changePasswordform.controls.cnfpass.value) {
      this.validationErrorsChangePassword.cnfpass = 'Confirm password is required'
    }

    if ((this.changePasswordform.controls.cnfpass.value || this.changePasswordform.controls.new_password.value) && this.changePasswordform.controls.cnfpass.value != this.changePasswordform.controls.new_password.value) {
      this.validationErrorsChangePassword.cnfpass = this.translate.instant('SIGNUP.confirm_password_not_matching');
      return;
    }

    if (this.validationErrorsChangePassword.password ||
      this.validationErrorsChangePassword.new_password ||
      this.validationErrorsChangePassword.cnfpass) {
      return false;
    }
    return true;
  }

  submitChangePassword() {
    if (!this.validateChangePasswordForm()) {
      return;
    }
    let body = JSON.stringify(
      {
        password: this.changePasswordform.controls.password.value,
        new_password: this.changePasswordform.controls.new_password.value
      }
    );
    this.changePasswordSaving = true;
    this.securedService.changePassword(body)
      .subscribe(result => {
        this.changePasswordSaving = false;
        this.changePasswordResultView = true;
        this.changePasswordLoaded = true;
        this.changePassword = result;
        setTimeout(()=>{
          window.location.reload();
        }, 1700);
      },
        error => {
          this.changePasswordSaving = false;
          if (error.status == 422) {
            for (var i = 0; i < error.error.length; i++) {
              let element = error.error[i];
              if (element.field == 'password') {
                this.validationErrorsChangePassword.password = element.message;
              }
              if (element.field == 'new_password') {
                this.validationErrorsChangePassword.new_password = element.message;
              }
            }
          }
          if (error.status == 429 || error.status == 500) {
            alert(this.translate.instant('COMMON.500'));
          }
        }
      );
  }

  validationErrorsChangeEmail = {
    password: '',
    new_email: ''
  };

  resetValidationErrorsChangeEmail() {
    this.validationErrorsChangeEmail = {
      password: '',
      new_email: ''
    };
  }

  validateChangeEmailForm(): Boolean {
    this.resetValidationErrorsChangeEmail();
    if (!this.changeEmailform.controls.password.value) {
      this.validationErrorsChangeEmail.password = 'Your password is required';
    }
    if (!this.changeEmailform.controls.new_email.value) {
      this.validationErrorsChangeEmail.new_email = 'Email is required'
    }
    if (this.validationErrorsChangeEmail.password ||
      this.validationErrorsChangeEmail.new_email) {
      return false;
    }
    return true;
  }

  submitChangeEmail() {
    if (!this.validateChangeEmailForm()) {
      return;
    }
    let body = JSON.stringify(
      {
        password: this.changeEmailform.controls.password.value,
        new_email: this.changeEmailform.controls.new_email.value
      }
    );
    this.changeEmailSaving = true;
    this.securedService.changeEmail(body)
      .subscribe(result => {
        this.changeEmailSaving = false;
        this.changeEmailResultView = true;
        this.changeEmailLoaded = true;
        this.changeEmail = result;
        setTimeout(()=>{
          window.location.reload();
        }, 1700);
      },
        error => {
          this.changeEmailSaving = false;
          if (error.status == 422) {
            for (var i = 0; i < error.error.length; i++) {
              let element = error.error[i];
              if (element.field == 'password') {
                this.validationErrorsChangeEmail.password = element.message;
              }
              if (element.field == 'new_email') {
                this.validationErrorsChangeEmail.new_email = element.message;
              }
            }
          }
          if (error.status == 429 || error.status == 500) {
            alert(this.translate.instant('COMMON.500'));
          }
        }
      );
  }


  validationErrorsChangeMobile = {
    password: '',
    new_mobile: ''
  };

  resetValidationErrorsChangeMobile() {
    this.validationErrorsChangeMobile = {
      password: '',
      new_mobile: ''
    };
  }

  validateChangeMobileForm(): Boolean {

    this.resetValidationErrorsChangeMobile();
    if (!this.changeMobileform.controls.password.value) {
      this.validationErrorsChangeMobile.password = 'Your password is required';
    }
    if (!this.changeMobileform.controls.new_mobile.value) {
      this.validationErrorsChangeMobile.new_mobile = 'New password is required'
    }
    if (this.validationErrorsChangeMobile.password ||
      this.validationErrorsChangeMobile.new_mobile) {
      return false;
    }
    return true;
  }

  submitChangeMobile() {
    if (!this.validateChangeMobileForm()) {
      return;
    }
    let body = JSON.stringify(
      {
        password: this.changeMobileform.controls.password.value,
        new_mobile: this.changeMobileform.controls.new_mobile.value
      }
    );
    this.changeMobileSaving = true;
    this.securedService.changeMobile(body)
      .subscribe(result => {
        this.changeMobileSaving = false;
        this.changeMobileResultView = true;
        this.changeMobileLoaded = true;
        this.changeMobile = result;
        setTimeout(()=>{
          window.location.reload();
        }, 1700);
      },
        error => {
          this.changeMobileSaving = false;
          if (error.status == 422) {
            for (var i = 0; i < error.error.length; i++) {
              let element = error.error[i];
              if (element.field == 'password') {
                this.validationErrorsChangeMobile.password = element.message;
              }
              if (element.field == 'new_mobile') {
                this.validationErrorsChangeMobile.new_mobile = element.message;
              }
            }
          }
          if (error.status == 429 || error.status == 500) {
            alert(this.translate.instant('COMMON.500'));
          }
        }
      );
  }



  validationErrorsHealthSafety = {
    client_name: '',
    client_location: '',
    client_contact: '',
    contact_detail: '',
    manager_name: '',
    manager_telephone: '',
    current_insurance_employers_liability: '',
    current_insurance_public_liability: '',
    current_insurance_motor_vehicle: '',
    policy_health_safety: '',
    health_safety_law_poster: '',
    supervision_temporary_workers_suitable_information: '',
    supervision_someone_supervising_temporary_workers: '',
    supervision_standards_of_supervision: '',
    health_safety_instructions: '',
    health_safety_briefing: '',
    health_safety_personal_responsibilities: '',
    health_safety_arrangements_for_customers: '',
    first_aiders_available: '',
    first_aiders_name1: '',
    first_aiders_name2: '',
    personal_protective_equipment: '',
    personal_protective_make_sure: '',
    personal_toilets_and_wash: '',
    hazard_assess_risk: '',
    finding_of_risk_assessment: '',
    instructions_for_workers: '',
    signature: ''
  };

  resetValidationErrorsHealthSafety() {
    this.validationErrorsHealthSafety = {
      client_name: '',
      client_location: '',
      client_contact: '',
      contact_detail: '',
      manager_name: '',
      manager_telephone: '',
      current_insurance_employers_liability: '',
      current_insurance_public_liability: '',
      current_insurance_motor_vehicle: '',
      policy_health_safety: '',
      health_safety_law_poster: '',
      supervision_temporary_workers_suitable_information: '',
      supervision_someone_supervising_temporary_workers: '',
      supervision_standards_of_supervision: '',
      health_safety_instructions: '',
      health_safety_briefing: '',
      health_safety_personal_responsibilities: '',
      health_safety_arrangements_for_customers: '',
      first_aiders_available: '',
      first_aiders_name1: '',
      first_aiders_name2: '',
      personal_protective_equipment: '',
      personal_protective_make_sure: '',
      personal_toilets_and_wash: '',
      hazard_assess_risk: '',
      finding_of_risk_assessment: '',
      instructions_for_workers: '',
      signature: ''
    };
  }

  validateHealthSafetyForm(): Boolean {

    this.resetValidationErrorsHealthSafety();
    if(this.signaturePad.isEmpty()) {
      this.validationErrorsHealthSafety.signature = 'Please provide a signature';
      return false;
    }
    /* if (!this.healthSafetyform.controls.client_name.value) {
      this.validationErrorsHealthSafety.client_name = 'Your field is required';
    }
    if (!this.healthSafetyform.controls.new_mobile.value) {
      this.validationErrorsHealthSafety.new_mobile = 'New password is required'
    }
    if (this.validationErrorsHealthSafety.password ||
      this.validationErrorsHealthSafety.new_mobile) {
      return false;
    } */
    return true;
  }

  submitHealthSafety() {
    if (!this.validateHealthSafetyForm()) {
      return;
    }
    let body = JSON.stringify(
      {
        client_name: this.healthSafetyform.controls.client_name.value,
        client_location: this.healthSafetyform.controls.client_location.value,
        client_contact: this.healthSafetyform.controls.client_contact.value,
        contact_detail: this.healthSafetyform.controls.contact_detail.value,
        manager_name: this.healthSafetyform.controls.manager_name.value,
        manager_telephone: this.healthSafetyform.controls.manager_telephone.value,
        current_insurance_employers_liability: this.healthSafetyform.controls.current_insurance_employers_liability.value,
        current_insurance_public_liability: this.healthSafetyform.controls.current_insurance_public_liability.value,
        current_insurance_motor_vehicle: this.healthSafetyform.controls.current_insurance_motor_vehicle.value,
        policy_health_safety: this.healthSafetyform.controls.policy_health_safety.value,
        health_safety_law_poster: this.healthSafetyform.controls.health_safety_law_poster.value,
        supervision_temporary_workers_suitable_information: this.healthSafetyform.controls.supervision_temporary_workers_suitable_information.value,
        supervision_someone_supervising_temporary_workers: this.healthSafetyform.controls.supervision_someone_supervising_temporary_workers.value,
        supervision_standards_of_supervision: this.healthSafetyform.controls.supervision_standards_of_supervision.value,
        health_safety_instructions: this.healthSafetyform.controls.health_safety_instructions.value,
        health_safety_briefing: this.healthSafetyform.controls.health_safety_briefing.value,
        health_safety_personal_responsibilities: this.healthSafetyform.controls.health_safety_personal_responsibilities.value,
        health_safety_arrangements_for_customers: this.healthSafetyform.controls.health_safety_arrangements_for_customers.value,
        first_aiders_available: this.healthSafetyform.controls.first_aiders_available.value,
        first_aiders_name1: this.healthSafetyform.controls.first_aiders_name1.value,
        first_aiders_name2: this.healthSafetyform.controls.first_aiders_name2.value,
        personal_protective_equipment: this.healthSafetyform.controls.personal_protective_equipment.value,
        personal_protective_make_sure: this.healthSafetyform.controls.personal_protective_make_sure.value,
        personal_toilets_and_wash: this.healthSafetyform.controls.personal_toilets_and_wash.value,
        hazard_assess_risk: this.healthSafetyform.controls.hazard_assess_risk.value,
        finding_of_risk_assessment: this.healthSafetyform.controls.finding_of_risk_assessment.value,
        instructions_for_workers: this.healthSafetyform.controls.instructions_for_workers.value,
        signature: this.signaturePad.toDataURL()
      }
    );
    this.healthSafetySaving = true;
    this.securedService.healthSafety(body)
      .subscribe(result => {
        this.healthSafetySaving = false;
        this.healthSafetyResultView = true;
        setTimeout(()=>{
          window.location.reload();
        }, 1700);
      },
        error => {
          this.healthSafetySaving = false;
          if (error.status == 422) {
            for (var i = 0; i < error.error.length; i++) {
              let element = error.error[i];
              if (element.field == 'client_name') {
                this.validationErrorsHealthSafety.client_name = element.message;
              }
              if (element.field == 'client_location') {
                this.validationErrorsHealthSafety.client_location = element.message;
              }
              if (element.field == 'client_contact') {
                this.validationErrorsHealthSafety.client_contact = element.message;
              }
              if (element.field == 'contact_detail') {
                this.validationErrorsHealthSafety.contact_detail = element.message;
              }
              if (element.field == 'manager_name') {
                this.validationErrorsHealthSafety.manager_name = element.message;
              }
              if (element.field == 'manager_telephone') {
                this.validationErrorsHealthSafety.manager_telephone = element.message;
              }
              if (element.field == 'current_insurance_employers_liability') {
                this.validationErrorsHealthSafety.current_insurance_employers_liability = element.message;
              }
              if (element.field == 'current_insurance_public_liability') {
                this.validationErrorsHealthSafety.current_insurance_public_liability = element.message;
              }
              if (element.field == 'current_insurance_motor_vehicle') {
                this.validationErrorsHealthSafety.current_insurance_motor_vehicle = element.message;
              }
              if (element.field == 'policy_health_safety') {
                this.validationErrorsHealthSafety.policy_health_safety = element.message;
              }
              if (element.field == 'health_safety_law_poster') {
                this.validationErrorsHealthSafety.health_safety_law_poster = element.message;
              }
              if (element.field == 'supervision_temporary_workers_suitable_information') {
                this.validationErrorsHealthSafety.supervision_temporary_workers_suitable_information = element.message;
              }
              if (element.field == 'supervision_someone_supervising_temporary_workers') {
                this.validationErrorsHealthSafety.supervision_someone_supervising_temporary_workers = element.message;
              }
              if (element.field == 'supervision_standards_of_supervision') {
                this.validationErrorsHealthSafety.supervision_standards_of_supervision = element.message;
              }
              if (element.field == 'health_safety_instructions') {
                this.validationErrorsHealthSafety.health_safety_instructions = element.message;
              }
              if (element.field == 'health_safety_briefing') {
                this.validationErrorsHealthSafety.health_safety_briefing = element.message;
              }
              if (element.field == 'health_safety_personal_responsibilities') {
                this.validationErrorsHealthSafety.health_safety_personal_responsibilities = element.message;
              }
              if (element.field == 'health_safety_arrangements_for_customers') {
                this.validationErrorsHealthSafety.health_safety_arrangements_for_customers = element.message;
              }
              if (element.field == 'first_aiders_available') {
                this.validationErrorsHealthSafety.first_aiders_available = element.message;
              }
              if (element.field == 'first_aiders_name1') {
                this.validationErrorsHealthSafety.first_aiders_name1 = element.message;
              }
              if (element.field == 'first_aiders_name2') {
                this.validationErrorsHealthSafety.first_aiders_name2 = element.message;
              }
              if (element.field == 'personal_protective_equipment') {
                this.validationErrorsHealthSafety.personal_protective_equipment = element.message;
              }
              if (element.field == 'personal_protective_make_sure') {
                this.validationErrorsHealthSafety.personal_protective_make_sure = element.message;
              }
              if (element.field == 'personal_toilets_and_wash') {
                this.validationErrorsHealthSafety.personal_toilets_and_wash = element.message;
              }
              if (element.field == 'hazard_assess_risk') {
                this.validationErrorsHealthSafety.hazard_assess_risk = element.message;
              }
              if (element.field == 'finding_of_risk_assessment') {
                this.validationErrorsHealthSafety.finding_of_risk_assessment = element.message;
              }
              if (element.field == 'instructions_for_workers') {
                this.validationErrorsHealthSafety.instructions_for_workers = element.message;
              }
              if (element.field == 'signature') {
                this.validationErrorsHealthSafety.signature = element.message;
              }
            }
          }
          if (error.status == 429 || error.status == 500) {
            alert(this.translate.instant('COMMON.500'));
          }
        }
      );
  }


  clearPad() {
    this.signaturePad.clear();
  }

  /* drawComplete() {
    console.log(this.signaturePad.toDataURL());
  }

  drawStart() {
    console.log('begin drawing');
  }

  clearSignature() {
    this.signaturePad.clear();
  }

  savePad() {
    const base64Data = this.signaturePad.toDataURL();
    this.signatureImg = base64Data;
  } */

}
