<footer class="dark-footer skin-dark-footer">
    <div>
        <div class="container">
            <div class="row">
                
                <div class="col-lg-4 col-md-4">
                    <div class="footer-widget">
                        <i class="logo-drivers2day" style="width: 100%;max-height:50px;"></i>
                        <div class="footer-add">
                            <p><strong>DRIVERS2DAY ONLINE LTD</strong></p>
                            <p><strong>Company Number : 15101133</strong></p>
                           <!--  <p><strong>VAT : 390 7163 86</strong></p> -->
                            <p>124 City Road, London,<br> England - EC1V 2NX</p>
                            <ul class="footer-bottom-social">
                                <li><a href="https://www.facebook.com/drivers2day" target="_blank"><i class="fa fa-facebook"></i></a></li>
                                <li><a href="https://www.linkedin.com/company/drivers2day" target="_blank"><i class="fa fa-linkedin"></i></a></li>
                            </ul>
                        </div>
                        
                    </div>
                </div>
                
                <div class="col-lg-4 col-md-4">
                    <div class="footer-widget">
                        <h4 class="widget-title">Contact</h4>
                        <div class="footer-add">
                            <p><strong>Email:</strong> <a href="mailto:contactus@drivers2day.co.uk" style="color: #858b9f;text-decoration: none;">contactus@drivers2day.co.uk</a></p>
                            <p><strong>Call:</strong> 0151 272 0464</p>
                            <li  class="list-inline-item">
                                <a [routerLink]="['/about']">About Us</a>
                            </li>
                            <li  class="list-inline-item">
                                <a [routerLink]="['/faq']">Help</a>
                            </li>
                            <li  class="list-inline-item" style="margin-bottom: 10px;">
                                <a [routerLink]="['/policies']">Policy</a>
                            </li>

                            <p> <a href="./assets/GQEA-certificate.pdf" target="_blank"><img src="./assets/images/GQEA-certificate.png"></a></p>
                            <br>
                            <!-- TrustBox widget - Micro Review Count -->
                            <div class="trustpilot-widget" data-locale="en-GB" data-template-id="5419b6a8b0d04a076446a9ad" data-businessunit-id="6163f1c8cf0989001db02926" data-style-height="24px" data-style-width="100%" data-theme="dark" data-min-review-count="10" data-style-alignment="center" style="border: 1px solid #ffff;">
                                <a href="https://uk.trustpilot.com/review/drivers2day.co.uk" target="_blank" rel="noopener">Trustpilot</a>
                            </div>
                            <!-- End TrustBox widget -->
                            
                        </div>
                    </div>
                </div>
                
                <div class="col-lg-4 col-md-4">
                    <div class="footer-widget">
                        <h4 class="widget-title">Download Apps</h4>
                        <a href="#" class="other-store-link">
                            <div class="other-store-app">
                                <div class="os-app-icon">
                                    <i class="fa fa-android theme-cl"></i>
                                </div>
                                <div class="os-app-caps">
                                    Google Play
                                    <!-- <span>Get It Now</span> -->
                                    <span>Coming soon</span>
                                </div>
                            </div>
                        </a>
                        <a href="#" class="other-store-link">
                            <div class="other-store-app">
                                <div class="os-app-icon">
                                    <i class="fa fa-apple theme-cl"></i>
                                </div>
                                <div class="os-app-caps">
                                    App Store
                                    <!-- <span>Now it Available</span> -->
                                    <span>Coming soon</span>
                                </div>
                            </div>
                        </a>
                    </div>
                </div>
                
            </div>
        </div>
    </div>
    
    <div class="footer-bottom">
        <div class="container">
            <div class="row align-items-center">
                
                <div class="col-lg-6 col-md-6">
                    <p class="mb-0">© {{currentYear}} DRIVERS2DAY ONLINE LTD. All Rights Reserved</p>
                </div>
                
                <div class="col-lg-6 col-md-6 text-right">
                    <ul class="footer-bottom-social">
                        <li><a href="https://www.facebook.com/drivers2day" target="_blank"><i class="fa fa-facebook"></i></a></li>
                        <li><a href="https://www.linkedin.com/company/drivers2day" target="_blank"><i class="fa fa-linkedin"></i></a></li>
                    </ul>
                </div>
                
            </div>
        </div>
    </div>
</footer>