import { Component } from '@angular/core';
import { JwtService } from './services/jwt.service';
import { DataSharingService } from './services/data-sharing.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'Drivers 2day';
  loggedIn: boolean = false;

  constructor(private dataSharingService: DataSharingService,
    private jwtService: JwtService) {

    this.loggedIn = this.jwtService.loggedIn;
    this.dataSharingService.isLoggedIn.next(this.loggedIn);

  }


  onActivate(event) {
    window.scroll(0, 0);
    //or document.body.scrollTop = 0;
    //or document.querySelector('body').scrollTo(0,0)

  }
}
