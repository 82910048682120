<div class="booking-landing" style=" margin-top: 65px;min-height: 700px;padding-top: 30px;;">

    <div class="container">

        <div [hidden]="loading" class="col-md-12"
            style="margin-top: 20px;background-color: #efefef;min-height: 204px; border: solid 1px #ccc;">

            <div class="row">

                <div class="col-md-6" style="text-align: center;">
                    <div
                        style="display: none; height: 20px;color: #a29c9c;float: right;font-size: 31px;margin-right: 20px;">
                        <span><i class="fa fa-cog" aria-hidden="true"></i></span>
                    </div>
                    <div class="col-12" style="margin: 10px;font-family: Lato-Regular;">
                        {{isDriver?driver.full_name:company.company_name}}
                    </div>
                    <div style="width: 100%;float: left;">
                        <div [hidden]="isDriver?!driver.profile_image:!company.company_logo" style="
                                background-image: url({{isDriver?driver.profile_image:company.company_logo}});
                                height: 100px;   
                                margin: 10px auto;
                                width: 100px;
                                background-size: cover;
                                border-radius: 63px;
                                background-position: center;
                                ">
                        </div>
                        <img [hidden]="isDriver?driver.profile_image:company.company_logo"
                            style=" width: 100px;margin: 10px auto;" src="../../assets/images/no-dp-2.png" />
                    </div>


                </div>
                <div class="col-md-6" style="text-align: center;">


                    <div class="form-group" style="margin-top:20px;">
                        <a *ngIf="isCompany" class="btn btn-success btn-md"
                            style="padding-left: 40px;padding-right: 40px;background-color: #337777;border-color: #337777;width: 200px;"
                            [routerLink]="['/invoice']">Go to Invoices</a>
                        <a *ngIf="isDriver" class="btn btn-success btn-md"
                            style="padding-left: 40px;padding-right: 40px;background-color: #337777;border-color: #337777;width: 200px;"
                            [routerLink]="['/payout']">Go to Payments</a>
                    </div>

                    <div class="form-group" style="margin-top:20px;">
                        <button [routerLink]="['/profile']" class="btn btn-success btn-md"
                            style="padding-left: 40px;padding-right: 40px;background-color: #337777;border-color: #337777;width: 200px;">Go
                            to Profile</button>
                    </div>

                </div>

            </div>






        </div>


        <app-today-booking style="width: 100%; margin-bottom: 50px;" ></app-today-booking>

        
        <div [hidden]="loading">

            <div class="form-group" style="margin-top: 20px;">
                <button (click)="setBookingType('UPCOMING')"
                    [style.background-color]="booking_type =='UPCOMING' ? '#337777' : (booking_type =='COMPLETED' ? '#eee' : null)"
                    [style.color]="booking_type =='UPCOMING' ? '#fff' : (booking_type =='COMPLETED' ? '#4a4a4a' : null)"
                    class="btn btn-success btn-lg"
                    style="background-color: #337777;width: 200px;font-size: 14px;border: none;border-radius: 0px;">Upcoming
                    Bookings</button>

                <button (click)="setBookingType('COMPLETED')"
                    [style.color]="booking_type =='COMPLETED' ? '#fff' : (booking_type =='UPCOMING' ? '#4a4a4a' : null)"
                    [style.background-color]="booking_type =='COMPLETED' ? '#337777' : (booking_type =='UPCOMING' ? '#eee' : null)"
                    class="btn btn-success btn-lg"
                    style="background-color: #337777;width: 200px;font-size: 14px;border: none;border-radius: 0px;">Completed
                    Bookings</button>
            </div>


        </div>


        <div  [hidden]="loading" class="row col-md-12" style="    margin: 20px 0 35px 0;
        padding: 0;">

            <ng-container *ngFor="let booking of bookings">
                <div style="border: dashed 1px #ccc;background-color: #fdfdfd;
               padding: 20px;
               margin-top: 27px;" class="col-md-12">
                    <div class="row" style="margin-top: 25px;">
                        <div class="col-12 col-sm-1 col-lg-1">
                            <label for="booking_date" class="control-label" style="font-family: Lato-Regular;">#ID</label>
                            <p class="form-control-static">{{ booking.id }}</p>
                        </div>
                        <div class="col-12 col-sm-2 col-lg-2">
                            <label for="booking_date" class="control-label"
                                style="font-family: Lato-Regular;">{{isDriver?"Company Name":"Driver Name"}}</label>
                            <p class="form-control-static">{{isDriver?booking.company_name:booking.driver_name}}</p>
                        </div>
                        
                        <div class="col-12 col-sm-9 col-lg-9">
                            <div class="row">
                                <div class="col-6 col-sm-3 col-lg-3">
                                    <label for="start_time" class="control-label" style="font-family: Lato-Regular;">Start
                                        time</label>
                                    <p class="form-control-static">{{ booking.start_time | date : 'MMM d, y h:mm a' }}</p>
                                </div>
                                <div *ngIf="!booking.hours_drived" class="col-6 col-sm-3 col-lg-3">
                                    <label for="end_time" class="control-label" style="font-family: Lato-Regular;">End
                                        time</label>
                                    <p class="form-control-static">{{ booking.end_time | date : 'MMM d, y h:mm a' }}</p>
                                </div>
                                <div *ngIf="booking.hours_drived" class="col-6 col-sm-3 col-lg-3">
                                    <label for="end_time" class="control-label" style="font-family: Lato-Regular;">Hours Drived</label>
                                    <p class="form-control-static">{{ booking.hours_drived }}</p>
                                </div>
                                <div class="col-6 col-sm-3 col-lg-3">
                                    <label for="end_time" class="control-label" style="font-family: Lato-Regular;">Hourly
                                        Rate</label>
                                    <p class="form-control-static"><i class="fa fa-gbp" style="font-size: 14px;"></i>{{
                                        booking.hourly_rate }} <br *ngIf="booking.hourly_rate!=booking.hourly_rate_night"> <span *ngIf="booking.hourly_rate!=booking.hourly_rate_night">(Night: £{{booking.hourly_rate_night}})</span></p>
                                </div>
                                <div class="col-6 col-sm-3 col-lg-3">
                                    <label for="end_time" class="control-label"
                                        style="font-family: Lato-Regular;">Status</label>
                                    <p [hidden]="!loading && isDriver && booking_type=='UPCOMING' && booking.booking_status == 'REQUESTED'"
                                        class="form-control-static">{{ booking.booking_status }}</p>
                                    <p *ngIf="!loading && isDriver && booking_type=='UPCOMING' && booking.booking_status == 'REQUESTED'"
                                        class="form-control-static">
                                        <button class="btn btn-sm"
                                            style="color: #fff; background-color: #337777;border-color: #337777;"
                                            (click)="acceptRow(booking)"><i class="fa fa-check"></i> Accept</button>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr>
                    <p *ngIf="booking.booking_status == 'COMPLETED'" style="float: right;"><strong>Total Amount: <i class="fa fa-gbp" style="font-size: 14px;"></i>{{booking.total_amount}}</strong> <!-- {{isCompany?"+VAT":""}} --></p>
                    <label class="control-label" style="text-align:left; Lato-Regular;margin-bottom: 0px;width: 100%;"><strong>Location</strong> </label><br>
                    <address style="text-align:left;display: inline-block;">
                        {{booking.location_title}}<br>
                        {{booking.location_address}}<br>
                        <a data-toggle="modal" data-target="#bookingMapModal"
                            style="color: rgb(51 119 119);
                            text-decoration: underline;
                            font-weight: bold;
                            cursor: pointer;"
                            (click)="loadMap(booking)">View Map</a>
                    </address>

                    <div *ngIf="booking.booking_status != 'COMPLETED' && booking.hirer_note" class="form-group">
                        <label class="control-label " style="font-family: Lato-Regular;margin-bottom: 0px;">Hirer Note:
                        </label>
                        <p>{{booking.hirer_note}}</p>
                    </div>
                    
                    <div *ngIf="booking.booking_status == 'COMPLETED'" class="form-group">
                        <label class="control-label" style="font-family: Lato-Regular;margin-bottom: 0px;">Rating: </label>
                        <div *ngIf="booking.rating" class="form-control-static">
                            <ul class="list-inline rating-list" 
                              *ngFor="let star of stars" style="display: inline-block" >
                                <li [ngClass]="{'selected': (star <= booking.rating)}">
                                  <i class="fa fa-star"></i>
                                </li> 
                            </ul>
                        </div>
                        <div *ngIf="!booking.rating" class="form-control-static">
                            <ul class="list-inline rating-list not-set-rating" 
                              *ngFor="let star of stars" style="display: inline-block" >
                                <li (click)="setStar(star, booking)"
                                    [ngClass]="{'selected': (star <= booking.rating)}">
                                  <i class="fa fa-star"></i>
                                </li> 
                            </ul>
                        </div>
                    </div>
                </div>
            </ng-container>
            <div *ngIf='bookings.length == 0' style="margin-top: 30px;">
                No {{booking_type=='COMPLETED'?'completed':'upcoming'}} booking to show
            </div>
            <div *ngIf='viewMoreUrl' class="view-more-button" (click)="viewMore()">
                View More
            </div>
        </div>
        <div [hidden]="!loading" style="width: 100%;background-color: #efefef;min-height: 204px;margin-top: 20px;">
            <div class="ion-padding" style="text-align: center;min-height: 100px;padding-top: 50px;">
                <img src="../../assets/images/loader.gif"
                    style=" margin-top: 10px;   width: 70px;padding-left: 20px;" />
            </div>
        </div>

    </div>
</div>


<div id="bookingMapModal" class="modal fade" role="dialog" data-keyboard="false" data-backdrop="static">
    <div class="modal-dialog">
        <div class="modal-content" style="min-height: 200px;;">

            <div class="modal-body">
                <h5 style="color: #337777;font-family: Lato-Regular;text-align: center;">Location</h5>

                <div style="text-align: center;">
                    <div #gmap style="height:300px; width:100%; min-width: 350px;">
                    </div>
                </div>

                <div class="button-group"
                    style="text-align: right;margin-bottom: -15px;margin-top: 15px;margin-right: -15px;">
                    <button type="button" id="closLocationMapModelPopup" class="btn btn-default"
                        data-dismiss="modal">Close</button>
                </div>
            </div>

        </div>
    </div>